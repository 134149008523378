import { Grid } from "@mui/material";
import React from "react";
import { messagingStyles } from "../styles/messagingStyles";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const DoctorModalSharedFileCard = ({ attachment }) => {
  return (
    <Grid
      style={{
        ...messagingStyles.doctorModalFileCard,
        width: 100,
        height: 100,
      }}
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/*
    <Grid style={{ marginTop: -40, marginRight: -60 }}>
    <RemoveCircleIcon style={{ cursor: "pointer", color: "#515563" }} />
    </Grid>
  */}
      <img
        src={attachment.imageUrl}
        alt="image"
        style={{ width: 90, height: 80 }}
      />
      {/*
      <div style={messagingStyles.doctorModalFileName}>
      {attachment?.fileName}
      </div>
    */}
    </Grid>
  );
};

export default DoctorModalSharedFileCard;
