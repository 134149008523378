import { Box, CardActions, Menu, Stack } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../../assets/colors";
import { clockCalender } from "../../../assets/icons";
import { StyledBodyText, StyledCaptionText } from "..";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import DefaultButton from "../../consultations/DefaultButton";
import PropTypes from "prop-types";
import moment from "moment";
import useUrgentCare from "../../../providers/urgent-care.provider";

const stateTimeZones = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AS: "Pacific/Pago_Pago",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  AA: "America/New_York",
  AE: "UTC",
  AP: "Pacific/Honolulu",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  DC: "America/New_York",
  FM: "Pacific/Chuuk",
  FL: "America/New_York",
  GA: "America/New_York",
  GU: "Pacific/Guam",
  HI: "Pacific/Honolulu",
  ID: "America/Denver",
  IL: "America/Chicago",
  IN: "America/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MH: "Pacific/Majuro",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  MP: "Pacific/Saipan",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PW: "Pacific/Palau",
  PA: "America/New_York",
  PR: "America/Puerto_Rico",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VI: "America/St_Thomas",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
};

const TimeRangePicker = (props) => {
  const { urgentCarePayload } = useUrgentCare();
  const { handleSubmit, value, existingDate, setExistingDate } = props;
  const [startTime, setStartTime] = useState(value);

  // console.log("value time:", startTime);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectTime = () => {
    // console.log("time:::", startTime?.$d || value);
    setAnchorEl(null);
    setExistingDate(null);
    handleSubmit(startTime?.$d || value);
  };

  console.log(existingDate);

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: `1px solid ${COLORS.DISABLED}`, pb: 1 }}
        onClick={handleClick}
      >
        <img src={clockCalender} alt="Clock" />
        <StyledCaptionText sx={{ ml: 1 }}>
          {existingDate !== null
            ? `${moment
                .tz(existingDate, stateTimeZones[urgentCarePayload?.state])
                .format("hh:mm a")} --${" "}${moment
                .tz(existingDate, stateTimeZones[urgentCarePayload?.state])
                .add({ hours: 2 })
                .format("hh:mm a")}`
            : value === null
            ? "Select Your Prefered Time"
            : startTime.$d
            ? `${moment(startTime?.$d).format("hh:mm a")} --${" "}
            ${moment(startTime?.$d).add({ hours: 2 }).format("hh:mm a")}`
            : null}
        </StyledCaptionText>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row">
            <Box>
              <StyledBodyText sx={{ textAlign: "center", mb: 2 }}>
                Enter Time
              </StyledBodyText>
              <MultiSectionDigitalClock
                views={["hours", "minutes"]}
                // value={dayjs(startTime)}
                onChange={(value) => {
                  console.log(value);
                  setStartTime(value);
                }}
              />
            </Box>
          </Stack>
        </LocalizationProvider>
        <CardActions>
          <DefaultButton variant="outlined" onClick={handleClose}>
            Cancel
          </DefaultButton>
          <DefaultButton onClick={handleSelectTime}>Done</DefaultButton>
        </CardActions>
      </Menu>
    </Box>
  );
};

TimeRangePicker.propTypes = {
  handleSubmit: PropTypes.func,
};

export default TimeRangePicker;
