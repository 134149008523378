export const Endpoints = {
  MTM_BASE_URL: "https://staging.mytelemedicine.com/go/api",
  VIRTUAL_CARE_GROUP: "https://staging.thevirtualcaregroup.com/",
  BASE_URL: 'https://staging.getlyric.com/go/api/',
  VIRTUAL_CARE_GROUP_staging: "https://staging.thevirtualcaregroup.com/",
  MTM_BASE_URL_staging: "https://staging.mytelemedicine.com/go/api",
  BASE_URL_staging: 'https://staging.getlyric.com/go/api/',

  LOGIN: "/login",
  STATES: "/states/all",
  CONSULTATIONS_HISTORY: "/consultation/getActive",
  GET_ALL_CONSULTATION: "/consultationHistory/getAllConsultations",
  POLLING: "/consultation/getActive",
  CANCEL_CONSULTATION: "consultation/inactivate",
  ALLSTATES: "/states/all",
  HEALTH_RECORDS: "/healthRecords",
  MEDICATION: "/medication",
  SURGICAL_HISTORY: "/surgicalHistory",
  MEDICAL_CONDITION: "/medicalCondition",
  MEDICATION_ALLERGY: "/medicationAllergies",
  PERSONAL_INFO: "/personalInfo",
  DOCUMENTS: "/attachment",
  SEARCH_MEDICATION: "/medicaion/search",
  MESSAGES: "/askADoctor",
  MESSAGES_WITH_ATTACHMENT: "/attachment/addAskADoctorAttachment",
  GET_ATTACHMENTS: "/attachment/stream/",
  UPDATE_MEMBER: "/memberAccount/updateMemberContactInfo",
  UPDATE_MEMBER_PASSWORD: "/memberAccount/updatePassword",
  GET_PATIENT_AND_CONSULT_OBJECT: "v2/consultation/psychiatry",
  CREATE_URGENT_CARE_CONSULTATION: "/consultation/new",
  CREATE_PSYCHIATRIST_CONSULTATION:
    "/consultation/createConsultation/psychiatry",
  GET_PROVIDERS: "/v2/consultation/availability",
  SEARCH_PHARMACY: "/pharmacy/search",
  SEARCH_ALLERGIES: "/medicationAllergies/search",
  HEALTH_RECORDS_VITALS: "/vitals",
  UPDATE_PHARMACY: "/memberAccount/addUserToSureScriptPharmacy",
  GET_ELIGIBILITY: "/v2/consultation/eligibility",
};
