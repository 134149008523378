import { Box, Grid, MenuItem, Modal } from '@mui/material'
import React from 'react'
import { healthRecordsStyles } from '../healthRecords/styles/healthRecordsStyles'
import { modalStyle } from '../../constant'
import { DefaultDropdown, DefaultTextField, StyledBodyText } from '../common'
import Dimens from '../../assets/Dimens'
import DefaultButton from './DefaultButton'
import { COLORS } from '../../assets/colors'

const EditMedicationRefillDialog = (props) => {
	const {matchesSM, matchesXS, modalOpen, handleClose, setMedicationRefill, medicationRefill, handleEditMedicationRefill} = props
  return (
	 <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        {/*animi. section*/}
        <Grid
          container
          direction={"column"}
          // justifyContent={"center"}
          alignItems={"center"}
		  rowGap={2}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 30px" }}
        >
          <div style={healthRecordsStyles.editModalTitle}>Edit Medication refill</div>
		  <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            Medication name
          </StyledBodyText>
          <DefaultTextField
            id="medicationName"
            value={medicationRefill?.medicationName}
            onChange={(e) => setMedicationRefill({...medicationRefill,medicationName: e.target.value})}
          />
<StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            Frequency
          </StyledBodyText>
          <DefaultTextField
            type={"number"}
            value={medicationRefill?.frequency}
            id="frequency"
            onChange={(e) => setMedicationRefill({...medicationRefill,frequency: e.target.value})}
          />
 <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            Comment
          </StyledBodyText>
          <DefaultTextField
            value={medicationRefill?.comment}
            id="comment"
            multiline
            minRows={2}
            onChange={(e) => setMedicationRefill({...medicationRefill ,comment: e.target.value})}
          />
<StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            Currently using
          </StyledBodyText>
          <DefaultDropdown
            showOutline
            value={medicationRefill?.currentlyUsing ? "Yes" : "No"}
            id="currentlyUsing"
            renderValue={(option) => (
              <MenuItem
                value={option.value}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  // bgcolor: option === value ? "#FFB74D" : "#fff",
                  "&:hover": {
                    bgcolor: "#FFB74D",
                  },
                }}
              >
                {option.label}
              </MenuItem>
            )}
            onChange={(e) => setMedicationRefill({...medicationRefill, currentlyUsing: e.target.value})}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
<DefaultButton
          sx={{ bgcolor: COLORS.BUTTON_SECONDARY }}
          onClick={handleEditMedicationRefill}
        >
          {"add refill"}
        </DefaultButton>
		  </Grid>
		  </Box>
		  </Modal>
  )
}

export default EditMedicationRefillDialog
