export const validateEmail = (email) => {
  const emailRegex =
    /^([A-Za-z0-9_\+\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  const result = emailRegex.test(email);
  return result;
};

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  const result = passwordRegex.test(password);
  return result;
};
