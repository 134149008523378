import { Card, Grid } from "@mui/material";
import React from "react";
import { registrationCardStyles } from "./styles/RegistrationCardStyles";
import { images } from "../../constant";

const RegistrationCardLayout = ({children}) => {
  return (
    <Card sx={registrationCardStyles.cardStyle}>
      <Grid container>
        <Grid container item style={{ width: 415, }}>
          <img
            src={images.leftSide}
            alt="Welcome image"
            style={{ width: 415, height: 758 }}
          />
        </Grid>
        <Grid container item style={{ width: 700 }}>
        {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default RegistrationCardLayout;
