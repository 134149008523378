import { clear } from "@testing-library/user-event/dist/clear";

const LocalStorageWrapper = {
  get(key) {
    try {
      const json = localStorage.getItem(key);
      if (json) {
        const value = JSON.parse(json);
        return value;
      }
      return null;
    } catch (err) {
      return null;
    }
  },

  set(key, value) {
    try {
      const json = JSON.stringify(value);
      localStorage.setItem(key, json);
      return true;
    } catch (error) {
      return false;
    }
  },

  remove(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  },
};

export const LocalStorageKeys = {
  USER: "USER",
  TOKEN: "_dgashj",
  URGENT_CARE: "_ucapp",
  URGENT_CARE_PHARMACY: "_dsahdgh",
  CONSULT_OBJ: "_dsjagdjh",
  PSYCHIATRIST_PAYLOAD: "_dsgdjh",
  CONSULT_PSY_OBJ: "_dsgdjhdksjak",
  MEDICATION_REFILL: "_dsgdjhdjsagdtywe",
  MEDICATION_ARRAY: "_dsgldkjsye",
  MEDICAL_CONDITION_ARRAY: "_trwyyshdg",
  SURGERY_ARRAY: "_rwrrewqefs",
  ALERGIES: "_eweeqesdfa",
  PHARMACY: "_ewewqewqdsascxzcx",
  INTAKE_QUESTIONAIRE_DATA: "_wqwxsadzxzxvxcvds",
};

export default LocalStorageWrapper;
