import React from "react";
import { useLocation } from "react-router-dom";
import TokVideoPageComponent from "./TokVideoPageComponent";

const TokVideoPage = () => {
  const location = useLocation();
  return <TokVideoPageComponent location={location} />;
};

export default TokVideoPage;
