import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldSection from "../TextFieldSection";
import { stateOptions } from "../../../constant";
import { useHealthRecordsContext } from "../../../providers/HealthRecordsContext";
import { useTranslation } from "react-i18next";

const CityStateZipFieldsCard = ({
  matchesSM,
  personalInfo,
  setPersonalInfo,
}) => {
  const { allStates } = useHealthRecordsContext();
  const {t} = useTranslation("translations")
  const [allStatesOptions, setAllStatesOptions] = useState([]);

  useEffect(() => {
    setAllStatesOptions(allStates);
  }, [allStates]);

  return (
    <Grid container style={{ width: matchesSM ? "100%" : "80%" }}>
      <Grid container>
        <Grid xs={5.5}>
          <TextFieldSection
            label={t("city")}
            value={personalInfo.city}
            onChange={(e) =>
              setPersonalInfo({
                ...personalInfo,
                city: e.target.value,
              })
            }
            addressFields={true}
          />
        </Grid>
        <Grid xs={3.2}>
          <TextFieldSection
            label={t("state")}
            value={personalInfo.state}
            select={true}
            options={allStatesOptions}
            stateField={true}
            onChange={(e) =>
              setPersonalInfo({
                ...personalInfo,
                state: e.target.value,
              })
            }
            setStateId={(stateId) => {
              setPersonalInfo({
                ...personalInfo,
                stateId: stateId,
              });
            }}
            addressFields={true}
          />
        </Grid>
        <Grid xs={3.3}>
          <TextFieldSection
            label={t("zip_code")}
            value={personalInfo.zip}
            onChange={(e) =>
              setPersonalInfo({
                ...personalInfo,
                zip: e.target.value,
              })
            }
            addressFields={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CityStateZipFieldsCard;
