import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DefaultTextArea = (props) => {
  const { minRows, sx, value, onChange, placeholder, error, helperText } = props;
  return (
    <FormControl fullWidth error={error}>
      <OutlinedInput
        multiline
        minRows={minRows}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        sx={{ borderRadius: "20px", fontFamilily: "Open Sans", ...sx }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

DefaultTextArea.propTypes = {
  minRows: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DefaultTextArea;
