import { Alert, Slide, Snackbar } from '@mui/material'
import React from 'react'
import useFeedback from '../../../providers/feedback.provider';

const SlideComponent = (props) => <Slide {...props} direction="down" />;

const SnackBar = () => {
	 const { openSnack, snackProps, removeSnack } = useFeedback();
	 const [severity, setSeverity] = React.useState();

	 React.useEffect(() => {
		 if (snackProps?.error) {
			 setSeverity("error");
		 } else if (openSnack) {
			 setSeverity("success");
		 }
	 }, [openSnack, snackProps?.error]);

  return (
	<Snackbar TransitionComponent={SlideComponent} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnack} autoHideDuration={3000} onClose={removeSnack}>
        <Alert onClose={removeSnack} severity={severity} sx={{ width: '100%' }}>
          {snackProps?.message}
        </Alert>
      </Snackbar>
  )
}

export default SnackBar
