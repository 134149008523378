import React from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import RegistrationCardLayout from "../../components/registration/RegistrationCardLayout";
import { Button, Grid } from "@mui/material";
import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <LoginDesignPage>
      <RegistrationCardLayout>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <span style={registrationCardStyles.titleTextStyles}>
            You’re all set!
          </span>
          <span style={registrationCardStyles.successTextStyles}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </span>
          <Button
            variant="contained"
            onClick={() => navigate("/")}
            style={registrationCardStyles.successDoneButtonStyles}
          >
            Done
          </Button>
        </Grid>
      </RegistrationCardLayout>
    </LoginDesignPage>
  );
};

export default SuccessPage;
