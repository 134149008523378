import React from "react";
import { Grid } from "@mui/material";

import { registrationCardStyles } from "../registration/styles/RegistrationCardStyles";
// import {
//   privacyPolicyArray,
//   privacyPolicyTableOfContentArray,
// } from "../../constant";
import { useTranslation } from "react-i18next";

const TermsOfServicesTab = () => {
  const { t } = useTranslation("translations");
  return (
    <Grid container direction={"column"}>
      <Grid container direction={"column"} style={mainContainerStyles}>
        {/*
      <span style={registrationCardStyles.termsOfServicesDate}>
      {t("updated_date")}
      </span>
    */}
        <Grid container direction={"column"} alignItems={"center"}>
          {/*privacy policy section start*/}
          <span style={registrationCardStyles.termsOfServicesTitle}>
            {t("privacy_policy")}
          </span>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_info_one")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_info_two")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_info_three")}
          </div>
          {/*privacy policy section end*/}

          {/*acceptance section start*/}
          <span style={registrationCardStyles.termsOfServicesTitle}>
            {t("privacy_policy_acceptance")}
          </span>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_acceptance_info")}
          </div>
          {/*acceptance section end*/}

          {/*Notice of HIPAA Privacy Practices section start*/}
          <span style={registrationCardStyles.termsOfServicesTitle}>
            {t("notice_of_hipaa_privacy_practices")}
          </span>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("notice_of_hipaa_privacy_practices_info_one")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("notice_of_hipaa_privacy_practices_info_two")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("notice_of_hipaa_privacy_practices_info_three")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("notice_of_hipaa_privacy_practices_info_four")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            <b>{t("privacy_policy_treatment")}</b>{" "}
            {t("privacy_policy_treatment_info")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            <b>{t("privacy_policy_payment")}</b>{" "}
            {t("privacy_policy_payment_info")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            <b>{t("privacy_policy_health_care_operations")}</b>{" "}
            {t("privacy_policy_health_care_operations_info_one")}
          </div>

          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_health_care_operations_info_two")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_health_care_operations_info_three")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_health_care_operations_info_four")}
          </div>
          <div
            style={{
              ...termsOfServicesText,
            }}
          >
            {t("privacy_policy_health_care_operations_info_five")}
          </div>
          <div style={termsOfServicesText}>
            <ul>
              <li>{t("privacy_policy_health_care_operations_rights_one")}</li>
              <li>{t("privacy_policy_health_care_operations_rights_two")}</li>
              <li>{t("privacy_policy_health_care_operations_rights_three")}</li>
              <li>{t("privacy_policy_health_care_operations_rights_four")}</li>
              <li>{t("privacy_policy_health_care_operations_rights_five")}</li>
            </ul>
          </div>
          {/*Notice of HIPAA Privacy Practices section end*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsOfServicesTab;

const mainContainerStyles = {
  padding: 40,
  background: "#fff",
  borderRadius: 20,
  width: "100%",
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  // width: 720,
  // marginRight: -30,
};
