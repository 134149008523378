import { Divider, Grid } from "@mui/material";
import React, { Fragment } from "react";
import { messagingStyles } from "./styles/messagingStyles";
import moment from "moment";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTranslation } from "react-i18next";

const MessageCard = ({ message, onMessageClick, matchesSM }) => {
  const { t } = useTranslation("translations");
  return (
    <Fragment>
      <Grid
        style={{
          ...messagingStyles.messageCard,
          width: matchesSM ? "100%" : "80%",
          background: "#fff",
          // background:
          //   message && message.whenRead && message.whenRead !== ""
          //     ? "#fff"
          //     : "#FFF2E1",
        }}
        container
        direction={"column"}
        onClick={() => onMessageClick(message.askADoctorThread_id)}
      >
        <div style={messagingStyles.messageCardDate}>
          {" "}
          {message &&
            message.lastUpdatedDate &&
            moment(message.lastUpdatedDate).format("MMM DD, hh:mm a")}
        </div>
        <div style={messagingStyles.messageCardQuestion}>
          {" "}
          {message && message.subject && message.subject}
        </div>
        <div style={messagingStyles.messageCardDoctor}>
          {" "}
          {message && message.doctor ? (
            message.doctor
          ) : (
            <Grid container alignItems={"center"}>
              {t("waiting_for_doctor")}{" "}
              <AutorenewIcon fontSize="small" style={{ marginLeft: 5 }} />
            </Grid>
          )}
          {/*
            <Grid
            container
            style={{
              ...messagingStyles.messageCardStatus,
              background:
                message && message.newReply && message.newReply > 0
                  ? "#FFF2E1"
                  : "#fff",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {message && message.newReply && message.newReply > 0
              ? message.newReply
              : undefined}
            //{message && message.totalCount && message.totalCount}
             </Grid>
        */}
        </div>
      </Grid>
      <Divider style={messagingStyles.messageCardDivider} />
    </Fragment>
  );
};

export default MessageCard;
