import React from "react";
import { Divider, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { images, modalStyle } from "../../constant";
import { messagingStyles } from "./styles/messagingStyles";
import DoctorModalSharedFileCard from "./newQuestion/DoctorModalSharedFileCard";

const DoctorCard = ({ handleClose }) => (
  <Grid container justifyContent={"space-between"}>
    <Grid item container xs>
      <img
        src={images.doctorIcon}
        style={{ width: 60, height: 60 }}
        alt="doctor"
      />
      <Grid
        container
        direction={"column"}
        style={{ width: "50%", marginLeft: 15, padding: 5 }}
        justifyContent={"space-between"}
      >
        {/*
      <div style={messagingStyles.doctorModalTitle}>Dr. Name</div>
      <div style={messagingStyles.doctorModalSubtitle}>speciality</div>
    */}
      </Grid>
    </Grid>
    <Grid item container xs justifyContent={"flex-end"}>
      <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
    </Grid>
  </Grid>
);

const DoctorDetailsModal = ({
  modalOpen,
  handleClose,
  matchesSM,
  matchesXS,
  attachments,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 682,
          p: 0,
        }}
      >
        <Grid
          container
          direction={"column"}
          style={{ padding: matchesSM || matchesXS ? "20px" : "20px 30px" }}
        >
          <DoctorCard handleClose={handleClose} />
          <Divider style={messagingStyles.doctorModalDivider} />
          {/*
          <div style={messagingStyles.doctorModalTitle}>About</div>
          <div style={messagingStyles.doctorModalSubtitle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus nulla
            amet non facilisi risus. Sed vel at non pellentesque et. At neque,
            suspendisse odio lorem nisl suspendisse quisque. Maecenas sed
            sollicitudin volutpat odio urna. Turpis mauris nec fermentum turpis
            sed.Dui luctus nisl euismod ultrices in. Lorem eget dolor purus enim
            a. Viverra nec nisi ipsum sapien.
          </div>
        */}
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          style={messagingStyles.doctorModalSharedFilesCard}
        >
          <div style={messagingStyles.doctorModalTitle}>Shared files</div>
          <Grid container item style={{ marginTop: 20 }}>
            {attachments &&
              attachments.map((attachment, index) => (
                <DoctorModalSharedFileCard
                  attachment={attachment}
                  key={index}
                />
              ))}
            {/*
              <DoctorModalSharedFileCard />
              <DoctorModalSharedFileCard />
            */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DoctorDetailsModal;
