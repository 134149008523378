export const language = [
	{
		id: 1,
		isoCode: '620',
		name: 'Español',
		phoneCode: '+34',
		shortName: 'es',
		language: 'es',
		code: 'ES',
	},
	{
		id: 2, isoCode: '620', name: 'English', phoneCode: '44', shortName: 'us', language: 'en', code: 'enUS',
	},
	{
		id: 3, isoCode: '620', name: 'Português', phoneCode: '351', shortName: 'pt', language: 'pt', code: 'ptPt',
	},
	{
		id: 4, isoCode: '156', name: '中國人', phoneCode: '156', shortName: 'cn', language: 'cn', code: 'cn',
	},
	{
		id: 5, isoCode: '156', name: 'Français', phoneCode: '33', shortName: 'fr', language: 'fr', code: 'fr',
	},
	{
		id: 6, isoCode: '156', name: 'Русский', phoneCode: '7', shortName: 'ru', language: 'ru', code: 'ru',
	},
	{
		id: 7, isoCode: '156', name: '日本語', phoneCode: '81', shortName: 'jp', language: 'jp', code: 'jp',
	},
	{
		id: 8, isoCode: '156', name: 'हिंदी', phoneCode: '81', shortName: 'in', language: 'in', code: 'in',
	},
	{
		id: 8, isoCode: '156', name: 'বাংলা', phoneCode: '880', shortName: 'bd', language: 'bd', code: 'bd',
	},
	{
		id: 8, isoCode: '156', name: 'ਪੰਜਾਬੀ (ਭਾਰਤ)', phoneCode: '81', shortName: 'in', language: 'pn', code: 'in',
	}
]

// Arabic
// Punjabi