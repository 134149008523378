import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { healthRecordsStyles } from "../styles/healthRecordsStyles";

const MedicationTextfield = ({
  title,
  placeholder,
  value,
  onChange,
  multiline,
  rows,
  select,
  MedicalHistory,
  type,
}) => {
  const { t } = useTranslation("translations");

  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        marginBottom: 30,
      }}
    >
      <div style={healthRecordsStyles.textfieldsTitle}>{title} </div>
      <TextField
        placeholder={placeholder}
        variant="standard"
        value={value}
        multiline={multiline}
        rows={rows}
        type={type}
        onChange={(e) => onChange(e)}
        inputProps={{
          sx: healthRecordsStyles.medicationTextFieldSX,
        }}
        InputLabelProps={{
          sx: healthRecordsStyles.medicationInputLabelSX,
        }}
        select={select}
      >
        {select && (
          <MenuItem
            value={MedicalHistory ? 1 : t("Yes")}
            style={{
              ...healthRecordsStyles.selectMenuItem,
              background: value === "Yes" || value === 1 ? "#FFB74D" : "#fff",
            }}
          >
            {MedicalHistory ? t("Currently in such condition") : t("Yes")}
          </MenuItem>
        )}
        {select && (
          <MenuItem
            value={MedicalHistory ? 2 : t("No")}
            style={{
              ...healthRecordsStyles.selectMenuItem,
              background: value === "No" || value === 2 ? "#FFB74D" : "#fff",
            }}
          >
            {MedicalHistory ? t("Had condition in the past") : t("No")}
          </MenuItem>
        )}
      </TextField>
    </Grid>
  );
};

export default MedicationTextfield;
