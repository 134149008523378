import { Button, Grid } from "@mui/material";
import React from "react";
import { helpCenterCardStyles } from "./styles/HelpCenterStyles";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { useTranslation } from "react-i18next";

const TopHeader = ({ navigate }) => {
  const { t } = useTranslation("translations");
  return (
    <Grid
      container
      alignItems={"center"}
      // style={{ marginTop: 10, paddingLeft: 30, paddingRight: 30 }}
    >
      <Grid xs>
        <Grid container alignItems={"center"}>
          <ArrowBackIosOutlinedIcon
            onClick={() => navigate(-1)}
            style={helpCenterCardStyles.backIconStyles}
          />
          <div
            onClick={() => navigate(-1)}
            style={helpCenterCardStyles.backTextStyles}
          >
            {t("back")}
          </div>
        </Grid>
      </Grid>
      <Grid xs container justifyContent={"end"}>
        <Button
          variant="contained"
          style={helpCenterCardStyles.contactUsButtonStyles}
        >
          {t("contact_us")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TopHeader;
