import React from "react";
import { Grid, TextField, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { healthRecordsStyles } from "../../healthRecords/styles/healthRecordsStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none", // Remove default border
      },
    },
    "& textarea": {
      resize: "vertical", // Allow vertical resizing
      minHeight: "80px", // Adjust the minimum height as desired
      paddingTop: 0,
    },
  },
}));

const QuestionTextField = ({
  title,
  placeholder,
  value,
  onChange,
  multiline,
  rows,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        marginBottom: 22,
      }}
    >
      <div style={healthRecordsStyles.textfieldsTitle}>{title} </div>
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={value}
        multiline={multiline}
        fullWidth
        rows={rows}
        onChange={(e) => onChange(e)}
        classes={{ root: classes.root }}
        style={{ width: "92%" }}
        inputProps={{
          sx: healthRecordsStyles.medicationTextFieldSX,
        }}
        InputLabelProps={{
          sx: healthRecordsStyles.medicationInputLabelSX,
        }}
      />
      {multiline ? undefined : (
        <Divider style={{ width: "92%", backgroundColor: "#19A6FE" }} />
      )}
    </Grid>
  );
};

export default QuestionTextField;
