import { Box, CircularProgress, Stack } from "@mui/material";
import React from "react";
import {
  ConsultationListCard,
  NoAppointmentText,
} from "../../components/consultations";
import ListItemHidden from "../../components/consultations/ListItemHidden";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import Applinks from "../../assets/route-links";

const InProgress = (props) => {
  const { consultations, loading, handleJoinAppointment } = props;
  const { t } = useTranslation("translations");
  const navigate = useNavigate();

  console.log("consultations::", consultations);

  if (loading)
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  return (
    <Box>
      {consultations && consultations.length === 0 && (
        <NoAppointmentText label={t("you_have_no_inprogress_appointments")} />
      )}
      {consultations.map((consultation) => (
        <ConsultationListCard
          consultation={consultation}
          hiddenItem={
            <ListItemHidden
              comment={consultation.comments}
              reason={consultation.reason}
              showJoinAppointment={
                consultation.primaryPatientToken && consultation.videoSessionId
              }
              // onJoinAppointmentClick={() => {handleJoinAppointment(consultation)}}
              onJoinAppointmentClick={() => {
                navigate(`${Applinks.VIDEO_CALL_SCREEN}`, {
                  state: {
                    token: consultation.primaryPatientToken,
                    sessionId: consultation.videoSessionId,
                    apiKey: consultation.openTokApiKey,
                  },
                });
                // navigate(`${Applinks.VIDEO_CALL_SCREEN}`);
                //   navigate(`${Applinks.VIDEO_CALL_SCREEN}?${createSearchParams({
                //     token: consultation.primaryPatientToken,
                //     sessionId: consultation.videoSessionId,
                //     apiKey: consultation.openTokApiKey,
                // })}`);
              }}
              showCancelAppointment={consultation.status === "New"}
            />
          }
        />
      ))}
    </Box>
  );
};

export default InProgress;
