import { Stack, Checkbox, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import Dimens from "../../../assets/Dimens";

const CheckBox = (props) => {
  const { checked, onChange, sx, children, center } = props;
  return (
    <Stack
      direction="row"
      alignItems={center ? "center" : "flex-start"}
      sx={{ mt: 2 }}
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        color="default"
        disableRipple
        checkedIcon={<CheckBoxOutlined />}
        icon={<CheckBoxOutlineBlank />}
      />
      <Typography
        sx={{
          fontFamily: "Open Sans",
          fontWeight: Dimens.FONT_WEIGHT_LIGHT,
          fontSize: Dimens.FONT_SIZE_SMALL,
          lineHeight: Dimens.LINE_HEIGHT_SMALL,
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
};

CheckBox.protoTypes = {
  checked: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  center: PropTypes.bool,
};

export default CheckBox;
