import axios from "axios";
import LocalStorageWrapper, { LocalStorageKeys } from "../utils/storage.utils";


export const mtmRequest = async (config) => {
  const bearerToken = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);
  
  const $axios = axios.create({baseURL: process.env.REACT_APP_MTM_BASE_URL});
  config.headers = {
    ...config.headers,
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    ...(bearerToken
      ? {
          Authorization: bearerToken,
        }
      : {}),
  };

  $axios.interceptors.response.use(async (response) => {
    const { data } = response;
    if (data.message === "Token Expired") {
      LocalStorageWrapper.clear();
      window.location.reload();
    } else {
      return response;
    }
  });

  const returnValue = await $axios({ ...config });
  return returnValue;
};

export const mtmReqGet = async (config) =>
  mtmRequest({
    method: "GET",
    ...config,
  });

export const mtmReqPost = async (config) =>
  mtmRequest({
    method: "POST",
    ...config,
  });

export const mtmReqDelete = async (config) =>
  mtmRequest({
    method: "DELETE",
    ...config,
  });
