import React from "react";
import GoogleMapReact from "google-map-react";
import i18next from "i18next";

// const GOOGLE_API_KEY = "AIzaSyDJm5aqTmyT5OEYqUiDIy6JJnsUtN2OpvQ";

const Map = (props) => {
  const { children, center, zoom } = props;
  const lang = i18next.language.substring(0, 2) 
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GOOGLE_API_KEY,
        language: lang,
      }}
      center={center} // Use dynamic center
      zoom={zoom} // Use dynamic zoom
      // defaultCenter={center}
      // defaultZoom={5}
    >
      {children}
    </GoogleMapReact>
  );
};

export default Map;
