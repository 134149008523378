import { Grid } from "@mui/material";
import React from "react";
import { settingsStyles } from "../styles/SettingsStyles";

const PasswordRequirements = () => {
  return (
    <Grid
      container
      direction={"column"}
      style={{
        width: 360,
        // height: 195,
        borderRadius: 10,
        padding: "30px 20px 35px",
        boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
        zIndex: 2,
        position: "absolute",
        backgroundColor: "#FFFFFF",
        padding: 10,
        // marginTop: 20,
      }}
    >
      <span style={settingsStyles.passwordReqTitle}>Password Requirements</span>
      <li>
        <span style={settingsStyles.passwordReqItem}>
          Passwords Must Be At Least 8 Characters In Length
        </span>
      </li>
      <li>
        <span style={settingsStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Uppercase Letter
        </span>
      </li>
      <li>
        <span style={settingsStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Lowercase Letter
        </span>
      </li>
      <li>
        <span style={settingsStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Symbol
        </span>
      </li>
      <li>
        <span style={settingsStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Number
        </span>
      </li>
    </Grid>
  );
};

export default PasswordRequirements;
