import React from "react";
import { Button, Checkbox, Grid } from "@mui/material";

import RegistrationCardLayout from "./RegistrationCardLayout";
import { registrationCardStyles } from "./styles/RegistrationCardStyles";
import { loginCardStyles } from "../login/styles/LoginCardStyles";
import ButtonLoader from "../ButtonLoader";
import ActivateAccountTextFieldsSection from "./ActivateAccountTextFields";
import { useNavigate } from "react-router-dom";

const ActivateAccountCard = ({
  newUser,
  setNewUser,
  loading,
  handleActivateAccount,
}) => {
  const navigate = useNavigate();
  return (
    <RegistrationCardLayout>
      <Grid container direction={"column"}>
        <form style={registrationCardStyles.formStyle} noValidate>
          <span style={registrationCardStyles.titleTextStyles}>
            Activate your account
          </span>
          {/* Text fields section starts*/}
          <ActivateAccountTextFieldsSection
            newUser={newUser}
            setNewUser={setNewUser}
          />
          {/* Text fields section ended*/}
          {/* Register Button area  */}
          {loading ? (
            <Grid style={{ marginTop: 65 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                newUser.name &&
                newUser.dob &&
                newUser.zipCode &&
                newUser.language
                  ? true
                  : false
              }
              style={
                newUser.name ||
                newUser.dob ||
                newUser.zipCode ||
                newUser.language
                  ? registrationCardStyles.buttonStyle
                  : registrationCardStyles.buttonDisabledStyle
              }
              onClick={handleActivateAccount}
            >
              Register
            </Button>
          )}
          {/* Remember me area  */}
          <Grid
            container
            style={{ marginTop: 28, width: 376 }}
            alignItems={"center"}
          >
            <Checkbox
              // checked={checked}
              // onChange={handleChange}
              defaultChecked
              color="default"
              sx={{
                color: "#F8F8F8",
                width: 24,
                height: 24,
                // border: "1px solid #19A6FE",
                "&.Mui-checked": {
                  color: "#19A6FE",
                },
              }}
            />{" "}
            <span
              style={{
                color: "#515563",
                fontSize: 14,
                fontWeight: 400,
                // width: 290,
                marginLeft: 10,
              }}
            >
              I agree to the{" "}
              <a
                style={{ cursor: "pointer", color: "#001011" }}
                onClick={() => navigate("/terms-of-services")}
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                style={{ cursor: "pointer", color: "#001011" }}
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </a>
            </span>
          </Grid>
          {/* Need help area  */}
          <span
            style={{
              color: "#000000",
              fontSize: 14,
              fontWeight: 400,
              marginTop: 70,
            }}
          >
            Need Help?{" "}
            <Button
              style={loginCardStyles.resetPasswordStyles}
              onClick={() => navigate("/help-center")}
            >
              Contact Us
            </Button>
          </span>
        </form>
      </Grid>
    </RegistrationCardLayout>
  );
};

export default ActivateAccountCard;
