import { Stack, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Dimens from "../../../assets/Dimens";
import Applinks from "../../../assets/route-links";
import {
  DefaultRadioButton,
  DefaultTextField,
  Header,
  StyledBodyText,
  StyledCaptionText,
  StyledCard,
} from "../../../components/common";
import { SymptomCheckLabel } from "../../../components/consultations";
import DefaultButton from "../../../components/consultations/DefaultButton";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import MainPageLayout from "../../MainPageLayout";
import { useTranslation } from "react-i18next";

const IntakeQuestionaire = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const { intakeQuestionaireData, setIntakeQuestionaireData } =
    usePsychiatrist();

  const handleContinueClick = () => {
    navigate(Applinks.SCHEDULE_PSYCHIATRIST_DATE_TIME);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // console.log("intakeQuestionaireData", intakeQuestionaireData);

  const handleMultipleChoiceClick = (value) => {
    const updatedIntakeQuestionaireData = {
      ...intakeQuestionaireData,
      questions: intakeQuestionaireData?.questions?.map((el) => {
        if (el?.question === value?.question) {
          return {
            ...el,
            choices: el?.choices?.map((sympthom) => {
              if (sympthom?.choice === value?.choice) {
                return {
                  ...sympthom,
                  checked: !sympthom?.checked,
                };
              }
              return sympthom;
            }),
          };
        }
        return el;
      }),
    };
    setIntakeQuestionaireData(updatedIntakeQuestionaireData);
  };

  const handleSingleChoiceClick = (question, value) => {
    const updatedIntakeQuestionaireData = {
      ...intakeQuestionaireData,
      questions: intakeQuestionaireData?.questions?.map((el) => {
        if (el?.question === question) {
          return {
            ...el,
            value: value,
          };
        }
        return el;
      }),
    };
    setIntakeQuestionaireData(updatedIntakeQuestionaireData);
  };

  const handleTextFieldChange = (question, symptom, value) => {
    const updatedIntakeQuestionaireData = {
      ...intakeQuestionaireData,
      questions: intakeQuestionaireData?.questions?.map((el) => {
        if (el?.question === question) {
          return {
            ...el,
            value: value,
          };
        }
        return el;
      }),
    };
    setIntakeQuestionaireData(updatedIntakeQuestionaireData);
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header title={t("intake_questionaire")} handleGoBack={handleGoBack} />
        {intakeQuestionaireData?.questions?.map((el) => (
          <StyledCard sx={{ mt: 4, pl: 4, pt: 2 }} elevation={0}>
            <StyledBodyText>{t(el?.question)}</StyledBodyText>
            {el?.type === "multi-choice" && (
              <StyledCaptionText sx={{ textDecoration: "underline" }}>
                {t("check_all_that_apply")}
              </StyledCaptionText>
            )}
            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={2}
              useFlexGap
              sx={{ mt: 1 }}
            >
              {el?.type === "multi-choice" &&
                el?.choices?.map((sympthom) => (
                  <>
                    {sympthom?.type === "checkbox" && (
                      <SymptomCheckLabel
                        sympthom={t(sympthom?.choice)}
                        checked={sympthom.checked}
                        onChange={() =>
                          handleMultipleChoiceClick({
                            question: el.question,
                            choice: sympthom.choice,
                          })
                        }
                      />
                    )}
                    {sympthom?.type === "textarea" && (
                      <Box sx={{ flexBasis: "100%", order: 1 }}>
                        <StyledBodyText
                          sx={{ fontSize: Dimens.FONT_SIZE_SMALL, mt: 2 }}
                        >
                          {t("provide_an_explanation")}
                        </StyledBodyText>
                        <DefaultTextField
                          multiline
                          minRows={3}
                          sx={{ mt: 1 }}
                          placeholder={t("add_details_here")}
                          value={el?.value}
                          onChange={(e) =>
                            handleTextFieldChange(
                              el.question,
                              sympthom.choice,
                              e.target.value
                            )
                          }
                        />
                      </Box>
                    )}
                  </>
                ))}
              {el?.type === "single-choice" && (
                <DefaultRadioButton
                  negativeLabel={t(el?.choices[1]?.choice)}
                  positiveLabel={t(el?.choices[0]?.choice)}
                  value={el?.value}
                  onChange={(e) =>
                    handleSingleChoiceClick(el.question, e.target.value)
                  }
                />
              )}
            </Stack>
          </StyledCard>
        ))}
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <DefaultButton onClick={handleContinueClick}>
            {t("continue")}
          </DefaultButton>
        </Stack>
      </Box>
    </MainPageLayout>
  );
};

export default IntakeQuestionaire;
