import { ErrorOutlined } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../assets/colors";
import Dimens from "../../assets/Dimens";
import {
  StyledBodyText,
  StyledCaptionText,
} from "../common";
import DefaultButton from "./DefaultButton";
import PropTypes from "prop-types";
import MedicationRefilItem from "./MedicationRefilItem";
import AutoComplete from "../common/autocomplete/AutoComplete";
import { useTranslation } from "react-i18next";

const AlergyForm = (props) => {
  const {
    handleSubmit,
    alergyArray,
    onDeleteClick,
    onEditClick,
    onSearchAllergy,
    alergiesResp,
    clearAllergyForm
  } = props;
  const {t} = useTranslation("translations")
  const [alergies, setAlergies] = useState({});

  const [query, setQuery] = useState("");

  
  useEffect(() => {
    clearAllergyForm.current = clearForm
  }, [])

  const clearForm = () => {
    setAlergies("")
  }

  useEffect(() => {
    if (Object.keys(alergies).length > 0) {
      setAddRefillDisabled(false);
    } else {
      setAddRefillDisabled(true);
    }
  }, [alergies]);

  useEffect(() => {
    onSearchAllergy(query);
  }, [query]);

  const [addRefillDisabled, setAddRefillDisabled] = useState(false);

  const handleChange = (value) => {
    setAlergies(value);
  };

  return (
    <Box>
      <StyledBodyText sx={{ mt: 2 }}>{t("medication_allergies")}</StyledBodyText>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          pt: 1,
          pb: 1,
          pl: 1,
          bgcolor: COLORS.PRIMARY_MAIN,
          borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
        }}
      >
        <Stack direction="row" alignItems="center">
          <ErrorOutlined />
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, ml: 2 }}>
            {t("add_one_allergy_time")}
          </StyledCaptionText>
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("medication_name")}
          </StyledBodyText>
          <AutoComplete
            options={alergiesResp?.data?.suggestions || []}
            label={t("allergy")}
            onInputChange={(text) => setQuery(text)}
            placeholder={t("allergy")}
            value={alergies}
            renderOption={(autoCompleteProps, option) => (
              <l1 {...autoCompleteProps} key={option.code}>
                {option.medicationAllergyName}
              </l1>
            )}
            getOptionLabel={(option) => option.medicationAllergyName || ''}
            onChange={(e, value) => handleChange(value)}
          />
        </Grid>
      </Grid>
      <Stack alignItems={"center"} sx={{ mt: 2, mb: 3 }}>
        <DefaultButton
          sx={{ bgcolor: COLORS.BUTTON_SECONDARY }}
          disabled={addRefillDisabled}
          onClick={() => handleSubmit(alergies)}
        >
          {t("add_allergy")}
        </DefaultButton>
      </Stack>
      {alergyArray?.map((el, index) => (
        <MedicationRefilItem
          key={el.userMedicationAllergy_id}
          medicationName={el.allergymedicationname}
          onDeleteClick={() => onDeleteClick(index)}
          onEditClick={() => onEditClick(index)}
        />
      ))}
    </Box>
  );
};
AlergyForm.propTypes = {
  handleSubmit: PropTypes.func,
  alergyArray: PropTypes.array,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  aleriesResponse: PropTypes.array,
  onSearchAllergy: PropTypes.func,
  alergiesResp: PropTypes.object,
  clearAllergyForm: PropTypes.object
};

export default AlergyForm;
