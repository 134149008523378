import { AppBar, Box, Grid, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { images } from "../../constant";
import { layoutStyles } from "./styles/LayoutStyles";

// import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import useAuth from "../../providers/auth.provider";
import { AlertDialog } from "../common";
import LanguageSelector from "../common/language-selector/LanguageSelector";
import usePsychiatrist from "../../providers/psychiatrist-provider";
import useUrgentCare from "../../providers/urgent-care.provider";

const Header = ({ matchesXS, matchesSM, matchesMD, matchesXl, matchesLG }) => {
  const { clearUser } = useAuth();
  const { removeFromLocal: removeFromLocalPsych, psychiatristPayload } =
    usePsychiatrist();
  const { removeFromLocal: removeFromLocalUrgent, urgentCarePayload } =
    useUrgentCare();

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const handleLogout = () => {
    // console.log("p payload:",psychiatristPayload)
    // console.log("urgent care::", urgentCarePayload);

    // remove saved local states/Data from Psychiatrist consultation
    removeFromLocalPsych();
    // remove saved local states/Data from Urgent Care consultation
    removeFromLocalUrgent();
    clearUser();
  };

  const showLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <AppBar
      position="relative"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "none",
        boxShadow: "none",
      }}
    >
      <Stack
        direction={"row"}
        style={{ height: 112 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          item
          // container
          xs
          style={
            {
              // marginLeft: matchesXS
              //   ? 10
              //   : matchesSM
              //   ? 20
              //   : matchesMD
              //   ? 50
              //   : matchesLG
              //   ? 80
              //   : matchesXl
              //   ? 108
              //   : 10,
              //   background: "red"
            }
          }
        >
          <Grid
            container
            justifyContent={"center"}
            style={{
              paddingLeft: matchesSM ? 10 : undefined,
              width:matchesXl ? 300 : matchesLG ? 270 : matchesMD ? 200 : undefined,
              // width:matchesXl ? 440 : matchesLG ? 270 : matchesMD ? 200 : undefined,
              // backgroundColor: "red",
            }}
          >
            <img
              src={images.logoIcon}
              alt="Logo icon"
              style={{
                width: matchesXS ? 120 : 133,
                height: 43,
                // marginLeft: matchesXS ? 10 : 500,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <Stack
            item
            container
            xs
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            style={{
              marginRight: matchesXS
                ? 10
                : matchesSM
                ? 20
                : matchesMD
                ? 50
                : matchesLG
                ? 80
                : matchesXl
                ? 101
                : 10,
            }}
          >
            {/*
        <Stack direction={"row"} alignItems={"center"}>
              <Box sx={{ display: { md: "block", xs: "none" }, mr: 1 }}>
                <LanguageSelector />
              </Box>
        */}
            <Box sx={{ display: { md: "block" }, mr: 1 }}>
              <LanguageSelector />
            </Box>
            {/*Notification counts section commented out because we don't have its apis*/}
            {/*
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              style={{
                ...layoutStyles.notificationMainBox,
                marginRight: matchesXS ? 10 : undefined,
                padding: matchesXS ? 4 : undefined,
              }}
            >
              <NotificationsIcon
                fontSize="medium"
                style={{ color: "#515563" }}
              />
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  ...layoutStyles.notificationCountBox,
                  marginLeft: matchesXS ? 7 : undefined,
                }}
              >
                0
              </Grid>
            </Grid>
          */}
            {/*
          </Stack>
        */}
            <IconButton sx={{ m: 0, p: 0 }} onClick={showLogoutModal}>
              <ExitToAppIcon style={layoutStyles.logoutIcon} />
            </IconButton>
          </Stack>
        </Grid>
      </Stack>
      <AlertDialog
        modalOpen={logoutModalOpen}
        handleClose={closeLogoutModal}
        title={"Log Out"}
        icon={null}
        description={"You are logging out of VGC. Do you want to proceed?"}
        primaryButtonText={"yes"}
        secondaryButtonText={"no"}
        primaryButtonAction={handleLogout}
        secondaryButtonAction={closeLogoutModal}
      />
    </AppBar>
  );
};

export default Header;
