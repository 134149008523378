import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { modalStyle, stateOptions } from "../../../constant";
import { settingsStyles } from "../styles/SettingsStyles";
import TextFieldSection from "../TextFieldSection";

import InfoSharpIcon from "@mui/icons-material/InfoSharp";

const AddPharmacyModal = ({
  modalOpen,
  handleClose,
  newPharmacy,
  setNewPharmacy,
  matchesSM,
  matchesMD,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM ? "65%" : matchesMD ? "80%" : 774,
          p: 6,
        }}
      >
        <Grid container direction={"column"}>
          {/*title section started*/}
          <Grid container justifyContent={"center"}>
            <div style={settingsStyles.componentMainTitle}>
              Add New Pharmacy
            </div>
          </Grid>
          {/*title section ended here*/}

          {/*Text fields section started here*/}
          <Grid
            container
            spacing={{ xs: 0, md: 1 }}
            columns={{ xs: 4, sm: 4, md: 8 }}
            style={{ marginTop: 4 }}
          >
            <Grid item xs={4} sm={4} md={4}>
              <TextFieldSection
                label="Fax"
                value={newPharmacy.fax}
                onChange={(e) =>
                  setNewPharmacy({
                    ...newPharmacy,
                    fax: e.target.value,
                  })
                }
                pharmacyModal={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextFieldSection
                label="Address"
                value={newPharmacy.address}
                onChange={(e) =>
                  setNewPharmacy({
                    ...newPharmacy,
                    address: e.target.value,
                  })
                }
                pharmacyModal={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextFieldSection
                label="Name"
                value={newPharmacy.name}
                onChange={(e) =>
                  setNewPharmacy({
                    ...newPharmacy,
                    name: e.target.value,
                  })
                }
                pharmacyModal={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <PhoneInput
                // country={"us"}
                placeholder="Phone Number"
                onChange={(value) =>
                  setNewPharmacy({ ...newPharmacy, primaryPhone: value })
                }
                containerStyle={phoneContainerStyles}
                inputStyle={phoneInputStyles}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextFieldSection
                label="City"
                value={newPharmacy.city}
                onChange={(e) =>
                  setNewPharmacy({
                    ...newPharmacy,
                    city: e.target.value,
                  })
                }
                pharmacyModal={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Grid container>
                <Grid xs={5}>
                  <TextFieldSection
                    label={"State"}
                    value={newPharmacy.state}
                    select={true}
                    options={stateOptions}
                    onChange={(e) =>
                      setNewPharmacy({
                        ...newPharmacy,
                        state: e.target.value,
                      })
                    }
                    pharmacyModal={true}
                  />
                </Grid>
                <Grid xs={6.5} style={{ marginLeft: "auto" }}>
                  <TextFieldSection
                    label={"Zip Code"}
                    value={newPharmacy.zip}
                    onChange={(e) =>
                      setNewPharmacy({
                        ...newPharmacy,
                        zip: e.target.value,
                      })
                    }
                    pharmacyModal={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*Text fields section ended here*/}
          {/*Grren card text section started*/}
          <Grid
            container
            alignItems={"center"}
            style={{
              marginTop: 4,
              background: "#83CFFE",
              borderRadius: 20,
              padding: 15,
            }}
          >
            <InfoSharpIcon fontSize="small" />
            <div style={{ marginLeft: 10 }}>
              There may be a delay in filling your prescription with pharmacies
              that don't accept e-prescribing
            </div>
          </Grid>
          {/*Grren card text section ended*/}
          {/*Buttons section started*/}
          <Grid
            container
            direction={"column"}
            style={{ marginTop: 40 }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              style={addButtonStyles}
              onClick={handleClose}
            >
              Add Pharmacy
            </Button>
            <Button
              variant="text"
              style={cancelButtonStyles}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
          {/*Buttons section ended*/}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddPharmacyModal;

const phoneContainerStyles = {
  width: "100%",
  height: 60,
  borderBottom: "3px solid #515563",
  marginBottom: 30,
};

const phoneInputStyles = {
  height: 60,
  width: "100%",
  background: "#FFFAF5",
  border: "none",
  borderRadius: "10px 10px 0px 0px",
};

const addButtonStyles = {
  width: "50%",
  borderRadius: 60,
  background: "#19A6FE",
  height: 50,
  color: "#000",
};

const cancelButtonStyles = {
  width: "50%",
  borderRadius: 60,
  height: 50,
  marginTop: 10,
  color: "#0D3B66",
};
