import React, { Fragment } from "react";
import { Divider, Grid } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import { settingsStyles } from "../styles/SettingsStyles";
import PropTypes from "prop-types";

const PharmacyDetailsCard = (props) => {
  const { pharmacyTitle, pharmacyPhone, pharmacyAddress, state, handleClick } =
    props;
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={10} direction={"column"} container>
          <Grid container>
            <LocationOnIcon fontSize="small" style={{ marginRight: 10 }} />{" "}
            <div style={settingsStyles.passwordReqTitle}>{pharmacyTitle}</div>
            <div
              style={{
                color: "#515563",
                fontSize: 14,
                fontWeight: 400,
                marginLeft: "auto",
                paddingRight: 30,
              }}
            >
              {pharmacyPhone}
            </div>
          </Grid>
          <div
            style={{
              color: "#515563",
              fontSize: 14,
              fontWeight: 400,
              paddingLeft: 30,
            }}
          >
            {pharmacyAddress}
          </div>
          <div
            style={{
              color: "#515563",
              fontSize: 14,
              fontWeight: 400,
              paddingLeft: 30,
            }}
          >
            {state}
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{
              width: 51,
              height: 56,
              borderRadius: 16,
              backgroundColor: "#83CFFE",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <AddIcon fontSize="large" />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 2, color: "#BBC2C7" }} />
    </Fragment>
  );
};

PharmacyDetailsCard.propTypes = {
  pharmacyTitle: PropTypes.string,
  pharmacyPhone: PropTypes.string,
  pharmacyAddress: PropTypes.string,
  handleClick: PropTypes.func,
};

export default PharmacyDetailsCard;
