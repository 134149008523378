import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { settingsStyles } from "./styles/SettingsStyles";
import { loginCardStyles } from "../login/styles/LoginCardStyles";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const TextFieldPasswordSection = ({
  label,
  value,
  onChange,
  passwordError,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={label}
        variant="filled"
        fullWidth
        value={value}
        onChange={(e) => onChange(e)}
        type={showPassword ? "text" : "password"}
        helperText={passwordError}
        error={passwordError !== null ? true : false}
        sx={{
          ...settingsStyles.textFieldStyle,
          width: matchesSM ? "100%" : "80%",
        }}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </ThemeProvider>
  );
};

export default TextFieldPasswordSection;
