import { Card, styled } from "@mui/material";
import React from "react";
import Dimens from "../../../assets/Dimens";

const StyledCard = styled(Card)(({ theme }) => ({
  paddingLeft: Dimens.CARD_PADDING_HORIZANTAL,
  paddingRight: Dimens.CARD_PADDING_HORIZANTAL,
  paddingBottom: Dimens.CARD_PADDING_VERTICAL,
  borderRadius: Dimens.BORDER_RADIUS_LARGE,
  paddingTop: Dimens.CARD_PADDING_VERTICAL,
  [theme.breakpoints.down("md")]: {
    paddingLeft: Dimens.CARD_PADDING_HORIZANTAL_MOBILE,
    paddingTop: Dimens.CARD_PADDING_VERTICAL_MOBILE,
    paddingRight: Dimens.CARD_PADDING_HORIZANTAL_MOBILE,
    paddingBottom: Dimens.CARD_PADDING_VERTICAL_MOBILE,
  },
}));

export default StyledCard;
