import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MedicationTextfield from "./MedicationTextfield";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";

import { Scrollbars } from "react-custom-scrollbars-2";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const AddMedicationCard = ({
  matchesLG,
  matchesXl,
  matchesSM,
  matchesMD,
  matchesXS,
  loading,
  newMedication,
  setNewMedication,
  handleAddMedication,
  handleSearchMedication,
  searchedMedication,
  setselectedSearchedMedication,
}) => {
  const { t } = useTranslation("translations");

  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    searchedMedication &&
      searchedMedication.length > 0 &&
      setShowSuggestions(true);
  }, [searchedMedication]);

  return (
    <Grid
      container
      direction={"column"}
      style={{
        ...healthRecordsStyles.personalInfoMainBox,
        width: matchesLG || matchesXl ? "80%" : "100%",
        minHeight: matchesXS || matchesSM || matchesMD ? "auto" : 615,
      }}
    >
      <MedicationTextfield
        title={t("medication_name")}
        placeholder={t("add_medication_name_here")}
        value={newMedication.name}
        onChange={(e) => {
          handleSearchMedication(e.target.value);
          // setNewMedication({
          //   ...newMedication,
          //   name: e.target.value,
          // });
        }}
      />
      {showSuggestions && (
        <Grid
          container
          direction={"column"}
          style={{
            width: matchesSM ? 180 : 360,
            height: 195,
            borderRadius: 10,
            padding: "30px 20px 35px",
            boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
            zIndex: 2,
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: 10,
            marginTop: 100,
          }}
        >
          <Scrollbars autoHide>
            {searchedMedication.map((medication, index) => (
              <div
                key={index}
                style={{
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setselectedSearchedMedication(medication);
                  setNewMedication({
                    ...newMedication,
                    name: medication.value,
                  });
                  setShowSuggestions(false);
                }}
              >
                {medication.value}
              </div>
            ))}
          </Scrollbars>
        </Grid>
      )}
      <MedicationTextfield
        title={t("frequency")}
        placeholder={t("add_your_frequency_here")}
        value={newMedication.frequency}
        onChange={(e) => {
          setNewMedication({
            ...newMedication,
            frequency: e.target.value,
          });
        }}
      />
      <MedicationTextfield
        title={t("comment")}
        placeholder={t("add_your_comment_here")}
        value={newMedication.comment}
        multiline={true}
        rows={3}
        onChange={(e) => {
          setNewMedication({
            ...newMedication,
            comment: e.target.value,
          });
        }}
      />
      <MedicationTextfield
        title={t("currently_using")}
        placeholder={t("select_an_option")}
        value={newMedication.isCurrentlyUsing === 1 ? t("Yes") : t("No")}
        select={true}
        onChange={(e) => {
          setNewMedication({
            ...newMedication,
            isCurrentlyUsing: e.target.value === "No" ? 0 : 1,
          });
        }}
      />
      {loading ? (
        <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={
            newMedication.name &&
            newMedication.frequency &&
            newMedication.comment &&
            newMedication.isCurrentlyUsing
              ? false
              : true
          }
          style={
            newMedication.name &&
            newMedication.frequency &&
            newMedication.comment &&
            newMedication.isCurrentlyUsing
              ? healthRecordsStyles.addMedicationButtonEnabled
              : healthRecordsStyles.addMedicationButtonDisabled
          }
          onClick={handleAddMedication}
        >
          {t("add_medication")}
        </Button>
      )}
    </Grid>
  );
};

export default AddMedicationCard;
