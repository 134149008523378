import React from "react";
import {
  OTSession,
  // OTPublisher,
  OTStreams,
  // OTSubscriber,
  preloadScript,
} from "opentok-react";
// import { useNavigate } from "react-router-dom";
import ConnectionStatus from "./VideoTokComponents/ConnectionStatus";
import Publisher from "./VideoTokComponents/Publisher";
import Subscriber from "./VideoTokComponents/Subscriber";
import { Grid } from "@mui/material";

class TokVideoPage extends React.Component {
  // const [sessionId, setSessionId] = useState(
  //   "2_MX40Nzg3Njc2MX5-MTcxMTM1MTA1MDM0MH5HM2lhbDFGNmV1NEQyMDdZRG85N3V6SXl-fn4"
  // );
  // const [tokToken, setTokToken] = useState(
  //   "T1==cGFydG5lcl9pZD00Nzg3Njc2MSZzaWc9YWExYTY4ZTMxMjE0YmZlOTA5NWUwYWU0YjgzMTQ5ZmU4M2UyYzc1MzpzZXNzaW9uX2lkPTJfTVg0ME56ZzNOamMyTVg1LU1UY3hNVE0xTVRBMU1ETTBNSDVITTJsaGJERkdObVYxTkVReU1EZFpSRzg1TjNWNlNYbC1mbjQmY3JlYXRlX3RpbWU9MTcxMTM1MTA1MCZub25jZT0wLjUxNjM1NTUyOTQ5OTU1Mjgmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTcxMzk0MzA1MCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=="
  // );
  // const [apiKey, setApiKey] = useState("47876761");
  constructor(props) {
    super(props);
    const { state } = this.props.location; // Accessing state passed through navigation
    this.state = {
      error: null,
      connected: false,
      token: state ? state.token : "",
      sessionId: state ? state.sessionId : "",
      apiKey: state ? state.apiKey : "",
    };
    this.sessionEvents = {
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      },
    };
  }
  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
  };
  render() {
    console.log("api key:", this.state.apiKey);
    console.log("session key:", this.state.sessionId);
    console.log("token key:", this.state.token);
    return (
      <OTSession
        // apiKey={this.state.apiKey}
        // sessionId={this.state.sessionId}
        // token={this.state.token}
        apiKey={"47876761"}
        sessionId={
          "2_MX40Nzg3Njc2MX5-MTcxMTM1MTA1MDM0MH5HM2lhbDFGNmV1NEQyMDdZRG85N3V6SXl-fn4"
        }
        token={
          "T1==cGFydG5lcl9pZD00Nzg3Njc2MSZzaWc9YWExYTY4ZTMxMjE0YmZlOTA5NWUwYWU0YjgzMTQ5ZmU4M2UyYzc1MzpzZXNzaW9uX2lkPTJfTVg0ME56ZzNOamMyTVg1LU1UY3hNVE0xTVRBMU1ETTBNSDVITTJsaGJERkdObVYxTkVReU1EZFpSRzg1TjNWNlNYbC1mbjQmY3JlYXRlX3RpbWU9MTcxMTM1MTA1MCZub25jZT0wLjUxNjM1NTUyOTQ5OTU1Mjgmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTcxMzk0MzA1MCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=="
        }
        eventHandlers={this.sessionEvents}
        onError={this.onError}
      >
        {this.state.error ? <div id="error">{this.state.error}</div> : null}
        <ConnectionStatus connected={this.state.connected} />
        <Grid container style={{ position: "relative" }}>
          <Publisher />
          <OTStreams>
            <Subscriber />
          </OTStreams>
        </Grid>
      </OTSession>
    );
  }
}

export default TokVideoPage;
// export default preloadScript(TokVideoPage);
