import React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import { images } from "../../constant";
import MainPageLayout from "../MainPageLayout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HealthRecords = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.mainTitle}>{t("health_records")}</div>
        <div style={healthRecordsStyles.messageText}>
          {t("health_records_info")}
        </div>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: matchesLG ? 800 : matchesXl ? 800 : undefined,
              marginTop: 45,
            }}
          >
            <Grid
              container
              direction={"column"}
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/personal-info")}
            >
              <img
                src={images.personalInfo}
                alt="personal info"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("personal_information")}
              </div>
            </Grid>
            <Grid
              container
              direction={"column"}
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/medications")}
            >
              <img
                src={images.medication}
                alt="Medication"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("medication")}
              </div>
            </Grid>
            <Grid
              direction={"column"}
              container
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/medication-allergies")}
            >
              <img
                src={images.allergies}
                alt="Medication Allergies"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("medication_allergies")}
              </div>
            </Grid>
            <Grid
              direction={"column"}
              container
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/medical-history")}
            >
              <img
                src={images.medicalHistory}
                alt="Medical History"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("medical_history")}
              </div>
            </Grid>
            <Grid
              direction={"column"}
              container
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/surgical-history")}
            >
              <img
                src={images.surgical}
                alt="Surgical History"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("surgical_history")}
              </div>
            </Grid>
            <Grid
              direction={"column"}
              container
              style={{
                ...healthRecordsStyles.healthRecordsBox,
                marginRight: matchesSM ? 30 : matchesXS ? 20 : undefined,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/health-records/document-management")}
            >
              <img
                src={images.documentIcon}
                alt="Document management"
                style={{ width: 80, height: 80 }}
              />
              <div style={healthRecordsStyles.healthRecordsBoxText}>
                {t("document_management")}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default HealthRecords;
