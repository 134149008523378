import React from "react";
import { Menu, MenuItem, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#FFB74D",
    color: "#000F",
  },
  textTransform: "capitalize",
}));
const EditDeleteMenu = ({
  open,
  anchorEl,
  handleClose,
  handleEditClick,
  handleDeleteClick,
  document,
  onClickPreview,
}) => {
  const { t } = useTranslation("translations");
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "menu-icon",
      }}
    >
      {document ? (
        <CustomMenuItem
          onClick={() => {
            handleClose();
            onClickPreview();
          }}
        >
          {t("Preview")}
        </CustomMenuItem>
      ) : (
        <CustomMenuItem
          onClick={() => {
            handleClose();
            handleEditClick();
          }}
        >
          {t("Edit")}
        </CustomMenuItem>
      )}
      <CustomMenuItem
        onClick={() => {
          handleClose();
          handleDeleteClick();
        }}
      >
        {t("Delete")}
      </CustomMenuItem>
    </Menu>
  );
};

export default EditDeleteMenu;
