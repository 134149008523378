import React, {
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from "react";
import LocalStorageWrapper, { LocalStorageKeys } from "../utils/storage.utils";

const UrgentCareContext = createContext({});

export const UrgentCareProvider = ({ children }) => {
  const mPayload = LocalStorageWrapper.get(LocalStorageKeys.URGENT_CARE);
  const mPharmacy = LocalStorageWrapper.get(
    LocalStorageKeys.URGENT_CARE_PHARMACY
  );
  const mConsultObj = LocalStorageWrapper.get(LocalStorageKeys.CONSULT_OBJ);
  const [urgentCarePayload, setUrgentCarePayload] = useState(
    mPayload
      ? mPayload
      : {
          modalities: ["phone"],
          problems: {
            otherProblems: [],
          },
          translate: "",
        }
  );
  const [pharmacy, setPharmacy] = useState(mPharmacy || null);
  const [consultObject, setConsultObject] = useState(mConsultObj || null);

  useEffect(() => {
    saveToLocal();
  }, [urgentCarePayload, pharmacy, consultObject]);

  const saveToLocal = () => {
    LocalStorageWrapper.set(LocalStorageKeys.URGENT_CARE, urgentCarePayload);
    if (pharmacy) {
      LocalStorageWrapper.set(LocalStorageKeys.URGENT_CARE_PHARMACY, pharmacy);
    }
    if (consultObject) {
      LocalStorageWrapper.set(LocalStorageKeys.CONSULT_OBJ, consultObject);
    }
  };

  const removeFromLocal = () => {
    LocalStorageWrapper.remove(LocalStorageKeys.URGENT_CARE);
    LocalStorageWrapper.remove(LocalStorageKeys.URGENT_CARE_PHARMACY);
    LocalStorageWrapper.remove(LocalStorageKeys.CONSULT_OBJ);
    setUrgentCarePayload({
      modalities: ["phone"],
      problems: {
        otherProblems: [],
      },
      translate: "",
    });
  };

  const value = useMemo(
    () => ({
      urgentCarePayload,
      setUrgentCarePayload,
      pharmacy,
      setPharmacy,
      consultObject,
      setConsultObject,
      removeFromLocal,
    }),
    [
      urgentCarePayload,
      setUrgentCarePayload,
      pharmacy,
      setPharmacy,
      consultObject,
      setConsultObject,
      removeFromLocal,
    ]
  );

  return (
    <UrgentCareContext.Provider value={value}>
      {children}
    </UrgentCareContext.Provider>
  );
};

export default function useUrgentCare() {
  return useContext(UrgentCareContext);
}
