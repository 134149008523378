import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import { layoutStyles } from "./styles/LayoutStyles";
import { useTranslation } from "react-i18next";

const SideDrawer = ({
  matchesXS,
  matchesSM,
  matchesMD,
  matchesLG,
  matchesXl,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const [sideDrawerItems, setSideDrawerItems] = useState([
    {
      title: t("dashboard"),
      icon: HomeIcon,
      id: 1,
      selected: true,
      path: "/dashboard",
    },
    {
      title: t("health_records"),
      icon: ReceiptLongIcon,
      id: 2,
      selected: false,
      path: "/health-records",
    },
    {
      title: t("consultations"),
      icon: MedicalServicesIcon,
      id: 3,
      selected: false,
      path: "/consultations",
    },
    {
      title: t("message_center"),
      icon: NotificationsIcon,
      id: 4,
      selected: false,
      path: "/message-center",
    },
    {
      title: t("settings"),
      icon: SettingsSharpIcon,
      id: 5,
      selected: false,
      path: "/settings",
    },
  ]);

  //   onClick={() => {
  //     let temp = [...drawerItems];
  //     temp.forEach((item) => {
  //       item.selected = false;
  //     });
  //     let index = temp.indexOf(item);
  //     temp[index].selected = true;
  //     setDrawerItems(temp);
  //     navigate(item.path);
  //   }}

  useEffect(() => {
    let temp = [...sideDrawerItems];
    let paths = window.location.pathname.split("/");
    let newPathname = "/";
    newPathname += paths[1];

    temp.forEach((item) => {
      let index = temp.indexOf(item);
      item.selected = false;
      if (item.path == window.location.pathname) {
        temp[index].selected = true;
      }
      if (item.path == newPathname) {
        temp[index].selected = true;
      }
    });
    setSideDrawerItems(temp);
  }, [window.location.pathname]);

  return (
    <Grid
      container
      direction={"column"}
      sx={{
        ...layoutStyles.sideDrawerBox,
        width: matchesLG ? 250 : matchesXl ? 270 : matchesMD ? 200 : 80,
        paddingLeft: matchesMD ? 1 : 1.5,
        display: { xs: "none", md: "block" },
      }}
    >
      <List>
        {sideDrawerItems.map((item, index) => (
          <ListItem
            key={item.id}
            disablePadding
            style={
              item.selected
                ? layoutStyles.selectedDrawerItem
                : layoutStyles.drawerItem
            }
          >
            <ListItemButton
              onClick={() => {
                let temp = [...sideDrawerItems];
                temp.forEach((item) => {
                  item.selected = false;
                });
                let index = temp.indexOf(item);
                temp[index].selected = true;
                setSideDrawerItems(temp);
                navigate(item.path);
              }}
              style={{ width: matchesXS || matchesSM ? 55 : undefined }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: matchesSM || matchesXS ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {
                  <item.icon
                    style={
                      item.selected
                        ? layoutStyles.selectedDrawerIcon
                        : layoutStyles.drawerIcon
                    }
                  />
                }
              </ListItemIcon>
              {matchesSM && matchesXS && matchesMD && (
                <ListItemText>
                  <div
                    style={{
                      ...layoutStyles.drawerText,
                      fontSize: matchesMD? 14 : 17,
                      // fontSize: matchesLG ? 16 : matchesMD ? 15 : 17,
                      fontWeight: matchesLG ? 600 : matchesMD ? 500 : 700,
                    }}
                  >
                    {item.title}
                  </div>
                </ListItemText>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default SideDrawer;
