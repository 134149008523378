import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import React from "react";
import Dimens from "../../../assets/Dimens";

const DefaultTextField = (props) => {
  const { sx, error, helperText, ...remainingProps } = props;
  return (
    <FormControl fullWidth error={error}>
      <OutlinedInput
        size="small"
        fullWidth
        sx={{ borderRadius: Dimens.BORDER_RADIUS_MEDIUM, ...sx }}
        {...remainingProps}
      />
      <FormHelperText style={{color: "red"}}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default DefaultTextField;
