import React, { useState } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import EditDeleteMenu from "../medications/EditDeleteMenu";
import DataCardNameMenu from "../medications/DataCardNameMenu";
import ButtonLoader from "../../ButtonLoader";

const AllergiesCard = ({
  data,
  matchesSM,
  matchesXS,
  setSelectedAllergy,
  handleEditAllergyModalOpen,
  handleDeleteAllergyModalOpen,
  handleMarkInactiveAllergy,
}) => {
  const { t } = useTranslation("translations");

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction={"column"}
      //   alignItems={"center"}
      style={{
        padding: matchesSM || matchesXS ? 5 : 20,
        width: matchesSM || matchesXS ? "100%" : "80%",
      }}
    >
      <DataCardNameMenu
        name={data.allergymedicationname}
        open={open}
        hideMenu={true}
        handleClick={(e) => {
          setSelectedAllergy(data);
          handleClick(e);
        }}
      />

      {data.active === 1 &&
        (loading ? (
          <Grid container justifyContent={"center"}>
            <ButtonLoader />
          </Grid>
        ) : (
          <Button
            onClick={async () => {
              setLoading(true);
              await handleMarkInactiveAllergy(
                data.userMedicationAllergy_id,
                data.foreign_id
              );
              setLoading(false);
            }}
          >
            {t("Mark Inactive")}
          </Button>
        ))}

      <Divider
        style={{ color: "#19A6FE", marginTop: 10, width: "98%" }}
        variant="middle"
      />
      <EditDeleteMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleEditClick={handleEditAllergyModalOpen}
        handleDeleteClick={handleDeleteAllergyModalOpen}
      />
    </Grid>
  );
};

export default AllergiesCard;
