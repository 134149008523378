import { Divider, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { images } from "../../constant";
import { layoutStyles } from "./styles/LayoutStyles";
import { useNavigate } from "react-router-dom";
import useAuth from "../../providers/auth.provider";
import { AlertDialog } from "../common";
import { useTranslation } from "react-i18next";
import { hippa_badge, hippa_seal } from "../../assets/images";

const Footer = ({ matchesXS, matchesSM, matchesMD, matchesXl, matchesLG }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const { clearUser } = useAuth();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleLogout = () => {
    clearUser();
  };
  const showLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <Grid
      item
      container
      direction={matchesLG || matchesXl || matchesMD ? "row" : "column"}
      style={{
        height: matchesXS ? "auto" : matchesSM ? "auto" : 253,
        background: "#FFFFFF",
        marginTop: 100,
        paddingBottom: 50,
      }}
    >
      {/*Logo and Phone number sections starts*/}
      <Grid
        item
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        xs
        style={{
          marginLeft: matchesSM || matchesXS ? undefined : 83,
          marginTop: matchesSM || matchesXS ? 40 : undefined,
        }}
      >
        <img
          src={images.logoIcon}
          alt="Logo icon"
          style={{ width: 131, height: 43 }}
        />
        <div style={layoutStyles.footerPhoneText}>
          {t("telephone_assistance_hotline")}
        </div>
        <div style={layoutStyles.footerPhoneNumber}>(866) 533-1827</div>
        <div style={layoutStyles.footerPhoneNumber}>
          © {new Date().getFullYear()} {t("All rights reserved")}.
          v2023.1107.1.S (376)
        </div>
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={1}
          sx={{ marginTop: 4 }}
        >
          <img src={hippa_badge} alt="HIPPA badge" height={"60%"} />
          <img src={hippa_seal} alt={"HIPPA seal"} height={"80%"} />
        </Stack>
      </Grid>
      {/*Logo and Phone number sections ended*/}
      <Grid
        item
        container
        direction={matchesLG || matchesXl || matchesMD ? "row" : "column"}
        xs
      >
        {/* Legal section starts*/}
        <Grid
          item
          container
          direction={"column"}
          alignItems={matchesSM || matchesXS ? "center" : undefined}
          xs
          style={{ paddingTop: 63 }}
        >
          <div style={layoutStyles.footerListTitle}>{t("legal")}</div>
          <div
            style={layoutStyles.footerListItem}
            onClick={() => navigate("/terms-of-services")}
          >
            {t("terms_of_use")}
          </div>
          <div
            style={layoutStyles.footerListItem}
            onClick={() => navigate("/privacy-policy")}
          >
            {t("privacy_policy_and_privacy_practices")}
          </div>
          <div
            style={layoutStyles.footerListItem}
            onClick={() => navigate("/informed-patient-consent")}
          >
            {t("informed_patient_consent")}
          </div>
          <div
            style={layoutStyles.footerListItem}
            onClick={() => navigate("/do-not-sell-my-personal-info")}
          >
            {t("do_not_sell_my_personal_information")}
          </div>
        </Grid>
        {/* Legal section ended*/}
        {matchesLG ||
          matchesXl ||
          (matchesMD && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              style={{ height: 137, color: "#DFF0FF", marginTop: 63 }}
            />
          ))}

        {/* Tools section starts*/}
        <Grid
          item
          container
          direction={"column"}
          alignItems={matchesSM || matchesXS ? "center" : undefined}
          xs
          style={{
            marginLeft: matchesSM || matchesXS ? undefined : 60,
            paddingTop: 63,
          }}
        >
          <div style={layoutStyles.footerListTitle}>{t("Tools")}</div>
          {/*
          <div style={layoutStyles.footerListItem}>
          Test your video capabilites
          </div>
        */}
          <div
            style={layoutStyles.footerListItem}
            onClick={() => navigate("/settings")}
          >
            {t("my_account")}
          </div>
          <div style={layoutStyles.footerListItem} onClick={showLogoutModal}>
            {t("sign_out")}
          </div>
        </Grid>
        {/* Tools section ended*/}
      </Grid>
      <AlertDialog
        modalOpen={logoutModalOpen}
        handleClose={closeLogoutModal}
        title={t("sign_out")}
        icon={null}
        description={t("signout_info")}
        primaryButtonText={t("yes")}
        secondaryButtonText={t("no")}
        primaryButtonAction={handleLogout}
        secondaryButtonAction={closeLogoutModal}
      />
    </Grid>
  );
};

export default Footer;
