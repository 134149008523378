import React from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import { Card, Grid } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { Scrollbars } from "react-custom-scrollbars-2";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PatientConsent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <LoginDesignPage>
        <Card
          sx={{
            ...cardStyle,
            width: matchesXS
              ? "98%"
              : matchesSM
              ? "95%"
              : matchesMD
              ? "90%"
              : matchesLG
              ? "80%"
              : 1115,
          }}
        >
          <Grid
            container
            direction={"column"}
            style={
              matchesSM || matchesXS
                ? subContainerStylesSM
                : matchesMD
                ? subContainerStylesMD
                : matchesLG
                ? subContainerStyles
                : matchesXl
                ? subContainerStyles
                : subContainerStyles
            }
          >
            <Grid
              container
              alignItems={"center"}
              onClick={() => navigate(-1)}
              style={backButtonStyles}
            >
              <ArrowBackIosOutlinedIcon style={backIconStyles} />
              <div style={{ textDecoration: "underline", marginLeft: 12 }}>
                {t("back")}
              </div>
            </Grid>
            <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("informed_patient_consent")}
            </span>
            <Grid container direction={"column"} alignItems={"center"}>
              <Scrollbars
                style={scrollbarContainerStyle}
                thumbMinSize={30}
                autoHide
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.thumbVerticalStyle,
                    }}
                  />
                )}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.trackVerticalStyle,
                    }}
                  />
                )}
              >
                {/* Informed Consent of Services Performed: start */}

                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("informed_consent_of_services_performed")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 50,
                  }}
                >
                  {t("informed_consent_of_services_performed_info")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("patient_medical_records")}</li>
                    <li>{t("medical_images")}</li>
                    <li>{t("live_two_way_audio_and_video")}</li>
                    <li>{t("output_data_from_medical_devices")}</li>
                  </ul>
                </div>
                <div style={termsOfServicesText}>
                  {t("output_data_from_medical_devices_info")}
                </div>
                <div style={termsOfServicesText}>
                  {t("output_data_from_medical_devices_info_one")}
                </div>

                {/* Informed Consent of Services Performed: end */}

                {/* Expected Benefits: start */}
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("expected_benefits")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("expected_benefits_info")}</li>
                    <li>{t("expected_benefits_info_one")}</li>
                    <li>{t("expected_benefits_info_two")}</li>
                  </ul>
                </div>
                {/* Expected Benefits: end */}

                {/* Possible Risks: start  */}
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("possible_risks")}
                </div>
                <div style={termsOfServicesText}>
                  {t("possible_risks_info")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("possible_risks_info_one")}</li>
                    <li>{t("possible_risks_info_two")}</li>
                    <li>{t("possible_risks_info_three")}</li>
                    <li>{t("possible_risks_info_four")}</li>
                  </ul>
                </div>
                <div style={termsOfServicesText}>
                  {t("agreeing_and_understanding_following")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("recorded_for_quality_assurance")}</li>
                    <li>{t("recorded_for_quality_assurance_info")}</li>
                    <li>{t("recorded_for_quality_assurance_info_one")}</li>
                    <li>{t("recorded_for_quality_assurance_info_two")}</li>
                    <li>{t("recorded_for_quality_assurance_info_three")}</li>
                    <li>{t("recorded_for_quality_assurance_info_four")}</li>
                    <li>{t("recorded_for_quality_assurance_info_five")}</li>
                  </ul>
                </div>
                {/* Possible Risks: end  */}

                {/* Patient Consent To The Use of Telemedicine: start  */}
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("patient_consent_to_the_use_of_telemedicine")}
                </div>
                <div style={termsOfServicesText}>
                  {t("patient_consent_to_the_use_of_telemedicine_info")}
                </div>
                <div style={termsOfServicesText}>
                  {t("patient_consent_to_the_use_of_telemedicine_info_one")}
                </div>
                {/* Patient Consent To The Use of Telemedicine: end  */}
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("read_and_agree_to_this_document")}
                </div>
              </Scrollbars>
            </Grid>
          </Grid>
        </Card>
      </LoginDesignPage>
    </ThemeProvider>
  );
};

export default PatientConsent;

const cardStyle = {
  position: "absolute",
  width: 1115,
  height: "98vh",
  // height: 758,
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
  // "@media (minWidth: 960px)": {
  //   width: "1115px",
  //   height: "758px",
  // },
};

const subContainerStyles = {
  paddingTop: 70,
  paddingLeft: 134,
  paddingRight: 90,
};
const subContainerStylesSM = {
  padding: 30,
};
const subContainerStylesMD = {
  paddingTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
};
const subContainerStylesLG = {
  padding: 70,
};

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  height: 493,
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
  // marginRight: -30,
};

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
};
