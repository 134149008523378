import React from "react";
import MainPageLayout from "../MainPageLayout";
import { Box, Stack } from "@mui/material";
import { emergency, group_discussion, neurology } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import ScheduleConsultationBox from "../../components/consultations/schedule-consultation/ScheduleConsultationBox";
import Applinks from "../../assets/route-links";
import { Header } from "../../components/common";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const MTM_Psychiatry = "MTM_Psychiatry";
const MTM_PrimaryCare_One = "DiagnosticPhone";
const MTM_PrimaryCare_Two = "DiagnosticVideo";
const behavioralHealth = "behavioralHealth";
const studentBehavioralHealth = "st_studentBH";
// const studentBehavioralHealth = "TVCGStudentBH";

const ScheduleConsultation = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const showPsychiatry = user?.modules?.includes(MTM_Psychiatry);
  const showUrgentCare =
    user?.modules?.includes(MTM_PrimaryCare_One) &&
    user?.modules?.includes(MTM_PrimaryCare_Two);
  const showBehavioralHealth = user?.modules?.includes(behavioralHealth);
  const showStudentBehavioralHealth = user?.modules?.includes(
    studentBehavioralHealth
  );

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%", marginTop: 3 }}>
        <Header
          handleGoBack={() => navigate(-1)}
          title={t("schedule_consultation")}
          subTitle={t("schedule_consultation_info")}
        />
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          justifyContent={"space-around"}
          spacing={2}
          sx={{ mt: 8 }}
        >
          {showUrgentCare && (
            <ScheduleConsultationBox
              title={t("urgent_care")}
              description={t("common_acute_conditions")}
              imageUrl={emergency}
              onClick={() =>
                handleNavigate(Applinks.SCHEDULE_CONSULATATIONS_URGENT_CARE)
              }
            />
          )}
          {showBehavioralHealth && (
            <ScheduleConsultationBox
              title={t("behavioral_health_virtual_counseling")}
              description={t("counseling_services")}
              // phoneNumber={"866-576-1130"}
              imageUrl={group_discussion}
              onClick={() =>
                handleNavigate(
                  Applinks.SCHEDULE_CONSULATATIONS_BEHAVIORAL_HEALTH
                )
              }
            />
          )}
          {showStudentBehavioralHealth && (
            <ScheduleConsultationBox
              title={t("student_behavioural_health_counseling")}
              description={t("counseling_services")}
              // phoneNumber={"855-522-1226"}
              imageUrl={group_discussion}
              onClick={() =>
                handleNavigate(
                  Applinks.SCHEDULE_CONSULATATIONS_STUDENT_BEHAVIORAL_HEALTH
                )
              }
            />
          )}
          {showPsychiatry && (
            <ScheduleConsultationBox
              title={t("psychiatrist")}
              description={t("psychiatric_medical_care")}
              imageUrl={neurology}
              onClick={() =>
                handleNavigate(Applinks.SCHEDULE_CONSULTATION_PSYCHIATRIST)
              }
            />
          )}
        </Stack>
      </Box>
    </MainPageLayout>
  );
};

export default ScheduleConsultation;
