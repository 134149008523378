import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import React from "react";
import { COLORS } from "../../../assets/colors";
import { StyledBodyText } from "../../common";
import { useTranslation } from "react-i18next";

const Limitations = [
  "Our doctors are prohibited from prescribing Benzodiazepines such as Xanax, Ativan, Klonopin, or Valium.",
  "Our doctors are prohibited from prescribing Stimulants such as Adderall, Ritalin or Dexedrine.",
  "Our doctors are prohibited from prescribing Opioids such as Codeine, Vicodin, Methadone or Suboxone.",
  "Our doctors are prohibited from prescribing Hypnotics such as Ambien, Sonata or Lunesta.",
  "Our doctors are prohibited from prescribing Antipsychotics such as Seroquel or Zyprexa.",
  "Click here for a full list of ` substances.",
];

const PrescriptionLimitationModel = (props) => {
  const { modalOpen, handleClose } = props;
  const { t } = useTranslation("translations");
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      //   sx={{ height: "100vh" }}
      open={modalOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          m: 2,
          pb: 2,
          borderBottom: `1px solid ${COLORS.PRIMARY_MAIN}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <StyledBodyText sx={{ textAlign: "center" }}>
          {t("perscription_limitations")}
        </StyledBodyText>
      </Box>
      <Box sx={{ mb: 6 }}>
        <ul>
          {Limitations.map((el) => (
            <li key={el} style={{ marginTop: "10px" }}>
              {el}
            </li>
          ))}
        </ul>
      </Box>
    </Dialog>
  );
};

export default PrescriptionLimitationModel;
