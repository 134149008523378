import axios from "axios";
import { Endpoints } from "../../endpoints";
import { reqPost } from "../../request.api";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../../utils/storage.utils";

// update member's firstName, lastName, dob, gender, primaryPhone, secondaryPhone, email, address, city, state, zipcode, timezoneId, isInternationalPhone;
// export const updateMember = async (params) => {
//   console.log("updateMember params", params)

//   const resp = await reqPost({
//     url: `/memberAccount/updateMemberContactInfo`,
//     data: params,
//   });
//   console.log("updateMember resp", resp)
//   const { data } = resp;
//   // return data;
//   return resp;
// };

//update member's password (oldPassword, NewPassword, confirmpassword)
export const updateMemberPassword = async (params) => {
  console.log(params);
  const resp = await reqPost({
    url: `${Endpoints.UPDATE_MEMBER_PASSWORD}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

const bearerToken = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);

// updateMember
export const updateMember = async (data) => {
  try {
    const resp = await reqPost({
      url: `${Endpoints.UPDATE_MEMBER}`,
      data: data,
    });
    // const { data } = resp;
    return resp;
    // const res = await axios.post(
    //   `https://staging.getlyric.com/go/api/memberAccount/updateMemberContactInfo?firstName=${data.firstName}&lastName=${data?.lastName}1&dob=${data?.dob}&gender=${data?.gender}&primaryPhone=${data?.primaryPhone}&secondaryPhone=${data?.secondaryPhone}&email=${data?.email}&address=${data?.address}&address2=${data?.address2}&city=${data?.city}&state=${data?.stateId}&zipCode=${data?.zipCode}&timezone=${data?.timezoneId}&isInternationalPhone=${data?.isInternationalPhone}`,
    //   {},
    //   {
    //     headers: {
    //       Authorization: bearerToken,
    //     },
    //   }
    // );

    // console.log("updateMember Res", res);
    // return res;
  } catch (error) {
    console.log("Error in update-user", error);
  }
};

export const updateMemberPasswordApi = async (data) => {
  try {
    const res = await axios.post(
      `https://staging.getlyric.com/go/api/memberAccount/updatePassword?oldpassword=${data.oldPassword}&password=${data.newPassword}&passwordVerify=${data.confirmPassword}`,
      {},
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    );

    console.log("updateMemberPassword", res);
    return res;
  } catch (error) {
    console.log("Error in update-Member password", error);
  }
};
