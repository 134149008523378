import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ErrorIcon from "@mui/icons-material/Error";

import { modalStyle } from "../../../constant";
import { useTranslation } from "react-i18next";
import { messagingStyles } from "../../messaging/styles/messagingStyles";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";

const ErrorModal = ({ modalOpen, handleClose, matchesSM, matchesXS }) => {
  const { t } = useTranslation("translations");
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 80px" }}
        >
          <ErrorIcon style={messagingStyles.errorIcon} />
          <span style={messagingStyles.modalTitleStyles}>
            {t("Document couldn’t be Deleted")}
          </span>
          <span style={messagingStyles.modalSubTitleStyles}>
            {t("It is currently assigned to an Message A Doctor request.")}
          </span>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={healthRecordsStyles.okayButtonStyles}
          >
            {t("done")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
