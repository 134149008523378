import { HealthRecordsProvider } from "../providers/HealthRecordsContext";
import { MessageCenterProvider } from "../providers/MessageCenterContext";
import { AuthProvider } from "../providers/auth.provider";
import { PsychiatristProvider } from "../providers/psychiatrist-provider";
import { UrgentCareProvider } from "../providers/urgent-care.provider";
import { FeedbackProvider } from "../providers/feedback.provider";
import { LanguageProvider } from "../providers/language.provider";

const withContext = (WrappedComponent) => (props) =>
  (
    <LanguageProvider>
    <FeedbackProvider>
      <AuthProvider>
        <HealthRecordsProvider>
          <MessageCenterProvider>
            <UrgentCareProvider>
              <PsychiatristProvider>
                <WrappedComponent />
              </PsychiatristProvider>
            </UrgentCareProvider>
          </MessageCenterProvider>
        </HealthRecordsProvider>
      </AuthProvider>
    </FeedbackProvider>
    </LanguageProvider>
  );

export default withContext;
