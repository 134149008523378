import { Endpoints } from "../../endpoints";
import { reqGet, reqPost } from "../../request.api";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../../utils/storage.utils";

// let user = JSON.parse(localStorage.getItem("user"));
const bearerToken = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);

export const getAllMessages = async () => {
  const resp = await reqGet({
    url: `${Endpoints.MESSAGES + "/1"}`,
    // url: `${Endpoints.MESSAGES + "/" + user.userId}`,
  });
  const { data } = resp;
  return data;
};

export const getAttachmentDummy = async (setImageSrc) => {
  await fetch(
    "https://staging.getlyric.com/go/api/attachment/stream/21459/attachment/2499200",
    {
      method: "GET",
      responseType: "blob",
    },
    {
      headers: {
        Authorization: bearerToken,
      },
    }
  )
    .then((response) => response.blob()) // Use response.blob() to get blob data
    .then((blobData) => {
      // Create an object URL for the blob data
      const imageUrl = URL.createObjectURL(blobData);
      setImageSrc(imageUrl);
      console.log("image response::::", imageUrl);
      return imageUrl;
    })
    .catch((error) => {
      console.error("Error fetching image:", error);
    });
};

export const getAttachments = async (attachmentId, streamMethod, userId) => {
  const resp = await reqGet({
    url: `/attachment/stream/${attachmentId}/${streamMethod}/${userId}`,
    responseType: "blob", // or 'arraybuffer'
  });
  const { data } = resp;
  return resp;
  // console.log("token:", bearerToken);
  // // /attachment/stream/{attachmentId}/{streamMethod}/{userId}
  // const resp = await reqGet({
  //   url: `${
  //     "https://staging.getlyric.com/go/api" +
  //     "/10" +
  //     "/attachment" +
  //     "/" +
  //     user.userId
  //   }`,
  // });

  // https://staging.mytelemedicine.com/go/api/personal/update/%7BuserId%7D
  // // console.log("attachments start:");
  // let res = await axios.get(
  //   // `https://staging.mytelemedicine.com/go/api/${attachmentId}/attachment/${user.userId}`,
  //   `https://staging.mytelemedicine.com/go/api/attachment/stream/${attachmentId}/${streamMethod}/${userId}`,
  //   {},
  //   {
  //     headers: {
  //       Authorization: bearerToken,
  //     },
  //   }
  // );
  // console.log("attachments Res:", res);
  // return res;
};

export const getAllSpecificMessage = async (threadId) => {
  const resp = await reqGet({
    url: `${Endpoints.MESSAGES + "/thread/" + threadId}`,
    // url: `${Endpoints.MESSAGES + "/" + user.userId}`,
  });
  const { data } = resp;
  return data;
};

export const replyToMessage = async (params) => {
  const resp = await reqPost({
    url: `${Endpoints.MESSAGES + "/add"}`,
    data: params,
  });
  console.log("file response:", resp);
  if (resp.status === 200) {
    const attachmentResp = await replyToMessageWithAttachment(params);
    console.log("file response::::", attachmentResp);
  }
  const { data } = resp;
  return data;
};

export const sendNewMessage = async (params, imageFileTrue) => {
  const resp = await reqPost({
    url: `${Endpoints.MESSAGES + "/add"}`,
    data: params,
  });
  console.log("message response:", resp);
  if (resp.status === 200) {
    if (imageFileTrue) {
      params.append("askADoctorThreadId", resp.data.askADoctorThreadId);
      const attachmentResp = await replyToMessageWithAttachment(params);
      console.log("file uploaded response::::", attachmentResp);
    }
  }
  const { data } = resp;
  return data;
};

// attachments
// askADoctorThreadId
export const replyToMessageWithAttachment = async (params) => {
  const resp = await reqPost({
    url: Endpoints.MESSAGES_WITH_ATTACHMENT,
    data: params,
  });
  const { data } = resp;
  return data;
};
