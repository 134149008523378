export const COLORS = {
  PRIMARY_MAIN: "#19A6FE",
  PRIMARY_LIGHT: "#DFF0FF",
  PRIMARY_DARK: "#0B3EB9",
  SECONDARY_MAIN: "#0D3B66",
  PENDING: "#FF8B4A",
  SUCCESS: "#B7E27F",
  BLACK: "#000",
  TEXT_SECONDARY: "#fff",
  DISABLED: "#515563",
  BUTTON_SECONDARY: "#FFB74D",
  BUTTON_PRIMARY: "#83CFFE",
  CARD_SECONDARY: "#FFF2E1",
  TEXT: "#001011",
};
