import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../assets/colors";
import PropTypes from "prop-types";
import Dimens from "../../assets/Dimens";
import moment from "moment-timezone";
// import moment from "moment";
import { useTranslation } from "react-i18next";

const DoctorHiddenItem = (props) => {
  const { bio, availableSlots, handleSelect, selectedSlot } = props;
  const { t } = useTranslation("translations");
  const [timezoneInfo, setTimezoneInfo] = useState({});

  // let timezoneInfo;

  useEffect(() => {
    setTimezoneInfo(
      offsetMap[availableSlots[0]?.startTime?.slice(-5)] || {
        abbreviation: "Unknown",
        fullName: "Unknown Timezone",
      }
    );
    // timezoneInfo = offsetMap[availableSlots[0]?.startTime?.slice(-5)] || {
    //   abbreviation: "Unknown",
    //   fullName: "Unknown Timezone",
    // };
  }, [availableSlots]);

  const offsetMap = {
    "-1200": { abbreviation: "AoE", fullName: "Anywhere on Earth" },
    "-1100": { abbreviation: "NUT", fullName: "Niue Time" },
    "-1000": { abbreviation: "HST", fullName: "Hawaii Standard Time" },
    "-0900": { abbreviation: "AKST", fullName: "Alaska Standard Time" },
    "-0800": { abbreviation: "PST", fullName: "Pacific Standard Time" },
    "-0700": { abbreviation: "MST", fullName: "Mountain Standard Time" },
    "-0600": { abbreviation: "CST", fullName: "Central Standard Time" },
    "-0500": { abbreviation: "EST", fullName: "Eastern Standard Time" },
    "-0400": { abbreviation: "AST", fullName: "Atlantic Standard Time" },
    "-0300": { abbreviation: "ART", fullName: "Argentina Time" },
    "-0200": { abbreviation: "FNT", fullName: "Fernando de Noronha Time" },
    "-0100": { abbreviation: "AZOT", fullName: "Azores Standard Time" },
    "+0000": { abbreviation: "GMT", fullName: "Greenwich Mean Time" },
    "+0100": { abbreviation: "CET", fullName: "Central European Time" },
    "+0200": { abbreviation: "EET", fullName: "Eastern European Time" },
    "+0300": { abbreviation: "MSK", fullName: "Moscow Time" },
    "+0400": { abbreviation: "GST", fullName: "Gulf Standard Time" },
    "+0500": { abbreviation: "PKT", fullName: "Pakistan Standard Time" },
    "+0530": { abbreviation: "IST", fullName: "Indian Standard Time" },
    "+0600": { abbreviation: "BST", fullName: "Bangladesh Standard Time" },
    "+0630": { abbreviation: "MMT", fullName: "Myanmar Standard Time" },
    "+0700": { abbreviation: "ICT", fullName: "Indochina Time" },
    "+0800": { abbreviation: "CST", fullName: "China Standard Time" },
    "+0900": { abbreviation: "JST", fullName: "Japan Standard Time" },
    "+0930": {
      abbreviation: "ACST",
      fullName: "Australian Central Standard Time",
    },
    "+1000": {
      abbreviation: "AEST",
      fullName: "Australian Eastern Standard Time",
    },
    "+1100": { abbreviation: "VUT", fullName: "Vanuatu Time" },
    "+1200": { abbreviation: "NZST", fullName: "New Zealand Standard Time" },
    "+1300": { abbreviation: "TOT", fullName: "Tonga Time" },
    "+1400": { abbreviation: "LINT", fullName: "Line Islands Time" },
  };

  // const offsetMap = {
  //   '-1200': 'AoE', // Anywhere on Earth
  //   '-1100': 'NUT', // Niue Time
  //   '-1000': 'HST', // Hawaii Standard Time
  //   '-0900': 'AKST', // Alaska Standard Time
  //   '-0800': 'PST', // Pacific Standard Time
  //   '-0700': 'MST', // Mountain Standard Time
  //   '-0600': 'CST', // Central Standard Time
  //   '-0500': 'EST', // Eastern Standard Time
  //   '-0400': 'AST', // Atlantic Standard Time
  //   '-0300': 'ART', // Argentina Time
  //   '-0200': 'FNT', // Fernando de Noronha Time
  //   '-0100': 'AZOT', // Azores Standard Time
  //   '+0000': 'GMT', // Greenwich Mean Time
  //   '+0100': 'CET', // Central European Time
  //   '+0200': 'EET', // Eastern European Time
  //   '+0300': 'MSK', // Moscow Time
  //   '+0400': 'GST', // Gulf Standard Time
  //   '+0500': 'PKT', // Pakistan Standard Time
  //   '+0530': 'IST', // Indian Standard Time
  //   '+0600': 'BST', // Bangladesh Standard Time
  //   '+0630': 'MMT', // Myanmar Standard Time
  //   '+0700': 'ICT', // Indochina Time
  //   '+0800': 'CST', // China Standard Time
  //   '+0900': 'JST', // Japan Standard Time
  //   '+0930': 'ACST', // Australian Central Standard Time
  //   '+1000': 'AEST', // Australian Eastern Standard Time
  //   '+1100': 'VUT', // Vanuatu Time
  //   '+1200': 'NZST', // New Zealand Standard Time
  //   '+1300': 'TOT', // Tonga Time
  //   '+1400': 'LINT' // Line Islands Time
  // };

  // function getTimezoneAbbreviation(date) {
  //   const offset = moment(date).utcOffset() / 60; // Convert offset to hours

  //   switch (offset) {
  //     case 5:
  //       return "PKT"; // Pakistan Standard Time
  //     case -5:
  //       return "EST"; // Eastern Standard Time
  //     case -6:
  //       return "CST"; // Central Standard Time
  //     case -7:
  //       return "MST"; // Mountain Standard Time
  //     case -8:
  //       return "PST"; // Pacific Standard Time
  //     case -10:
  //       return "HST"; // Hawaii-Aleutian Standard Time
  //     default:
  //       return ""; // Fallback for offsets that don't match
  //   }
  // }

  return (
    <Box sx={{ pb: 3 }}>
      <Box sx={{ bgcolor: COLORS.PRIMARY_MAIN, height: "2px" }} />
      <Box sx={{ pt: 2 }}>
        <Typography variant="h6">{t("about")}</Typography>
        <Typography sx={{ pt: 1 }}>{bio}</Typography>
      </Box>
      <Box
        sx={{
          bgcolor: COLORS.CARD_SECONDARY,
          ml: `-${Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL}`,
          mr: `-${Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL}`,
          pl: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pr: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pt: Dimens.PADDING_TOP_SMALL,
          pb: Dimens.PADDING_TOP_SMALL,
          mt: 3,
        }}
      >
        <Typography variant="h6">{t("availability")}</Typography>
      </Box>
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        useFlexGap
        spacing={2}
        sx={{ pt: 2 }}
      >
        {availableSlots?.map((el) => (
          <Box
            key={el.providerschedule_id}
            component="span"
            sx={{
              pl: Dimens.BOX_LABEL_HEIGHT,
              pr: Dimens.BOX_LABEL_HEIGHT,
              border: `1px solid ${COLORS.DISABLED}`,
              pt: Dimens.PADDING_TOP_SMALL,
              pb: Dimens.PADDING_TOP_SMALL,
              borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
              bgcolor:
                selectedSlot === el.providerschedule_id
                  ? COLORS.BUTTON_PRIMARY
                  : COLORS.TEXT_SECONDARY,
              cursor: "pointer",
            }}
            onClick={() => handleSelect(el.providerschedule_id)}
          >
            {/*
          {moment(el.startTime).format("hh:mm a") +" - "+ moment(el.startTime).format("Z")}
        */}
            {moment(el.startTime).format("hh:mm a")}
          </Box>
        ))}
        {/*
     
       {availableSlots?.length > 0 && (
         <Box>
           {`**All Times are in ${getTimezoneAbbreviation(availableSlots[0]?.startTime)}`}
         </Box>
       )}
       {availableSlots?.length > 0 && (
         <Box>{`**All Times are  in GMT${moment(availableSlots[0]?.startTime)
           .format("Z")
           .replace(":00", "")
           .replace(/\b0+/g, "")}`}</Box>
       )}
       
      */}
        {availableSlots?.length > 0 && (
          <Box>
            {`**All Times are  in (${timezoneInfo?.abbreviation}, ${timezoneInfo?.fullName})`}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

DoctorHiddenItem.propTypes = {
  bio: PropTypes.string,
  availableSlots: PropTypes.array,
  handleSelect: PropTypes.func,
  selectedSlot: PropTypes.number,
};

export default DoctorHiddenItem;
