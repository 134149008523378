import { Box, CircularProgress, Stack } from "@mui/material";
import { useState } from "react";
import {
  CancelConsultationModel,
  ConsultationListCard,
  NoAppointmentText,
} from "../../components/consultations";
import ListItemHidden from "../../components/consultations/ListItemHidden";
import { useTranslation } from "react-i18next";

const Upcoming = (props) => {
  const {
    consultations,
    loading,
    handleCancelConsultation,
    handleJoinAppointment,
  } = props;
  const { t } = useTranslation("translations");
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [consultationToDelete, setConsultationToDelete] = useState(null);

  console.log("consultations:::", consultations);

  const handleCancelClick = (id) => {
    console.log("id:", id);
    setConsultationToDelete(id);
    setShowCancelModel(true);
  };

  const handleCloseModal = () => {
    setShowCancelModel(false);
  };

  const handleSubmitReason = (reason) => {
    // console.log("id:", consultationToDelete)
    handleCancelConsultation(consultationToDelete, reason);
    setShowCancelModel(false);
  };

  if (loading)
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Box>
      {consultations.length === 0 && (
        <NoAppointmentText label={t("you_have_no_upcoming_appointments")} />
      )}
      {consultations &&
        consultations?.map((consultation) => (
          <ConsultationListCard
            key={consultation.userConsultation_id}
            consultation={consultation}
            hiddenItem={
              <ListItemHidden
                comment={consultation.comments}
                reason={consultation.reason}
                onCancelClick={() =>
                  handleCancelClick(
                    consultation.userConsultation_id
                      ? consultation.userConsultation_id
                      : consultation.consultationId
                      ? consultation.consultationId
                      : consultation.userConsultation_id
                  )
                }
                showJoinAppointment={
                  consultation.primaryPatientToken &&
                  consultation.videoSessionId
                }
                onJoinAppointmentClick={() => {
                  handleJoinAppointment(consultation);
                }}
                showCancelAppointment={true}
                // showCancelAppointment={consultation.status === "New"}
              />
            }
          />
        ))}
      <CancelConsultationModel
        modalOpen={showCancelModel}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitReason}
      />
    </Box>
  );
};

export default Upcoming;
