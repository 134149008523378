import { Box, Dialog, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Dimens from "../../assets/Dimens";
import PropTypes from "prop-types";
import { COLORS } from "../../assets/colors";
import { ErrorOutline } from "@mui/icons-material";
import DefaultButton from "./DefaultButton";
import { DefaultTextArea } from "../common";

const CancelConsultationModel = (props) => {
  const { modalOpen, handleClose, handleSubmit } = props;
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const handleAcceptClick = () => {
    if (!reason) {
      setReasonError("Please enter a reason to cancel the consultation");
      return;
    }
    handleSubmit?.call(0, reason);
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          pt: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pb: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pl: {
            md: Dimens.CONSULTATION_MODEL_PADDING_HORIZANTAL,
            xs: Dimens.PADDING_TOP_SMALL,
          },
          pr: {
            md: Dimens.CONSULTATION_MODEL_PADDING_HORIZANTAL,
            xs: Dimens.PADDING_TOP_SMALL,
          },
          bgcolor: COLORS.TEXT_SECONDARY,
          textAlign: "center",
        }}
      >
        <ErrorOutline
          color="error"
          sx={{
            height: Dimens.WARNING_ICON_DIMENSION,
            width: Dimens.WARNING_ICON_DIMENSION,
            pt: 1,
          }}
        />
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontWeight: Dimens.FONT_WEIGHT_BOLD,
            fontSize: Dimens.FONT_SIZE_LARGE,
            lineHeight: Dimens.LINE_HEIGHT_LARGE,
            pt: 1,
          }}
        >
          Cancel Appointment
        </Typography>
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontWeight: Dimens.FONT_WEIGHT_LIGHT,
            fontSize: Dimens.FONT_SIZE_SMALL,
            lineHeight: Dimens.LINE_HEIGHT_SMALL,
            pt: 1,
          }}
        >
          Are you sure you want to cancel the scheduled appointment?
        </Typography>

        <DefaultTextArea
          sx={{ mt: 2 }}
          label="Reason"
          error={Boolean(reasonError)}
          helperText={reasonError}
          onChange={handleChange}
          placeholder="Please enter a reason to cancel the appointment (1-5000 characters)"
        />

        <Stack direction="row" justifyContent={"center"} sx={{ pt: 3 }}>
          <DefaultButton onClick={handleAcceptClick}>accept</DefaultButton>
          <DefaultButton
            variant="outlined"
            sx={{ ml: 1, color: COLORS.SECONDARY_MAIN }}
            onClick={handleClose}
          >
            decline
          </DefaultButton>
        </Stack>
      </Box>
    </Dialog>
  );
};

CancelConsultationModel.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default CancelConsultationModel;
