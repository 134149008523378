import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainPageLayout from "../../MainPageLayout";
import { Box, Stack } from "@mui/material";
import {
  CallTypeCard,
  ServiceAvailablityBox,
  // ConditionLabel,
  PsychiatristResourcesModel,
} from "../../../components/consultations";
import { Header, StyledBodyText } from "../../../components/common";
import { videocall } from "../../../assets/icons";
// import psychicConditions from "../../../assets/psychicConditions";
import DefaultButton from "../../../components/consultations/DefaultButton";
import Applinks from "../../../assets/route-links";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import { getPatientAndConsultObject } from "../../../data/requests/consultations";
import useAuth from "../../../providers/auth.provider";
// import { COLORS } from "../../../assets/colors";
import { useTranslation } from "react-i18next";

const SchedulePsychiatrist = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const [video, setVideo] = useState(true);
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  // const [selectedConditions, setSelectedConditions] = useState([]);
  const { user } = useAuth();
  const {
    psychiatristPayload,
    setPsychiatristPayload,
    patientConsultData,
    setPatientConsultData,
    setIntakeQuestionaireData,
  } = usePsychiatrist();

  console.log("patientConsultData", patientConsultData);

  // const handleConditionClick = (condition) => {
  //   const mSelectedConditions = [...selectedConditions];
  //   if (mSelectedConditions.includes(condition)) {
  //     mSelectedConditions.splice(mSelectedConditions.indexOf(condition), 1);
  //     setSelectedConditions(mSelectedConditions);
  //     return;
  //   }
  //   mSelectedConditions.push(condition);
  //   setSelectedConditions(mSelectedConditions);
  // };

  useEffect(() => {
    getConsultObject();
  }, [video]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const getConsultObject = async () => {
    try {
      const patientData = await getPatientAndConsultObject(
        user.userId,
        video ? "video" : ""
      );
      if (patientData.success === true) {
        setPatientConsultData(patientData);
        setPsychiatristPayload({
          ...psychiatristPayload,
          ehr: {
            ...psychiatristPayload.ehr,
            heightFeet: patientData?.patient?.ehr?.personal[0]?.heightFeet,
            heightInches: patientData?.patient?.ehr?.personal[0]?.heightInches,
            weight: patientData?.patient?.ehr?.personal[0]?.weight,
          },
        });
        setIntakeQuestionaireData(
          patientData?.consultation?.available_questionnaires[0]
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAppointmentClick = () => {
    if (!video) {
    } else {
      setShowResourcesModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowResourcesModal(false);
  };

  const handleContinueClick = () => {
    setShowResourcesModal(false);
    navigate(Applinks.SCHEDULE_PSYCHIATRIST_MEDICAL_QUESTIONAIRE);
  };

  const handleVideoClick = () => {
    setVideo(!video);
    setPsychiatristPayload({
      ...psychiatristPayload,
      modality: video ? "video" : "",
    });
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header
          title={t("psychiatrist")}
          handleGoBack={handleGoBack}
          subTitle={t("psychiatrist_info")}
        />
        <Stack sx={{ pt: 2 }} alignItems="center">
          <ServiceAvailablityBox
            initialFeeKey={t("initial_fee")}
            initialFeeValue={t("free")}
            initialFeeCaption={`/${t("visit")}`}
            visitFeeKey={t("follow_up")}
            visitFeeValue={t("free")}
            visitFeeCaption={`/${t("visit")}`}
            availabilityKey={`${t("mon")} - ${t("fri")}`}
            availabilityValue="8am - 5pm"
            availabilityCaption="CST"
          />
        </Stack>
        <StyledBodyText sx={{ mt: 6 }}>{t("choose_call_type")}</StyledBodyText>
        <Stack direction={{ md: "row", xs: "column" }} sx={{ mt: 2 }}>
          <CallTypeCard
            icon={<img src={videocall} />}
            title={t("video")}
            checked={video}
            onChange={handleVideoClick}
            cardSx={{ ml: { md: 2, xs: 0 }, mt: { md: 0, xs: 2 } }}
          />
        </Stack>
        <StyledBodyText sx={{ mt: 6 }}>{t("common_conditions")}</StyledBodyText>
        {/*<Stack
          direction="row"
          flexWrap={"wrap"}
          useFlexGap
          sx={{ mt: 2 }}
          spacing={3}
        >
          {psychicConditions.map((condition) => (
            <ConditionLabel
              key={condition.id}
              title={condition.title}
              bgcolor={
                selectedConditions.find((c) => c.id === condition.id)
                  ? COLORS.SECONDARY_MAIN
                  : COLORS.TEXT_SECONDARY
              }
              textColor={
                selectedConditions.find((c) => c.id === condition.id)
                  ? COLORS.TEXT_SECONDARY
                  : COLORS.TEXT
              }
              onClick={() => handleConditionClick(condition)}
            />
          ))}
            </Stack>*/}
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <DefaultButton
            disabled={!video}
            sx={{ alignSelf: "center" }}
            onClick={handleAppointmentClick}
          >
            {t("make_appointment")}
          </DefaultButton>
        </Stack>
        <PsychiatristResourcesModel
          modalOpen={showResourcesModal}
          handleClose={handleCloseModal}
          handleContinue={handleContinueClick}
        />
      </Box>
    </MainPageLayout>
  );
};

export default SchedulePsychiatrist;
