const conditions = [
  {
    id: 1,
    title: "Allergies",
  },
  {
    id: 2,
    title: "Eye Irritation",
  },
  {
    id: 3,
    title: "Laryngitis",
  },
  {
    id: 4,
    title: "Scabies",
  },
  {
    id: 5,
    title: "Acid Reflux",
  },
  {
    id: 6,
    title: "Fever",
  },
  {
    id: 7,
    title: "Mastitis",
  },
  {
    id: 8,
    title: "Sinus Problems",
  },
  {
    id: 9,
    title: "Acute Bronchitis",
  },
  {
    id: 10,
    title: "Furuncles",
  },
  {
    id: 11,
    title: "Medication Refills ",
    info: "*ONLY 2 refills per medication, per year",
  },
  {
    id: 12,
    title: "Sore Throat",
  },
  {
    id: 13,
    title: "Asthma",
  },
  {
    id: 14,
    title: "Gas",
  },
  {
    id: 15,
    title: "Thrush",
  },
  {
    id: 16,
    title: "Bladder Infections ",
    desc: "Females ONLY",
  },
];

export default conditions;
