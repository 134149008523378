import { IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { countryToFlag } from '../../utils/flag.helper'
import { language } from '../../assets/translations/languages';
// import useLanguage from '../../providers/language.provider';
import i18next from 'i18next';

const LanguageButton = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
  const [countryName] = React.useState(language.find(el => i18next.language.substring(0, 2) === el.language)?.shortName)
  // const { changeLocale } = useLanguage();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    const lang = value;
    // const locale = i18next.language.substring(0, 2) === "en" ? "es" : "enUS";
    i18next.changeLanguage(lang);
    // changeLocale(locale);
    window.location.reload();
    handleClose()
  };


  return (
	<>
  <IconButton onClick={handleClick}>
	   <img src={countryToFlag(countryName)} alt='lang'  /> 
  </IconButton>
	  <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        
      >
        {language.map((option) => (
          <MenuItem key={option.name} selected={option === 'Pyxis'} onClick={() => handleChange(option.language)}>
			      <img 
            src={countryToFlag(option.shortName)} 
            alt="language" 
            style={{paddingRight: '5px'}} />
            {option.name}
          </MenuItem>
        ))}
      </Menu>
	</>
  )
}

export default LanguageButton
