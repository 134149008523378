import { Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import Dimens from "../../assets/Dimens";
import { StyledBodyText, StyledCaptionText } from "../common";
import PropTypes from "prop-types";
import { COLORS } from "../../assets/colors";
import { MoreHoriz } from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MedicationRefilItem = (props) => {
  const {
    frequency,
    medicationName,
    comment,
    procedureDate,
    onDeleteClick,
    onEditClick,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation("translations")
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setAnchorEl(null);
    onEditClick();
  };
  const handleDeleteClick = () => {
    setAnchorEl(null);
    onDeleteClick();
  };
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems="flex-start"
      sx={{
        borderBottomColor: COLORS.PRIMARY_MAIN,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        pb: 1,
        pt: 2,
      }}
    >
      <Box>
        <StyledBodyText sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }}>
          {medicationName}
        </StyledBodyText>
        {comment && (
          <StyledBodyText
            sx={{
              fontWeight: Dimens.FONT_WEIGHT_LIGHT,
              fontSize: Dimens.FONT_SIZE_SMALL,
            }}
          >
            {comment}
          </StyledBodyText>
        )}
        {frequency && (
          <StyledCaptionText>{t("currently_using")} - {frequency}</StyledCaptionText>
        )}
        {procedureDate && (
          <StyledCaptionText>
            {moment(procedureDate).format("MM/DD/yyyy")}
          </StyledCaptionText>
        )}
      </Box>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ m: 0, p: 0 }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleEditClick}>Edit</MenuItem> */}
        <MenuItem onClick={handleDeleteClick}>{t("delete")}</MenuItem>
      </Menu>
    </Stack>
  );
};

MedicationRefilItem.propTypes = {
  medicationName: PropTypes.string,
  comment: PropTypes.string,
  frequency: PropTypes.string,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  procedureDate: PropTypes.string,
};

export default MedicationRefilItem;
