import { ErrorOutlined } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../assets/colors";
import Dimens from "../../assets/Dimens";
import {
  DefaultTextField,
  StyledBodyText,
  StyledCaptionText,
} from "../common";
import DefaultButton from "./DefaultButton";
import PropTypes from "prop-types";
import MedicationRefilItem from "./MedicationRefilItem";
import { useTranslation } from "react-i18next";

const MedicalConditionForm = (props) => {
  const { handleSubmit, medicalConditionArray, onDeleteClick, onEditClick, clearMedicalConditionForm } =
    props;
  const {t} = useTranslation("translations")
  const [medicalCondition, setMedicalCondition] = useState({
    conditionName: "",
    description: "",
  });
  const [addRefillDisabled, setAddRefillDisabled] = useState(false);

useEffect(() => {
  clearMedicalConditionForm.current = clearForm
}, [])

const clearForm = () => {
  setMedicalCondition({
    conditionName: "",
    description: "",
  })
}

  useEffect(() => {
    if (medicalCondition.conditionName && medicalCondition.description) {
      setAddRefillDisabled(false);
    } else {
      setAddRefillDisabled(true);
    }
  }, [medicalCondition.conditionName, medicalCondition.description]);
  
  const handleChange = (key, value) => {
    setMedicalCondition({ ...medicalCondition, [key]: value });
  };
  return (
    <Box>
      <StyledBodyText sx={{ mt: 2 }}>{t("medical_conditions")}</StyledBodyText>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          pt: 1,
          pb: 1,
          pl: 1,
          bgcolor: COLORS.PRIMARY_MAIN,
          borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
        }}
      >
        <Stack direction="row" alignItems="center">
          <ErrorOutlined />
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, ml: 2 }}>
            {t("one_condition_at_time")}
          </StyledCaptionText>
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("condition_name")}
          </StyledBodyText>
          <DefaultTextField
            id="conditionName"
            value={medicalCondition.conditionName}
            onChange={(e) => handleChange("conditionName", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("description")}
          </StyledBodyText>
          <DefaultTextField
            value={medicalCondition.description}
            id="description"
            multiline
            minRows={2}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>
      </Grid>
      <Stack alignItems={"center"} sx={{ mt: 2, mb: 3 }}>
        <DefaultButton
          sx={{ bgcolor: COLORS.BUTTON_SECONDARY }}
          disabled={addRefillDisabled}
          onClick={() => handleSubmit(medicalCondition)}
        >
          {t("add_condition")}
        </DefaultButton>
      </Stack>
      {medicalConditionArray.map((el, index) => (
        <MedicationRefilItem
          medicationName={el.medicationName || el.name}
          comment={el.comment || el.description}
          frequency={el.frequency}
          onDeleteClick={() => onDeleteClick(index)}
          onEditClick={() => onEditClick(index)}
        />
      ))}
    </Box>
  );
};
MedicalConditionForm.propTypes = {
  handleSubmit: PropTypes.func,
  medicationRefillArray: PropTypes.array,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  clearMedicalConditionForm: PropTypes.object
};

export default MedicalConditionForm;
