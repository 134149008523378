import "../../../fonts/comfortaa.css";

export const settingsStyles = {
  mainTitle: {
    color: "#001011",
    fontSize: 28,
    fontWeight: 400,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  componentMainTitle: {
    color: "#001011",
    fontSize: 23,
    fontWeight: 700,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  backIcon: {
    border: "2px solid #2E3A59",
    borderRadius: "50%",
    padding: 1,
    fontSize: 14,
    marginRight: 11,
    cursor: "pointer",
  },
  tabStyles: { textTransform: "none", fontSize: 14, color: "#001011" },
  textFieldStyle: {
    marginBottom: 4,
    color: "#515563",
    height: 60,
    // width: "80%",
    borderRadius: "10px 10px 0px 0px",
  },
  inputPropsStyles: {
    color: "#001011",
    fontSize: 16,
    fontWeight: 400,
    background: "#FFFAF5",
    borderBottom: "2px solid #515563",
    height: 60,
    // marginTop: 2,
    borderRadius: "10px 10px 0px 0px",
    "&:hover fieldset": {
      borderBottom: "2px solid #515563!important",
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      borderBottom: "2px solid #515563!important",
    },
  },
  phoneTextfieldContainer: {
    width: "100%", // Set the width to 100% to occupy the full width of the container
    height: 60,
    marginBottom: 30,
    borderBottom: "2px solid #515563",
  },
  phoneTextfieldInput: {
    height: 60,
    width: "100%", // Set the width to 100% to occupy the full width of the input field
    background: "#FFFAF5",
    border: "none",
    borderRadius: "10px 10px 0px 0px",
  },
  passwordReqContainer: {
    width: 360,
    // height: 195,
    borderRadius: 10,
    padding: "30px 20px 35px",
    boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
    zIndex: 2,
    position: "absolute",
    backgroundColor: "#FFFFFF",
    padding: 10,
    marginTop: 20,
  },
  passwordReqTitle: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Comfortaa",
    marginBottom: 5,
  },
  passwordReqItem: { fontSize: 12, fontWeight: 400, marginBottom: 3 },
  textfeildBottomMargin: { marginBottom: 30 },
  pharmacyTitle: {
    fontSize: 17,
    fontWeight: 700,
    fontFamily: "Comfortaa",
    marginBottom: 10,
  },
  currentPharmacyContainer: {
    // height: 110,
    padding: "15px 15px 15px 20px",
    borderRadius: 20,
    backgroundColor: "#fff",
    marginBottom: 40,
  },
  pharmacyDetailsText: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 30,
    color: "#001011",
  },
  addPharmacyDetailsText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#001011",
  },
  linkStyle: { color: "#0B3EB9", cursor: "pointer", fontWeight: 600 },
};
