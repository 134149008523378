import {
  Autocomplete as AutoCompleteMui,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";

const AutoComplete = (props) => {
  const renderInput = (params) => (
    <TextField
      {...params}
      onChange={(event) => {
        props.onInputChange?.call(0, event.target.value);
      }}
      variant="outlined"
      error={props?.error}
      helperText={props?.helperText}
      label={props?.label}
      size="small"
      placeholder={props?.placeholder}
      autoComplete="off"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {props?.loading ? (
              <CircularProgress color="inherit" size={15} />
            ) : null}
          </>
        ),
      }}
    />
  );

  const aParams = {
    autoHighlight: true,
    // filterSelectedItems: true,
    limitTags: props?.limitTags,
    options: props?.options,
    groupBy: props?.groupBy,
    getOptionLabel: props?.getOptionLabel,
    renderOption: props?.renderOption,
    getOptionDisabled: props?.getOptionDisabled,
    onChange: props?.onChange
  };

  return (
    <AutoCompleteMui
      key="Auto-1"
      {...aParams}
      size="small"
      disabled={props?.disabled}
      renderInput={(params) => renderInput(params)}
      isOptionEqualToValue={props?.isOptionEqualToValue}
      value={props?.value}
    />
  );
};

export default AutoComplete;
