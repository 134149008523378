import { Box, Link, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainPageLayout from "../../MainPageLayout";
import DefaultButton from "../../../components/consultations/DefaultButton";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import { Error, Warning } from "@mui/icons-material";
import {
  CheckBox,
  Header,
  StyledBodyText,
  StyledCaptionText,
} from "../../../components/common";
import PriceLabel from "../../../components/consultations/PriceLabel";
import { ehr } from "../../../assets/icons";
import { MemberConsentCard } from "../../../components/consultations";
import Applinks from "../../../assets/route-links";
import useUrgentCare from "../../../providers/urgent-care.provider";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHealthRecordsContext } from "../../../providers/HealthRecordsContext";
import EmrModal from "../../../components/consultations/schedule-consultation/EmrModal";

// STUB DATA EHR to be replaced by actual condition in future

// EHR Missing stub data to be replaced by actual condition in future

const EHRAgreement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const { personalInfo, emrLastUpdated } = useHealthRecordsContext();
  let fieldError = false;

  const { urgentCarePayload, setUrgentCarePayload } = useUrgentCare();
  const [updateInfo, setUpdateInfo] = useState(false);
  const [{ emr, terms, privacyPolicy }, setChecked] = useState({
    emr: false,
    terms: false,
    privacyPolicy: false,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emrModal, setEmrModal] = useState(false);
  // const [missingFields, setMissingFields] = useState('');

  const getMissingFields = (checkField) => {
    const missingFields = [];
    if (personalInfo && personalInfo[0]) {
      Object.keys(personalInfo[0]).forEach((key) => {
        if (!personalInfo[0][key]) {
          missingFields.push(t(key));
        }
      });
    }
    if (missingFields.length > 1) {
      fieldError = true;
      // setMissingFields(missingFields.join(", "))
      return missingFields.join(", ");
    } else {
      setUpdateInfo(true);
      return moment(emrLastUpdated).format("MM/DD/YYYY");
    }
  };

  const handleChange = (key) => {
    setChecked((prevState) => ({ ...prevState, [key]: !prevState[key] }));

    setUrgentCarePayload({
      ...urgentCarePayload,
      problems: {
        ...urgentCarePayload.problems,
        acceptInformedConsent: true,
        acceptInformedConsentDateTime: moment(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      },
    });
  };

  useEffect(() => {
    if (emr && terms && privacyPolicy) {
      setButtonDisabled(false);
    } else if (!updateInfo) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [emr, terms, privacyPolicy, updateInfo]);

  const handleClick = () => {
    if (fieldError) {
      navigate("/health-records/personal-info");
    } else if (!updateInfo) {
      setUpdateInfo(true);
    } else {
      if (!buttonDisabled) {
        navigate(Applinks.MEDICAL_QUESTIONAIRE_URGENT_CARE);
      }
    }
  };

  const handleMemberConsentClick = () => {
    setEmrModal(true);
  };

  const handleEmrModalClose = () => {
    setEmrModal(false);
  };

  return (
    <MainPageLayout>
      <Header
        handleGoBack={() => navigate(-1)}
        title={t("electronic_health_record")}
        subTitle={t("electronic_health_record_info")}
      />
      <Box
        sx={{
          mt: 2,
          width: "100%",
          minHeight: Dimens.EHR_CARD_HEIGHT,
          bgcolor: COLORS.TEXT_SECONDARY,
          borderRadius: Dimens.BORDER_RADIUS_LARGE,
          pt: { md: 0, xs: 2 },
          pb: { md: 0, xs: 2 },
        }}
      >
        {!updateInfo && (
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{ height: "100%" }}
          >
            <Warning />
            <StyledBodyText
              sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT, ml: 1 }}
            >
              {t("incomplete_ehr_info_one")}{" "}
              <strong>{getMissingFields()}</strong>.{" "}
              {t("incomplete_ehr_info_two")}
            </StyledBodyText>
          </Stack>
        )}
        {updateInfo && (
          <Stack
            direction={{ md: "row", xs: "column" }}
            alignItems="center"
            justifyContent={"center"}
            sx={{ height: "100%" }}
          >
            <Error />
            <StyledBodyText
              sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT, ml: 1, mr: 4 }}
            >
              {t("last_health_record_update")}
            </StyledBodyText>
            <PriceLabel title="02/21/2022" />
          </Stack>
        )}
      </Box>
      {updateInfo && (
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          sx={{ mt: 4 }}
          spacing={6}
        >
          <Box
            sx={{
              p: 6,
              bgcolor: COLORS.PRIMARY_MAIN,
              borderRadius: Dimens.BORDER_RADIUS_LARGE,
            }}
          >
            <img src={ehr} width="100%" alt="Report" />
          </Box>
          <Box sx={{ width: { md: "40%", xs: "100%" } }}>
            <CheckBox
              sx={{ mt: 2 }}
              checked={emr}
              onChange={() => handleChange("emr")}
            >
              <StyledCaptionText
                dangerouslySetInnerHTML={{
                  __html: t("electronic_medical_record_certification"),
                }}
              ></StyledCaptionText>
            </CheckBox>
            <CheckBox checked={terms} onChange={() => handleChange("terms")}>
              {t("terms_informed_member_consent")}{" "}
              <a
                style={{ cursor: "pointer" }}
                href="/informed-patient-consent"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={() =>
                //   window.open(
                //     "/informed-patient-consent",
                //     "_blank",
                //     "rel=noopener noreferrer"
                //   )
                // }
                // to={"/informed-patient-consent"}
              >
                {t("informed_member_consent")}
              </a>
              .
            </CheckBox>
            <MemberConsentCard onClick={handleMemberConsentClick} />
            <CheckBox
              checked={privacyPolicy}
              onChange={() => handleChange("privacyPolicy")}
            >
              {t("read_and_agree_with")}{" "}
              <a // to={"/terms-of-services"}
                // onClick={() =>
                //   window.open(
                //     "/terms-of-services",
                //     "_blank",
                //     "rel=noopener noreferrer"
                //   )
                // }
                href="/terms-of-services"
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                {t("terms_of_use")}
              </a>
              ,{" "}
              <a // to={"/privacy-policy"}
                // onClick={() =>
                //   window.open(
                //     "/privacy-policy",
                //     "_blank",
                //     "rel=noopener noreferrer"
                //   )
                // }
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                {t("privacy_policy_and_privacy_practices")}
              </a>
            </CheckBox>
          </Box>
        </Stack>
      )}
      <Stack alignItems={"center"}>
        <DefaultButton
          sx={{ mt: 6 }}
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          {!updateInfo && t("update_information")}
          {updateInfo && t("continue")}
        </DefaultButton>
      </Stack>
      <EmrModal modalOpen={emrModal} handleClose={handleEmrModalClose} />
    </MainPageLayout>
  );
};

export default EHRAgreement;
