import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { modalStyle } from "../../../constant";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import EditModalTextField from "../medications/EditModalTextField";
import ButtonLoader from "../../ButtonLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EditSurgicalHistoryModal = ({
  modalOpen,
  handleClose,
  surgicalHistory,
  setSurgicalHistory,
  handleEditSurgicalHistory,
  matchesSM,
  matchesXS,
  loading,
}) => {
  const { t } = useTranslation("translations");
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        {/*animi. section*/}
        <Grid
          container
          direction={"column"}
          // justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 30px" }}
        >
          <div style={healthRecordsStyles.editModalTitle}>
            {t("edit_surgery")}
          </div>
          <EditModalTextField
            title={t("procedure_name")}
            placeholder={t("add_procedure_name_here")}
            value={surgicalHistory && surgicalHistory.name}
            onChange={(e) => {
              setSurgicalHistory({
                ...surgicalHistory,
                name: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("description")}
            placeholder={t("add_details_here")}
            value={surgicalHistory && surgicalHistory.description}
            multiline={true}
            rows={3}
            onChange={(e) => {
              setSurgicalHistory({
                ...surgicalHistory,
                description: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("date")}
            placeholder={"MM/DD/YYYY"}
            // value={
            //   surgicalHistory &&
            //   moment(surgicalHistory.whenCreated).format("yyyy-MM-DD")
            // }
            value={surgicalHistory && moment(surgicalHistory.procedureDate).format("yyyy-MM-DD")}
            type={"date"}
            onChange={(e) => {
              setSurgicalHistory({
                ...surgicalHistory,
                // whenCreated: e.target.value,
                procedureDate: e.target.value,
              });
            }}
          />
          {loading ? (
            <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              fullWidth
              disabled={
                surgicalHistory &&
                surgicalHistory.name &&
                surgicalHistory.description &&
                // surgicalHistory.whenCreated
                surgicalHistory.procedureDate
                  ? false
                  : true
              }
              style={
                surgicalHistory &&
                surgicalHistory.name &&
                surgicalHistory.description &&
                // surgicalHistory.whenCreated
                surgicalHistory.procedureDate
                  ? healthRecordsStyles.addMedicationButtonEnabled
                  : healthRecordsStyles.addMedicationButtonDisabled
              }
              onClick={() => {
                handleEditSurgicalHistory();
              }}
            >
              {t("save")}
            </Button>
          )}
          <Button
            variant="text"
            fullWidth
            style={healthRecordsStyles.cancelButton}
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditSurgicalHistoryModal;
