import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginDesignPage from "./LoginDesignPage";
import CreateNewPasswordCard from "../../components/login/CreateNewPasswordCard";
import PasswordUpdatedCard from "../../components/login/PasswordUpdatedCard";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreatePassword = () => {
    try {
      setLoading(true);
      if (password === confirmPassword) {
        setPasswordError(null);
        setPasswordUpdated(true);
      } else {
        setPasswordError("Both passwords does not match. Try again");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleNavigateToLogin = () => {
    navigate("/");
  };
  return (
    <LoginDesignPage>
      {passwordUpdated ? (
        <PasswordUpdatedCard handleNavigateToLogin={handleNavigateToLogin} />
      ) : (
        <CreateNewPasswordCard
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleCreatePassword={handleCreatePassword}
          passwordError={passwordError}
          loading={loading}
        />
      )}
    </LoginDesignPage>
  );
};

export default CreateNewPassword;
