import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './english.json';
import spanish from './spanish.json';
import chineese from './chineese.json';
import portuguese from './portuguese.json';
import french from './french.json';
import russian from './russian.json';
import japanese from './japanese.json'
import hindi from'./hindi.json'
import bengali from './bengali.json'
import punjabi from './punjabi.json'

export const resources = {
	es: spanish,
	en: english,
	pt: portuguese,
	cn: chineese,
	fr: french,
	ru: russian,
	jp: japanese,
	in: hindi,
	bd: bengali,
	pn: punjabi,
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === 'development',
		returnNull: false,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		resources,
	});

export default i18n;
