import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getHealthRecords } from "../data/requests/healthRecords";
import { getAllMessages } from "../data/requests/messageCenter";
import useAuth from "./auth.provider";

export const MessageCenterContext = createContext();
export const useMessageCenterContext = () => useContext(MessageCenterContext);

export const MessageCenterProvider = ({ children }) => {
  const { user } = useAuth();

  const [allMessages, setAllMessages] = useState([]);

  useEffect(() => {
    handleGetAllMessages();
  }, [user]);

  // let user = localStorage.getItem("user");
  // console.log("Current user:", JSON.parse(user).userId);

  const handleGetAllMessages = async () => {
    try {
      const healthRecordsTemp = await getAllMessages();
      console.log("all messages:", healthRecordsTemp.asks.all);
      setAllMessages(healthRecordsTemp.asks.all);
    } catch (error) {
      console.log("error getting all messages:", error.message);
    }
  };

  const value = useMemo(
    () => ({
      allMessages,
      setAllMessages,
      handleGetAllMessages,
    }),
    [allMessages]
  );

  return (
    <MessageCenterContext.Provider value={value}>
      {children}
    </MessageCenterContext.Provider>
  );
};
