import { Grid } from "@mui/material";
import React from "react";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

const DataCardNameMenu = ({ name, open, handleClick, hideMenu }) => {
  return (
    <Grid container>
      <div style={{ color: "#001011", fontSize: 16, fontWeight: 400 }}>
        {name && name}
      </div>
      <div style={{ marginLeft: "auto", paddingRight: 30 }}>
        {/*
         */}
        {hideMenu ? null : (
          <MoreHorizOutlinedIcon
            style={{ cursor: "pointer" }}
            id="menu-icon"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
        )}
      </div>
    </Grid>
  );
};

export default DataCardNameMenu;
