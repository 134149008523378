import { Stack, Typography } from "@mui/material";
import React from "react";
import Dimens from "../../../assets/Dimens";
import PropTypes from "prop-types";
import { COLORS } from "../../../assets/colors";

const ScheduleConsultationBox = (props) => {
  const { title, imageUrl, description, onClick, } = props;
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent={!title ? "center" : "space-around"}
      onClick={onClick}
      sx={{
        height: Dimens.CONSULTATION_BOX_DIMENSION,
        width: Dimens.CONSULTATION_BOX_DIMENSION,
        borderRadius: Dimens.CONSULTATION_LIST_ITEM_BORDER_RADIUS,
        bgcolor: COLORS.SECONDARY_MAIN,
        cursor: "pointer",
        textAlign: "center",
        color: COLORS.TEXT_SECONDARY,
      }}
    >
      <img src={imageUrl} />
      {title && (
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontWeight: Dimens.FONT_WEIGHT_BOLDER,
            fontSize: Dimens.FONT_SIZE_MEDIUM,
            lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
          }}
        >
          {title}
        </Typography>
      )}
      <Typography
        sx={{
          fontFamily: "Open Sans",
          fontWeight: Dimens.FONT_WEIGHT_LIGHT,
          fontSize: Dimens.FONT_SIZE_SMALLER,
          lineHeight: Dimens.LINE_HEIGHT_SMALLER,
          mt: !title ? 1 : 0,
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

ScheduleConsultationBox.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default ScheduleConsultationBox;
