import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AccountInfoTab from "./AccountInfoTab";
import { settingsStyles } from "./styles/SettingsStyles";
import PersonalInfoTab from "./PersonalInfoTab";
import PharmacyPreferencesTab from "./PharmacyPreferencesTab";
import TermsOfServicesTab from "./TermsOfServicesTab";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, matchesSM, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpannel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: matchesSM ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpannel-${index}`,
  };
}

const SettingsTabs = ({
  accountInfo,
  setAccountInfo,
  personalInfo,
  setPersonalInfo,
  newPharmacy,
  setNewPharmacy,
  handleUpdateAccountInfo,
  handleUpdatePersonalInfo,
  matchesXS,
  matchesSM,
  matchesMD,
  matchesLG,
  matchesXl,
  loading,
}) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("translations");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ color: "black" }}
          aria-label="Settigs Tabs"
          variant="scrollable"
          textColor={"#001011"}
        >
          <Tab
            label={t("account_information")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: value === 0 ? 700 : undefined,
            }}
            {...a11yProps(0)}
          />
          <Tab
            label={t("personal_information")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: value === 1 ? 700 : undefined,
            }}
            {...a11yProps(1)}
          />
          <Tab
            label={t("pharmacy_preferences")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: value === 2 ? 700 : undefined,
            }}
            {...a11yProps(2)}
          />
          {/*
          <Tab
            label="Notifications"
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: value === 3 ? 700 : undefined,
            }}
            {...a11yProps(3)}
          />
        */}
          <Tab
            label={t("privacy_policy")}
            // label="Terms of Services"
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: value === 3 ? 700 : undefined,
            }}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} matchesSM={matchesSM}>
        <AccountInfoTab
          accountInfo={accountInfo}
          setAccountInfo={setAccountInfo}
          matchesXS={matchesXS}
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesLG={matchesLG}
          matchesXl={matchesXl}
          handleUpdateAccountInfo={handleUpdateAccountInfo}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1} matchesSM={matchesSM}>
        <PersonalInfoTab
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          matchesSM={matchesSM}
          handleUpdatePersonalInfo={handleUpdatePersonalInfo}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={2} matchesSM={matchesSM}>
        <PharmacyPreferencesTab
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          newPharmacy={newPharmacy}
          setNewPharmacy={setNewPharmacy}
        />
      </TabPanel>
      {/*
      <TabPanel value={value} index={3} matchesSM={matchesSM}>
        Notifications
      </TabPanel>
    */}
      <TabPanel value={value} index={3} matchesSM={matchesSM}>
        <TermsOfServicesTab />
      </TabPanel>
    </Box>
  );
};

export default SettingsTabs;
