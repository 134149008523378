import i18next from "i18next";
import React from "react";
// import useLanguage from "../../../providers/language.provider";
import DefaultDropdown from "../default-dropdown/DefaultDropdown";
import { MenuItem } from "@mui/material";
import {  language } from "../../../assets/translations/languages";
import { countryToFlag } from "../../../utils/flag.helper";

const LanguageSelector = () => {
  // const { changeLocale } = useLanguage();

  const handleChange = (value) => {
    const lang = value;
    // const locale = i18next.language.substring(0, 2) === "en" ? "es" : "enUS";
    i18next.changeLanguage(lang);
    window.location.reload();
    // changeLocale(locale);
  };

  return (
    <DefaultDropdown
      handleChange={handleChange}
      showOutline
      value={
        language.find((el) => i18next.language.substring(0, 2) === el.language)
          ?.name
      }
      renderValue={(option) => (
        <MenuItem
          value={option.language}
          sx={{
            fontSize: 14,
            fontWeight: 400,
            // bgcolor: option === value ? "#FFB74D" : "#fff",
            "&:hover": {
              bgcolor: "#FFB74D",
            },
          }}
        >
          <img src={countryToFlag(option.shortName)} alt="language" style={{paddingRight: '10px'}} />
          {option.name}
        </MenuItem>
      )}
      onChange={(e) => handleChange(e.target.value)}
      options={language}
    />
  );
};

export default LanguageSelector;
