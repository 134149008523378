import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { COLORS } from "../../assets/colors";
import { StyledCaptionText } from "../common";
import Dimens from "../../assets/Dimens";

const BehavioralCardItem = (props) => {
  const { icon, title } = props;
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        pt: 1.5,
        pl: 3.3,
        pr: 3.3,
        pb: 1.5,
        bgcolor: COLORS.BUTTON_SECONDARY,
        borderRadius: Dimens.BORDER_RADIUS_LARGE,
        width: "150px",
        height: "150px",
      }}
    >
      {icon}
      <StyledCaptionText sx={{ pt: 1, textAlign: 'center' }}>{title}</StyledCaptionText>
    </Stack>
  );
};

BehavioralCardItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
};

export default BehavioralCardItem;
