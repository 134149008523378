import "../../../fonts/comfortaa.css";

export const layoutStyles = {
  notificationMainBox: {
    width: 109,
    heigh: 46,
    borderRadius: 15,
    background: "#fff",
    marginRight: 20,
    padding: 8,
  },
  notificationCountBox: {
    width: 47,
    height: 35,
    borderRadius: 10,
    background: "#FFB74D",
    marginLeft: 14,
  },
  logoutIcon: { width: 29, height: 29, color: "#515563" },
  sideDrawerBox: {
    width: 270,
    height: 720,
    borderRadius: 10,
    background: "#FFB74D",
    paddingTop: 6,
    paddingLeft: 1.5,
    paddingRight: 2,
    marginLeft: 1,
  },
  selectedDrawerItem: {
    background: "#0D3B66",
    borderRadius: 10,
    marginBottom: 10,
    color: "#fff",
    marginBottom: 15,
  },
  drawerItem: {
    marginBottom: 10,
    color: "#001011",
    marginBottom: 15,
  },
  drawerText: { fontSize: 17, fontWeight: 700, fontFamily: "Comfortaa" },
  drawerIcon: { color: "#001011" },
  selectedDrawerIcon: { color: "#FFF" },
  footerPhoneText: {
    color: "#001011",
    fontSize: 12,
    fontWeight: 700,
    marginTop: 40,
  },
  footerPhoneNumber: {
    color: "#001011",
    fontSize: 12,
    fontWeight: 400,
    marginTop: 12,
  },
  footerListTitle: {
    color: "#001011",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 8,
    fontFamily: "Comfortaa",
    
  },
  footerListItem: {
    color: "#001011",
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 8,
    cursor: "pointer",
  },
};
