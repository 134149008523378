import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { modalStyle } from "../../../constant";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import EditModalTextField from "../medications/EditModalTextField";
import { useTranslation } from "react-i18next";

const EditAllergyModal = ({
  modalOpen,
  handleClose,
  allergy,
  setAllergy,
  handleEditAllergy,
  matchesSM,
  matchesXS,
}) => {
  const {t} = useTranslation("translations")
  //   console.log(typeof handleEditAllergy)
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        <Grid
          container
          direction={"column"}
          // justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 30px" }}
        >
          <div style={healthRecordsStyles.editModalTitle}>
            {t("edit_medication_allergy")}
          </div>
          <EditModalTextField
            title={t("drug_allergy_name")}
            placeholder={t("add_allergy_name_here")}
            value={allergy && allergy.allergymedicationname}
            onChange={(e) => {
              setAllergy({
                ...allergy,
                allergymedicationname: e.target.value,
              });
            }}
          />

          <Button
            variant="contained"
            fullWidth
            disabled={allergy && allergy.allergymedicationname ? false : true}
            style={
              allergy && allergy.allergymedicationname
                ? healthRecordsStyles.addMedicationButtonEnabled
                : healthRecordsStyles.addMedicationButtonDisabled
            }
            onClick={() => {
              handleEditAllergy();
            }}
          >
            {t("save")}
          </Button>
          <Button
            variant="text"
            fullWidth
            style={healthRecordsStyles.cancelButton}
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditAllergyModal;
