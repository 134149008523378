import { Box, Menu, Stack } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { StyledBodyText, StyledCaptionText } from "..";
import { COLORS } from "../../../assets/colors";
import { clockCalender } from "../../../assets/icons";

const DatePicker = (props) => {
  const { handleSubmit, value } = props;
  const [date, setDate] = useState(value || new Date());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectTime = (value) => {
    setDate(value?.$d);
    setAnchorEl(null);
    handleSubmit(value?.$d);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: `1px solid ${COLORS.DISABLED}`, pb: 1 }}
        onClick={handleClick}
      >
        <img src={clockCalender} alt="Calender" />
        <StyledCaptionText sx={{ ml: 1 }}>
          {moment(date).format("ddd, MMM DD")}
        </StyledCaptionText>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row">
            <Box>
              <StyledBodyText sx={{ textAlign: "center", mb: 2 }}>
                Enter Date
              </StyledBodyText>
              <DateCalendar value={dayjs(date)} onChange={handleSelectTime} />
            </Box>
          </Stack>
        </LocalizationProvider>
      </Menu>
    </Box>
  );
};

export default DatePicker;
