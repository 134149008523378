import "../../../fonts/comfortaa.css";

export const healthRecordsStyles = {
  mainTitle: {
    color: "#001011",
    fontSize: 28,
    fontWeight: 400,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  componentMainTitle: {
    color: "#001011",
    fontSize: 23,
    fontWeight: 700,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  backIcon: {
    border: "2px solid #2E3A59",
    borderRadius: "50%",
    padding: 1,
    fontSize: 14,
    marginRight: 11,
    cursor: "pointer",
  },
  messageText: {
    color: "#001011",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 23,
    textAlign: "justify",
  },
  healthRecordsBox: {
    width: 180,
    height: 180,
    borderRadius: 20,
    background: "#0D3B66",
    marginRight: 80,
    marginBottom: 60,
    cursor: "pointer",
  },
  healthRecordsBoxText: {
    fontSize: 12,
    fontWeight: 400,
    color: "#fff",
    marginTop: 30,
  },
  personalInfoMainBox: {
    borderRadius: 20,
    padding: 40,
    background: "#fff",
    marginTop: 30,
  },
  medicationWhiteBox: {
    borderRadius: 20,
    padding: 40,
    background: "#fff",
    marginTop: 30,
  },
  textfieldsTitle: {
    marginBottom: 5,
    color: "#001011",
    fontSize: 17,
    fontWeight: 700,
    fontFamily: "Comfortaa",
  },
  selectMenuItem: {
    color: "#001011",
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
    // paddingLeft: 200,
  },
  medicationInputLabelSX: {
    color: "#515563",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
    padding: "0px 10px",
  },
  medicationTextFieldSX: {
    padding: "14px 0px",
    fontSize: "16px",
    fontWeight: 400,
    color: "#515563",
  },
  addMedicationButtonDisabled: {
    marginTop: 20,
    borderRadius: 60,
    height: 50,
    background: "#515563",
    fontSize: 13,
    fontWeight: 400,
    color: "#fff",
  },
  addMedicationButtonEnabled: {
    marginTop: 20,
    borderRadius: 60,
    height: 50,
    background: "#19A6FE",
    fontSize: 13,
    fontWeight: 400,
    color: "#001011",
  },
  editModalTitle: {
    color: "#000000",
    fontSize: 19,
    fontWeight: 500,
    marginTop: 4,
    marginBottom: "40px",
    fontFamily: "Comfortaa",
  },
  cancelButton: {
    color: "#0D3B66",
    fontWeight: 400,
    fontSize: 13,
    marginTop: 20,
  },
  declineButton: {
    color: "#0D3B66",
    borderColor: "#0D3B66",
    fontWeight: 400,
    fontSize: 13,
    marginTop: 10,
    borderRadius: 50,
    height: 50
  },
  okayButtonStyles: {
    borderRadius: 90,
    height: 55,
    fontSize: 13,
    fontWeight: 500,
    background: "#FFB74D",
    color: "#001011",
  },
  deleteModalTitle: {
    color: "#000000",
    fontSize: 23,
    fontWeight: 700,
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Comfortaa",
  },
  deleteModalMessage: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 30,
    fontFamily: "Comfortaa",
    textAlign: "center",
  },
};
