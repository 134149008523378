import React, { Fragment } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";

import { healthRecordsStyles } from "../styles/healthRecordsStyles";

const EditModalTextField = ({
  title,
  placeholder,
  value,
  onChange,
  multiline,
  rows,
  select,
  type,
  MedicalHistory,
}) => {
  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        marginBottom: 20,
      }}
    >
      <div style={healthRecordsStyles.textfieldsTitle}>{title} </div>
      <TextField
        placeholder={placeholder}
        variant="standard"
        value={value}
        defaultValue={value}
        multiline={multiline}
        rows={rows}
        type={type}
        onChange={(e) => onChange(e)}
        inputProps={{
          sx: healthRecordsStyles.medicationTextFieldSX,
        }}
        InputLabelProps={{
          sx: healthRecordsStyles.medicationInputLabelSX,
        }}
        select={select}
      >
        {select && (
          <MenuItem
            value={MedicalHistory ? 1 : "Yes"}
            style={{
              ...healthRecordsStyles.selectMenuItem,
              background: value === "Yes" ? "#FFB74D" : "#fff",
            }}
          >
            {MedicalHistory ? "Currently in such condition" : "Yes"}
          </MenuItem>
        )}
        {select && (
          <MenuItem
            value={MedicalHistory ? 2 : "No"}
            style={{
              ...healthRecordsStyles.selectMenuItem,
              background: value === "No" ? "#FFB74D" : "#fff",
            }}
          >
            {MedicalHistory ? "Not in such condition" : "No"}
          </MenuItem>
        )}
      </TextField>
    </Grid>
  );
};

export default EditModalTextField;
