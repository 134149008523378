import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const Tabbar = () => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const { t } = useTranslation("translations");
  const [sideDrawerItems] = useState([
    {
      title: t("dashboard"),
      icon: <HomeIcon />,
      id: 1,
      selected: true,
      path: "/dashboard",
    },
    {
      title: t("health_records"),
      icon: <ReceiptLongIcon />,
      id: 2,
      selected: false,
      path: "/health-records",
    },
    {
      title: t("consultations"),
      icon: <MedicalServicesIcon />,
      id: 3,
      selected: false,
      path: "/consultations",
    },
    {
      title: t("message_center"),
      icon: <NotificationsIcon />,
      id: 4,
      selected: false,
      path: "/message-center",
    },
    {
      title: t("settings"),
      icon: <SettingsSharpIcon />,
      id: 5,
      selected: false,
      path: "/settings",
    },
  ]);
  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          display: { md: "none", xs: "block" },
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          // value={value}
          // onChange={(event, newValue) => {
          // 	setValue(newValue);
          // }}
        >
          {sideDrawerItems.map((el) => (
            <Link
              style={{
                textDecoration: "none",
                margin: -5,
                paddingTop: 8,
              }}
              component={RouterLink}
              to={el.path}
            >
              <BottomNavigationAction
                sx={{ p: 0, m: 0 }}
                key={el.id}
                label={el.title}
                icon={el.icon}
              />
            </Link>
          ))}
        </BottomNavigation>
      </Paper>
    </ThemeProvider>
  );
};

export default Tabbar;
