import { Box } from "@mui/material";
import React from "react";
import { COLORS } from "../../assets/colors";

const PriceLabel = (props) => {
  const { title } = props;
  return (
    <Box
      component="span"
      sx={{
        border: "1px solid #19A6FE",
        pl: "30px",
        pr: "30px",
        pt: "4px",
        pb: "4px",
        borderRadius: 100,
        fontSize: "14px",
        bgcolor: COLORS.TEXT_SECONDARY,
      }}
    >
      {title}
    </Box>
  );
};

export default PriceLabel;
