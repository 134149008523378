import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import NoMedicationsAdded from "../../components/healthRecords/medications/NoMedicationsAdded";
import MedicationCard from "../../components/healthRecords/medications/MedicationCard";
import AddMedicationCard from "../../components/healthRecords/medications/AddMedicationCard";
import EditMedicationModal from "../../components/healthRecords/medications/EditMedicationModal";
import DeleteMedicationModal from "../../components/healthRecords/medications/DeleteMedicationModal";
import MedicationDeletedModal from "../../components/healthRecords/medications/MedicationDeletedModal";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import {
  addMedication,
  deleteMedication,
  markInactiveMedication,
  searchMedication,
} from "../../data/requests/healthRecords";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const Medications = () => {
  const { user } = useAuth();

  const { medications, handleGetHealthRecords } = useHealthRecordsContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation("translations");

  // let user = JSON.parse(localStorage.getItem("user"));

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  // console.log("medicaions:", medications);

  const [newMedication, setNewMedication] = useState({
    name: "",
    comment: "",
    isCurrentlyUsing: 1,
    frequency: "",
  });
  const [searchedMedication, setSearchedMedication] = useState([]);
  const [selectedSearchedMedication, setselectedSearchedMedication] =
    useState(null);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editMedicationModal, setEditMedicationModal] = useState(false);
  const [deleteMedicationModal, setDeleteMedicationModal] = useState(false);
  const [medicationDeletedModal, setMedicationDeletedModal] = useState(false);

  const [medicationsData, setMedicationsData] = useState([]);

  useEffect(() => {
    medications && setMedicationsData(medications);
  }, [medications]);

  const handleEditMedicationModalOpen = () => {
    setEditMedicationModal(true);
  };
  const handleEditMedicationModalClose = () => {
    setEditMedicationModal(false);
  };
  const handleDeleteMedicationModalOpen = () => {
    setDeleteMedicationModal(true);
  };
  const handleDeleteMedicationModalClose = () => {
    setDeleteMedicationModal(false);
  };
  const handleMedicationDeletedModalOpen = () => {
    handleDeleteMedicationModalClose();
    setMedicationDeletedModal(true);
  };
  const handleMedicationDeletedModalClose = () => {
    setMedicationDeletedModal(false);
  };

  const handleSearchMedication = async (value) => {
    setNewMedication({ ...newMedication, name: value });
    try {
      const responseTemp = await searchMedication(value);
      setSearchedMedication(responseTemp.suggestions);
      console.log("search response:", responseTemp);
    } catch (error) {
      console.log("Error searching Medication", error.message);
    } finally {
    }
  };

  const handleAddMedication = async () => {
    try {
      setLoading(true);

      const newData = {
        medicationName: newMedication.name,
        medicationFrequency: newMedication.frequency,
        medicationComment: newMedication.comment,
        medicationCurrentUse:
          newMedication.isCurrentlyUsing === 1 ? true : false,
        medicationForeignId: selectedSearchedMedication.medicationId,
        medicationNDC: selectedSearchedMedication.ndc,
      };

      const responseTemp = await addMedication(newData, user.userId);
      console.log("add medication response:", responseTemp);
      await handleGetHealthRecords();

      // const updatedDataTemp = [...medicationsData];
      // const newId = medicationsData.length + 1;

      // const newMed = {
      //   id: newId,
      //   name: newMedication.name,
      //   comment: newMedication.comment,
      //   isCurrentlyUsing: newMedication.isCurrentlyUsing,
      //   frequency: newMedication.frequency,
      // };

      // updatedDataTemp.push(newMed);
      // setMedicationsData(updatedDataTemp);

      // Reset the newMedication state
      setNewMedication({
        name: "",
        comment: "",
        isCurrentlyUsing: 1,
        frequency: "",
      });
    } catch (error) {
      console.log("error adding medication:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditMedication = () => {
    try {
      setModalLoading(true);
      console.log("selected Medication:", selectedMedication);
      const updatedDataTemp = [...medicationsData];
      const index = updatedDataTemp.findIndex(
        (medication) => medication.id === selectedMedication.id
      );

      if (index !== -1) {
        updatedDataTemp[index] = selectedMedication;
        console.log(updatedDataTemp);
        // setTimeout(() => {
        setMedicationsData(updatedDataTemp);
        setSelectedMedication(null);
        // }, 1000);
      }

      handleEditMedicationModalClose();
    } catch (error) {
      console.log("error editing medication:", error.message);
    } finally {
      setModalLoading(false);
    }
  };

  const handleMarkInactiveMedication = async (medicationId) => {
    try {
      const responseTemp = await markInactiveMedication(
        medicationId,
        user.userId
      );
      console.log("mark inactive medication response:", responseTemp);
      await handleGetHealthRecords();
    } catch (error) {
      console.log("error editing medication:", error.message);
    }
  };

  const handleDeleteMedication = async () => {
    try {
      setModalLoading(true);
      const responseTemp = await deleteMedication(
        selectedMedication.userMedication_id,
        user.userId
      );
      console.log("delete medication response:", responseTemp);
      await handleGetHealthRecords();
      // const updatedDataTemp = medicationsData.filter(
      //   (medication) => medication.id !== selectedMedication.id
      // );

      // setMedicationsData(updatedDataTemp);
      handleMedicationDeletedModalOpen();
      setSelectedMedication(null);
    } catch (error) {
      console.log("error deleting medication:", error.message);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          {" "}
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("medications")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("medications_info")}
        </div>
        {matchesXS || matchesSM || matchesMD ? (
          <Grid container direction={"column"}>
            <Grid item container xs>
              <AddMedicationCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXS={matchesXS}
                loading={loading}
                newMedication={newMedication}
                setNewMedication={setNewMedication}
                handleAddMedication={handleAddMedication}
                handleSearchMedication={handleSearchMedication}
                searchedMedication={searchedMedication}
                setselectedSearchedMedication={setselectedSearchedMedication}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {medicationsData && medicationsData.length > 0 ? (
                medicationsData.map((data, index) => (
                  <MedicationCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedMedication={setSelectedMedication}
                    handleEditMedicationModalOpen={
                      handleEditMedicationModalOpen
                    }
                    handleDeleteMedicationModalOpen={
                      handleDeleteMedicationModalOpen
                    }
                    handleMarkInactiveMedication={handleMarkInactiveMedication}
                  />
                ))
              ) : (
                <NoMedicationsAdded text={"No medications added yet."} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={"row"}>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {medicationsData && medicationsData.length > 0 ? (
                medicationsData.map((data, index) => (
                  <MedicationCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedMedication={setSelectedMedication}
                    handleEditMedicationModalOpen={
                      handleEditMedicationModalOpen
                    }
                    handleDeleteMedicationModalOpen={
                      handleDeleteMedicationModalOpen
                    }
                    handleMarkInactiveMedication={handleMarkInactiveMedication}
                  />
                ))
              ) : (
                <NoMedicationsAdded text={"No medications added yet."} />
              )}
            </Grid>

            <Grid item container xs>
              <AddMedicationCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXS={matchesXS}
                loading={loading}
                newMedication={newMedication}
                setNewMedication={setNewMedication}
                handleAddMedication={handleAddMedication}
                handleSearchMedication={handleSearchMedication}
                searchedMedication={searchedMedication}
                setselectedSearchedMedication={setselectedSearchedMedication}
              />
            </Grid>
          </Grid>
        )}

        <EditMedicationModal
          modalOpen={editMedicationModal}
          handleClose={handleEditMedicationModalClose}
          medication={selectedMedication}
          setMedication={setSelectedMedication}
          handleEditMedication={handleEditMedication}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={modalLoading}
        />
        <DeleteMedicationModal
          modalOpen={deleteMedicationModal}
          title={t("delete_medication")}
          message={t("delete_medication_info")}
          handleClose={handleDeleteMedicationModalClose}
          handleDelete={handleDeleteMedication}
          medication={selectedMedication}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={modalLoading}
        />
        <MedicationDeletedModal
          modalOpen={medicationDeletedModal}
          title={t("medicaltion_deleted")}
          handleClose={handleMedicationDeletedModalClose}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default Medications;
