import { Box, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import PropTypes from "prop-types";
import { StyledCaptionText, StyledBlueBoxText } from "../../common";

const ServiceAvailablityBox = (props) => {
  const {
    initialFeeKey,
    initialFeeValue,
    initialFeeCaption,
    visitFeeKey,
    visitFeeValue,
    visitFeeCaption,
    availabilityKey,
    availabilityValue,
    availabilityCaption,
  } = props;

  return (
    <Stack
      direction={{md: "row", xs: "column"}}
      alignItems="center"
      justifyContent={"space-evenly"}
      sx={{
        minHeight: Dimens.SERVICE_AVAILABILITY_BOX_HEIGHT,
        width: {
          md: Dimens.SERVICE_AVAILABILITY_BOX_WIDTH,
          xs: Dimens.SERVICE_AVAILABILITY_BOX_WIDTH_MOBILE,
        },
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        borderRadius: Dimens.CONSULTATION_LIST_ITEM_BORDER_RADIUS,
        bgcolor: COLORS.TEXT_SECONDARY,
        p: {md: 0, xs: 1}
      }}
    >
      <Box>
        <StyledCaptionText align="center">{initialFeeKey}</StyledCaptionText>
        <StyledBlueBoxText sx={{ mt: 0.5 }}>
          {initialFeeValue}
          {initialFeeCaption && (
            <StyledCaptionText align="center">Service fee</StyledCaptionText>
          )}
        </StyledBlueBoxText>
      </Box>
      {visitFeeKey && (
        <>
          <Box
            sx={{ height: "80%", width: "1px", bgcolor: COLORS.PRIMARY_MAIN }}
          />
          <Box>
            <StyledCaptionText align="center">{visitFeeKey}</StyledCaptionText>
            <StyledBlueBoxText sx={{ mt: 0.5 }}>
              {visitFeeValue}
              {visitFeeCaption && (
                <StyledCaptionText align="center">
                  {visitFeeCaption}
                </StyledCaptionText>
              )}
            </StyledBlueBoxText>
          </Box>
        </>
      )}
      <Box sx={{ height: "80%", width: "1px", bgcolor: COLORS.PRIMARY_MAIN }} />
      <Box>
        <StyledCaptionText align="center">{availabilityKey}</StyledCaptionText>
        <StyledBlueBoxText sx={{ mt: 0.5 }}>
          {availabilityValue}
          {availabilityCaption && (
            <StyledCaptionText align="center">
              {availabilityCaption}
            </StyledCaptionText>
          )}
        </StyledBlueBoxText>
      </Box>
    </Stack>
  );
};

ServiceAvailablityBox.propTypes = {
  initialFeeKey: PropTypes.string,
  initialFeeValue: PropTypes.string,
  initialFeeCaption: PropTypes.string,
  visitFeeKey: PropTypes.string,
  visitFeeValue: PropTypes.string,
  visitFeeCaption: PropTypes.string,
  availabilityKey: PropTypes.string,
  availabilityValue: PropTypes.string,
  availabilityCaption: PropTypes.string,
};

export default ServiceAvailablityBox;
