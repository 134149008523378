import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { modalStyle } from "../../../constant";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const DeleteMedicationModal = ({
  modalOpen,
  title,
  message,
  handleClose,
  handleDelete,
  matchesSM,
  matchesXS,
  loading,
}) => {
  const {t} = useTranslation("translations")
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 60px" }}
        >
          <DeleteForeverOutlinedIcon
            style={{ width: 60, height: 70, color: "#0D3B66" }}
          />
          <div style={healthRecordsStyles.deleteModalTitle}>{title}</div>
          <div style={healthRecordsStyles.deleteModalMessage}>{message}</div>
          {loading ? (
            <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleDelete}
              style={healthRecordsStyles.addMedicationButtonEnabled}
            >
              {t("accept")}
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={healthRecordsStyles.declineButton}
          >
            {t("decline")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteMedicationModal;
