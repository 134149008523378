const Dimens = {
  CONSULTATION_LIST_ITEM_HEIGHT: "106px",
  CONSULTATION_LIST_ITEM_BORDER_RADIUS: "20px",
  CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL: "32px",
  CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL_MOBILE: "10px",
  CONSULTATION_LIST_ITEM_IMAGE_HEIGHT: "48px",

  CONSULTATION_BOX_DIMENSION: "200px",
  CONSULTATION_MODEL_PADDING_HORIZANTAL: "106px",
  WARNING_ICON_DIMENSION: "66px",

  EHR_CARD_HEIGHT: "70px",

  PADDING_TOP_SMALL: "10px",
  PADDING_TOP_MEDIUM: "20px",

  CARD_PADDING_HORIZANTAL: "60px",
  CARD_PADDING_HORIZANTAL_MOBILE: "10px",
  CARD_PADDING_VERTICAL: "44px",
  CARD_PADDING_VERTICAL_MOBILE: "10px",

  BORDER_RADIUS_MEDIUM: "10px",
  BORDER_RADIUS_LARGE: "20px",

  SERVICE_AVAILABILITY_BOX_HEIGHT: "86px",
  SERVICE_AVAILABILITY_BOX_WIDTH: "361px",
  SERVICE_AVAILABILITY_BOX_WIDTH_MOBILE: "250px",

  CALL_TYPE_CARD_HEIGHT: "104px",
  CALL_TYPE_CARD_WIDTH: "315px",
  CALL_TYPE_CARD_WIDTH_MOBILE: "220px",

  ICON_BOX_DIMENSION: "54px",

  BOX_LABEL_HEIGHT: "18px",

  // FONT WEIGHTS
  FONT_WEIGHT_LIGHTER: "300",
  FONT_WEIGHT_LIGHT: "400",
  FONT_WEIGHT_NORMAL: "500",
  FONT_WEIGHT_BOLD: "600",
  FONT_WEIGHT_BOLDER: "700",

  // FONT SIZES

  FONT_SIZE_SMALLER: "12px",
  FONT_SIZE_SMALL: "14px",
  FONT_SIZE_MEDIUM: "17px",
  FONT_SIZE_LARGE: "19px",
  FONT_SIZE_EXTRA_LARGE: "23px",
  // LINE HEIGHTS
  LINE_HEIGHT_SMALLER: "14px",
  LINE_HEIGHT_SMALL: "20px",
  LINE_HEIGHT_MEDIUM: "22px",
  LINE_HEIGHT_LARGE: "30px",

  // FONT BOX
  SERVICE_AVAILABILITY_BOX_TEXT_PADDING_HORIZANTAL: "18.5px",
  SERVICE_AVAILABILITY_BOX_TEXT_PADDING_VERTICAL: "5px",

  // TEXT INPUT

  TEXT_INPUT_HEIGHT: "40px",
  TEXT_INPUT_RADIUS: "30px",
};

export default Dimens;
