import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";

import MainPageLayout from "../MainPageLayout";
import TopHeaderSection from "../../components/messaging/newQuestion/TopHeaderSection";
import AddQuestionCard from "../../components/messaging/newQuestion/AddQuestionCard";

import UploadFileCard from "../../components/messaging/newQuestion/UploadFileCard";
import MessageSentModal from "../../components/messaging/newQuestion/MessageSentModal";
import ErrorModal from "../../components/messaging/newQuestion/ErrorModal";
import { sendNewMessage } from "../../data/requests/messageCenter";
import { useMessageCenterContext } from "../../providers/MessageCenterContext";
import { useTranslation } from "react-i18next";

const NewQuestion = () => {
  const { handleGetAllMessages } = useMessageCenterContext();

  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [searchValue, setSearchValue] = useState("");
  const [newMessage, setNewMessage] = useState({ question: "", details: "" });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [countryState, setCountryState] = useState(null);

  const [messageSentModal, setMessageSentModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMessageSentModalOpen = () => {
    setMessageSentModal(true);
  };
  const handleMessageSentModalClose = () => {
    setMessageSentModal(false);
  };
  const handleErrorModalOpen = () => {
    setErrorModal(true);
  };
  const handleErrorModalClose = () => {
    setErrorModal(false);
  };

  const handleSearchMessages = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSaveMessage = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      if (uploadedFiles.length > 0) {
        // formData.append("attachments", uploadedFiles);
        // uploadedFiles?.forEach((item) => {
        //   formData.append("AttachmentFile", item);
        // });
        formData.append("AttachmentFile", uploadedFiles[0]);
        // console.log("file::::", uploadedFiles[0]);
      }
      formData.append("subject", newMessage.question);
      formData.append("details", newMessage.details);
      // formData.append("askADoctorThreadId", 0);
      // formData.append("askADoctorThreadId", 5529);
      // let newData = {
      //   subject: "You",
      //   details: newMessage,
      //   askADoctorThreadId: selectedMessage.askADoctorThreadId,
      // };
      console.log("new message:::", newMessage);

      const responseTemp = await sendNewMessage(
        formData,
        uploadedFiles.length > 0 ? true : undefined
      );
      console.log("reply message repsonse:", responseTemp);
      await handleGetAllMessages();
      setNewMessage({ question: "", details: "" });
      setUploadedFiles([]);
      handleMessageSentModalOpen();
      navigate("/message-center");
    } catch (error) {
      console.log("error sending new message:", error.message);
    } finally {
      setLoading(false);
    }
    // Add the new message to the messagesData array
    // let temp = { ...newMessage };
    // setMessagesData(temp);
  };

  //   console.log("message:", newMessage);

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <TopHeaderSection
          matchesLG={matchesLG}
          matchesMD={matchesMD}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          navigate={navigate}
          searchValue={searchValue}
          title={"New Message"}
          handleSearchMessages={handleSearchMessages}
        />
        {matchesXS || matchesSM || matchesMD ? (
          <Grid
            container
            //   direction={matchesMD ? "column" : "row"}
            justifyContent={"space-between"}
          >
            <UploadFileCard
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              matchesSM={matchesSM}
              matchesXS={matchesXS}
              matchesMD={matchesMD}
            />
            <AddQuestionCard
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              countryState={countryState}
              setCountryState={setCountryState}
              matchesSM={matchesSM}
              handleSaveMessage={handleSaveMessage}
              loading={loading}
            />
          </Grid>
        ) : (
          <Grid
            container
            //   direction={matchesMD ? "column" : "row"}
            justifyContent={"space-between"}
          >
            <AddQuestionCard
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              countryState={countryState}
              setCountryState={setCountryState}
              matchesSM={matchesSM}
              handleSaveMessage={handleSaveMessage}
              loading={loading}
            />
            <UploadFileCard
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              matchesSM={matchesSM}
              matchesXS={matchesXS}
              matchesMD={matchesMD}
            />
          </Grid>
        )}

        <MessageSentModal
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          modalOpen={messageSentModal}
          handleClose={handleMessageSentModalClose}
          title={t("your_message_was_sent")}
        />
        <ErrorModal
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          modalOpen={errorModal}
          handleClose={handleErrorModalClose}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default NewQuestion;
