import axios from "axios";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../../utils/storage.utils";
import { Endpoints } from "../../endpoints";
import { mtmReqGet, mtmReqPost } from "../../request-mtm.api";
import { reqGet, reqPost } from "../../request.api";

const token = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);

export const getAllConsultations = async (status) => {
  const resp = await reqPost({
    url: `${Endpoints.GET_ALL_CONSULTATION}?status=${status}&start=0&length=10`,
  });
  const { data } = resp;

  if (status === "new") {
    const pollingResp = await reqGet({
      url: `${Endpoints.POLLING}?status=polling&start=0&length=10`,
    });
    const filteredData = pollingResp.data.requestedConsultations.filter(
      (item) => item.status === "ConsultRequestCreated"
    );
    let customizedData = [];
    filteredData.forEach((item) => {
      customizedData.push({
        ...item,
        physician: {
          firstName: item.physicianFirstName,
          lastName: item.physicianLastName,
        },
        friendlySubTypeName: item.consultationSubType,
        modality: item.modalities,
      });
    });
    data.data = [...data.data, ...customizedData];
    data?.data?.sort(
      (a, b) => new Date(b.whenScheduled) - new Date(a.whenScheduled)
    );
  }
  if (status === "inprogress") {
    const pollingResp = await reqGet({
      url: `${Endpoints.POLLING}?status=polling&start=0&length=10`,
    });
    const filteredData = pollingResp.data.requestedConsultations.filter(
      (item) => item.status === "InProgress"
    );
    let customizedData = [];
    filteredData.forEach((item) => {
      customizedData.push({
        ...item,
        physician: {
          firstName: item.physicianFirstName,
          lastName: item.physicianLastName,
        },
        friendlySubTypeName: item.consultationSubType,
        modality: item.modalities,
      });
    });
    data.data = [...data.data, ...customizedData];
    data?.data?.sort(
      (a, b) => new Date(b.whenScheduled) - new Date(a.whenScheduled)
    );
  }
  return data;
};
export const cancelConsultations = async (
  consultId,
  cancellationExplanation
) => {
  const resp = await reqPost({
    url: `${Endpoints.CANCEL_CONSULTATION}?consultId=${consultId}&cancellationExplanation=${cancellationExplanation}`,
  });
  const { data } = resp;
  return data;
};

export const getPatientAndConsultObject = async (userId, modality) => {
  const resp = await mtmReqGet({
    url: `${Endpoints.GET_PATIENT_AND_CONSULT_OBJECT}?user_id=${userId}&modality=${modality}`,
  });
  const { data } = resp;
  return data;
};

export const addUrgentCareConsultation = async (params) => {
  const resp = await mtmReqPost({
    url: `${
      Endpoints.CREATE_URGENT_CARE_CONSULTATION
    }?payload=${encodeURIComponent(JSON.stringify(params))}`,
  });
  const { data } = resp;
  return data;
};

export const getProviders = async (date, gender, userId, stateId) => {
  const resp = await mtmReqGet({
    url: `${Endpoints.GET_PROVIDERS}?date=${date}&state=${stateId}&${
      gender ? `gender=${gender}` : ""
    }&userId=${userId}&pageNumber=1&consultation_type=psychiatry`,
  });
  const { data } = resp;
  return data;
};

export const createPsychiatristConsultation = async (params) => {
  // const resp = await mtmReqPost({
  //   url: `${
  //     Endpoints.CREATE_PSYCHIATRIST_CONSULTATION
  //   }?payload=${encodeURIComponent(JSON.stringify(params))}`,
  // });
  // const { data } = resp;
  // return data;
  ////////////////////
  console.log("PSY-OBJ",JSON.stringify(params,null,2))
  let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_MTM_BASE_URL || Endpoints.MTM_BASE_URL}/consultation/createConsultation/psychiatry`,
      headers: {
        'Authorization': `token ${token}`,
        'Content-Type': 'application/json',
      },
      data : params
    };

    const res = axios(config);
    return res
    /////////////////////////////////
  // console.log("data::", params);
  // console.log("token::", token);
  // const resp = await axios({
  //   method: "POST",
  //   url: `${process.env.REACT_APP_MTM_BASE_URL || Endpoints.MTM_BASE_URL}${
  //     Endpoints.CREATE_PSYCHIATRIST_CONSULTATION
  //   }`,
  //   // url: `${Endpoints.MTM_BASE_URL}${Endpoints.CREATE_PSYCHIATRIST_CONSULTATION}`,
  //   params,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
  // console.log("resp", resp);
  // const res = resp;
  // return res.data;
};

export const getEligibility = async () => {
  const [
    psychiatryEligibilityResp,
    urgentCareEligibilityResp,
    studentBehaviouralResp,
  ] = await Promise.all([
    mtmReqGet({
      url: `${Endpoints.GET_ELIGIBILITY}?consultation_type=psychiatry`,
    }),
    mtmReqGet({
      url: `${Endpoints.GET_ELIGIBILITY}?consultation_type=commonacute`,
    }),
    mtmReqGet({
      url: `${Endpoints.GET_ELIGIBILITY}?consultation_type=TVCGStudentBH`,
    }),
  ]);
  return {
    psychiatryEligibility: psychiatryEligibilityResp.data,
    urgentCareEligibility: urgentCareEligibilityResp.data,
    studentBehaviouralEligibility: studentBehaviouralResp.data,
  };
};
