import { Box, Button, Stack, Tab, Tabs, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import MainPageLayout from "../MainPageLayout";
import Cancelled from "./Cancelled";
import Completed from "./Completed";
import InProgress from "./InProgress";
import Upcoming from "./Upcoming";
import { appTheme } from "../../assets/theme";
import DefaultButton from "../../components/consultations/DefaultButton";
import { createSearchParams, useNavigate } from "react-router-dom";
import Applinks from "../../assets/route-links";
import { useRequest } from "../../data/useRequest";
import PropTypes from "prop-types";
import {
  cancelConsultations,
  getAllConsultations,
} from "../../data/requests/consultations";
import useFeedback from "../../providers/feedback.provider";
import { settingsStyles } from "../../components/settings/styles/SettingsStyles";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, matchesSM, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpannel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: matchesSM ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpannel-${index}`,
  };
}

const Consultations = () => {
  const navigate = useNavigate();
  const { addDialog, addSnack } = useFeedback();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {t} = useTranslation("translations")

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [status, setStatus] = useState("new");

  const consultationResp = useRequest(([param]) => getAllConsultations(param));

  useEffect(() => {
    consultationResp.execute(status);
  }, [selectedTab]);

  const handleScheduleConsultation = () => {
    navigate(Applinks.SCHEDULE_CONSULATATIONS);
  };

   const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        setStatus("new");
        break;
      case 1:
        setStatus("inprogress");
        break;
      case 2:
        setStatus("complete");
        break;
      case 3:
        setStatus("canceled");
        break;
      default:
        setStatus("new");
        break;
    }
  };

  const handleCancelConsultation = async (id, reason) => {
    try {
      console.log(id, reason)
      const data = await cancelConsultations(id, reason);
      if (data.success === true) {
        addSnack({
          error: false,
          message: t("consultation_cancelled_successfully"),
        });
        consultationResp.execute(status);
        // consultationResp.execute(selectedTab);
      }
    } catch (err) {
      addDialog({
        error: true,
        title: t("error"),
        message: err.response.data.message || err.message,
      });
    }
  };

  const handleJoinAppointment = (consultation) => {
    if(consultation.primaryPatientToken && consultation.videoSessionId){
      navigate(`${Applinks.VIDEO_CALL_SCREEN}?${createSearchParams({
        token: consultation.primaryPatientToken,
        sessionId: consultation.videoSessionId,
        apiKey: consultation.openTokApiKey,
    })}`);
    }else{
      addDialog({
        error: true,
        title: t("error"),
        message: t("no_video_session_for_this_appointment"),
      })
    }
  }

  return (
    <MainPageLayout>
      <ThemeProvider theme={appTheme}>
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <div style={healthRecordsStyles.mainTitle}>
            {t("consultations")}
          </div>
          <DefaultButton
            variant="contained"
            onClick={handleScheduleConsultation}
          >
            {t("schedule_consultation")}
          </DefaultButton>
        </Stack>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          sx={{ color: "black" }}
          aria-label="Consultation Tabs"
          variant="scrollable"
          textColor={"#001011"}
        >
          <Tab
            label={t("upcoming")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: selectedTab === 'new' ? 700 : undefined,
            }}
            {...a11yProps(0)}
          />
          <Tab
            label={t("in_progress")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: selectedTab === 'inprogress' ? 700 : undefined,
            }}
            {...a11yProps(1)}
          />
          <Tab
            label={t("completed")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: selectedTab === 'complete' ? 700 : undefined,
            }}
            {...a11yProps(2)}
          />
          <Tab
            label={t("cancelled")}
            sx={{
              ...settingsStyles.tabStyles,
              fontWeight: selectedTab === 'canceled' ? 700 : undefined,
            }}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0} matchesSM={matchesSM}>
        <Upcoming
              consultations={consultationResp?.data?.data || []}
              loading={consultationResp.loading}
              handleCancelConsultation={handleCancelConsultation}
              handleJoinAppointment={handleJoinAppointment}
            />
      </TabPanel>
      <TabPanel value={selectedTab} index={1} matchesSM={matchesSM}>
        <InProgress
              consultations={consultationResp?.data?.data || []}
              loading={consultationResp.loading}
            />
      </TabPanel>
      <TabPanel value={selectedTab} index={2} matchesSM={matchesSM}>
        <Completed
              consultations={consultationResp?.data?.data || []}
              loading={consultationResp.loading}
            />
      </TabPanel>
      <TabPanel value={selectedTab} index={3} matchesSM={matchesSM}>
        <Cancelled
              consultations={consultationResp?.data?.data || []}
              loading={consultationResp.loading}
            />
      </TabPanel>
      </Box>
      </ThemeProvider>
    </MainPageLayout>
  );
};

export default Consultations;
