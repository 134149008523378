import React from "react";
import { healthRecordsStyles } from "./styles/healthRecordsStyles";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, TextField, useMediaQuery } from "@mui/material";

const TextfieldSection = ({
  title,
  required,
  label,
  value,
  onChange,
  error,
  helperText,
  type,
}) => {
  const theme = useTheme();

  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Grid
        item
        container
        direction={"column"}
        style={{
          padding: matchesLG || matchesMD || matchesXl ? "0px 50px" : "0px",
          marginBottom: 30,
        }}
      >
        <div style={healthRecordsStyles.textfieldsTitle}>
          {title}{" "}
          <span style={{ marginLeft: 5, color: "#E53935" }}>
            {required ? "*" : undefined}
          </span>
        </div>
        <TextField
          label={label}
          variant="standard"
          error={error}
          type={type}
          helperText={helperText}
          value={value}
          // inputProps={{
          //   maxLength: 1,
          //   }}
          onChange={(e) => onChange(e)}
          InputLabelProps={{
            style: {
              color: "#515563",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default TextfieldSection;
