import { Endpoints } from "../../endpoints";
import { reqPost } from "../../request.api";

export const login = async (email, password) => {
  const resp = await reqPost({
    url: Endpoints.LOGIN,
    data: { email, password },
  });
  return resp;
};
