import dotsIcon from "../images/dotsIcon.png";
import orangeIcon from "../images/orangIcon.png";
import blueIcon from "../images/blueIcon.png";
import dotsWithbgIcon from "../images/dotswithbg.png";
import logoIcon from "../images/logoIcon.png";
import verifyAccountAnimi from "../images/verifyAccountAnimi.png";
import whiteBGAnimi from "../images/whiteBGAnimi.png";
import emailSent from "../images/emailSent.png";
import leftSide from "../images/leftSide.png";

import personalInfo from "../images/healthRecords/personalInfo.png";
import medication from "../images/healthRecords/medication.png";
import allergies from "../images/healthRecords/allergies.png";
import medicalHistory from "../images/healthRecords/medicalHistory.png";
import surgical from "../images/healthRecords/surgical.png";
import documentIcon from "../images/healthRecords/documentIcon.png";
import uploadDocumentIcon from "../images/healthRecords/uploadDocumentIcon.png";

import sent from "../images/messaging/sent.png";
import doctorIcon from "../images/messaging/DoctorIcon.png";

export const userEmail = "dawood@chopdawg.com";
export const userPassword = "Abcd@12345";

export const images = {
  dotsIcon,
  orangeIcon,
  blueIcon,
  dotsWithbgIcon,
  logoIcon,
  verifyAccountAnimi,
  whiteBGAnimi,
  emailSent,
  leftSide,
  personalInfo,
  medication,
  allergies,
  medicalHistory,
  surgical,
  documentIcon,
  uploadDocumentIcon,
  sent,
  doctorIcon,
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  // textAlign: "center",
};

export const privacyPolicyArray = [
  " Thank you for choosing to be part of our community at The Virtual Care Group, LLC (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at info@thevirtualcaregroup.com.",
  " When you visit our and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our and our services.",
  " This privacy policy applies to all information collected through our and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the “Services“).",
  " Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.",
];

export const privacyPolicyTableOfContentArray = [
  " What information do we collect?",
  " Will your information be shared with anyone?",
  " Do we use cookies and other tracking technologies?",
  "How do we handle your social logins?",
  "Is your information transferred internationally?",
  "What is our stance on third-party websites?",
  "How long do we keep your information?",
  "Do we collect information from minors?",
  "What are your privacy rights?",
  "Controls or do-not-track features.",
  "Do California residents have specific privacy rights?",
  "Do we make updates to this policy?",
  "How can you contact us about this policy?",
];

export const termsOfServicesArray = [
  " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc suspendisse arcu malesuada cursus elementum. Sed sit quis massa luctus diam. Ullamcorper libero tellus suspendisse proin risus nunc. Sed ultrices accumsan diam eu lorem ornare ut condimentum et. Eget tortor sit adipiscing nulla. Massa id nec gravida ultricies integer lectus eget. Id auctor venenatis dis dapibus velit euismod. Sed ut vel scelerisque at at amet risus convallis cursus. Vitae non facilisi ac, tortor. Lectus vitae accumsan porttitor scelerisque. A hendrerit porta integer suspendisse donec urna gravida euismod. Pharetra mi tellus at nulla. Consequat fermentum eu netus est a, suspendisse quis tincidunt. Habitant imperdiet in proin at. In arcu vitae molestie venenatis. Ornare faucibus laoreet ullamcorper faucibus diam libero ac.",
  " Etiam tellus nunc mauris ornare duis amet, mattis. Mauris et molestie cras ipsum. Duis adipiscing ipsum dignissim pretium ut arcu tempus massa elementum. Rutrum enim in nullam sed ornare varius ipsum lacus risus. Enim mattis lectus morbi maecenas justo. Integer porttitor adipiscing dignissim dignissim massa. Ultrices eleifend tempus vitae nunc. Odio at ac diam massa donec sagittis. Eu pellentesque sed lorem porttitor et felis quam. Nunc velit tristique et, adipiscing arcu risus purus. Maecenas convallis in a, senectus orci convallis. Arcu, fames enim, leo pretium viverra. Tellus in dignissim faucibus porttitor.",
  " Volutpat id odio libero amet, elementum tincidunt diam enim. Aliquam et lacus massa cras eu. Imperdiet at metus platea vel nisl lectus et. Luctus mauris ornare sit mauris iaculis sagittis. Ultrices feugiat nibh habitasse ultricies. Pellentesque sit sagittis, eros posuere at scelerisque odio. Velit in parturient dolor, vel ac. Egestas scelerisque mauris tortor eget velit sed at enim.",
  "Etiam tellus nunc mauris ornare duis amet, mattis. Mauris et molestie cras ipsum. Duis adipiscing ipsum dignissim pretium ut arcu tempus massa elementum. Rutrum enim in nullam sed ornare varius ipsum lacus risus. Enim mattis lectus morbi maecenas justo. Integer porttitor adipiscing dignissim dignissim massa. Ultrices eleifend tempus vitae nunc. Odio at ac diam massa donec sagittis. Eu pellentesque sed lorem porttitor et felis quam. Nunc velit tristique et, adipiscing arcu risus purus. Maecenas convallis in a, senectus orci convallis. Arcu, fames enim, leo pretium viverra. Tellus in dignissim faucibus porttitor.",
  " Volutpat id odio libero amet, elementum tincidunt diam enim. Aliquam et lacus massa cras eu. Imperdiet at metus platea vel nisl lectus et. Luctus mauris ornare sit mauris iaculis sagittis. Ultrices feugiat nibh habitasse ultricies. Pellentesque sit sagittis, eros posuere at scelerisque odio. Velit in parturient dolor, vel ac. Egestas scelerisque mauris tortor eget velit sed at enim.",
];

export const stateOptions = [
  "NC",
  "NY",
  "CA",
  "TX",
  "FL",
  "GA",
  "WA",
  "TN",
  "SC",
  "VA",
  "OH",
  "PA",
  "IL",
  "MA",
  "NJ",
  "MI",
  "AZ",
  "MD",
  "CO",
  "MN",
  "IN",
  "MO",
  "WI",
  "OR",
  "AL",
  "LA",
  "KY",
  "OK",
  "CT",
  "UT",
  "IA",
  "NV",
  "AR",
  "MS",
  "KS",
  "NM",
  "NE",
  "ID",
  "WV",
  "HI",
  "ME",
  "NH",
  "RI",
  "MT",
  "DE",
  "SD",
  "ND",
  "AK",
  "VT",
  "WY",
];

export const expandedStateOptions = [
  { label: "Alabama", abbrev: "AL", key: "al" },
  { label: "Alaska", abbrev: "AK", key: "ak" },
  { label: "Arizona", abbrev: "AZ", key: "az" },
  { label: "Arkansas", abbrev: "AR", key: "ar" },
  { label: "California", abbrev: "CA", key: "ca" },
  { label: "Colorado", abbrev: "CO", key: "co" },
  { label: "Connecticut", abbrev: "CT", key: "ct" },
  { label: "Delaware", abbrev: "DE", key: "de" },
  { label: "Florida", abbrev: "FL", key: "fl" },
  { label: "Georgia", abbrev: "GA", key: "ga" },
  { label: "Hawaii", abbrev: "HI", key: "hi" },
  { label: "Idaho", abbrev: "ID", key: "id" },
  { label: "Illinois", abbrev: "IL", key: "il" },
  { label: "Indiana", abbrev: "IN", key: "in" },
  { label: "Iowa", abbrev: "IA", key: "ia" },
  { label: "Kansas", abbrev: "KS", key: "ks" },
  { label: "Kentucky", abbrev: "KY", key: "ky" },
  { label: "Louisiana", abbrev: "LA", key: "la" },
  { label: "Maine", abbrev: "ME", key: "me" },
  { label: "Maryland", abbrev: "MD", key: "md" },
  { label: "Massachusetts", abbrev: "MA", key: "ma" },
  { label: "Michigan", abbrev: "MI", key: "mi" },
  { label: "Minnesota", abbrev: "MN", key: "mn" },
  { label: "Mississippi", abbrev: "MS", key: "ms" },
  { label: "Missouri", abbrev: "MO", key: "mo" },
  { label: "Montana", abbrev: "MT", key: "mt" },
  { label: "Nebraska", abbrev: "NE", key: "ne" },
  { label: "Nevada", abbrev: "NV", key: "nv" },
  { label: "New Hampshire", abbrev: "NH", key: "nh" },
  { label: "New Jersey", abbrev: "NJ", key: "nj" },
  { label: "New Mexico", abbrev: "NM", key: "nm" },
  { label: "New York", abbrev: "NY", key: "ny" },
  { label: "North Carolina", abbrev: "NC", key: "nc" },
  { label: "North Dakota", abbrev: "ND", key: "nd" },
  { label: "Ohio", abbrev: "OH", key: "oh" },
  { label: "Oklahoma", abbrev: "OK", key: "ok" },
  { label: "Oregon", abbrev: "OR", key: "or" },
  { label: "Pennsylvania", abbrev: "PA", key: "pa" },
  { label: "Rhode Island", abbrev: "RI", key: "ri" },
  { label: "South Carolina", abbrev: "SC", key: "sc" },
  { label: "South Dakota", abbrev: "SD", key: "sd" },
  { label: "Tennessee", abbrev: "TN", key: "tn" },
  { label: "Texas", abbrev: "TX", key: "tx" },
  { label: "Utah", abbrev: "UT", key: "ut" },
  { label: "Vermont", abbrev: "VT", key: "vt" },
  { label: "Virginia", abbrev: "VA", key: "va" },
  { label: "Washington", abbrev: "WA", key: "wa" },
  { label: "West Virginia", abbrev: "WV", key: "wv" },
  { label: "Wisconsin", abbrev: "WI", key: "wi" },
  { label: "Wyoming", abbrev: "WY", key: "wy" }
];

