import "../../../fonts/comfortaa.css";

export const loginCardStyles = {
  cardStyle: {
    position: "absolute",
    width: 575,
    height: "98vh",
    background: "#FFFFFF",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    zIndex: 2,
    // "@media (minWidth: 960px)": {
    //   width: "575px",
    //   height: "758px",
    // },
  },
  imageStyle: {
    position: "absolute",
    width: 174,
    height: 56,
    // left: 200.5,
    top: 49,
    "@media (minWidth: 960px)": {
      width: "200px",
      height: "65px",
      top: "64px",
    },
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  textFieldStyle: {
    marginBottom: 4,
    color: "#515563",
    height: 60,
    borderRadius: "10px 10px 0px 0px",
  },
  formHelperTextStyles: {
    color: "#CF2D23",
    fontSize: 12,
    fontWeight: 300,
  },
  needHelpTextStyles: {
    color: "#001011",
    fontSize: 14,
    fontWeight: 400,
    marginTop: 50,
    textAlign: "center",
  },
  titleTextStyles: {
    color: "#001011",
    fontSize: 23,
    marginBottom: 20,
    fontWeight: 700,
    textAlign: "center",
    fontFamily: "Comfortaa",
  },
  messageTextStyles: {
    color: "#23262F",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 30,
    textAlign: "center",
  },
  messageText2Styles: {
    color: "#23262F",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 30,
    textAlign: "center",
  },
  inputPropsStyles: {
    color: "#001011",
    fontSize: 16,
    fontWeight: 400,
    background: "#FFFAF5",
    borderBottom: "2px solid #515563",
    height: 60,
    borderRadius: "10px 10px 0px 0px",
    "&:hover fieldset": {
      borderBottom: "2px solid #515563!important",
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      borderBottom: "2px solid #515563!important",
    },
  },
  buttonStyle: {
    background: "#19A6FE",
    color: "#001011",
    borderRadius: 60,
    // marginTop: 65,
    height: 50,
    fontWeight: 400,
  },
  buttonDisabledStyle: {
    background: "#515563",
    color: "#FFF",
    borderRadius: 60,
    marginTop: 65,
    height: 50,
    fontWeight: 400,
  },
  resetPasswordStyles: {
    color: "#0D3B66",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 700,
  },

  createAccountStyles: {
    color: "#0D3B66",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 700,
    marginTop: 23,
  },
  resendButtonStyles: {
    color: "#0D3B66",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 700,
  },
};
