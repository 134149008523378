import React, { useState } from "react";
import { Button, Divider, Grid } from "@mui/material";

import EditDeleteMenu from "./EditDeleteMenu";
import DataCardNameMenu from "./DataCardNameMenu";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const MedicationCard = ({
  data,
  matchesSM,
  matchesXS,
  setSelectedMedication,
  handleEditMedicationModalOpen,
  handleDeleteMedicationModalOpen,
  handleMarkInactiveMedication,
}) => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation("translations");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(data.userMedication_id)

  return (
    <Grid
      container
      direction={"column"}
      //   alignItems={"center"}
      style={{
        padding: matchesSM || matchesXS ? 5 : 20,
        width: matchesSM || matchesXS ? "100%" : "80%",
      }}
    >
      <DataCardNameMenu
        name={data.name}
        open={open}
        hideMenu={true}
        handleClick={(e) => {
          setSelectedMedication(data);
          handleClick(e);
        }}
      />

      <div
        style={{
          color: "#001011",
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 7,
        }}
      >
        {data.comment && data.comment}
      </div>
      <div style={{ color: "#001011", fontSize: 14, fontWeight: 400 }}>
        {`${
          data.isCurrentlyUsing == 1
            ? t("currently_using")
            : t("Not using currently")
        }`}{" "}
        - {data.frequency && data.frequency}
      </div>
      {data.isCurrentlyUsing === 1 &&
        (loading ? (
          <Grid container justifyContent={"center"}>
            <ButtonLoader />
          </Grid>
        ) : (
          <Button
            onClick={async () => {
              setLoading(true);
              await handleMarkInactiveMedication(data.userMedication_id);
              setLoading(false);
            }}
          >
            {t("Mark Inactive")}
          </Button>
        ))}
      <Divider
        style={{ color: "#19A6FE", marginTop: 10, width: "98%" }}
        variant="middle"
      />
      <EditDeleteMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleEditClick={handleEditMedicationModalOpen}
        handleDeleteClick={handleDeleteMedicationModalOpen}
      />
    </Grid>
  );
};

export default MedicationCard;
