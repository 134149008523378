import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { healthRecordsStyles } from "./styles/healthRecordsStyles";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const DropDownTextfieldSection = ({
  title,
  required,
  label,
  value,
  onChange,
  options,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("translations");

  console.log("value:", value);

  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  return (
    <ThemeProvider theme={theme}>
      <Grid
        item
        container
        direction={"column"}
        style={{
          padding: matchesLG || matchesMD || matchesXl ? "0px 50px" : "0px",
          marginBottom: 30,
        }}
      >
        <div style={healthRecordsStyles.textfieldsTitle}>
          {title}{" "}
          <span style={{ marginLeft: 5, color: "#E53935" }}>
            {required ? "*" : undefined}
          </span>
        </div>
        <FormControl variant="standard">
          <InputLabel
            id={label}
            style={{ color: "#515563", fontSize: 16, fontWeight: 400 }}
          >
            {label}
          </InputLabel>
          <Select
            label={label}
            labelId={label}
            id={label}
            value={t(value) ? t(value) : value}
            // onChange={(e) => onChange(e.target.value)}
            sx={{ borderColor: "#19A6FE", color: "#000" }}
            inputProps={{ style: { color: "#000" } }}
            defaultValue={t(value) ? t(value) : value}
            // defaultValue={value}
          >
            {options &&
              options.map((option, index) => (
                <MenuItem
                  onClick={() => {
                    onChange(option);
                    console.log(value);
                  }}
                  value={option?.value}
                  style={{
                    color: "#001011",
                    fontSize: 14,
                    fontWeight: 400,
                    background: option === value ? "#FFB74D" : "#fff",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                >
                  {t(option?.name) ? t(option?.name) : option?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </ThemeProvider>
  );
};

export default DropDownTextfieldSection;
