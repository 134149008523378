import { Box, Dialog, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { registrationCardStyles } from '../../registration/styles/RegistrationCardStyles'
import { useTranslation } from 'react-i18next'
import Dimens from '../../../assets/Dimens'
import { COLORS } from '../../../assets/colors'

const EmrModal = (props) => {
	  const { modalOpen, handleClose } = props
	  const {t} = useTranslation("translations")
  return (
	<Dialog open={modalOpen}  onClose={handleClose}>
		<Box
        sx={{
          pt: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pb: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
		  msOverflowStyle: 'none',
		   scrollbarWidth: 'none',
          pl: {
            md: Dimens.CARD_PADDING_HORIZANTAL,
            xs: Dimens.CARD_PADDING_HORIZANTAL_MOBILE,
          },
          pr: {
            md: Dimens.CARD_PADDING_HORIZANTAL,
            xs: Dimens.CARD_PADDING_HORIZANTAL_MOBILE,
          },
          bgcolor: COLORS.TEXT_SECONDARY,
          textAlign: "center",
        }}
      >
	  <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("informed_patient_consent")}
            </span>
            <Grid container direction={"column"} alignItems={"center"}>
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("informed_consent_of_services_performed")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 50,
                  }}
                >
                  {t("informed_consent_of_services_performed_info")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("patient_medical_records")}</li>
                    <li>{t("medical_images")}</li>
                    <li>{t("live_two_way_audio_and_video")}</li>
                    <li>{t("output_data_from_medical_devices")}</li>
                  </ul>
                </div>
                <div style={termsOfServicesText}>
                  {t("output_data_from_medical_devices_info")}
                </div>
                <div style={termsOfServicesText}>
                  {t("output_data_from_medical_devices_info_one")}
                </div>
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("expected_benefits")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("expected_benefits_info")}</li>
                    <li>{t("expected_benefits_info_one")}</li>
                    <li>{t("expected_benefits_info_two")}</li>
                  </ul>
                </div>
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("possible_risks")}
                </div>
                <div style={termsOfServicesText}>
                  {t("possible_risks_info")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("possible_risks_info_one")}</li>
                    <li>{t("possible_risks_info_two")}</li>
                    <li>{t("possible_risks_info_three")}</li>
                    <li>{t("possible_risks_info_four")}</li>
                  </ul>
                </div>
                <div style={termsOfServicesText}>
                  {t("agreeing_and_understanding_following")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>{t("recorded_for_quality_assurance")}</li>
                    <li>{t("recorded_for_quality_assurance_info")}</li>
                    <li>{t("recorded_for_quality_assurance_info_one")}</li>
                    <li>{t("recorded_for_quality_assurance_info_two")}</li>
                    <li>{t("recorded_for_quality_assurance_info_three")}</li>
                    <li>{t("recorded_for_quality_assurance_info_four")}</li>
                    <li>{t("recorded_for_quality_assurance_info_five")}</li>
                  </ul>
                </div>
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("patient_consent_to_the_use_of_telemedicine")}
                </div>
                <div style={termsOfServicesText}>
                  {t("patient_consent_to_the_use_of_telemedicine_info")}
                </div>
                <div style={termsOfServicesText}>
                  {t("patient_consent_to_the_use_of_telemedicine_info_one")}
                </div>
                <div style={registrationCardStyles.termsOfUseSubTitle}>
                  {t("read_and_agree_to_this_document")}
                </div>
            </Grid>
			</Box>
	</Dialog>
  )
}

EmrModal.propTypes = {
	  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func
}

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
};


export default EmrModal
