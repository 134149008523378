import { Grid } from "@mui/material";
import React from "react";
import { registrationCardStyles } from "./styles/RegistrationCardStyles";

const PasswordRequirements = () => {
  return (
    <Grid
      container
      direction={"column"}
      style={registrationCardStyles.passwordReqContainer}
    >
      <span style={registrationCardStyles.passwordReqTitle}>
        Password Requirements
      </span>
      <li>
        <span style={registrationCardStyles.passwordReqItem}>
          Passwords Must Be At Least 8 Characters In Length
        </span>
      </li>
      <li>
        <span style={registrationCardStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Uppercase Letter
        </span>
      </li>
      <li>
        <span style={registrationCardStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Lowercase Letter
        </span>
      </li>
      <li>
        <span style={registrationCardStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Symbol
        </span>
      </li>
      <li>
        <span style={registrationCardStyles.passwordReqItem}>
          Passwords Must Contain At Least 1 Number
        </span>
      </li>
    </Grid>
  );
};

export default PasswordRequirements;
