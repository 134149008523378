import { styled, Typography } from "@mui/material";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";

const ValueText = styled(Typography)(() => ({
  fontFamily: "Comfortaa",
  paddingLeft: Dimens.SERVICE_AVAILABILITY_BOX_TEXT_PADDING_HORIZANTAL,
  paddingRight: Dimens.SERVICE_AVAILABILITY_BOX_TEXT_PADDING_HORIZANTAL,
  paddingTop: Dimens.SERVICE_AVAILABILITY_BOX_TEXT_PADDING_VERTICAL,
  paddingBottom: Dimens.SERVICE_AVAILABILITY_BOX_TEXT_PADDING_VERTICAL,
  borderRadius: Dimens.SERVICE_AVAILABILITY_BOX_TEXT_PADDING_VERTICAL,
  backgroundColor: COLORS.SECONDARY_MAIN,
  color: COLORS.TEXT_SECONDARY,
  fontWeight: Dimens.FONT_WEIGHT_BOLDER,
  fontSize: Dimens.FONT_SIZE_MEDIUM,
  lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
  textAlign: "center",
}));

export default ValueText;
