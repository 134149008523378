import { Button, Grid } from "@mui/material";
import React from "react";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import MedicationTextfield from "../medications/MedicationTextfield";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const AddMedicalHistoryCard = ({
  matchesLG,
  matchesXl,
  matchesSM,
  matchesXS,
  matchesMD,
  loading,
  newMedicalHistory,
  setNewMedicalHistory,
  handleAddMedicalHistory,
}) => {
  const { t } = useTranslation("translations");
  return (
    <Grid
      container
      direction={"column"}
      style={{
        ...healthRecordsStyles.personalInfoMainBox,
        width: matchesLG || matchesXl ? "80%" : "100%",
        minHeight: matchesXS || matchesSM || matchesMD ? "auto" : 615,
      }}
    >
      <MedicationTextfield
        title={t("condition_name")}
        placeholder={t("add_condition_name_here")}
        value={newMedicalHistory.name}
        onChange={(e) => {
          setNewMedicalHistory({
            ...newMedicalHistory,
            name: e.target.value,
          });
        }}
      />

      <MedicationTextfield
        title={t("description")}
        placeholder={t("add_details_here")}
        value={newMedicalHistory.description}
        multiline={true}
        rows={3}
        onChange={(e) => {
          setNewMedicalHistory({
            ...newMedicalHistory,
            description: e.target.value,
          });
        }}
      />
      <MedicationTextfield
        title={t("status")}
        placeholder={t("add_status_here")}
        value={newMedicalHistory.status}
        select={true}
        MedicalHistory={true}
        onChange={(e) => {
          setNewMedicalHistory({
            ...newMedicalHistory,
            status: e.target.value,
          });
        }}
      />
      {loading ? (
        <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={
            newMedicalHistory.name &&
            newMedicalHistory.description &&
            newMedicalHistory.status
              ? false
              : true
          }
          style={
            newMedicalHistory.name &&
            newMedicalHistory.description &&
            newMedicalHistory.status
              ? healthRecordsStyles.addMedicationButtonEnabled
              : healthRecordsStyles.addMedicationButtonDisabled
          }
          onClick={handleAddMedicalHistory}
        >
          {t("add record")}
        </Button>
      )}
    </Grid>
  );
};

export default AddMedicalHistoryCard;
