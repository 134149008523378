import { Endpoints } from "../../endpoints";
import { mtmReqPost } from "../../request-mtm.api";
import { reqGet, reqPost } from "../../request.api";

export const searchPharmacies = async (query) => {
  const resp = await reqGet({
    url: `${Endpoints.SEARCH_PHARMACY}?query=${query}`,
  });
  const {data} = resp
  return data
};

export const updatePharmacy = async (params) => {
  const resp = await mtmReqPost({
    url: `${Endpoints.UPDATE_PHARMACY}?sureScriptPharmacyId=${params?.sureScriptPharmacy_id}&isPreferred=${params?.status}`
  });
  const {data} = resp
  return data
};