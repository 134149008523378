import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { loginCardStyles } from "./styles/LoginCardStyles";
import { images } from "../../constant";
import ButtonLoader from "../ButtonLoader";
import { useTranslation } from "react-i18next";

const LoginCard = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  passwordError,
  emailError,
  loginError,
  loading,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("translations");
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickRegistration = () => {
    navigate("/registration");
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ ...loginCardStyles.cardStyle, width: matchesSM ? "94%" : 575 }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src={images.logoIcon}
            alt="Logo"
            style={loginCardStyles.imageStyle}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{
            padding: matchesSM ? "70px 40px 40px 40px" : "50px 70px 70px 70px",
            marginTop: matchesSM ? 50 : 90,
          }}
        >
          <form style={loginCardStyles.formStyle} onSubmit={handleLogin} noValidate>
            {/* Email field  */}
            <TextField
              label={t("email_address")}
              variant="filled"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError !== null ? true : false}
              helperText={emailError}
              sx={loginCardStyles.textFieldStyle}
              InputLabelProps={{
                style: {
                  color: "#515563",
                },
              }}
              InputProps={{
                sx: loginCardStyles.inputPropsStyles,
              }}
            />
            {/* Password field  */}
            <TextField
              label={t("password")}
              variant="filled"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              helperText={passwordError}
              error={passwordError !== null ? true : false}
              sx={loginCardStyles.textFieldStyle}
              FormHelperTextProps={{
                sx: loginCardStyles.formHelperTextStyles,
              }}
              InputLabelProps={{
                style: {
                  color: "#515563",
                },
              }}
              InputProps={{
                sx: loginCardStyles.inputPropsStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* Forgot password area  */}
            <Grid container style={{ marginTop: 23 }}>
              <span style={{ color: "#000000", fontSize: 14, fontWeight: 400 }}>
                {t("forgot_password")}{" "}
                <Button
                  style={loginCardStyles.resetPasswordStyles}
                  onClick={() => navigate("/reset-password")}
                >
                  {t("reset_password")}
                </Button>
              </span>
            </Grid>

            {/* Remember me area  */}
            <Grid container style={{ marginTop: 28 }}>
              <span style={{ color: "#000000", fontSize: 16, fontWeight: 400 }}>
                <Checkbox
                  // checked={checked}
                  // onChange={handleChange}
                  defaultChecked
                  color="default"
                  sx={{}}
                />{" "}
                {t("remember_me")}
              </span>
            </Grid>
            <Typography variant={"body1"} sx={{ color: "#FF0000" }}>
              {loginError}
            </Typography>
            {/* Login Button area  */}
            <Grid
              container
              style={{ marginTop: matchesSM ? 15 : 25 }}
              justifyContent={"center"}
            >
              {loading ? (
                <ButtonLoader />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={email && password ? false : true}
                  style={
                    email && password
                      ? loginCardStyles.buttonStyle
                      : loginCardStyles.buttonDisabledStyle
                  }
                  onClick={handleLogin}
                >
                  {t("login")}
                </Button>
              )}
            </Grid>

            {/*create account area */}
            {/*
            <Button
              style={loginCardStyles.createAccountStyles}
              onClick={handleClickRegistration}
            >
              Create Account
            </Button>
          */}

            {/* Need help area  */}
            {/*
            <span
              style={{
                color: "#000000",
                fontSize: 14,
                fontWeight: 400,
                marginTop: matchesMD ? 15 : 30,
              }}
            >
              {t("need_help")}{" "}
              <Button
                style={loginCardStyles.resetPasswordStyles}
                onClick={() => navigate("/help-center")}
              >
                {t("contact_us")}
              </Button>
            </span>
          */}
          </form>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default LoginCard;
