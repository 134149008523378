export const loginStyles = {
  containerStyles: {
    position: "relative",
    width: "100%",
    height: "100vh",
    background: "linear-gradient(117.35deg, #FFF2E1 -2.24%, #FFF2E1 97.39%)",
    overflowX: "hidden",
  },
  logo1Styles: {
    position: "absolute",
    zIndex: 1,
    top: "15%",
    left: "10%",
    width: "106px",
    height: "106px",
  },
  logo2Styles: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    width: "70vw",
    height: "70vw",
    maxWidth: "700px",
    maxHeight: "700px",
  },
  logo3Styles: {
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    width: "35vw",
    height: "45vw",
    maxWidth: "350px",
    maxHeight: "450px",
  },
  logo4Styles: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    width: "50vw",
    height: "60vw",
    maxWidth: "580px",
    maxHeight: "600px",
  },
};
