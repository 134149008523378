import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { images, modalStyle } from "../../constant";
import { codeSentModalStyles } from "./styles/CodeSentModaltyles";

const CodeSentModal = ({ modalOpen, handleClose }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        {/*animi. section*/}
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={images.whiteBGAnimi}
            alt="bg"
            style={codeSentModalStyles.imageStyles}
          />
          <span style={codeSentModalStyles.titleStyles}>Code Resent!</span>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={codeSentModalStyles.okayButtonStyles}
          >
            DONE
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CodeSentModal;
