import React, { Fragment, useState } from "react";
import LoginDesignPage from "./LoginDesignPage";
import VerifyAccountCard from "../../components/login/VerifyAccountCard";

import { useNavigate } from "react-router-dom";
import CodeSentModal from "../../components/login/CodeSentModal";

export const VerifyAccount = () => {
  const navigate = useNavigate();

  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeSentModal, setCodeSentModal] = useState(false);

  const handleCodeSentModalClose = () => {
    setCodeSentModal(false);
  };

  const handleVerifyAccount = () => {
    try {
      setLoading(true);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <LoginDesignPage>
        <VerifyAccountCard
          pin={pin}
          setPin={setPin}
          handleVerifyAccount={handleVerifyAccount}
          loading={loading}
          handleCodeSentModalOpen={() => setCodeSentModal(true)}
        />
      </LoginDesignPage>
      <CodeSentModal
        modalOpen={codeSentModal}
        handleClose={handleCodeSentModalClose}
      />
    </Fragment>
  );
};
