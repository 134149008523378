import React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { images } from "../../constant";
import { Grid, useMediaQuery } from "@mui/material";
import { loginCardStyles } from "./styles/LoginCardStyles";

const PasswordUpdatedCard = ({ handleNavigateToLogin }) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ ...loginCardStyles.cardStyle, width: matchesSM ? "94%" : 575 }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src={images.logoIcon}
            alt="Logo"
            style={loginCardStyles.imageStyle}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{ padding: 100, marginTop: 90 }}
        >
          <form style={loginCardStyles.formStyle} noValidate>
            <span style={loginCardStyles.titleTextStyles}>
              Password updated
            </span>

            <span style={loginCardStyles.messageTextStyles}>
              Your password has been successfully updated.
            </span>

            <img
              src={images.verifyAccountAnimi}
              alt="animi"
              style={{ width: 160, height: 160, marginTop: 30 }}
            />
            {/* Register Button area  */}

            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={loginCardStyles.buttonStyle}
              onClick={handleNavigateToLogin}
            >
              Confirm
            </Button>

            {/* Need help area  */}
            <span style={loginCardStyles.needHelpTextStyles}>
              Need Help?{" "}
              <Button style={loginCardStyles.resetPasswordStyles}>
                Contact Us
              </Button>
            </span>
          </form>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default PasswordUpdatedCard;
