import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";

import QuestionTextField from "./QuestionTextField";
import { messagingStyles } from "../styles/messagingStyles";
import ButtonLoader from "../../ButtonLoader";
import TextFieldSection from "../../settings/TextFieldSection";
// Constants
import { expandedStateOptions } from "../../../constant";
import { useHealthRecordsContext } from "../../../providers/HealthRecordsContext";
import { useTranslation } from "react-i18next";

const AddQuestionCard = ({
  newMessage,
  countryState,
  setNewMessage,
  setCountryState,
  matchesSM,
  handleSaveMessage,
  loading,
}) => {
  const { allStates } = useHealthRecordsContext();
  const [allStatesOptions, setAllStatesOptions] = useState([]);
  const { t } = useTranslation("translations");

  useEffect(() => {
    setAllStatesOptions(allStates);
  }, [allStates]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md
      lg={6.5}
      xl={6.5}
      container
      direction={"column"}
    >
      <Grid
        container
        justifyContent={"left"}
        style={{
          ...messagingStyles.messegingMainBox,
          padding: matchesSM ? "20px 0px 0px 20px" : 52,
        }}
      >
        <TextFieldSection
          label={t("state")}
          value={countryState}
          select={true}
          onChange={() => {}}
          options={allStatesOptions}
          stateField={true}
          messageSection={true}
          setCountryState={(state) => {
            setCountryState(state);
          }}
          // pharmacyModal={true}
        />
        <Grid container direction={"column"}>
          <QuestionTextField
            title={t("your_question")}
            placeholder={t("your_question_info")}
            value={newMessage.question}
            onChange={(e) => {
              setNewMessage({
                ...newMessage,
                question: e.target.value,
              });
            }}
          />
          <QuestionTextField
            title={t("details")}
            placeholder={t("add_greater_Detail_here")}
            value={newMessage.details}
            multiline={true}
            //   rows={1}
            onChange={(e) => {
              setNewMessage({
                ...newMessage,
                details: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid container justifyContent={"center"} style={{ marginTop: 30, marginBottom: 30 }}>
          {loading ? (
            <ButtonLoader />
          ) : (
            <Button
              variant="contained"
              onClick={handleSaveMessage}
              style={messagingStyles.addQuestionSaveButton}
              disabled={
                newMessage.question && newMessage.details ? false : true
              }
            >
              {t("send_your_inquiry")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddQuestionCard;
