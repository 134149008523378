import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import NoMedicationsAdded from "../../components/healthRecords/medications/NoMedicationsAdded";
import DeleteMedicationModal from "../../components/healthRecords/medications/DeleteMedicationModal";
import MedicationDeletedModal from "../../components/healthRecords/medications/MedicationDeletedModal";
import SurgicalHistoryCard from "../../components/healthRecords/surgicalHistory/SurgicalHistoryCard";
import AddSurgicalHistoryCard from "../../components/healthRecords/surgicalHistory/AddSurgicalHistoryCard";
import EditSurgicalHistoryModal from "../../components/healthRecords/surgicalHistory/EditSurgicalHistoryModal";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import moment from "moment";
import {
  addSurgicalHistory,
  deleteSurgicalHistory,
  updateSurgicalHistory,
} from "../../data/requests/healthRecords";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const SurgicalHistory = () => {
  const { user } = useAuth();
  const { t } = useTranslation("translations");
  const { surgicalHistory, handleGetHealthRecords } = useHealthRecordsContext();

  const navigate = useNavigate();
  const theme = useTheme();

  // let user = JSON.parse(localStorage.getItem("user"));

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [newSurgicalHistory, setNewSurgicalHistory] = useState({
    name: "",
    description: "",
    procedureDate: null,
  });
  const [selectedSurgicalHistory, setSelectedSurgicalHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editSurgicalHistoryModal, setEditSurgicalHistoryModal] =
    useState(false);
  const [deleteSurgicalHistoryModal, setDeleteSurgicalHistoryModal] =
    useState(false);
  const [surgicalHistoryDeletedModal, setSurgicalHistoryDeletedModal] =
    useState(false);

  // console.log("surgical history:", surgicalHistory);

  const [surgicalHistoryData, setSurgicalHistoryData] = useState([]);

  useEffect(() => {
    setSurgicalHistoryData(surgicalHistory);
  }, [surgicalHistory]);

  const handleEditSurgicalHistoryModalOpen = () => {
    setEditSurgicalHistoryModal(true);
  };
  const handleEditSurgicalHistoryModalClose = () => {
    setEditSurgicalHistoryModal(false);
  };
  const handleDeleteSurgicalHistoryModalOpen = () => {
    setDeleteSurgicalHistoryModal(true);
  };
  const handleDeleteSurgicalHistoryModalClose = () => {
    setDeleteSurgicalHistoryModal(false);
  };
  const handleSurgicalHistoryDeletedModalOpen = () => {
    handleDeleteSurgicalHistoryModalClose();
    setSurgicalHistoryDeletedModal(true);
  };
  const handleSurgicalHistoryDeletedModalClose = () => {
    setSurgicalHistoryDeletedModal(false);
  };

  const handleAddSurgicalHistory = async () => {
    try {
      setLoading(true);

      const newData = {
        userId: user.userId,
        procedureName: newSurgicalHistory.name,
        procedureDescription: newSurgicalHistory.description,
        procedureDate: moment(newSurgicalHistory.procedureDate).format(
          "MMMM, DD YYYY hh:mm:ss z"
        ),
        // procedureDate: moment(
        //   newSurgicalHistory.procedureDate,
        //   "MMMM, DD YYYY"
        // ).format("MMMM, DD YYYY hh:mm:ss z"),
      };

      const responseTemp = await addSurgicalHistory(newData, user.userId);
      console.log("add surgical history response:", responseTemp);
      await handleGetHealthRecords();

      // const updatedDataTemp = [...surgicalHistoryData];
      // const newId = surgicalHistoryData.length + 1;
      // const newData = {
      //   userId: user.userId,
      //   name: newSurgicalHistory.name,
      //   description: newSurgicalHistory.description,
      //   procedureDate: moment(
      //     newSurgicalHistory.procedureDate,
      //     "MMMM, DD YYYY"
      //   ).format("MMMM, DD YYYY hh:mm:ss z"),
      //   // procedureDate: newSurgicalHistory.procedureDate,
      // };

      // updatedDataTemp.push(newData);
      // setSurgicalHistoryData(updatedDataTemp);

      // Reset the newSurgicalHistory state
      setNewSurgicalHistory({
        name: "",
        description: "",
        procedureDate: null,
      });
    } catch (error) {
      console.log("error adding medical History:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSurgicalHistory = async () => {
    try {
      setEditLoading(true);
      console.log("doc:", selectedSurgicalHistory);
      const newData = {
        userId: user.userId,
        procedureName: selectedSurgicalHistory.name,
        procedureDescription: selectedSurgicalHistory.description,
        procedureDate: moment(selectedSurgicalHistory.procedureDate).format(
          "MMMM, DD YYYY hh:mm:ss z"
        ),
        // procedureDate: moment(
        //   // selectedSurgicalHistory.whenCreated,
        //   selectedSurgicalHistory.procedureDate,
        //   "MMMM, DD YYYY"
        // ).format("MMMM, DD YYYY hh:mm:ss z"),
        // procedureDate: new Date(
        //   selectedSurgicalHistory.procedureDate
        // ).toISOString(),
        // selectedSurgicalHistory.whenCreated,
      };

      const responseTemp = await updateSurgicalHistory(
        newData,
        selectedSurgicalHistory.userSurgicalHistory_id,
        user.userId
      );
      console.log("update surgical history response:", responseTemp);
      await handleGetHealthRecords();

      handleEditSurgicalHistoryModalClose();
    } catch (error) {
      console.log("error editing surgical History:", error.message);
    } finally {
      setEditLoading(false);
    }
  };

  const handleDeleteSurgicalHistory = async () => {
    try {
      setDeleteLoading(true);

      // console.log(selectedSurgicalHistory.userSurgicalHistory_id);

      const responseTemp = await deleteSurgicalHistory(
        selectedSurgicalHistory.userSurgicalHistory_id,
        user.userId
      );
      console.log("delete surgical history response:", responseTemp);
      await handleGetHealthRecords();

      // const updatedDataTemp = surgicalHistoryData.filter(
      //   (surgicalHistory) => surgicalHistory.id !== selectedSurgicalHistory.id
      // );

      // setSurgicalHistoryData(updatedDataTemp);
      handleSurgicalHistoryDeletedModalOpen();
      setSelectedSurgicalHistory(null);
    } catch (error) {
      console.log("error deleting medical History:", error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("surgical_history")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("update_surgical_history")}
        </div>
        {matchesXS || matchesSM || matchesMD ? (
          <Grid container direction={"column"}>
            <Grid item container xs>
              <AddSurgicalHistoryCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesMD={matchesMD}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                newSurgicalHistory={newSurgicalHistory}
                setNewSurgicalHistory={setNewSurgicalHistory}
                handleAddSurgicalHistory={handleAddSurgicalHistory}
                loading={loading}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {surgicalHistoryData && surgicalHistoryData.length > 0 ? (
                surgicalHistoryData.map((data, index) => (
                  <SurgicalHistoryCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedSurgicalHistory={setSelectedSurgicalHistory}
                    handleEditSurgicalHistoryModalOpen={
                      handleEditSurgicalHistoryModalOpen
                    }
                    handleDeleteSurgicalHistoryModalOpen={
                      handleDeleteSurgicalHistoryModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_surgeries_on_your_record")} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction={matchesLG || matchesMD || matchesXl ? "row" : "column"}
          >
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {surgicalHistoryData && surgicalHistoryData.length > 0 ? (
                surgicalHistoryData.map((data, index) => (
                  <SurgicalHistoryCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedSurgicalHistory={setSelectedSurgicalHistory}
                    handleEditSurgicalHistoryModalOpen={
                      handleEditSurgicalHistoryModalOpen
                    }
                    handleDeleteSurgicalHistoryModalOpen={
                      handleDeleteSurgicalHistoryModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_surgeries_on_your_record")} />
              )}
            </Grid>

            <Grid item container xs>
              <AddSurgicalHistoryCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesMD={matchesMD}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                newSurgicalHistory={newSurgicalHistory}
                setNewSurgicalHistory={setNewSurgicalHistory}
                handleAddSurgicalHistory={handleAddSurgicalHistory}
                loading={loading}
              />
            </Grid>
          </Grid>
        )}

        <EditSurgicalHistoryModal
          modalOpen={editSurgicalHistoryModal}
          handleClose={handleEditSurgicalHistoryModalClose}
          surgicalHistory={selectedSurgicalHistory}
          setSurgicalHistory={setSelectedSurgicalHistory}
          handleEditSurgicalHistory={handleEditSurgicalHistory}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={editLoading}
        />
        <DeleteMedicationModal
          modalOpen={deleteSurgicalHistoryModal}
          title={t("delete_surgery")}
          message={t("delete_surgery_info")}
          handleClose={handleDeleteSurgicalHistoryModalClose}
          handleDelete={handleDeleteSurgicalHistory}
          surgicalHistory={selectedSurgicalHistory}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={deleteLoading}
        />
        <MedicationDeletedModal
          modalOpen={surgicalHistoryDeletedModal}
          title={t("surgical_history_deleted")}
          handleClose={handleSurgicalHistoryDeletedModalClose}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default SurgicalHistory;
