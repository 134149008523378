import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";

import Header from "./Header";
import SideDrawer from "./SideDrawer";
import Footer from "./Footer";
import Tabbar from "./Tabbar";
// import LanguageSelector from "../common/language-selector/LanguageSelector";
// import LanguageButton from "./LanguageButton";

const MainLayout = ({ children }) => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction={"column"} style={{ background: "#DFF0FF" }}>
        <Header
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Grid item container>
          <Grid
            item
            container
            justifyContent={"center"}
            sx={{ width: { md: "19.5%", xs: "0" } }}
          >
            <Grid item container direction={"column"} alignItems={"center"}>
              <SideDrawer
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXl={matchesXl}
                matchesLG={matchesLG}
                matchesXS={matchesXS}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              width: { md: "79.5%", xs: "100%" },
              paddingLeft: matchesMD ? 3 : undefined,
            }}
          >
            {children}
          </Grid>
        </Grid>
        <Footer
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Tabbar />
        {/*
      
        <Box
				boxShadow={2}
				sx={{
					display: { xs: 'flex', md: 'none' },
					position: 'fixed',
					bottom: '10%',
					left: theme.spacing(2),
					// borderRadius: 31 / 2,
					zIndex: 1000,
				}}
			>
				<LanguageButton />
			</Box>
      */}
      </Grid>
    </ThemeProvider>
  );
};

export default MainLayout;
