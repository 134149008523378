import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import MedicationTextfield from "../medications/MedicationTextfield";

import { Scrollbars } from "react-custom-scrollbars-2";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const AddAllergyCard = ({
  matchesLG,
  matchesXl,
  matchesSM,
  matchesMD,
  matchesXS,
  newAllergy,
  setNewAllergy,
  handleAddAllergy,
  handleSearchAllergies,
  searchedAllergies,
  setSelectedSearchedAllergy,
}) => {
  const { t } = useTranslation("translations");

  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    searchedAllergies &&
      searchedAllergies.length > 0 &&
      setShowSuggestions(true);
  }, [searchedAllergies]);

  return (
    <Grid
      container
      direction={"column"}
      style={{
        ...healthRecordsStyles.personalInfoMainBox,
        width: matchesLG || matchesXl ? "80%" : "100%",
        minHeight: matchesXS || matchesSM || matchesMD ? "auto" : 615,
      }}
    >
      <MedicationTextfield
        title={t("drug_allergy_name")}
        placeholder={t("add_one_allergy_time")}
        value={newAllergy}
        onChange={(e) => {
          handleSearchAllergies(e.target.value);
        }}
      />

      {showSuggestions && (
        <Grid
          container
          direction={"column"}
          style={{
            width: matchesSM ? 180 : 360,
            height: 195,
            borderRadius: 10,
            padding: "30px 20px 35px",
            boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
            zIndex: 2,
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: 10,
            marginTop: 100,
          }}
        >
          <Scrollbars autoHide>
            {searchedAllergies.map((allergy, index) => (
              <div
                key={index}
                style={{
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedSearchedAllergy(allergy);
                  setNewAllergy(allergy.value);
                  setShowSuggestions(false);
                }}
              >
                {allergy.value}
              </div>
            ))}
          </Scrollbars>
        </Grid>
      )}

      <Button
        variant="contained"
        disabled={newAllergy ? false : true}
        style={
          newAllergy
            ? healthRecordsStyles.addMedicationButtonEnabled
            : healthRecordsStyles.addMedicationButtonDisabled
        }
        onClick={handleAddAllergy}
      >
        {t("add_allergy")}
      </Button>
    </Grid>
  );
};

export default AddAllergyCard;
