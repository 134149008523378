import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import NoMedicationsAdded from "../../components/healthRecords/medications/NoMedicationsAdded";
import DeleteMedicationModal from "../../components/healthRecords/medications/DeleteMedicationModal";
import MedicationDeletedModal from "../../components/healthRecords/medications/MedicationDeletedModal";
import AddDocumentCard from "../../components/healthRecords/documentManagement/AddDocumentCard";
import DocumentCard from "../../components/healthRecords/documentManagement/DocumentCard";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import { addDocument, deleteDocument } from "../../data/requests/healthRecords";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../utils/storage.utils";
import ErrorModal from "../../components/healthRecords/documentManagement/ErrorModal";

const DocumentManagement = () => {
  const { user } = useAuth();
  const { documents, handleGetHealthRecords } = useHealthRecordsContext();
  const { t } = useTranslation("translations");

  const navigate = useNavigate();
  const theme = useTheme();

  // let user = JSON.parse(localStorage.getItem("user"));
  const bearerToken = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);

  // console.log("user:", bearerToken);

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [newDocument, setNewDocument] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [documentDeletedModal, setDocumentDeletedModal] = useState(false);

  // console.log("documents", documents);
  // console.log("selected document", selectedDocument);

  const [documentsData, setDocumentsData] = useState([]);
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    setDocumentsData(documents);
  }, [documents]);

  const handleErrorModalClose = () => {
    setErrorModal(false);
  };

  const handleDeleteDocumentModalOpen = () => {
    setDeleteDocumentModal(true);
  };
  const handleDeleteDocumentModalClose = () => {
    setDeleteDocumentModal(false);
  };
  const handleDocumentDeletedModalOpen = () => {
    handleDeleteDocumentModalClose();
    setDocumentDeletedModal(true);
  };
  const handleDocumentDeletedModalClose = () => {
    setDocumentDeletedModal(false);
  };

  const handleAddDocument = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("AttachmentFile", newDocument[0]);
      // let newData = {
      //   AttachmentFile: newDocument[0],
      //   userId: user.userId,
      // };
      // console.log("new data:", newData);
      let responseTemp = await addDocument(formData, user.userId);
      console.log("responseTemp", responseTemp);
      await handleGetHealthRecords();

      // Reset the newDocument state
      setNewDocument(null);
    } catch (error) {
      console.log("error adding medical History:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDocument = async () => {
    try {
      setDeleteLoading(true);
      let responseTemp = await deleteDocument(
        selectedDocument.userAttachment_id,
        user.userId
      );
      await handleGetHealthRecords();
      console.log("response delete doc:", responseTemp);
      // const updatedDataTemp = documentsData.filter(
      //   (surgicalHistory) => surgicalHistory.id !== selectedDocument.id
      // );

      // setDocumentsData(updatedDataTemp);
      handleDocumentDeletedModalOpen();
      setSelectedDocument(null);
    } catch (error) {
      console.log("error deleting medical History:", error.request);
      if (
        error.request.response ==
        '{"message":"Cannot delete attachment. It is currently assigned to an Message A Doctor request.","detail":"","success":false,"type":"mt:validation"}'
      ) {
        handleDeleteDocumentModalClose();
        setErrorModal(true);
      }
      // console.log("error deleting medical History:", error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  // const getImageUrl = (file) => {
  //   var binaryData = [];
  //   binaryData.push(file);
  //   if (typeof file === "object")
  //     return `url(${URL.createObjectURL(
  //       new Blob(binaryData, { type: "application/zip" })
  //     )})`;
  //   return `url(${file})`;
  // };

  // function getBase64(img, callback) {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // }

  // function base64toBlob(base64Data, contentType) {
  //   contentType = contentType || "";
  //   var sliceSize = 1024;
  //   var byteCharacters = atob(base64Data);
  //   var bytesLength = byteCharacters.length;
  //   var slicesCount = Math.ceil(bytesLength / sliceSize);
  //   var byteArrays = new Array(slicesCount);

  //   for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
  //     var begin = sliceIndex * sliceSize;
  //     var end = Math.min(begin + sliceSize, bytesLength);

  //     var bytes = new Array(end - begin);
  //     for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
  //       bytes[i] = byteCharacters[offset].charCodeAt(0);
  //     }
  //     byteArrays[sliceIndex] = new Uint8Array(bytes);
  //   }
  //   return new Blob(byteArrays, { type: contentType });
  // }

  // const handleGetPreview = async () => {
  //   try {
  //     setPreviewLoading(true);

  //     if (selectedDocument) {
  //       // let responseTemp = await getAttachmentDummy(setPreview);
  //       // console.log("image response:", responseTemp);
  //       // original data start
  //       let responseTemp = await getAttachments(
  //         selectedDocument.userAttachment_id,
  //         // "thumb",
  //         "attachment",
  //         user.userId
  //       );
  //       // let bolbData = responseTemp.bolb();
  //       const imageUrl = URL.createObjectURL(responseTemp.data);
  //       console.log("image:", imageUrl);
  //       setPreview(imageUrl);
  //       // original data end
  //       //     .then(response => response.blob()) // or response.arrayBuffer()
  //       // .then(blobData => {
  //       //   // Process the binary data as needed, e.g., display it as an image
  //       //   const imageUrl = URL.createObjectURL(blobData);
  //       //   const imageElement = document.createElement('img');
  //       //   imageElement.src = imageUrl;
  //       //   document.body.appendChild(imageElement);
  //       // })
  //       // let url = `data:image/png;base64,${responseTemp.data}`;
  //       // fetch(url)
  //       //   .then((res) => {
  //       //     res.blob();
  //       //     setPreview(res.blob());
  //       //   })
  //       //   .then(console.log);
  //       // getBase64(responseTemp.data, (imageUrl) => {
  //       //   setPreview(imageUrl);
  //       //   console.log(imageUrl);
  //       // });
  //       // console.log("responseTemp", responseTemp);
  //       // setPreview(responseTemp.data);
  //       // let image = window.URL.createObjectURL(
  //       //   new Blob(responseTemp.data, { type: "application/zip" })
  //       // );
  //       // setPreview(image);
  //       // console.log(image);
  //       // setPreview(URL.createObjectURL(responseTemp.data));
  //       // setPreview(`data:image/png;base64,${responseTemp.data}`);
  //     }
  //   } catch (error) {
  //     console.log("error getting preview:", error.message);
  //   } finally {
  //     setPreviewLoading(false);
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("document_management")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("document_management_info")}
        </div>
        {matchesXS || matchesSM || matchesMD ? (
          <Grid container direction={"column"}>
            <Grid item container xs>
              <AddDocumentCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesMD={matchesMD}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                loading={loading}
                newDocument={newDocument}
                setNewDocument={setNewDocument}
                handleAddDocument={handleAddDocument}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {documentsData && documentsData.length > 0 ? (
                documentsData.map((data, index) => (
                  <DocumentCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedDocument={setSelectedDocument}
                    handleDeleteDocumentModalOpen={
                      handleDeleteDocumentModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_attached_documents")} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={"row"}>
            {/*direction={matchesLG || matchesXl ? "row" : "column"}*/}
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {documentsData && documentsData.length > 0 ? (
                documentsData.map((data, index) => (
                  <DocumentCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedDocument={setSelectedDocument}
                    handleDeleteDocumentModalOpen={
                      handleDeleteDocumentModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_attached_documents")} />
              )}
            </Grid>

            <Grid item container xs>
              <AddDocumentCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                matchesMD={matchesMD}
                loading={loading}
                newDocument={newDocument}
                setNewDocument={setNewDocument}
                handleAddDocument={handleAddDocument}
              />
            </Grid>
          </Grid>
        )}

        <DeleteMedicationModal
          modalOpen={deleteDocumentModal}
          title={t("delete_file")}
          message={t("delete_file_info")}
          handleClose={handleDeleteDocumentModalClose}
          handleDelete={handleDeleteDocument}
          surgicalHistory={selectedDocument}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={deleteLoading}
        />
        <MedicationDeletedModal
          modalOpen={documentDeletedModal}
          title={t("document_deleted")}
          handleClose={handleDocumentDeletedModalClose}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
        <ErrorModal
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          modalOpen={errorModal}
          handleClose={handleErrorModalClose}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default DocumentManagement;
