import React from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import { Card, Grid } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { Scrollbars } from "react-custom-scrollbars-2";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DoNotSellMyInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <LoginDesignPage>
        <Card
          sx={{
            ...cardStyle,
            width: matchesXS
              ? "98%"
              : matchesSM
              ? "95%"
              : matchesMD
              ? "90%"
              : matchesLG
              ? "80%"
              : 1115,
          }}
        >
          <Grid
            container
            direction={"column"}
            style={
              matchesSM || matchesXS
                ? subContainerStylesSM
                : matchesMD
                ? subContainerStylesMD
                : matchesLG
                ? subContainerStyles
                : matchesXl
                ? subContainerStyles
                : subContainerStyles
            }
          >
            <Grid
              container
              alignItems={"center"}
              onClick={() => navigate(-1)}
              style={backButtonStyles}
            >
              <ArrowBackIosOutlinedIcon style={backIconStyles} />
              <div style={{ textDecoration: "underline", marginLeft: 12 }}>
                {t("back")}
              </div>
            </Grid>
            <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("do_not_sell_my_personal_information")}
            </span>
            <Grid container direction={"column"} alignItems={"center"}>
              <Scrollbars
                style={scrollbarContainerStyle}
                thumbMinSize={30}
                autoHide
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.thumbVerticalStyle,
                    }}
                  />
                )}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.trackVerticalStyle,
                    }}
                  />
                )}
              >
                {/*
        
            {termsOfServicesArray.map((item, index) => (
              <div
                style={registrationCardStyles.termsOfServicesText}
                key={index}
              >
                {item}
              </div>
            ))}
        */}
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 50,
                    width: "98%",
                  }}
                >
                  {t("do_not_sell_my_personal_information_info_one")}
                </div>
                <div style={termsOfServicesText}>
                  <div>{t("information_we_collect")}</div>
                  {t("information_we_collect_info")}
                </div>
                <div style={subTitleTextStyles}>A. {t("identifiers")}.</div>
                <div style={smallParagraphStyles}>{t("identifiers_info")}</div>

                <div style={infoCollectedTextStyles}>
                  {t("information_collected")}:{" "}
                  <span style={{ color: "#000" }}>
                    {t("yes").toUpperCase()}
                  </span>
                </div>

                <div style={subTitleTextStyles}>
                  B. {t("information_collected_info")}
                </div>
                <div style={smallParagraphStyles}>
                  {t("information_collected_info_one")}
                </div>
              </Scrollbars>
            </Grid>
          </Grid>
        </Card>
      </LoginDesignPage>
    </ThemeProvider>
  );
};

export default DoNotSellMyInfo;

const cardStyle = {
  position: "absolute",
  width: 1115,
  height: "98vh",
  // height: 758,
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
  // "@media (minWidth: 960px)": {
  //   width: "1115px",
  //   height: "758px",
  // },
};

const subContainerStyles = {
  paddingTop: 70,
  paddingLeft: 134,
  paddingRight: 90,
};
const subContainerStylesSM = {
  padding: 30,
};
const subContainerStylesMD = {
  paddingTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
};
const subContainerStylesLG = {
  padding: 70,
};

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  // width: 750,
  height: 493,
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
  // marginRight: -30,
};

const infoCollectedTextStyles = {
  color: "#19A6FE",
  fontSize: 16,
  fontWeight: 400,
  marginBottom: 20,
};

const subTitleTextStyles = {
  color: "#000",
  fontSize: 16,
  fontWeight: 700,
  background: "#DFF0FF",
  padding: 10,
};

const smallParagraphStyles = {
  ...termsOfServicesText,
  // width: "50%",
  marginTop: 15,
};

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
};
