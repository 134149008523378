import React from "react";
import { Grid } from "@mui/material";

import { Scrollbars } from "react-custom-scrollbars-2";
import { registrationCardStyles } from "../registration/styles/RegistrationCardStyles";

const ScrollbarView = ({ children }) => {
  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Scrollbars
        style={scrollbarContainerStyle}
        thumbMinSize={30}
        autoHide
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              ...registrationCardStyles.thumbVerticalStyle,
            }}
          />
        )}
        renderTrackVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              ...registrationCardStyles.trackVerticalStyle,
            }}
          />
        )}
      >
        {children}
      </Scrollbars>
    </Grid>
  );
};

export default ScrollbarView;

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  // width: 750,
  height: 493,
};
