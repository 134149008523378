import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/login";
import NavigateToLogin from "./NavigateToLogin";
import Dashboard from "./pages/dashboard";
import { VerifyAccount } from "./pages/login/VerifyAccount";
import { ResetPassword } from "./pages/login/ResetPassword";
import CreateNewPassword from "./pages/login/CreateNewPassword";
import Registration from "./pages/registration";
import SuccessPage from "./pages/registration/SuccessPage";
import PrivacyPolicy from "./pages/registration/PrivacyPolicy";
import HelpCenter from "./pages/helpCenter";
import HealthRecords from "./pages/healthRecords/index.js";
import Consultations from "./pages/consultations";
import Settings from "./pages/settings";
import MessageCenter from "./pages/messageCenter";
import PersonalInfo from "./pages/healthRecords/PersonalInfo";
import { ThemeProvider } from "@mui/material/styles";
import Medications from "./pages/healthRecords/Medications";
import MedicationAllergies from "./pages/healthRecords/MedicationAllergies";
import MedicalHistory from "./pages/healthRecords/MedicalHistory";
import SurgicalHistory from "./pages/healthRecords/SurgicalHistory";
import DocumentManagement from "./pages/healthRecords/DocumentManagement";
import NewQuestion from "./pages/messageCenter/NewQuestion";
import Applinks from "./assets/route-links";
import ScheduleConsultation from "./pages/consultations/ScheduleConsultation";
import ScheduleUrgentCare from "./pages/consultations/urgent-care/ScheduleUrgentCare";
import SchedulePsychiatrist from "./pages/consultations/psychiatrist/SchedulePsychiatrist";
import EHRAgreement from "./pages/consultations/urgent-care/EHRAgreement";
import PharmacyDetails from "./pages/consultations/urgent-care/PhramacyDetails";

import { appTheme } from "./assets/theme";
import UrgentCareMedicalQuestionaire from "./pages/consultations/urgent-care/UrgentCareMedicalQuestionaire";
import UrgentCareDateTime from "./pages/consultations/urgent-care/UrgentCareDateTime";
import PaymentMethodUrgent from "./pages/consultations/urgent-care/PaymentMethodUrgent";
import PsychiatristQuestionaire from "./pages/consultations/psychiatrist/PsychiatristQuestionaire";
import IntakeQuestionaire from "./pages/consultations/psychiatrist/IntakeQuestionaire";
import PsychiatristPharmacyDetails from "./pages/consultations/psychiatrist/PsychiatristPharmacyDetails";
import withContext from "./hoc/withContext";
import useAuth from "./providers/auth.provider";
import PsychiatristDateTime from "./pages/consultations/psychiatrist/PsychiatristDateTime";
import PsychiatristPayment from "./pages/consultations/psychiatrist/PsychiatristPayment";
import BehavioralHealthCounseling from "./pages/consultations/behavoural/BehavioralHealthCounseling";
import ErrorDialog from "./components/common/error-dailog/ErrorDialog";
import { ScrollToTop, SnackBar } from "./components/common";
import TokVideoPage from "./pages/consultations/TokVideoPage";
import DoNotSellMyInfo from "./pages/footer/DoNotSellMyInfo";
import PatientConsent from "./pages/footer/PatientConsent";
import {} from "./utils/string-extensions.utils";
import TermsOfUse from "./pages/footer/TermsOfUse";
import StudentBehaviouralHealthCouseling from "./pages/consultations/behavoural/StudentBehaviouralHealthCouseling.js";

function App() {
  // const theme = useTheme();

  const { user } = useAuth();
  return (
    <ThemeProvider theme={appTheme}>
      <ErrorDialog />
      <SnackBar />
      <BrowserRouter>
        <ScrollToTop />
        {!user ? (
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/*" element={<NavigateToLogin />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/create-new-password"
              element={<CreateNewPassword />}
            />
            <Route exact path="/registration" element={<Registration />} />
            <Route
              exact
              path="/registration-completed"
              element={<SuccessPage />}
            />
            <Route
              exact
              path="/terms-of-services"
              element={<TermsOfUse />}
              // element={<TermsOfServices />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/help-center" element={<HelpCenter />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              exact
              path="/terms-of-services"
              // element={<TermsOfServices />}
              element={<TermsOfUse />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/informed-patient-consent"
              element={<PatientConsent />}
            />
            <Route
              exact
              path="/do-not-sell-my-personal-info"
              element={<DoNotSellMyInfo />}
            />
            <Route exact path="/verify-account" element={<VerifyAccount />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/health-records" element={<HealthRecords />} />
            <Route
              exact
              path="/health-records/personal-info"
              element={<PersonalInfo />}
            />
            <Route
              exact
              path="/health-records/medications"
              element={<Medications />}
            />
            <Route
              exact
              path="/health-records/medication-allergies"
              element={<MedicationAllergies />}
            />
            <Route
              exact
              path="/health-records/medical-history"
              element={<MedicalHistory />}
            />
            <Route
              exact
              path="/health-records/surgical-history"
              element={<SurgicalHistory />}
            />
            <Route
              exact
              path="/health-records/document-management"
              element={<DocumentManagement />}
            />
            <Route exact path="/consultations" element={<Consultations />} />
            <Route
              exact
              path={Applinks.SCHEDULE_CONSULATATIONS}
              element={<ScheduleConsultation />}
            />

            <Route
              exact
              path={Applinks.SCHEDULE_CONSULATATIONS_URGENT_CARE}
              element={<ScheduleUrgentCare />}
            />
            <Route
              exact
              path={Applinks.MEDICAL_QUESTIONAIRE_URGENT_CARE}
              element={<UrgentCareMedicalQuestionaire />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_DATE_URGENT_CARE}
              element={<UrgentCareDateTime />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_CONSULTATION_PSYCHIATRIST}
              element={<SchedulePsychiatrist />}
            />
            <Route exact path={Applinks.EHR} element={<EHRAgreement />} />
            <Route
              exact
              path={Applinks.SCHEDULE_PHARMACY_DETAIL}
              element={<PharmacyDetails />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PAYMENT_METHOD_URGENT_CARE}
              element={<PaymentMethodUrgent />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PSYCHIATRIST_MEDICAL_QUESTIONAIRE}
              element={<PsychiatristQuestionaire />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PSYCHIATRIST_INTAKE_QUESTIONAIRE}
              element={<IntakeQuestionaire />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PSYCHIATRIST_PHARMACY_DETAIL}
              element={<PsychiatristPharmacyDetails />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PSYCHIATRIST_DATE_TIME}
              element={<PsychiatristDateTime />}
            />
            <Route
              exact
              path={Applinks.VIDEO_CALL_SCREEN}
              element={<TokVideoPage />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_PSYCHIATRIST_PAYMENT}
              element={<PsychiatristPayment />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_CONSULATATIONS_BEHAVIORAL_HEALTH}
              element={<BehavioralHealthCounseling />}
            />
            <Route
              exact
              path={Applinks.SCHEDULE_CONSULATATIONS_STUDENT_BEHAVIORAL_HEALTH}
              element={<StudentBehaviouralHealthCouseling />}
            />
            <Route exact path="/message-center" element={<MessageCenter />} />
            <Route
              exact
              path="/messages/new-message"
              element={<NewQuestion />}
            />
            <Route exact path="/settings" element={<Settings />} />
          </Routes>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withContext(App);
