import "../../../fonts/comfortaa.css";

export const messagingStyles = {
  mainTitle: {
    color: "#001011",
    fontSize: 28,
    fontWeight: 400,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  componentMainTitle: {
    color: "#001011",
    fontSize: 23,
    fontWeight: 700,
    // marginBottom: 8,
    fontFamily: "Comfortaa",
  },
  backIcon: {
    border: "2px solid #2E3A59",
    borderRadius: "50%",
    padding: 1,
    fontSize: 14,
    marginRight: 11,
    cursor: "pointer",
  },
  searchTextField: {
    height: 40,
    border: "1px solid #515563",
    borderRadius: 90,
    width: 370,
    background: "#fff",
    color: "#515563",
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 1,
    paddingRight: 2,
  },
  headerAddIcon: { color: "#515563", cursor: "pointer" },
  messegingMainBox: {
    borderRadius: 20,
    // padding: 40,
    background: "#fff",
    marginTop: 30,
  },
  // scrollbar: { width: "100%", height: "530px", marginLeft: "auto" },
  scrollbar: { width: "100%", height: "400px", marginLeft: "auto" },
  scrollbarAllMessages: { width: "100%", height: "580px", marginLeft: "auto" },
  scrollbarprops: {
    width: "4px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "2px",
  },
  messageCard: {
    borderRadius: 10,
    cursor: "pointer",
  },
  messageCardDate: {
    fontSize: 11,
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.6)",
    // marginLeft: "auto",
    textAlign: "right",
    paddingTop: 10,
    paddingRight: 18,
  },
  messageCardQuestion: {
    fontSize: 14,
    fontWeight: 700,
    color: "#000",
    paddingLeft: 17,
  },
  messageCardDoctor: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000",
    paddingLeft: 17,
    paddingTop: 5,
    display: "flex",
  },
  messageCardStatus: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000",
    marginRight: 18,
    marginLeft: "auto",
    width: 30,
    height: 30,
    background: "#fff",
    borderRadius: 35,
    marginBottom: 10,
  },
  messageCardDivider: { width: "80%", marginTop: 10, marginBottom: 10 },
  chatCard: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  chatCardDoctor: {
    fontWeight: 700,
    fontSize: 14,
    color: "#001011",
  },
  chatCardDivider: { width: "100%", marginTop: 10, color: "#19A6FE" },
  chatCardDate: {
    fontWeight: 400,
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.6)",
    paddingTop: 5,
    paddingBottom: 5,
  },
  chatYouCard: {
    background: "#83CFFE",
    borderRadius: 10,
    marginLeft: "auto",
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
  },
  chatCardQuestion: { fontSize: 14, fontWeight: 700, color: "#000" },
  chatCardQuestionDetails: {
    paddingTop: 5,
    fontSize: 14,
    fontWeight: 400,
    color: "#000",
  },
  chatCardReadFlag: { textAlign: "end", paddingRight: 10 },
  chatDoctorCard: {
    // width: 376,
    background: "#DFF0FF",
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
  },
  chatCardSender: { fontWeight: 700, fontSize: 12, color: "#000" },
  chatSenderMessageDate: { fontWeight: 400, fontSize: 12, color: "#000" },
  chatSenderMessage: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000",
  },
  chatTextfieldCard: {
    marginTop: "40px",
    // marginTop: "40px",
    border: "10px solid #FFB74D",
    width: "99%",
    borderRadius: 20,
    marginBottom: 20,
  },
  chatTextfieldSXProps: {
    "&:hover fieldset": {
      border: "2px solid #FFB74D!important",
      borderRadius: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "4px solid #FFB74D!important",
    },
  },
  addQuestionSaveButton: {
    width: "60%",
    // marginTop: 30,
    borderRadius: 60,
    background: "#19A6FE",
    height: 50,
    color: "#000",
    // marginLeft: 30,
  },
  uploadFileAddIcon: {
    background: "#83CFFE",
    borderRadius: 16,
    padding: 3,
    cursor: "pointer",
  },
  uploadFilesCard: {
    width: 55,
    height: 50,
    background: "#FFFAF5",
    borderRadius: 10,
    marginRight: 20,
  },
  uploadFilesDeleteIcon: {
    color: "#CF2D23",
    marginLeft: "auto",
    paddingRight: 15,
    cursor: "pointer",
  },
  uploadedFileName: {
    fontSize: 16,
    fontWeight: 700,
    color: "#001011",
    whiteSpace: "nowrap" /* Keep the text on a single line */,
    overflow: "hidden" /* Hide text that doesn't fit in the container */,
    textOverflow: "ellipsis" /* Add an ellipsis at the end of the text */,
    maxWidth: 150 /* Adjust the max width as needed */,
  },
  uploadedFileSizeDate: {
    fontSize: 14,
    fontWeight: 600,
    color: "#001011",
    marginTop: 7,
  },
  modalTitleStyles: {
    color: "#000000",
    fontSize: 19,
    fontWeight: 500,
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Comfortaa",
    textAlign: "center",
  },
  modalSubTitleStyles: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 30,
    fontFamily: "Comfortaa",
    textAlign: "center",
  },
  errorIcon: { width: 90, height: 90, color: "#F45757" },
  doctorModalTitle: {
    fontSize: 17,
    fontWeight: 700,
    color: "#001011",
    fontFamily: "Comfortaa",
  },
  doctorModalSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: "#001011",
    textAlign: "justify",
  },
  doctorModalDivider: {
    width: "99%",
    backgroundColor: "#19A6FE",
    marginTop: 25,
    marginBottom: 30,
  },
  doctorModalSharedFilesCard: {
    background: "#FFF2E1",
    marginTop: 25,
    padding: 20,
  },
  doctorModalFileCard: {
    width: 71,
    height: 71,
    borderRadius: 10,
    // background: "#fff",
    marginRight: 20,
  },
  doctorModalFileName: {
    fontSize: 14,
    fontWeight: 700,
    color: "#001011",
    fontFamily: "Comfortaa",
    textAlign: "justify",
    maxWidth: 50,
    ovewflow: "hidden",
  },
};
