import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { modalStyle } from "../../../constant";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import EditModalTextField from "./EditModalTextField";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const EditMedicationModal = ({
  modalOpen,
  handleClose,
  medication,
  setMedication,
  handleEditMedication,
  matchesSM,
  matchesXS,
  loading,
}) => {
  const {t} = useTranslation("translations")
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        {/*animi. section*/}
        <Grid
          container
          direction={"column"}
          // justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 30px" }}
        >
          <div style={healthRecordsStyles.editModalTitle}>{t("edit_medication")}</div>
          <EditModalTextField
            title={t("medication_name")}
            placeholder={t("add_medication_name_here")}
            value={medication && medication.name}
            onChange={(e) => {
              setMedication({
                ...medication,
                name: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("frequency")}
            placeholder={t("add_your_frequency_here")}
            value={medication && medication.frequency}
            onChange={(e) => {
              setMedication({
                ...medication,
                frequency: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("comment")}
            placeholder={t("add_your_comment_here")}
            value={medication && medication.comment}
            multiline={true}
            rows={3}
            onChange={(e) => {
              setMedication({
                ...medication,
                comment: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("currently_using")}
            placeholder={t("select_an_option")}
            value={
              medication && medication.isCurrentlyUsing === 1 ? "Yes" : "No"
            }
            select={true}
            onChange={(e) => {
              setMedication({
                ...medication,
                isCurrentlyUsing: e.target.value === "No" ? 0 : 1,
              });
            }}
          />
          {loading ? (
            <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              fullWidth
              disabled={
                medication &&
                medication.name &&
                medication.frequency &&
                medication.comment &&
                medication.isCurrentlyUsing
                  ? false
                  : true
              }
              style={
                medication &&
                medication.name &&
                medication.frequency &&
                medication.comment &&
                medication.isCurrentlyUsing
                  ? healthRecordsStyles.addMedicationButtonEnabled
                  : healthRecordsStyles.addMedicationButtonDisabled
              }
              onClick={() => {
                handleEditMedication();
              }}
            >
              {t("save")}
            </Button>
          )}
          <Button
            variant="text"
            fullWidth
            style={healthRecordsStyles.cancelButton}
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditMedicationModal;
