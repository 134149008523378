import React, {
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from "react";
import LocalStorageWrapper, { LocalStorageKeys } from "../utils/storage.utils";

const PsychiatristContext = createContext({});

export const PsychiatristProvider = ({ children }) => {
  // Get data from local storage and set in states if they exist and the user has refreshed the page
  const mPsychiatristPayload = LocalStorageWrapper.get(LocalStorageKeys.PSYCHIATRIST_PAYLOAD);
  const mConsultObj = LocalStorageWrapper.get(LocalStorageKeys.CONSULT_OBJ);
  const mMedicationRefill = LocalStorageWrapper.get(LocalStorageKeys.MEDICATION_REFILL);
  const mMedication = LocalStorageWrapper.get(LocalStorageKeys.MEDICATION_ARRAY)
  const mMedicalCondition = LocalStorageWrapper.get(LocalStorageKeys.MEDICAL_CONDITION_ARRAY)
  const mSurgery = LocalStorageWrapper.get(LocalStorageKeys.SURGERY_ARRAY)
  const mAllergies = LocalStorageWrapper.get(LocalStorageKeys.ALERGIES)
  const mPharmacy = LocalStorageWrapper.get(LocalStorageKeys.PHARMACY)
  const mIntakeQuestionaireData = LocalStorageWrapper.get(LocalStorageKeys.INTAKE_QUESTIONAIRE_DATA)

  // Initialize states with data from local storage if they exist
  const [psychiatristPayload, setPsychiatristPayload] = useState(mPsychiatristPayload ?? {});
  const [patientConsultData, setPatientConsultData] = useState(mConsultObj ?? null);
  const [medicationRefill, setMedicationRefill] = useState(mMedicationRefill ?? []);
  const [medicationArray, setMedicationArray] = useState(mMedication ?? []);
  const [medicalConditionArray, setMedicalConditionArray] = useState(mMedicalCondition ?? []);
  const [surgeryArray, setSurgeryArray] = useState(mSurgery ?? []);
  const [alergies, setAlergies] = useState(mAllergies ?? []);
  const [pharmacy, setPharmacy] = useState(mPharmacy ?? null);
  const [intakeQuestionaireData, setIntakeQuestionaireData] = useState(mIntakeQuestionaireData ?? null);

  const [refill, setRefill] = useState(false);
  const [alergy, setAlergy] = useState(true);
  const [medications, setMedications] = useState(true);
  const [surgeries, setSurgeries] = useState(true);
  const [medicalCondition, setMedicalCondition] = useState(true);

  useEffect(() => {
    if (patientConsultData) {
      setMedicationArray(patientConsultData?.patient?.ehr?.medications || []);
      setMedicalConditionArray(
        patientConsultData?.patient?.ehr?.medicalConditions || []
      );
      setSurgeryArray(patientConsultData?.patient?.ehr?.surgicalHistory || []);
      setAlergies(patientConsultData?.patient?.ehr?.allergies || []);
      setIntakeQuestionaireData(
        patientConsultData?.consultation?.available_questionnaires[0] || []
      );
    }
  }, [patientConsultData]);

  useEffect(() => {
    saveToLocal();
  }, [
    psychiatristPayload,
    patientConsultData,
    medicationRefill,
    medicationArray,
    medicalConditionArray,
    surgeryArray,
    alergies,
    pharmacy,
    intakeQuestionaireData
  ]);

  const saveToLocal = () => {
    LocalStorageWrapper.set(
      LocalStorageKeys.PSYCHIATRIST_PAYLOAD,
      psychiatristPayload
    );
    if (patientConsultData) {
      LocalStorageWrapper.set(
        LocalStorageKeys.CONSULT_OBJ,
        patientConsultData
      );
    }
    if (medicationRefill.length > 0) {
      LocalStorageWrapper.set(
        LocalStorageKeys.MEDICATION_REFILL,
        medicationRefill
      );
    }
    if (medicationArray.length > 0) {
      LocalStorageWrapper.set(
        LocalStorageKeys.MEDICATION_ARRAY,
        medicationArray
      );
    }
    if (medicalConditionArray.length > 0) {
      LocalStorageWrapper.set(
        LocalStorageKeys.MEDICAL_CONDITION_ARRAY,
        medicalConditionArray
      );
    }
    if (surgeryArray.length > 0) {
      LocalStorageWrapper.set(LocalStorageKeys.SURGERY_ARRAY, surgeryArray);
    }
    if (alergies.length > 0) {
      LocalStorageWrapper.set(LocalStorageKeys.ALERGIES, alergies);
    }
    if (pharmacy) {
      LocalStorageWrapper.set(LocalStorageKeys.PHARMACY, pharmacy);
    }
    if (intakeQuestionaireData) {
      LocalStorageWrapper.set(LocalStorageKeys.INTAKE_QUESTIONAIRE_DATA, intakeQuestionaireData);
    }
  };

  const removeFromLocal = () => {
    LocalStorageWrapper.remove(LocalStorageKeys.PSYCHIATRIST_PAYLOAD);
    LocalStorageWrapper.remove(LocalStorageKeys.CONSULT_OBJ);
    LocalStorageWrapper.remove(LocalStorageKeys.MEDICATION_REFILL);
    LocalStorageWrapper.remove(LocalStorageKeys.MEDICATION_ARRAY);
    LocalStorageWrapper.remove(LocalStorageKeys.MEDICAL_CONDITION_ARRAY);
    LocalStorageWrapper.remove(LocalStorageKeys.SURGERY_ARRAY);
    LocalStorageWrapper.remove(LocalStorageKeys.ALERGIES);
    LocalStorageWrapper.remove(LocalStorageKeys.PHARMACY);
    LocalStorageWrapper.remove(LocalStorageKeys.INTAKE_QUESTIONAIRE_DATA);
    setPsychiatristPayload({});
  }

  const value = useMemo(
    () => ({
      psychiatristPayload,
      setPsychiatristPayload,
      patientConsultData,
      setPatientConsultData,
      medicationRefill,
      setMedicationRefill,
      medicationArray,
      setMedicationArray,
      medicalConditionArray,
      setMedicalConditionArray,
      surgeryArray,
      setSurgeryArray,
      alergies,
      setAlergies,
      pharmacy,
      setPharmacy,
      intakeQuestionaireData,
      setIntakeQuestionaireData,
      refill,
      setRefill,
      alergy,
      setAlergy,
      medications,
      setMedications,
      surgeries,
      setSurgeries,
      medicalCondition,
      setMedicalCondition,
      removeFromLocal
    }),
    [
      psychiatristPayload,
      setPsychiatristPayload,
      patientConsultData,
      setPatientConsultData,
      medicationRefill,
      setMedicationRefill,
      medicationArray,
      setMedicationArray,
      medicalConditionArray,
      setMedicalConditionArray,
      surgeryArray,
      setSurgeryArray,
      alergies,
      setAlergies,
      pharmacy,
      setPharmacy,
      intakeQuestionaireData,
      setIntakeQuestionaireData,
      refill,
      setRefill,
      alergy,
      setAlergy,
      medications,
      setMedications,
      surgeries,
      setSurgeries,
      medicalCondition,
      setMedicalCondition,
    ]
  );

  return (
    <PsychiatristContext.Provider value={value}>
      {children}
    </PsychiatristContext.Provider>
  );
};

export default function usePsychiatrist() {
  return useContext(PsychiatristContext);
}
