import React from "react";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { StyledCaptionText } from "../../common";

const ConditionLabel = (props) => {
  const {
    title,
    onClick,
    bgcolor = COLORS.TEXT_SECONDARY,
    textColor = COLORS.TEXT,
  } = props;
  return (
    <Box
      sx={{
        width: Dimens.CONSULTATION_BOX_DIMENSION,
        pt: Dimens.PADDING_TOP_SMALL,
        pb: Dimens.PADDING_TOP_SMALL,
        pl: Dimens.PADDING_TOP_MEDIUM,
        bgcolor: bgcolor,
        borderRadius: Dimens.BORDER_RADIUS_LARGE,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <StyledCaptionText sx={{ color: textColor }}>{title}</StyledCaptionText>
    </Box>
  );
};

ConditionLabel.propTypes = {
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default ConditionLabel;
