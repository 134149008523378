/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import MainPageLayout from "../MainPageLayout";
import TextfieldSection from "../../components/healthRecords/TextfieldSection";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import DropDownTextfieldSection from "../../components/healthRecords/DropDownTextfieldSection";
import { useNavigate } from "react-router-dom";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { updateHealthRecordPersonalInformation } from "../../data/requests/healthRecords";
import {
  smokingOptions,
  drinkingOptions,
  exerciseOptions,
  bloodTypeOptions,
  maritalStatusOptions,
  exerciseLengthOptions,
} from "../../assets/constants/healthRecord-options";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const PersonalInfo = () => {
  const { user } = useAuth();
  const { personalInfo, handleGetHealthRecords } = useHealthRecordsContext();
  const missingFields = location.state;

  // console.log("missingFields", missingFields)
  console.log("personal info:", personalInfo[0]);

  const { t } = useTranslation("translations");

  const navigate = useNavigate();

  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);

  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [personalInfoData, setPersonalInfoData] = useState({
    height: "",
    weight: "",
    bloodType: "",
    bloodPressure: "",
    maritalStatus: "",
    smoking: "",
    drinking: "",
    exercise: "",
    exerciseDuration: "",
  });

  useEffect(() => {
    personalInfo &&
      personalInfo[0] &&
      setPersonalInfoData({
        height: personalInfo[0].heightFeet,
        heightInches: personalInfo[0].heightInches,
        weight: personalInfo[0].weight,
        bloodType: personalInfo[0].bloodType,
        bloodPressure: `${personalInfo[0].bloodPressureSystolic} / ${personalInfo[0].bloodPressureDiastolic}`,
        maritalStatus: personalInfo[0].maritalStatus,
        smoking: personalInfo[0].smoke,
        drinking: personalInfo[0].drink,
        exercise: personalInfo[0].exercise,
        exerciseDuration: personalInfo[0].exerciseLength,
      });
  }, [personalInfo]);

  const handleSavePersonalInfo = async () => {
    try {
      setIsLoading(true);
      var BPparts = personalInfoData?.bloodPressure?.split("/");
      const data = {
        heightFeet: Number(personalInfoData?.height),
        heightInches: Number(personalInfoData?.heightInches),
        weight: Number(personalInfoData?.weight),
        bloodPressureSystolic: parseInt(BPparts[0]),
        bloodPressureDiastolic: parseInt(BPparts[1]),
        smokingHabits: personalInfoData?.smoking,
        drinkingHabits: personalInfoData?.drinking,
        exerciseHabits: personalInfoData?.exercise,
        exerciseLength: personalInfoData?.exerciseDuration,
        bloodType: personalInfoData?.bloodType,
        maritalStatus: personalInfoData?.maritalStatus,
      };
      await updateHealthRecordPersonalInformation(data, user.userId);
      await handleGetHealthRecords();
    } catch (error) {
      console.log("error saving personal info:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          {" "}
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("personal_information")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("personal_information_info")}
        </div>
        <Grid container style={healthRecordsStyles.personalInfoMainBox}>
          <Grid
            item
            container
            direction={matchesLG || matchesMD || matchesXl ? "row" : "column"}
          >
            <Grid item xs container>
              <TextfieldSection
                title={t("height")}
                label={t("height_in_feet")}
                type={"number"}
                required={true}
                value={personalInfoData.height}
                error={!personalInfoData.height}
                helperText={
                  !personalInfoData.height && t("this_field_is_invalid")
                }
                onChange={(e) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    height: e.target.value,
                  })
                }
              />
              <TextfieldSection
                title={t("height")}
                label={t("height_in_inches")}
                type={"number"}
                required={true}
                value={Number(personalInfoData.heightInches)}
                // error={!personalInfoData.heightInches}
                // helperText={!personalInfoData.heightInches && t("this_field_is_invalid")}
                onChange={(e) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    heightInches: e.target.value,
                  })
                }
              />
              <TextfieldSection
                title={t("weight")}
                label={t("weight_in_pounds")}
                error={!personalInfoData.weight}
                helperText={!personalInfoData.height && t("required")}
                required={true}
                value={personalInfoData.weight}
                onChange={(e) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    weight: e.target.value,
                  })
                }
              />
              <DropDownTextfieldSection
                title={t("blood_type")}
                label={t("select_blood_type")}
                value={personalInfoData.bloodType}
                options={bloodTypeOptions}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                onChange={(option) => {
                  setPersonalInfoData({
                    ...personalInfoData,
                    bloodType: option?.value,
                  });
                  console.log(personalInfoData);
                }}
              />
              <TextfieldSection
                title={t("blood_pressure")}
                label={t("systolic_distolic")}
                value={personalInfoData.bloodPressure}
                onChange={(e) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    bloodPressure: e.target.value,
                  })
                }
              />
              <DropDownTextfieldSection
                title={t("marital_status")}
                label={t("select_an_option")}
                value={personalInfoData.maritalStatus}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                options={maritalStatusOptions}
                onChange={(option) => {
                  setPersonalInfoData({
                    ...personalInfoData,
                    maritalStatus: option?.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs container>
              <DropDownTextfieldSection
                title={t("do_you_smoke")}
                label={t("select_an_option")}
                value={personalInfoData.smoking}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                options={smokingOptions}
                onChange={(option) => {
                  setPersonalInfoData({
                    ...personalInfoData,
                    smoking: option?.value,
                  });
                }}
              />
              <DropDownTextfieldSection
                title={t("do_you_drink")}
                label={t("select_an_option")}
                value={personalInfoData.drinking}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                options={drinkingOptions}
                onChange={(option) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    drinking: option?.value,
                  })
                }
              />
              <DropDownTextfieldSection
                title={t("do_you_excercise")}
                label={t("select_an_option")}
                value={personalInfoData.exercise}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                options={exerciseOptions}
                onChange={(option) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    exercise: option?.value,
                  })
                }
              />
              <DropDownTextfieldSection
                title={t("average_exercise_length")}
                label={t("select_an_option")}
                value={personalInfoData.exerciseDuration}
                personalInfoData={personalInfoData}
                setPersonalInfoData={setPersonalInfoData}
                options={exerciseLengthOptions}
                onChange={(option) =>
                  setPersonalInfoData({
                    ...personalInfoData,
                    exerciseDuration: option?.value,
                  })
                }
              />
              <Grid
                item
                container
                direction={"column"}
                style={{
                  padding:
                    matchesLG || matchesMD || matchesXl ? "0px 50px" : "0px",
                  marginBottom: 30,
                }}
              >
                <Button
                  disabled={!!isLoading}
                  variant="contained"
                  style={healthRecordsStyles.addMedicationButtonEnabled}
                  onClick={handleSavePersonalInfo}
                >
                  {isLoading ? t("saving") : t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default PersonalInfo;
