import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { images, modalStyle } from "../../../constant";
import { codeSentModalStyles } from "../../login/styles/CodeSentModaltyles";
import { healthRecordsStyles } from "../../healthRecords/styles/healthRecordsStyles";
import { messagingStyles } from "../styles/messagingStyles";

const MessageSentModal = ({
  modalOpen,
  handleClose,
  matchesSM,
  matchesXS,
  title,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 80px" }}
        >
          <img
            src={images.sent}
            alt="bg"
            style={codeSentModalStyles.imageStyles}
          />
          <span style={messagingStyles.modalTitleStyles}>{title}</span>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={healthRecordsStyles.okayButtonStyles}
          >
            DONE
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MessageSentModal;
