import { Typography } from "@mui/material";
import React from "react";
import Dimens from "../../assets/Dimens";
import PropTypes from "prop-types";

const NoAppointmentText = (props) => {
  const { label } = props;
  return (
    <Typography
      sx={{
        fontFamily: "Open Sans",
        fontWeight: Dimens.FONT_WEIGHT_NORMAL,
        fontSize: Dimens.FONT_SIZE_SMALL,
        lineHeight: Dimens.LINE_HEIGHT_SMALL,
        textAlign: "center",
      }}
    >
      {label}
    </Typography>
  );
};
NoAppointmentText.propTypes = {
  label: PropTypes.string,
};

export default NoAppointmentText;
