import React from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import { Card, Grid } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";

import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <LoginDesignPage>
        <Card
          sx={{
            ...cardStyle,
            width: matchesXS
              ? "98%"
              : matchesSM
              ? "95%"
              : matchesMD
              ? "90%"
              : matchesLG
              ? "80%"
              : 1115,
          }}
        >
          <Grid
            container
            direction={"column"}
            style={
              matchesSM || matchesXS
                ? subContainerStylesSM
                : matchesMD
                ? subContainerStylesMD
                : matchesLG
                ? subContainerStyles
                : matchesXl
                ? subContainerStyles
                : subContainerStyles
            }
          >
            <Grid
              container
              alignItems={"center"}
              onClick={() => navigate(-1)}
              style={backButtonStyles}
            >
              <ArrowBackIosOutlinedIcon style={backIconStyles} />
              <div style={{ textDecoration: "underline", marginLeft: 12 }}>
                {t("back")}
              </div>
            </Grid>
            <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("privacy_policy_and_hipaa_privacy_practices")}
            </span>
            <span style={registrationCardStyles.termsOfServicesDate}>
              {t("privacy_policy_legal_policies")}
            </span>
            <Grid container direction={"column"} alignItems={"center"}>
              <Scrollbars
                style={scrollbarContainerStyle}
                thumbMinSize={30}
                autoHide
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.thumbVerticalStyle,
                    }}
                  />
                )}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.trackVerticalStyle,
                    }}
                  />
                )}
              >
                {/**/}
                {/*privacy policy section start*/}
                <span style={registrationCardStyles.termsOfServicesTitle}>
                  {t("privacy_policy")}
                </span>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_info_one")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_info_two")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_info_three")}
                </div>
                {/*privacy policy section end*/}

                {/*acceptance section start*/}
                <span style={registrationCardStyles.termsOfServicesTitle}>
                  {t("privacy_policy_acceptance")}
                </span>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_acceptance_info")}
                </div>
                {/*acceptance section end*/}

                {/*Notice of HIPAA Privacy Practices section start*/}
                <span style={registrationCardStyles.termsOfServicesTitle}>
                  {t("notice_of_hipaa_privacy_practices")}
                </span>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("notice_of_hipaa_privacy_practices_info_one")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("notice_of_hipaa_privacy_practices_info_two")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("notice_of_hipaa_privacy_practices_info_three")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("notice_of_hipaa_privacy_practices_info_four")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  <b>{t("privacy_policy_treatment")}</b>{" "}
                  {t("privacy_policy_treatment_info")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  <b>{t("privacy_policy_payment")}</b>{" "}
                  {t("privacy_policy_payment_info")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  <b>{t("privacy_policy_health_care_operations")}</b>{" "}
                  {t("privacy_policy_health_care_operations_info_one")}
                </div>

                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_health_care_operations_info_two")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_health_care_operations_info_three")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_health_care_operations_info_four")}
                </div>
                <div
                  style={{
                    ...termsOfServicesText,
                    marginTop: 20,
                  }}
                >
                  {t("privacy_policy_health_care_operations_info_five")}
                </div>
                <div style={termsOfServicesText}>
                  <ul>
                    <li>
                      {t("privacy_policy_health_care_operations_rights_one")}
                    </li>
                    <li>
                      {t("privacy_policy_health_care_operations_rights_two")}
                    </li>
                    <li>
                      {t("privacy_policy_health_care_operations_rights_three")}
                    </li>
                    <li>
                      {t("privacy_policy_health_care_operations_rights_four")}
                    </li>
                    <li>
                      {t("privacy_policy_health_care_operations_rights_five")}
                    </li>
                  </ul>
                </div>
                {/*Notice of HIPAA Privacy Practices section end*/}
              </Scrollbars>
            </Grid>
          </Grid>
        </Card>
      </LoginDesignPage>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;

const cardStyle = {
  position: "absolute",
  width: 1115,
  height: "98vh",
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
};

const subContainerStyles = {
  paddingTop: 70,
  paddingLeft: 134,
  paddingRight: 90,
};
const subContainerStylesSM = {
  padding: 30,
};
const subContainerStylesMD = {
  paddingTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
};
const subContainerStylesLG = {
  padding: 70,
};

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  height: 493,
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
  // marginRight: -30,
};

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
};
