import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainPageLayout from "../../MainPageLayout";
import { useNavigate } from "react-router-dom";
import StyledCard from "../../../components/common/styled-card/StyledCard";
import {
  AlertDialog,
  DefaultDropdown,
  DefaultRadioButton,
  DefaultTextArea,
  Header,
  StyledBodyText,
} from "../../../components/common";
import { SYMPTOMS } from "../../../assets/constants/symptoms";
import {
  MedicationRefillForm,
  SymptomCheckLabel,
} from "../../../components/consultations";
import DefaultButton from "../../../components/consultations/DefaultButton";
import Applinks from "../../../assets/route-links";
import useUrgentCare from "../../../providers/urgent-care.provider";
import { useRequest } from "../../../data/useRequest";
import { getStates } from "../../../data/requests/common";
// import useAuth from "../../../providers/auth.provider";
import { ALTERNATE_SERVICES } from "../../../assets/constants/service-alternates";
import { DeleteRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useFeedback from "../../../providers/feedback.provider";
// import moment from "moment";
const { DateTime } = require("luxon");

const UrgentCareMedicalQuestionaire = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const { urgentCarePayload, setUrgentCarePayload } = useUrgentCare();
  const { addSnack } = useFeedback();
  const states = useRequest(() => getStates());

  const [medicationRefill, setMedicationRefill] = useState([]);
  const [refill, setRefill] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [medicationRefillDialog, setMedicationRefillDialog] = useState(false);
  const [medicationToDelete, setMedicationToDelete] = useState(null);
  const [disableContinue, setDisableCOntinue] = useState(true);

  const [formErrors, setFormErrors] = useState({
    state: "",
    service: "",
    description: "",
    symptom: "",
  });

  const clearRefillForm = useRef();

  // console.log(
  //   "states::",
  //   states.data.states.forEach((item) => item.name)
  // );

  // useEffect(() => {
  //   let temp = [];
  //   states &&
  //     states.data.states.forEach((item) => {
  //       temp.push(item.name);
  //     });
  //     console.log(temp)
  // }, []);

  // function getTimeAndTimeZone(stateName) {
  //   try {
  //     // Map state names to time zone identifiers
  //     const stateTimeZones = {
  //       Alabama: "America/Chicago",
  //       Alaska: "America/Anchorage",
  //       "American Samoa": "Pacific/Pago_Pago",
  //       Arizona: "America/Phoenix",
  //       Arkansas: "America/Chicago",
  //       "Armed Forces Americas": "America/New_York",
  //       "Armed Forces Non-Americas": "UTC",
  //       "Armed Forces Pacific": "Pacific/Honolulu",
  //       California: "America/Los_Angeles",
  //       Colorado: "America/Denver",
  //       Connecticut: "America/New_York",
  //       Delaware: "America/New_York",
  //       "District of Columbia": "America/New_York",
  //       "Federated States of Micronesia": "Pacific/Chuuk",
  //       Florida: "America/New_York",
  //       Georgia: "America/New_York",
  //       Guam: "Pacific/Guam",
  //       Hawaii: "Pacific/Honolulu",
  //       Idaho: "America/Denver",
  //       Illinois: "America/Chicago",
  //       Indiana: "America/Indianapolis",
  //       Iowa: "America/Chicago",
  //       Kansas: "America/Chicago",
  //       Kentucky: "America/New_York",
  //       Louisiana: "America/Chicago",
  //       Maine: "America/New_York",
  //       "Marshall Islands": "Pacific/Majuro",
  //       Maryland: "America/New_York",
  //       Massachusetts: "America/New_York",
  //       Michigan: "America/Detroit",
  //       Minnesota: "America/Chicago",
  //       Mississippi: "America/Chicago",
  //       Missouri: "America/Chicago",
  //       Montana: "America/Denver",
  //       Nebraska: "America/Chicago",
  //       Nevada: "America/Los_Angeles",
  //       "New Hampshire": "America/New_York",
  //       "New Jersey": "America/New_York",
  //       "New Mexico": "America/Denver",
  //       "New York": "America/New_York",
  //       "North Carolina": "America/New_York",
  //       "North Dakota": "America/Chicago",
  //       "Northern Mariana Islands": "Pacific/Saipan",
  //       Ohio: "America/New_York",
  //       Oklahoma: "America/Chicago",
  //       Oregon: "America/Los_Angeles",
  //       Palau: "Pacific/Palau",
  //       Pennsylvania: "America/New_York",
  //       "Puerto Rico": "America/Puerto_Rico",
  //       "Rhode Island": "America/New_York",
  //       "South Carolina": "America/New_York",
  //       "South Dakota": "America/Chicago",
  //       Tennessee: "America/Chicago",
  //       Texas: "America/Chicago",
  //       Utah: "America/Denver",
  //       Vermont: "America/New_York",
  //       "Virgin Islands": "America/St_Thomas",
  //       Virginia: "America/New_York",
  //       Washington: "America/Los_Angeles",
  //       "West Virginia": "America/New_York",
  //       Wisconsin: "America/Chicago",
  //       Wyoming: "America/Denver",
  //     };

  //     // Check if the provided stateName is valid
  //     if (!(stateName in stateTimeZones)) {
  //       throw new Error("Invalid state name");
  //     }

  //     // Get the current time in the specified state's time zone
  //     const userTimeZone = stateTimeZones[stateName];
  //     const currentTimeInUserTimeZone = DateTime.now().setZone(userTimeZone);

  //     // Format the result
  //     const result = {
  //       time: currentTimeInUserTimeZone.toLocaleString(DateTime.TIME_SIMPLE),
  //       timeZone: userTimeZone,
  //     };

  //     return result;
  //   } catch (error) {
  //     // Handle errors (e.g., invalid state name)
  //     return { error: error.message };
  //   }
  // }

  // useEffect(() => {
  //    // Example usage
  //    const stateName = "Alaska";
  //    const result = getTimeAndTimeZone(stateName);

  //    if ("error" in result) {
  //      console.error(`Error: ${result.error}`);
  //    } else {
  //      console.log(`Time in ${stateName}: ${result.time}`);
  //      console.log(`Time zone: ${result.timeZone}`);
  //    }
  // }, [])

  // useEffect(() => {
  //   // // Assume userTimeZone is the selected state's time zone (e.g., 'America/Chicago' for Central Time)
  //   // const userTimeZone = "America/Chicago";

  //   // // Get the current time in the user's time zone
  //   // const currentTimeInUserTimeZone = new Date().toLocaleString("en-US", {
  //   //   timeZone: userTimeZone,
  //   // });

  //   // // Display the current time in the user's time zone
  //   // console.log(
  //   //   `Current time in user's time zone (${userTimeZone}): ${currentTimeInUserTimeZone}`
  //   // );

  //   // Get the user's time zone from the browser
  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //   // Get the current time in the user's time zone
  //   const currentTimeInUserTimeZone = new Date().toLocaleString("en-US", {
  //     timeZone: userTimeZone,
  //   });

  //   // Display the current time in the user's time zone
  //   console.log(
  //     `Current time in user's time zone (${userTimeZone}): ${currentTimeInUserTimeZone}`
  //   );

  //   // Assume accountTimeZone is the user's account location time zone (e.g., 'America/Los_Angeles' for Pacific Time)
  //   const accountTimeZone = "America/Los_Angeles";
  //   // const accountTimeZone = `America/${
  //   //   urgentCarePayload.state ? urgentCarePayload.state: 'virgin_islands'
  //   // }`;

  //   // Convert the current time to the account location time zone
  //   const currentTimeInAccountTimeZone = new Date();
  //   currentTimeInAccountTimeZone.toLocaleString("en-US", {
  //     timeZone: accountTimeZone,
  //   });

  //   // Display the current time in the account location time zone
  //   console.log(
  //     `Current time in account's time zone (${accountTimeZone}): ${moment(
  //       currentTimeInAccountTimeZone
  //     ).format("YYYY-MM-DD HH:mm a")}`
  //   );
  // }, []);

  useEffect(() => {
    if (
      urgentCarePayload?.problems?.otherProblems.length === 0 ||
      !urgentCarePayload.roi ||
      !urgentCarePayload.state ||
      !urgentCarePayload.patientDescription
    ) {
      const errors = {
        state: !urgentCarePayload.state ? "State is required" : "",
        service: !urgentCarePayload?.roi ? "Select required service" : "",
        description: !urgentCarePayload?.patientDescription
          ? "Description is required"
          : "",
        symptom:
          urgentCarePayload?.problems?.otherProblems.length === 0
            ? "Symptom(s) is required"
            : "",
      };
      setFormErrors(errors);
    } else {
      const errors = {
        state: "",
        service: "",
        description: "",
        symptom: "",
      };
      setFormErrors(errors);
      // if (refill && medicationRefill.length === 0) {
      //   setDisableCOntinue(true);
      // } else {
      //   setDisableCOntinue(false);
      // }
      if (refill === false) {
        setDisableCOntinue(false);
      }
    }
  }, [urgentCarePayload]);

  useEffect(() => {
    states.execute();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  console.log("urgent care::", urgentCarePayload);

  const handleContinue = () => {
    if (
      urgentCarePayload?.problems?.otherProblems.length === 0 ||
      !urgentCarePayload.roi ||
      !urgentCarePayload.state ||
      !urgentCarePayload.patientDescription
    ) {
      return;
    } 
    // else if (refill && medicationRefill.length === 0) {
    //   return;
    // }
    console.log("problems:", urgentCarePayload?.problems);
    // if (urgentCarePayload?.problems?.otherProblems >= 0) {
    urgentCarePayload?.problems?.otherProblems.forEach((problem) => {
      console.log(SYMPTOMS[problem]);
      if (
        problem === 3 ||
        problem === 5 ||
        problem === 9 ||
        problem === 22
        // SYMPTOMS[problem] === "Abdominal Pain" ||
        // "Chest Pain" ||
        // "Loss of Consciousness" ||
        // "Shortness of breath"
      ) {
        setShowDialog(true);
        console.log("warning state");
      } else {
        setShowDialog(false);
        navigate(Applinks.SCHEDULE_DATE_URGENT_CARE);
        console.log("no warning state");
      }
    });
    // }
    // setShowDialog(true);
  };

  const handleInputChange = (event, key) => {
    setUrgentCarePayload({
      ...urgentCarePayload,
      [key]: event.target.value,
    });
  };

  const handleSymptomChange = (index) => {
    let temp = [...urgentCarePayload.problems.otherProblems];
    let selectedIndex =
      urgentCarePayload.problems.otherProblems?.indexOf(index);
    if (selectedIndex === -1) {
      temp.push(index);
      setUrgentCarePayload({
        ...urgentCarePayload,
        problems: {
          ...urgentCarePayload.problems,
          otherProblems: temp,
        },
      });
    } else {
      setUrgentCarePayload({
        ...urgentCarePayload,
        problems: {
          ...urgentCarePayload.problems,
          otherProblems: urgentCarePayload.problems.otherProblems?.filter(
            (item) => item !== index
          ),
        },
      });
    }
  };

  const addRefill = (valueToAdd) => {
    const mRefill = [...medicationRefill];

    console.log(valueToAdd);

    mRefill.push(valueToAdd);
    setMedicationRefill(mRefill);
    setUrgentCarePayload({
      ...urgentCarePayload,
      prescriptionRefill: {
        isNeeded: valueToAdd.currentlyUsing,
        prescriptionDetails: valueToAdd.medicationName,
      },
    });
    clearRefillForm.current();
    addSnack({
      message: t("medication_refill_added"),
      type: "success",
    });
  };

  // console.log(refill, medicationRefill.length === 0, disableContinue)

  const handleEditClick = (index) => {};

  const handleDeleteClick = (index) => {
    setMedicationToDelete(index);
    setMedicationRefillDialog(true);
  };

  const handleAcceptClick = () => {
    if (medicationToDelete !== null) {
      const mRefill = [...medicationRefill];
      mRefill.splice(medicationToDelete, 1);
      setMedicationRefill(mRefill);
      setMedicationToDelete(null);
      setMedicationRefillDialog(false);
      addSnack({
        message: t("medication_refill_deleted"),
        type: "success",
      });
    }
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header handleGoBack={handleGoBack} title={t("medical_questionaire")} />
        <StyledCard sx={{ mt: 2 }} elevation={0}>
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent="space-between"
          >
            <Box>
              <StyledBodyText>{t("alternative_service")}</StyledBodyText>
              <DefaultDropdown
                placeholder={t("select_an_option")}
                options={ALTERNATE_SERVICES}
                value={t(urgentCarePayload.roi)}
                error={!!formErrors.service}
                helperText={t(formErrors.service)}
                onChange={(e) => handleInputChange(e, "roi")}
                renderValue={(option) => (
                  <MenuItem
                    value={option}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      // bgcolor: option === value ? "#FFB74D" : "#fff",
                      "&:hover": {
                        bgcolor: "#FFB74D",
                      },
                    }}
                  >
                    {t(option)}
                  </MenuItem>
                )}
              />
            </Box>
            <Box>
              <StyledBodyText>{t("state_location_question")}</StyledBodyText>
              <DefaultDropdown
                placeholder={t("select_an_option")}
                options={states.data?.states || []}
                value={urgentCarePayload.state}
                error={!!formErrors.state}
                helperText={t(formErrors.state)}
                onChange={(e) => {
                  handleInputChange(e, "state");
                }}
                renderValue={(option) => (
                  <MenuItem
                    value={option.abbreviation}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      // bgcolor: option === value ? "#FFB74D" : "#fff",
                      "&:hover": {
                        bgcolor: "#FFB74D",
                      },
                    }}
                    // onClick={() => {
                    //   console.log("value::::", option.name);
                    //   // Example usage
                    //   const stateName = option.name;
                    //   const result = getTimeAndTimeZone(stateName);

                    //   if ("error" in result) {
                    //     console.error(`Error: ${result.error}`);
                    //   } else {
                    //     console.log(`Time in ${stateName}: ${result.time}`);
                    //     console.log(`Time zone: ${result.timeZone}`);
                    //   }
                    // }}
                  >
                    {option.name}
                  </MenuItem>
                )}
              />
            </Box>
          </Stack>
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText>{t("medical_condition_description")}</StyledBodyText>
          <DefaultTextArea
            minRows={6}
            value={urgentCarePayload.patientDescription}
            error={!!formErrors.description}
            helperText={t(formErrors.description)}
            placeholder={t("add_details_here")}
            sx={{ mt: 1 }}
            onChange={(e) => handleInputChange(e, "patientDescription")}
          />
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText>{t("symptoms_experiencing")}</StyledBodyText>
          <Stack
            direction="row"
            flexWrap="wrap"
            spacing={2}
            useFlexGap
            sx={{ mt: 1 }}
          >
            {SYMPTOMS.map((symptom, index) => (
              <SymptomCheckLabel
                key={symptom}
                checked={urgentCarePayload.problems.otherProblems.find(
                  (el) => el === index
                )}
                sympthom={t(symptom)}
                onChange={(e) => handleSymptomChange(index)}
              />
            ))}
          </Stack>
          <span style={{ marginTop: 10, color: "red" }}>
            {!formErrors.state && t(formErrors.symptom)}
          </span>
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("need_medication_refill")}
          </StyledBodyText>
          <DefaultRadioButton
            value={refill}
            negativeLabel={t("no")}
            positiveLabel={t("yes")}
            onChange={() => {
              setRefill(!refill);
            }}
          />
          {refill && (
            <Collapse in={refill}>
              <MedicationRefillForm
                handleSubmit={addRefill}
                medicationRefillArray={medicationRefill}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                clearMedicationRefillForm={clearRefillForm}
                refill={refill}
              />
            </Collapse>
          )}
        </StyledCard>
        <Stack alignItems={"center"}>
          <DefaultButton
            sx={{ mt: 4 }}
            onClick={handleContinue}
            // disabled={disableContinue}
            disabled={
              // refill && medicationRefill.length === 0
              //   ? true
              //   :
                 disableContinue
                ? disableContinue
                : false
            }
          >
            {t("continue")}
          </DefaultButton>
        </Stack>
      </Box>
      <AlertDialog
        modalOpen={showDialog}
        handleClose={() => setShowDialog(false)}
        title={t("warning")}
        description={t("symptoms_experiencing_warning_info")}
        primaryButtonText={t("i_understand")}
        primaryButtonAction={() => {
          setShowDialog(false);
          navigate(Applinks.SCHEDULE_DATE_URGENT_CARE);
        }}
      />

      <AlertDialog
        modalOpen={medicationRefillDialog}
        icon={<DeleteRounded />}
        handleClose={() => setMedicationRefillDialog(false)}
        title={t("delete_medication_refill")}
        description={t("delete_medication_refill_info")}
        primaryButtonText={t("accept")}
        secondaryButtonText={t("decline")}
        primaryButtonAction={handleAcceptClick}
        secondaryButtonAction={() => {
          setMedicationRefillDialog(false);
          // navigate(Applinks.SCHEDULE_DATE_URGENT_CARE);
        }}
      />
    </MainPageLayout>
  );
};

export default UrgentCareMedicalQuestionaire;
