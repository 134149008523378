import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainPageLayout from "../../MainPageLayout";
import { useNavigate } from "react-router-dom";
import {
  CallTypeCard,
  ConditionLabel,
  ServiceAvailablityBox,
} from "../../../components/consultations";
import { phonecall, videocall } from "../../../assets/icons";
import {
  AlertDialog,
  Header,
  StyledBodyText,
} from "../../../components/common";
import conditions from "../../../assets/conditions";
import DefaultButton from "../../../components/consultations/DefaultButton";
import Applinks from "../../../assets/route-links";
import { COLORS } from "../../../assets/colors";
import useUrgentCare from "../../../providers/urgent-care.provider";
import useAuth from "../../../providers/auth.provider";
import { getPatientAndConsultObject } from "../../../data/requests/consultations";
import { useTranslation } from "react-i18next";

const ScheduleUrgentCare = () => {
  const navigate = useNavigate();
  const { urgentCarePayload, setUrgentCarePayload, setConsultObject } =
    useUrgentCare();
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const [showDialog, setShowDialog] = useState(false);
  const [callTypePhone, setCallTypePhone] = useState(
    urgentCarePayload.modalities[0] === "phone"
  );
  const [callTypeVideo, setCallTypeVideo] = useState(
    urgentCarePayload.modalities[0] === "video"
  );
  const [selectedConditions, setSelectedConditions] = useState([]);

  useEffect(() => {
    getConsultObject();
  }, [callTypePhone, callTypeVideo]);

  const getConsultObject = async () => {
    try {
      const resp = await getPatientAndConsultObject(
        user.userId,
        callTypePhone ? "phone" : "video"
      );
      if (resp.success === true) {
        setConsultObject(resp);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleConditionClick = (condition) => {
    const mSelectedConditions = [...selectedConditions];
    if (mSelectedConditions.includes(condition)) {
      mSelectedConditions.splice(mSelectedConditions.indexOf(condition), 1);
      setSelectedConditions(mSelectedConditions);
      return;
    }
    mSelectedConditions.push(condition);
    setSelectedConditions(mSelectedConditions);
  };

  const handleModalityChange = () => {
    setCallTypePhone((prev) => !prev);
    setCallTypeVideo((prev) => !prev);
    setUrgentCarePayload({
      ...urgentCarePayload,
      modalities: callTypePhone ? ["phone"] : ["video"],
    });
  };

  const handleAcceptClick = () => {
    navigate(Applinks.EHR);
  };

  const handleMakeAppointmentClick = () => {
    if (selectedConditions.length > 0) {
      if(callTypeVideo === true){
        setShowDialog(true);
      }else{
        navigate(Applinks.EHR);
      }
    }
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header
          handleGoBack={handleGoBack}
          title={t("urgent_care")}
          subTitle={t("urgent_care_info")}
        />
        <Stack sx={{ pt: 2 }} alignItems="center">
          <ServiceAvailablityBox
            initialFeeKey={t("service_fee")}
            initialFeeValue={t("free")}
            availabilityKey={t("availability")}
            availabilityValue="24/7"
          />
        </Stack>
        <StyledBodyText sx={{ mt: 6 }}>{t("choose_call_type")}</StyledBodyText>
        <Stack direction={{ md: "row", xs: "column" }} sx={{ mt: 2 }}>
          <CallTypeCard
            icon={<img src={phonecall} alt="Phone call" />}
            title={t("phone")}
            checked={callTypePhone}
            onChange={handleModalityChange}
          />
          <CallTypeCard
            icon={<img src={videocall} alt="video call" />}
            checked={callTypeVideo}
            title={t("video")}
            cardSx={{ ml: { md: 2, xs: 0 }, mt: { md: 0, xs: 2 } }}
            onChange={handleModalityChange}
          />
        </Stack>
        <StyledBodyText sx={{ mt: 6 }}>{t("common_conditions")}</StyledBodyText>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          useFlexGap
          sx={{ mt: 2 }}
          spacing={2}
        >
          {conditions.map((condition) => (
            <ConditionLabel
              key={condition.id}
              title={t(condition.title)}
              bgcolor={
                selectedConditions.find((c) => c.id === condition.id)
                  ? COLORS.SECONDARY_MAIN
                  : COLORS.TEXT_SECONDARY
              }
              textColor={
                selectedConditions.find((c) => c.id === condition.id)
                  ? COLORS.TEXT_SECONDARY
                  : COLORS.TEXT
              }
              onClick={() => handleConditionClick(condition)}
            />
          ))}
        </Stack>
        <Stack alignItems={"center"}>
          <DefaultButton
            sx={{ mt: 3 }}
            onClick={handleMakeAppointmentClick}
            disabled={selectedConditions.length === 0}
          >
            {t("make_appointment")}
          </DefaultButton>
        </Stack>
      </Box>
      <AlertDialog
        modalOpen={showDialog}
        handleClose={() => setShowDialog(false)}
        title={t("warning")}
        description={t("warning_info")}
        primaryButtonText={t("accept")}
        secondaryButtonText={t("decline")}
        secondaryButtonAction={() => setShowDialog(false)}
        primaryButtonAction={handleAcceptClick}
      />
    </MainPageLayout>
  );
};

export default ScheduleUrgentCare;
