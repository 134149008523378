import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginCard from "../../components/login/LoginCard";
import { login } from "../../data/requests/authentication";
import useAuth from "../../providers/auth.provider";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../utils/storage.utils";
import { validateEmail, validatePassword } from "../../utils/validation";
import LoginDesignPage from "./LoginDesignPage";
import { useMessageCenterContext } from "../../providers/MessageCenterContext";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { setCurrentUser } = useAuth();
  const { t } = useTranslation("translations");
  // const { handleGetHealthRecords, handleGetAllStates } =
  //   useHealthRecordsContext();
  // const { handleGetAllMessages } = useMessageCenterContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateFields = () => {
    if (!validateEmail(email)) {
      setEmailError(t("invalid_email"));
      return false;
    }
    if (!validatePassword(password)) {
      setPasswordError(t("invalid_password"));
      return false;
    }
    setEmailError(null);
    setPasswordError(null);
    return true;
  };

  const handleLogin = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      setLoading(true);
      const userResp = await login(email, password);
      if (userResp.status === 200 && userResp.data.success === true) {
        //do not remove this local storage, its needed for uodating user profile
        // localStorage.setItem("user", userResp.data);
        localStorage.setItem("userEmail", email);
        setCurrentUser(userResp.data, userResp.headers.authorization);
        console.log(userResp);
        navigate("/dashboard");

        // setTimeout(async () => {
        //   await handleGetHealthRecords();
        // }, 2000);
        // await handleGetAllMessages();
        // await handleGetAllStates();
      } else {
        setLoginError(userResp.data?.response?.message);
      }
    } catch (error) {
      setLoginError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoginDesignPage>
      <LoginCard
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
        passwordError={passwordError}
        emailError={emailError}
        loading={loading}
        loginError={loginError}
      />
    </LoginDesignPage>
  );
};

export default Login;
