import React from "react";
import { Divider, Grid } from "@mui/material";
import DataCardNameMenu from "../medications/DataCardNameMenu";
import EditDeleteMenu from "../medications/EditDeleteMenu";
import moment from "moment";

const SurgicalHistoryCard = ({
  data,
  matchesSM,
  matchesXS,
  setSelectedSurgicalHistory,
  handleEditSurgicalHistoryModalOpen,
  handleDeleteSurgicalHistoryModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction={"column"}
      //   alignItems={"center"}
      style={{
        padding: matchesSM || matchesXS ? 5 : 20,
        width: matchesSM || matchesXS ? "100%" : "80%",
      }}
    >
      <DataCardNameMenu
        name={data.name}
        open={open}
        handleClick={(e) => {
          setSelectedSurgicalHistory(data);
          handleClick(e);
        }}
      />

      <div
        style={{
          color: "#001011",
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 7,
        }}
      >
        {data.description && data.description}
      </div>
      <div style={{ color: "#001011", fontSize: 14, fontWeight: 400 }}>
      {data.procedureDate && moment(data.procedureDate).format("MM-DD-YYYY")}
      {/*
      {data.whenCreated && moment(data.whenCreated).format("MM-DD-YYYY")}
      */}
      </div>
      <Divider
        style={{ color: "#19A6FE", marginTop: 10, width: "98%" }}
        variant="middle"
      />
      <EditDeleteMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleEditClick={handleEditSurgicalHistoryModalOpen}
        handleDeleteClick={handleDeleteSurgicalHistoryModalOpen}
      />
    </Grid>
  );
};

export default SurgicalHistoryCard;
