import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import PharmacyDetailsCard from '../../settings/pharmacyPreferences/PharmacyDetailsCard'
import Dimens from '../../../assets/Dimens'
import { COLORS } from '../../../assets/colors'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const PharmacyDropdown = (props) => {
	const { pharmacies, loading, handleInputChange, handleAddPharmacy} = props
	const {t} = useTranslation("translations")
	const [open, setOpen] = React.useState(false)

	const handlePopperOpen = () => {
		setOpen(true)
	}

	const handlePopperClose = () => {
		setOpen(false)
	}

  return (
	<Autocomplete
            autoHighlight
            filterSelectedOptions
            open={open}
            fullWidth
            options={pharmacies}
            onOpen={handlePopperOpen}
            onClose={handlePopperClose}
            filterOptions={(options, state) => options}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false
                  },
                  {
                    name: 'preventOverflow',
                    enabled: false
                  }
                ]
              }
            }}
            renderOption={(_, option) => (
              <PharmacyDetailsCard
                pharmacyTitle={option.storeName}
                pharmacyPhone={option.phone}
                pharmacyAddress={`${option.data} ${option.address}`}
                state={`${option.city}, ${option.stateName}`}
                handleClick={() => {
                  handleAddPharmacy(option)
                  handlePopperClose()
                }}
              />
            )}
            getOptionLabel={(option) => option.storeName}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "100%",
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  borderRadius: Dimens.TEXT_INPUT_RADIUS,
                  color: COLORS.BLACK,
                  backgroundColor: COLORS.TEXT_SECONDARY,
                  ".MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${COLORS.DISABLED}`,
                    borderRadius: Dimens.TEXT_INPUT_RADIUS,
                  }
                }}
                onChange={handleInputChange}
                label={t("search_pharmacy")}
                placeholder={t("search_pharmacy_by_info")}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
  )
}

PharmacyDropdown.propTypes = {
	loading: PropTypes.bool,
	handleInputChange: PropTypes.func,
	pharmacies: PropTypes.array,
	handleAddPharmacy: PropTypes.func
}

export default PharmacyDropdown
