import { Endpoints } from "../../endpoints";
import { reqGet } from "../../request.api";

export const getStates = async () => {
  const resp = await reqGet({ url: Endpoints.STATES });
  const { data } = resp;
  return data;
};

export const searchAllergies = async (query) => {
  const resp = await reqGet({
    url: `${Endpoints.SEARCH_ALLERGIES}?query=${query}`,
  });
  const { data } = resp;
  return data;
};
