import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import React from "react";
import { COLORS } from "../../../assets/colors";
import { StyledBodyText } from "../../common";
import { useTranslation } from "react-i18next";

const conditions = [
  "Patients under age of 12.",
  "Emergency situations (suicidal, homicidal, self-harm).",
  "ADHD prescription of stimulant (therapy and non-stimulant medication management are in scope).",
  "Active hallucinations or delusions.",
  "Substance use in an active medical detox program.",
  "Moderate to severe autism spectrum disorders.",
  "Internationally located patients.",
  "Conditions that require laboratory or diagnostic imaging to determine final treatment recommendations without lab work present for review.",
];

const BehavouralHealthModel = (props) => {
  const { t } = useTranslation("translations");
  const { modalOpen, handleClose } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      //   sx={{ height: "100vh" }}
      open={modalOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          m: 2,
          pb: 2,
          borderBottom: `1px solid ${COLORS.PRIMARY_MAIN}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <StyledBodyText sx={{ textAlign: "center" }}>
          {t("conditions_not_treated_behavioral_health_services")}
        </StyledBodyText>
      </Box>
      <Box sx={{ mb: 6 }}>
        <ul>
          {conditions.map((el) => (
            <li key={el} style={{ marginTop: "10px" }}>
              {el}
            </li>
          ))}
        </ul>
      </Box>
    </Dialog>
  );
};

export default BehavouralHealthModel;
