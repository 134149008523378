// src/components/Subscriber.js
import React from "react";
import { OTSubscriber } from "opentok-react";
import CheckBox from "./CheckBox";
import { Grid } from "@mui/material";

class Subscriber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true,
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  };

  setVideo = (video) => {
    this.setState({ video });
  };

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  render() {
    return (
      <Grid
        item
        container
        direction={"column"}
        style={{ width: "78%", paddingLeft: 20, paddingTop: 35 }}
      >
        Doctor
        {this.state.error ? <div id="error">{this.state.error}</div> : null}
        <CheckBox
          label="Subscribe to Audio"
          initialChecked={this.state.audio}
          onChange={this.setAudio}
        />
        <CheckBox
          label="Subscribe to Video"
          initialChecked={this.state.video}
          onChange={this.setVideo}
        />
        <OTSubscriber
          properties={{
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
          }}
          onError={this.onError}
        />
      </Grid>
    );
  }
}
export default Subscriber;

/* <div className="subscriber"> */
