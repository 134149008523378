import { useContext, useMemo, useState } from "react";
import { createContext } from "react";
import { login } from "../data/requests/authentication";
import LocalStorageWrapper, { LocalStorageKeys } from "../utils/storage.utils";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const tempUser = localStorage.getItem("user");
  const [user, setUser] = useState(tempUser ? JSON.parse(tempUser) : null);

  const setCurrentUser = (user, authorization) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
        LocalStorageWrapper.set(
          LocalStorageKeys.TOKEN,
          authorization
        );
  };

  const loginUser = (email, password) => {
    login(email, password).then((data) => {
      console.log(data.headers);
    });
  };

  const clearUser = () => {
    setUser(null);
    localStorage.clear();
  };

  const value = useMemo(
    () => ({
      user,
      setCurrentUser,
      clearUser,
      loginUser,
    }),
    [user, setUser, setCurrentUser, clearUser]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  return useContext(AuthContext);
}
