import { createTheme } from "@mui/material";

export const appTheme = createTheme({
  pallete: {
    primary: {
      main: "#19A6FE",
      dark: "#0D3B66",
      light: "#DFF0FF",
      contrastText: "#000",
    },
    secondary: {
      main: "#FFB74D",
      light: "#FFF2E1",
      dark: "#FF8B4A",
      contrastText: "#FFF",
    },
    error: {
      main: "#CF2D23",
    },
    background: {
      default: "#DFF0FF",
    },
    success: {
      main: "#B7E27F",
      light: "#B7E27F",
      dark: "#B7E27F",
      contrastText: "#001011",
    },
    pending: {
      main: "#FF8B4A",
      contrastText: "#001011",
    },
  },
});
