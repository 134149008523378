import { styled, Typography } from "@mui/material";
import Dimens from "../../../assets/Dimens";

const LabelText = styled(Typography)((align) => ({
  fontWeight: Dimens.FONT_WEIGHT_LIGHT,
  fontSize: Dimens.FONT_SIZE_SMALLER,
  // lineHeight: Dimens.LINE_HEIGHT_SMALLER,
  fontFamily: "Open Sans",
  textAlign: align,
}));

export default LabelText;
