import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DefaultRadioButton = (props) => {
  const { value, positiveLabel, negativeLabel, unknownLabel, onChange } = props;
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
        onChange={onChange}
      >
        <Stack direction={"row"} alignItems="center">
          <FormControlLabel
            value={true}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#2E3A59",
                  },
                }}
              />
            }
            label={positiveLabel}
          />
          <FormControlLabel
            value={false}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#2E3A59",
                  },
                }}
              />
            }
            sx={{
              ml: 4,
            }}
            label={negativeLabel}
          />
          {unknownLabel && (
            <FormControlLabel
              value={"unknown"}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#2E3A59",
                    },
                  }}
                />
              }
              sx={{
                ml: 4,
              }}
              label={unknownLabel}
            />
          )}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

DefaultRadioButton.propTypes = {
  value: PropTypes.bool,
  positiveLabel: PropTypes.string,
  negativeLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default DefaultRadioButton;
