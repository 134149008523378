import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

export const Feedback = createContext({});

export const FeedbackProvider = ({ children }) => {
  const [openDailog, setOpenDailog] = useState(false);
  const [dailogProps, setDailogProps] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackProps, setSnackProps] = useState(null);

  const addSnack = (data) => {
    setOpenSnack(true);
    setSnackProps(data);
  }

  const removeSnack = () => {
    setOpenSnack(false);
    setSnackProps(null);
  }

  const removeDialog = () => {
    setOpenDailog(false);
    setDailogProps(null);
  };

  const addDialog = (data) => {
    setOpenDailog(true);
    setDailogProps(data);
  };

  const value = useMemo(
    () => ({
      openDailog,
      dailogProps,
      removeDialog,
      addDialog,
      openSnack,
      snackProps,
      addSnack,
      removeSnack
    }),
    [openDailog, dailogProps, removeDialog, addDialog, openSnack, snackProps, addSnack, removeSnack]
  );

  return <Feedback.Provider value={value}>{children}</Feedback.Provider>;
};

export default function useFeedback() {
  const { openDailog, dailogProps, removeDialog, addDialog, openSnack,
snackProps,
addSnack,
removeSnack } =
    useContext(Feedback);

  return {
    openDailog,
    dailogProps,
    removeDialog,
    addDialog,
    openSnack,
snackProps,
addSnack,
removeSnack
  };
}
