import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import NoMedicationsAdded from "../../components/healthRecords/medications/NoMedicationsAdded";
import DeleteMedicationModal from "../../components/healthRecords/medications/DeleteMedicationModal";
import MedicationDeletedModal from "../../components/healthRecords/medications/MedicationDeletedModal";
import AllergiesCard from "../../components/healthRecords/medicationAllergies/AllergiesCard";
import AddAllergyCard from "../../components/healthRecords/medicationAllergies/AddAllergyCard";
import EditAllergyModal from "../../components/healthRecords/medicationAllergies/EditAllergyModal";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import {
  addAllergy,
  deleteAllergy,
  markInactiveAllergy,
  searchMedication,
  searchMedicationAllergy,
} from "../../data/requests/healthRecords";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const MedicationAllergies = () => {
  const { user } = useAuth();

  const { allergies, handleGetHealthRecords } = useHealthRecordsContext();

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("translations");

  // let user = JSON.parse(localStorage.getItem("user"));

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [newAllergy, setNewAllergy] = useState("");
  const [selectedAllergy, setSelectedAllergy] = useState(null);
  const [searchedAllergies, setSearchedAllergies] = useState([]);
  const [selectedSearchedAllergy, setSelectedSearchedAllergy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editAllergyModal, setEditAllergyModal] = useState(false);
  const [deleteAllergyModal, setDeleteAllergyModal] = useState(false);
  const [allergyDeletedModal, setAllergyDeletedModal] = useState(false);

  // console.log("allergies:", allergies);

  const [allergiesData, setAllergiesData] = useState([]);

  useEffect(() => {
    setAllergiesData(allergies);
  }, [allergies]);

  // [
  //   {
  //     id: 1,
  //     allergymedicationname: "Medication Allergy 1",
  //   },
  //   {
  //     id: 2,
  //     allergymedicationname: "Medication Allergy 2",
  //   },
  //   {
  //     id: 3,
  //     allergymedicationname: "Medication Allergy 3",
  //   },
  //   {
  //     id: 4,
  //     allergymedicationname: "Medication Allergy 4",
  //   },
  // ]

  const handleEditAllergyModalOpen = () => {
    setEditAllergyModal(true);
  };
  const handleEditAllergyModalClose = () => {
    setEditAllergyModal(false);
  };
  const handleDeleteAllergyModalOpen = () => {
    setDeleteAllergyModal(true);
  };
  const handleDeleteAllergyModalClose = () => {
    setDeleteAllergyModal(false);
  };
  const handleAllergyDeletedModalOpen = () => {
    handleDeleteAllergyModalClose();
    setAllergyDeletedModal(true);
  };
  const handleAllergyDeletedModalClose = () => {
    setAllergyDeletedModal(false);
  };

  const handleSearchAllergies = async (value) => {
    setNewAllergy(value);
    try {
      const responseTemp = await searchMedicationAllergy(value);
      setSearchedAllergies(responseTemp.suggestions);
      console.log("search response:", responseTemp);
    } catch (error) {
      console.log("Error searching Medication", error.message);
    } finally {
    }
  };

  const handleAddAllergy = async () => {
    try {
      setLoading(true);

      let newData = {
        userId: user.userId,
        medicationAllergyForeignId:
          selectedSearchedAllergy.medicationAllergyForeignId,
        medicationAllergyName: selectedSearchedAllergy.medicationAllergyName,
        medicationAllergyDamConceptId: selectedSearchedAllergy.damConceptId,
        medicationAllergyDamConceptIdType:
          selectedSearchedAllergy.damConceptIdType,
      };

      const responseTemp = await addAllergy(newData, user.userId);
      console.log("response:", responseTemp);
      await handleGetHealthRecords();

      // const updatedDataTemp = [...allergiesData];
      // const newId = allergiesData.length + 1;

      // const newMed = {
      //   id: newId,
      //   allergymedicationname: newAllergy,
      // };

      // updatedDataTemp.push(newMed);
      // setAllergiesData(updatedDataTemp);

      // Reset the newAllergy state
      setNewAllergy("");
    } catch (error) {
      console.log("error adding medication allergy:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditAllergy = () => {
    try {
      setLoading(true);
      console.log("selected Medication Allergy:", selectedAllergy);
      const updatedDataTemp = [...allergiesData];
      const index = updatedDataTemp.findIndex(
        (medication) => medication.id === selectedAllergy.id
      );

      if (index !== -1) {
        updatedDataTemp[index] = selectedAllergy;
        console.log(updatedDataTemp);
        // setTimeout(() => {
        setAllergiesData(updatedDataTemp);
        setSelectedAllergy(null);
        // }, 1000);
      }

      handleEditAllergyModalClose();
    } catch (error) {
      console.log("error editing medication allergy:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkInactiveAllergy = async (allergyId, foreignId) => {
    try {
      const responseTemp = await markInactiveAllergy(
        allergyId,
        foreignId,
        user.userId
      );
      console.log("mark inactive allergy response:", responseTemp);
      await handleGetHealthRecords();
    } catch (error) {
      console.log("error mark inactive allergy:", error.message);
    }
  };

  const handleDeleteAllergy = async () => {
    try {
      setLoading(true);
      const responseTemp = await deleteAllergy(
        selectedAllergy.userMedicationAllergy_id,
        user.userId
      );
      console.log("response:", responseTemp);
      await handleGetHealthRecords();
      // const updatedDataTemp = allergiesData.filter(
      //   (medication) => medication.id !== selectedAllergy.id
      // );

      // setAllergiesData(updatedDataTemp);
      handleAllergyDeletedModalOpen();
      setSelectedAllergy(null);
    } catch (error) {
      console.log("error deleting medication allergy:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          {" "}
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("medication_allergies")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("indicate_known_allergies_name")}
        </div>
        {matchesXS || matchesSM || matchesMD ? (
          <Grid container direction={"column"}>
            <Grid item container xs>
              <AddAllergyCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXS={matchesXS}
                newAllergy={newAllergy}
                setNewAllergy={setNewAllergy}
                handleAddAllergy={handleAddAllergy}
                handleSearchAllergies={handleSearchAllergies}
                searchedAllergies={searchedAllergies}
                setSelectedSearchedAllergy={setSelectedSearchedAllergy}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {allergiesData && allergiesData.length > 0 ? (
                allergiesData.map((data, index) => (
                  <AllergiesCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedAllergy={setSelectedAllergy}
                    handleEditAllergyModalOpen={handleEditAllergyModalOpen}
                    handleDeleteAllergyModalOpen={handleDeleteAllergyModalOpen}
                    handleMarkInactiveAllergy={handleMarkInactiveAllergy}
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_allergies_added")} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={"row"}>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {allergiesData && allergiesData.length > 0 ? (
                allergiesData.map((data, index) => (
                  <AllergiesCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedAllergy={setSelectedAllergy}
                    handleEditAllergyModalOpen={handleEditAllergyModalOpen}
                    handleDeleteAllergyModalOpen={handleDeleteAllergyModalOpen}
                    handleMarkInactiveAllergy={handleMarkInactiveAllergy}
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_allergies_added")} />
              )}
            </Grid>

            <Grid item container xs>
              <AddAllergyCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXS={matchesXS}
                newAllergy={newAllergy}
                setNewAllergy={setNewAllergy}
                handleAddAllergy={handleAddAllergy}
                handleSearchAllergies={handleSearchAllergies}
                searchedAllergies={searchedAllergies}
                setSelectedSearchedAllergy={setSelectedSearchedAllergy}
              />
            </Grid>
          </Grid>
        )}

        <EditAllergyModal
          modalOpen={editAllergyModal}
          handleClose={handleEditAllergyModalClose}
          allergy={selectedAllergy}
          setAllergy={setSelectedAllergy}
          handleEditAllergy={handleEditAllergy}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
        <DeleteMedicationModal
          modalOpen={deleteAllergyModal}
          title={t("delete_allergy")}
          message={t("delete_allergy_info")}
          handleClose={handleDeleteAllergyModalClose}
          handleDelete={handleDeleteAllergy}
          allergy={selectedAllergy}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
        <MedicationDeletedModal
          modalOpen={allergyDeletedModal}
          title={t("medication_allergy_deleted")}
          handleClose={handleAllergyDeletedModalClose}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default MedicationAllergies;
