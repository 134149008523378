import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getAllstates, getHealthRecords } from "../data/requests/healthRecords";
import useAuth from "./auth.provider";

export const HealthRecordsContext = createContext();
export const useHealthRecordsContext = () => useContext(HealthRecordsContext);

export const HealthRecordsProvider = ({ children }) => {
  const { user } = useAuth();
  const [healthRecordsAllData, setHealthRecordsAllData] = useState();
  const [userContactInfo, setUserContactInfo] = useState();
  const [allStates, setAllStates] = useState([]);

  const [personalInfo, setPersonalInfo] = useState([]);
  const [medications, setMedications] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [surgicalHistory, setSurgicalHistory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [emrLastUpdated, setEmrLastUpdated] = useState('');

  useEffect(() => {
    handleGetHealthRecords();
    handleGetAllStates();
  }, [user]);

  // let user = localStorage.getItem("user");
  // console.log("Current user:", JSON.parse(user).userId);

  const handleGetHealthRecords = async () => {
    try {
      const healthRecordsTemp = await getHealthRecords(user.userId);
      console.log("health records:", healthRecordsTemp);
      setHealthRecordsAllData(healthRecordsTemp);
      setUserContactInfo(healthRecordsTemp.contactInfo);
      setPersonalInfo(healthRecordsTemp.Personal);
      setMedications(healthRecordsTemp.Medications);
      setAllergies(healthRecordsTemp.MedicationAllergies);
      setMedicalHistory(healthRecordsTemp.MedicalConditions);
      setSurgicalHistory(healthRecordsTemp.SurgicalHistory);
      setDocuments(healthRecordsTemp.Attachments);
      setEmrLastUpdated(healthRecordsTemp.EMRLastUpdated)
    } catch (error) {
      console.log("error getting health records:", error.message);
    }
  };
  const handleGetAllStates = async () => {
    try {
      const allStatesResp = await getAllstates();
      console.log("all states:", allStatesResp);
      setAllStates(allStatesResp.states);
    } catch (error) {
      console.log("error getting all states:", error.message);
    }
  };

  const value = useMemo(
    () => ({
      healthRecordsAllData,
      setHealthRecordsAllData,
      userContactInfo,
      setUserContactInfo,
      allStates,
      setAllStates,
      handleGetAllStates,
      personalInfo,
      setPersonalInfo,
      medications,
      setMedications,
      allergies,
      setAllergies,
      medicalHistory,
      setMedicalHistory,
      surgicalHistory,
      setSurgicalHistory,
      documents,
      setDocuments,
      handleGetHealthRecords,
      emrLastUpdated
    }),
    [
      healthRecordsAllData,
      userContactInfo,
      allStates,
      personalInfo,
      medicalHistory,
      medications,
      allergies,
      surgicalHistory,
      documents,
      emrLastUpdated
    ]
  );

  return (
    <HealthRecordsContext.Provider value={value}>
      {children}
    </HealthRecordsContext.Provider>
  );
};
