import axios from "axios";
import { Endpoints } from "../../endpoints";
import { reqGet, reqPost } from "../../request.api";
import LocalStorageWrapper, {
  LocalStorageKeys,
} from "../../../utils/storage.utils";

// let user = LocalStorageWrapper.get("user");
let user = JSON.parse(localStorage.getItem("user"));
const bearerToken = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);
// const token = LocalStorageWrapper.get(LocalStorageKeys.TOKEN);

// console.log("Current user:", user.userId);

//get all states
export const getAllstates = async () => {
  const resp = await reqGet({
    url: `${Endpoints.ALLSTATES}`,
  });
  const { data } = resp;
  return data;
};

export const getHealthRecords = async (userId) => {
  const resp = await reqGet({
    url: `${Endpoints.HEALTH_RECORDS + "/" + userId}`,
  });
  const { data } = resp;
  return data;
};

// medications apis start
export const addMedication = async (params, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.MEDICATION + "/add/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const markInactiveMedication = async (medicationId, userId) => {
  const resp = await reqGet({
    url: `${
      Endpoints.MEDICATION + "/currentuse/" + medicationId + "/" + userId + "/0"
    }`,
    data: { medicationId, userId },
  });
  const { data } = resp;
  return data;
};

export const updateMedication = async (params, recordId, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.MEDICATION + "/update/" + recordId + "/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const deleteMedication = async (recordId, userId) => {
  const resp = await reqGet({
    url: `${Endpoints.MEDICATION + "/delete/" + recordId + "/" + userId}`,
  });
  const { data } = resp;
  return data;
};

export const searchMedication = async (query) => {
  const resp = await reqGet({
    url: `${Endpoints.MEDICATION + "/search?query=" + query}`,
  });
  const { data } = resp;
  return data;
};

// medications apis end

// surgical history apis start
export const addSurgicalHistory = async (params, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.SURGICAL_HISTORY + "/add/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const updateSurgicalHistory = async (params, procedureId, userId) => {
  const resp = await reqPost({
    url: `${
      Endpoints.SURGICAL_HISTORY + "/update/" + procedureId + "/" + userId
    }`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const deleteSurgicalHistory = async (procedureId, userId) => {
  const resp = await reqGet({
    url: `${
      Endpoints.SURGICAL_HISTORY + "/delete/" + procedureId + "/" + userId
    }`,
  });
  const { data } = resp;
  return data;
};
// surgical history apis end

//medication allergies apis start
export const addAllergy = async (params, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.MEDICATION_ALLERGY + "/add/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const markInactiveAllergy = async (allergyId, foreignId, userId) => {
  const resp = await reqGet({
    url: `${
      Endpoints.MEDICATION_ALLERGY +
      "/markInactive/" +
      allergyId +
      "/" +
      foreignId +
      "/" +
      userId
    }`,
    data: { allergyId, foreignId, userId },
  });
  const { data } = resp;
  return data;
};

export const updateAllergy = async (params, allergyId, userId) => {
  const resp = await reqPost({
    url: `${
      Endpoints.MEDICATION_ALLERGY + "/update/" + allergyId + "/" + userId
    }`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const deleteAllergy = async (allergyId, userId) => {
  const resp = await reqGet({
    url: `${
      Endpoints.MEDICATION_ALLERGY + "/delete/" + allergyId + "/" + userId
    }`,
  });
  const { data } = resp;
  return data;
};

export const searchMedicationAllergy = async (query) => {
  console.log("query resp:", query);
  const resp = await reqGet({
    url: `${Endpoints.MEDICATION_ALLERGY + "/search?query=" + query}`,
  });
  const { data } = resp;
  return data;
};
//medicaion allergies apis end

//medical conditions/History apis start
export const addMedicalHistory = async (params, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.MEDICAL_CONDITION + "/add/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const updateMedicalHistory = async (
  params,
  medicalConditionId,
  userId
) => {
  const resp = await reqPost({
    url: `${
      Endpoints.MEDICAL_CONDITION +
      "/update/" +
      medicalConditionId +
      "/" +
      userId
    }`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const deleteMedicalHistory = async (medicalConditionId, userId) => {
  const resp = await reqGet({
    url: `${
      Endpoints.MEDICAL_CONDITION +
      "/delete/" +
      medicalConditionId +
      "/" +
      userId
    }`,
  });
  const { data } = resp;
  return data;
};
//medical conditions/History apis end

//documents/attachments management apis start
export const addDocument = async (params, userId) => {
  const resp = await reqPost({
    url: `${Endpoints.DOCUMENTS + "/add/" + userId}`,
    data: params,
  });
  const { data } = resp;
  return data;
};

export const deleteDocument = async (documentId, userId) => {
  const resp = await reqGet({
    url: `${Endpoints.DOCUMENTS + "/delete/" + documentId + "/" + userId}`,
  });
  console.log(resp)
  const { data } = resp;
  return data;
  // const resp = await axios({
  //   method: "GET",
  //   url: `${Endpoints.MTM_BASE_URL}${
  //     Endpoints.DOCUMENTS + "/delete/" + documentId + "/" + userId
  //   }}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: bearerToken,
  //   },
  // });
  // console.log("api response:",resp);
  // const res = resp;
  // return res.data;
};
//documents/attachments management apis end

// update personal information in health record
export const updateHealthRecordPersonalInformation = async (params, userId) => {
  try {
    const res = reqPost({
      url: `/personal/update/${userId}?${new URLSearchParams(params)}`,
    });
    return res;
  } catch (error) {
    console.log("Error in update-user", error);
  }
};
