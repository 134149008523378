import { PhoneInTalk } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../assets/colors";
import DefaultButton from "./DefaultButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ListItemHidden = (props) => {
  const { reason, comment, onCancelClick, onJoinAppointmentClick, showJoinAppointment, showCancelAppointment } = props;
  const {t} = useTranslation("translations")

  const handleCancelAppointment = () => {
    onCancelClick?.call(0);
  };
  const handleJoinAppointment = () => {
    onJoinAppointmentClick?.call(0)
  }
  return (
    <Box sx={{ pb: 3 }}>
      <Box sx={{ bgcolor: COLORS.PRIMARY_MAIN, height: "2px" }} />
      <Box sx={{ pt: 2 }}>
        <Typography variant="h6">{t("reason_for_consultation")}</Typography>
        <Typography sx={{ pt: 1 }}>{reason}</Typography>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h6">{t("additional_comments")}</Typography>
        <Typography sx={{ pt: 1 }}>{comment}</Typography>
      </Box>
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 2 }}
      >
        {showCancelAppointment&&<DefaultButton variant="outlined" onClick={handleCancelAppointment}>
          {t("cancel_appointment")}
        </DefaultButton>}
        {showJoinAppointment&&<DefaultButton
          sx={{ ml: { md: 2, xs: 0 }, mt: { md: 0, xs: 2 } }}
          endIcon={<PhoneInTalk />}
          onClick={handleJoinAppointment}
        >
          {t("join_appointment_now")}
        </DefaultButton>}
      </Stack>
    </Box>
  );
};

ListItemHidden.propTypes = {
  reason: PropTypes.string,
  comment: PropTypes.string,
  onCancelClick: PropTypes.func,
  onJoinAppointmentClick: PropTypes.func,
  showJoinAppointment: PropTypes.bool,
  showCancelAppointment: PropTypes.bool,
};

export default ListItemHidden;
