export const SYMPTOMS = [
  "¨Cold¨or ¨Flu¨",
  "Female problems",
  "Nausea, vomiting",
  "Abdominal pain",
  "Fever",
  "Shortness of breath",
  "Backache",
  "Foot pain",
  "Sinus congestion",
  "Chest pain",
  "General malaise",
  "Skin rash",
  "Chills",
  "Headache",
  "Sore throat",
  "Cough",
  "Hypertension (High blood pressure)",
  "Tired",
  "Diarrhea",
  "Lightheadedness or Dizziness",
  "Urinary problems",
  "Earache",
  "Loss of consciousness",
  "Weak",
  "Eye problem",
  "Male problems",
  "Multiple",
];
