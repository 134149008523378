import React from "react";
import PropTypes from "prop-types";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import { StyledBodyText, StyledCaptionText } from "../../common";
import Dimens from "../../../assets/Dimens";
import { Close } from "@mui/icons-material";
import { COLORS } from "../../../assets/colors";
import DefaultButton from "../DefaultButton";
import { useTranslation } from "react-i18next";

const phoneSupportLines = [
  "Emergency: 112",
  "National Suicide Prevention Lifeline: 1-800-273-TALK (8255)",
  "American Association of Poison Control Centers: 1-800-222-1222",
  "National Domestic Violence Hotline: 1-800-799-7233",
  "National Association of Anorexia Nervosa nad Associated Disorders: 1-630-577-1330",
  "National Eating Disorders Association Helpline: 1-800-931-2237",
  "Overeaters Anonymous: 1-505-891-2664",
  "Planned Parenthood Hotline: 1-800-230-PLAN (7526)",
  "LGBT Hotline: 1-888-843-4564",
  "Trevor Crisis Line (LGBTQ): 1-866-488-7386",
  "Substance Abuse and Mental Health Administration Helpline: 1-800-662-HELP (4357)",
  "Veterans Crisis Line: 1-800-273-8255",
];

const textSupportLines = [
  'Crisis Text Line: Text "HOME" to 741-741',
  "Veterans Crisis Text Line: Text 838255",
];

const onlineServices = [
  "SAFE Network: www.selfinjury.org",
  "Veterans Crisis Line: www.veteranscrisisline.net",
  "National Eating Disorders Association: www.nationaleatingdisorders.org",
  "Suicide Prevention Association Wiki: www.suicideprevention.wikia.com",
];

const PsychiatristResourcesModel = (props) => {
  const { modalOpen, handleClose, handleContinue } = props;
  const { t } = useTranslation("translations");
  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      sx={{ height: "100vh" }}
      open={modalOpen}
      onClose={handleClose}
    >
      <Box sx={{ p: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box />
          <Box>
            <StyledBodyText sx={{ textAlign: "center" }}>
              {t("mental_health_and_emergency_resources")}
            </StyledBodyText>
            <StyledCaptionText
              sx={{
                fontSize: Dimens.FONT_SIZE_SMALL,
                textAlign: "center",
                mt: 1,
              }}
            >
              {t("mental_health_and_emergency_resources_info")}
            </StyledCaptionText>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          spacing={2}
          useFlexGap
          sx={{ pt: 4 }}
        >
          <Box
            sx={{
              bgcolor: "#FFF2E1",
              width: { md: "45%", xs: "100%" },
              pl: 2,
              pt: 1.5,
              pb: 1.5,
            }}
          >
            <StyledCaptionText>{"PHONE SUPPORT LINES"}</StyledCaptionText>
          </Box>
          {phoneSupportLines.map((line) => (
            <Box
              key={line}
              sx={{
                p: 1,
                borderBottom: `1px solid ${COLORS.PRIMARY_MAIN}`,
                width: { md: "45%", xs: "100%" },
              }}
            >
              <StyledCaptionText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  mt: 1,
                }}
              >
                {line}
              </StyledCaptionText>
            </Box>
          ))}
          <Box
            sx={{
              bgcolor: "#FFF2E1",
              width: { md: "45%", xs: "100%" },
              pl: 2,
              pt: 1.5,
              pb: 1.5,
            }}
          >
            <StyledCaptionText>{"TEXT SUPPORT LINES"}</StyledCaptionText>
          </Box>
          {textSupportLines.map((line) => (
            <Box
              key={line}
              sx={{
                p: 1,
                borderBottom: `1px solid ${COLORS.PRIMARY_MAIN}`,
                width: { md: "45%", xs: "100%" },
              }}
            >
              <StyledCaptionText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  mt: 1,
                }}
              >
                {line}
              </StyledCaptionText>
            </Box>
          ))}
          <Box
            sx={{
              bgcolor: "#FFF2E1",
              width: { md: "45%", xs: "100%" },
              pl: 2,
              pt: 1.5,
              pb: 1.5,
            }}
          >
            <StyledCaptionText>{"ONLINE RESOURCES"}</StyledCaptionText>
          </Box>
          {onlineServices.map((line) => (
            <Box
              key={line}
              sx={{
                p: 1,
                borderBottom: `1px solid ${COLORS.PRIMARY_MAIN}`,
                width: { md: "45%", xs: "100%" },
              }}
            >
              <StyledCaptionText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  mt: 1,
                }}
              >
                {line}
              </StyledCaptionText>
            </Box>
          ))}
        </Stack>
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <DefaultButton onClick={handleContinue}>
            {t("continue")}
          </DefaultButton>
        </Stack>
      </Box>
    </Dialog>
  );
};

PsychiatristResourcesModel.propTypes = {
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleContinue: PropTypes.func,
};

export default PsychiatristResourcesModel;
