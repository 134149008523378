import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Header as ConsultationHeader,
  StyledBodyText,
  StyledCaptionText,
} from "../../../components/common";
import MainPageLayout from "../../MainPageLayout";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  behavioralImage,
  behaviouralBackground,
  people,
} from "../../../assets/images";
import Dimens from "../../../assets/Dimens";
import { COLORS } from "../../../assets/colors";
import DefaultButton from "../../../components/consultations/DefaultButton";
import { BehavioralCardItem } from "../../../components/consultations";
import { callCenter, groupDiscussion, mobile } from "../../../assets/icons";
import SideDrawer from "../../../components/mainLayout/SideDrawer";
import LanguageButton from "../../../components/mainLayout/LanguageButton";
import Tabbar from "../../../components/mainLayout/Tabbar";
import Footer from "../../../components/mainLayout/Footer";
import Header from "../../../components/mainLayout/Header";

const BehavioralHealthCounseling = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const handleGoBack = () => {
    navigate(-1);
  };

  const Services = [
    t("addiction"),
    t("adhd_learning"),
    t("disabilities"),
    t("adoption"),
    t("anger_management"),
    t("anxiety"),
    t("career_job_concerns"),
    t("faith_based_counseling"),
    t("crisis_intervention"),
    t("cross_cultural_issues"),
    t("depression"),
  ];

  const ServicesTwo = [
    t("discrimination"),
    t("divorce_separation"),
    t("domestic_violence"),
    t("aging_elder_care"),
    t("executive"),
    t("expat_counseling"),
    t("fertility_issues"),
    t("first_nation_aboriginal"),
    t("first_responders"),
    t("lgbtq"),
  ];

  const ServicesThree = [
    t("grief_bereavement"),
    t("military_families"),
    t("parenting"),
    t("postpartum_depression"),
    t("return_to_work"),
    t("sexual_abuse"),
    t("sexual_harassment"),
    t("transgender_gender_identity"),
    t("trauma"),
    t("workplace_issues"),
  ];

  return (
    <>
      <Grid
        container
        direction={"column"}
        style={{
          background: "#DFF0FF",
          borderBottomLeftRadius: Dimens.BORDER_RADIUS_LARGE,
          borderBottomRightRadius: Dimens.BORDER_RADIUS_LARGE,
        }}
      >
        <Header
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Grid item container>
          <Grid
            item
            container
            justifyContent={"center"}
            sx={{ width: { md: "19.5%", xs: "0" } }}
          >
            <Grid item container direction={"column"} alignItems={"center"}>
              <SideDrawer
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXl={matchesXl}
                matchesLG={matchesLG}
                matchesXS={matchesXS}
              />
            </Grid>
          </Grid>
          <Grid item container sx={{ width: { md: "79.5%", xs: "100%" } }}>
            <ConsultationHeader
              title={t("behavioral_health_counseling")}
              handleGoBack={handleGoBack}
            />
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ mt: { md: 5, xs: 2 }, height: { md: "90vh" } }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack
                    // justifyContent={"center"}
                    sx={{
                      p: 2,
                      bgcolor: { md: COLORS.CARD_SECONDARY, xs: "none" },
                      borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
                      height: "60%",
                    }}
                  >
                    <img
                      src={behavioralImage}
                      width={"100%"}
                      alt={t("behavioral_health_counseling")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: COLORS.TEXT_SECONDARY,
                      borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
                    }}
                  >
                    <StyledCaptionText
                      sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM }}
                    >
                      {t("behavioral_health_counseling_info")}
                    </StyledCaptionText>
                  </Box>
                  <Stack
                    direction={{ md: "row", xs: "column" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={3}
                    sx={{ mt: Dimens.CARD_PADDING_HORIZANTAL }}
                  >
                    <BehavioralCardItem
                      icon={<img src={mobile} />}
                      title="On-demand Crisis Counseling"
                    />
                    <BehavioralCardItem
                      icon={<img src={groupDiscussion} />}
                      title="Life Coaching"
                    />
                    <BehavioralCardItem
                      icon={<img src={callCenter} />}
                      title="50-MIN Teletherapy Sessions"
                    />
                  </Stack>
                  <Stack
                    alignItems={"center"}
                    sx={{ mt: Dimens.CARD_PADDING_HORIZANTAL }}
                  >
                    <DefaultButton>{t("give_us_a_call")}</DefaultButton>
                  </Stack>
                  <StyledCaptionText
                    sx={{
                      fontSize: Dimens.FONT_SIZE_MEDIUM,
                      textAlign: "center",
                      mt: Dimens.CARD_PADDING_HORIZANTAL,
                    }}
                  >
                    {t("or_dial")} 866-576-1130
                  </StyledCaptionText>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ pt: 5, pb: 5, bgcolor: "#fff" }}>
          <StyledBodyText sx={{ textAlign: "center" }}>
            {t("services")}
          </StyledBodyText>
          <StyledCaptionText sx={{ textAlign: "center" }}>
            {t("include_but_not_limited_to")}
          </StyledCaptionText>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={3}>
              <Stack
                // alignItems={"center"}
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  pl: 2,
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {Services.map((el) => (
                  <StyledCaptionText
                    id={el}
                    sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 2, mb: 2 }}
                  >
                    {el}
                  </StyledCaptionText>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  pl: 2,
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesTwo.map((el) => (
                  <StyledCaptionText
                    component="div"
                    id={el}
                    sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 2, mb: 2 }}
                  >
                    {el}
                  </StyledCaptionText>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  pl: 2,
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesThree.map((el) => (
                  <StyledCaptionText
                    id={el}
                    sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 2, mb: 2 }}
                  >
                    {el}
                  </StyledCaptionText>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundImage: `url(${behaviouralBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundPosition: "center",
            backgroundColor: "#fff",
            pt: "20%",
          }}
        >
          <Stack
            justifyContent={"center"}
            sx={{
              width: { md: "50%", xs: "100%" },
            }}
          >
            <StyledBodyText
              sx={{
                fontSize: Dimens.FONT_SIZE_EXTRA_LARGE,
              }}
            >
              {t("adapted_to_your_needs")}
            </StyledBodyText>
            <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM }}>
              {t("adapted_to_your_needs_info")}
            </StyledCaptionText>
          </Stack>
          <img src={people} alt="People from different ethnicities" />
        </Stack>
        <Footer
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Tabbar />
        <Box
          boxShadow={2}
          sx={{
            display: { xs: "flex", md: "none" },
            position: "fixed",
            bottom: "10%",
            left: theme.spacing(2),
            // borderRadius: 31 / 2,
            zIndex: 1000,
          }}
        >
          <LanguageButton />
        </Box>
      </Grid>
    </>
  );
};

export default BehavioralHealthCounseling;
