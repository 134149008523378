import { LoadingButton } from "@mui/lab";
import React, { useEffect } from "react";
import { COLORS } from "../../assets/colors";

const DefaultButton = (props) => {
  const {
    variant = "contained",
    onClick,
    disabled,
    sx,
    children,
    ...remainingProps
  } = props;

  const [buttonColor, setButtonColor] = React.useState("#19A6FE");

  useEffect(() => {
    if (variant === "contained") {
      setButtonColor("#19A6FE");
    } else {
      setButtonColor("#fff");
    }
    if (disabled) {
      setButtonColor(COLORS.DISABLED);
    }
  }, [disabled]);

  return (
    <LoadingButton
      variant={variant}
      size="small"
      disabled={disabled ? disabled : undefined}
      disableElevation
      onClick={onClick}
      sx={{
        bgcolor: buttonColor,
        mt: 0,
        pl: "30px",
        pr: "30px",
        pt: "5px",
        pb: "5px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "26px",
        borderRadius: 60,
        color: COLORS.BLACK,
        ...sx,
      }}
      {...remainingProps}
    >
      {children}
    </LoadingButton>
  );
};

export default DefaultButton;
