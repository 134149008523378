import { ErrorOutlined } from "@mui/icons-material";
import { Box, Grid, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../assets/colors";
import Dimens from "../../assets/Dimens";
import {
  DefaultDropdown,
  DefaultTextField,
  StyledBodyText,
  StyledCaptionText,
} from "../common";
import DefaultButton from "./DefaultButton";
import PropTypes from "prop-types";
import MedicationRefilItem from "./MedicationRefilItem";
import { useTranslation } from "react-i18next";
import { searchMedication } from "../../data/requests/healthRecords";
import Scrollbars from "react-custom-scrollbars-2";

const MedicationRefillForm = (props) => {
  const {
    handleSubmit,
    medicationRefillArray,
    onDeleteClick,
    onEditClick,
    addButtonText,
    clearMedicationRefillForm,
    refill,
  } = props;
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));

  const [medicationRefill, setMedicationRefill] = useState({
    medicationName: "",
    frequency: "",
    comment: "",
    currentlyUsing: true,
  });
  const [searchedMedication, setSearchedMedication] = useState([]);
  const [selectedSearchedMedication, setselectedSearchedMedication] =
    useState(null);

  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    searchedMedication &&
      searchedMedication.length > 0 &&
      setShowSuggestions(true);
  }, [searchedMedication]);

  useEffect(() => {
    clearMedicationRefillForm.current = clearForm;
  }, []);

  const clearForm = () => {
    setMedicationRefill({
      medicationName: "",
      frequency: "",
      comment: "",
      currentlyUsing: true,
    });
  };

  useEffect(() => {
    if (
      medicationRefill.medicationName &&
      medicationRefill.frequency &&
      medicationRefill.comment
      // &&
      // medicationRefill.currentlyUsing
    ) {
      setAddRefillDisabled(false);
    } else {
      setAddRefillDisabled(true);
    }
  }, [
    medicationRefill.medicationName,
    medicationRefill.frequency,
    medicationRefill.comment,
    medicationRefill.currentlyUsing,
  ]);

  // console.log(medicationRefill)

  const [addRefillDisabled, setAddRefillDisabled] = useState(false);

  const handleChange = (key, value) => {
    // console.log(value)
    setMedicationRefill({ ...medicationRefill, [key]: value });
  };

  const handleAddClick = () => {
    if (addRefillDisabled) {
      return;
    }
    handleSubmit(medicationRefill);
  };

  const handleSearchMedication = async (value) => {
    setMedicationRefill({ ...medicationRefill, medicationName: value });
    try {
      const responseTemp = await searchMedication(value);
      setSearchedMedication(responseTemp.suggestions);
      console.log("search response:", responseTemp);
    } catch (error) {
      console.log("Error searching Medication", error.message);
    } finally {
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <StyledBodyText sx={{ mt: 2 }}>
          {t("medication_refills")}
        </StyledBodyText>
        <Box
          sx={{
            mt: 2,
            width: "100%",
            pt: 1,
            pb: 1,
            pl: 1,
            bgcolor: COLORS.PRIMARY_MAIN,
            borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
          }}
        >
          <Stack direction="row" alignItems="center">
            <ErrorOutlined />
            <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, ml: 2 }}>
              {t("one_medication_and_dosis_at_time")}
            </StyledCaptionText>
          </Stack>
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
              {t("medication_name")}
            </StyledBodyText>
            <DefaultTextField
              id="medicationName"
              value={medicationRefill.medicationName}
              error={!medicationRefill.medicationName}
              helperText={
                medicationRefill.medicationName ? undefined : t("required")
              }
              onChange={(e) => {
                handleSearchMedication(e.target.value);
              }}
              // onChange={(e) => handleChange("medicationName", e.target.value)}
            />
            {showSuggestions && (
              <Grid
                container
                direction={"column"}
                style={{
                  width: matchesSM ? 180 : 360,
                  height: 195,
                  borderRadius: 10,
                  padding: "30px 20px 35px",
                  boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
                  zIndex: 2,
                  position: "absolute",
                  backgroundColor: "#FFFFFF",
                  padding: 10,
                  // marginTop: 100,
                }}
              >
                <Scrollbars autoHide>
                  {searchedMedication.map((medication, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: 10,
                        fontWeight: 600,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setselectedSearchedMedication(medication);
                        setMedicationRefill({
                          ...medicationRefill,
                          medicationName: medication.value,
                        });
                        setShowSuggestions(false);
                      }}
                    >
                      {medication.value}
                    </div>
                  ))}
                </Scrollbars>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
              {t("frequency")}
            </StyledBodyText>
            <DefaultTextField
              id="frequency"
              value={medicationRefill.frequency}
              error={!medicationRefill.frequency}
              helperText={
                medicationRefill.frequency ? undefined : t("required")
              }
              onChange={(e) => handleChange("frequency", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
              {t("comment")}
            </StyledBodyText>
            <DefaultTextField
              id="comment"
              value={medicationRefill.comment}
              error={!medicationRefill.comment}
              helperText={medicationRefill.comment ? undefined : t("required")}
              multiline
              minRows={2}
              onChange={(e) => handleChange("comment", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
              {t("currently_using")}
            </StyledBodyText>
            <DefaultDropdown
              showOutline
              value={medicationRefill.currentlyUsing ? t("yes") : t("no")}
              id="currentlyUsing"
              // error={!!medicationRefill.currentlyUsing === true || false}
              // helperText={t("required")}
              renderValue={(option) => (
                <MenuItem
                  value={option.value}
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    // bgcolor: option === value ? "#FFB74D" : "#fff",
                    "&:hover": {
                      bgcolor: "#FFB74D",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              )}
              onChange={(e) => handleChange("currentlyUsing", e.target.value)}
              options={[
                { label: t("yes"), value: true },
                { label: t("no"), value: false },
              ]}
            />
          </Grid>
        </Grid>
        <Stack alignItems={"center"} sx={{ mt: 2, mb: 3 }}>
          <DefaultButton
            sx={{ bgcolor: COLORS.BUTTON_SECONDARY }}
            disabled={addRefillDisabled}
            onClick={handleAddClick}
          >
            {addButtonText ? addButtonText : t("add_refill")}
          </DefaultButton>
        </Stack>
        {medicationRefillArray.map((el, index) => (
          <MedicationRefilItem
            key={el.medicationName || el.name}
            medicationName={el.medicationName || el.name}
            comment={el.comment}
            frequency={el.frequency}
            onDeleteClick={() => onDeleteClick(index)}
            onEditClick={() => onEditClick(index)}
          />
        ))}
      </Box>
    </ThemeProvider>
  );
};
MedicationRefillForm.propTypes = {
  handleSubmit: PropTypes.func,
  medicationRefillArray: PropTypes.array,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  addButtonText: PropTypes.string,
};

export default MedicationRefillForm;
