import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import {
  CancelConsultationModel,
  ConsultationListCard,
  NoAppointmentText,
} from "../../components/consultations";
import ListItemHidden from "../../components/consultations/ListItemHidden";
import { useTranslation } from "react-i18next";

const Cancelled = (props) => {
  const { consultations, loading } = props;
  const [showCancelModel, setShowCancelModel] = useState(false);
  const {t} = useTranslation("translations")

  const handleCancelClick = () => {
    setShowCancelModel(true);
  };

  const handleCloseModal = () => {
    setShowCancelModel(false);
  };

  if (loading)
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Box>
      {consultations.length === 0 && (
        <NoAppointmentText label={t("you_have_no_cancelled_appointments")} />
      )}
      {consultations &&
        consultations?.map((consultation) => (
          <ConsultationListCard
            consultation={consultation}
            hiddenItem={
              <ListItemHidden
                comment={consultation.comments}
                reason={consultation.reason}
                onCancelClick={handleCancelClick}
                showJoinAppointment={false}
                
              />
            }
          />
        ))}
      <CancelConsultationModel
        modalOpen={showCancelModel}
        handleClose={handleCloseModal}
      />
    </Box>
  );
};

export default Cancelled;
