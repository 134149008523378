import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginDesignPage from "./LoginDesignPage";
import ResetPasswordCard from "../../components/login/ResetPasswordCard";
import { userEmail } from "../../constant";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [emailsent, setEmailsent] = useState(false);
  const [emailResent, setEmailResent] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleResetPassword = () => {
    try {
      setLoading(true);
      if (email === userEmail) {
        setEmailError(null);
        setEmailsent(true);
        // navigate("/");
      } else {
        setEmailError("Incorrect Email Address. Try again");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDoneEmailChecked = () => {
    try {
      setLoading(true);
      setEmailResent(true);
      navigate("/create-new-password");
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleResendEmail = () => {
    try {
      setLoading(true);
      setEmailResent(true);
      // navigate("/");
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginDesignPage>
      <ResetPasswordCard
        email={email}
        setEmail={setEmail}
        emailError={emailError}
        handleResetPassword={handleResetPassword}
        loading={loading}
        emailsent={emailsent}
        emailResent={emailResent}
        handleResendEmail={handleResendEmail}
        handleDoneEmailChecked={handleDoneEmailChecked}
      />
    </LoginDesignPage>
  );
};
