import { Search } from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";

const OutlinedRoundedInput = (props) => {
  const {
    onChange,
    placeholder,
    value,
    width = "35%",
    onSearchClick,
    ...remainingProps
  } = props;

  return (
    <OutlinedInput
      sx={{
        border: `1px solid ${COLORS.DISABLED}`,
        borderRadius: Dimens.TEXT_INPUT_RADIUS,
        bgcolor: COLORS.TEXT_SECONDARY,
        width,
        height: Dimens.TEXT_INPUT_HEIGHT,
        fontSize: Dimens.FONT_SIZE_SMALL,
      }}
      {...remainingProps}
      endAdornment={
        <Search sx={{ cursor: "pointer" }} onClick={onSearchClick} />
      }
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};

OutlinedRoundedInput.protoTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
};

export default OutlinedRoundedInput;
