import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Dimens from "../../../assets/Dimens";
import PropTypes from "prop-types";

const Header = (props) => {
  const { handleGoBack, title, subTitle } = props;
  return (
    <Box>
      <Box
        sx={{
          color: "#001011",
          fontSize: Dimens.FONT_SIZE_EXTRA_LARGE,
          fontWeight: Dimens.FONT_WEIGHT_BOLDER,
          fontFamily: "Comfortaa",
        }}
      >
        <ArrowBackIosOutlinedIcon
          sx={{
            border: "2px solid #2E3A59",
            borderRadius: "50%",
            padding: "1px",
            fontSize: "14px",
            marginRight: "11px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        />
        {title}
      </Box>
      {subTitle && (
        <Typography
          sx={{
            color: "#001011",
            fontSize: 16,
            fontWeight: 400,
            marginTop: "23px",
            textAlign: "justify",
          }}
        >
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};

Header.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default Header;
