import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { loginCardStyles } from "./styles/LoginCardStyles";
import { images } from "../../constant";
import ButtonLoader from "../ButtonLoader";

const CreateNewPasswordCard = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleCreatePassword,
  passwordError,
  loading,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ ...loginCardStyles.cardStyle, width: matchesSM ? "94%" : 575 }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src={images.logoIcon}
            alt="Logo"
            style={loginCardStyles.imageStyle}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{ padding: 100, marginTop: 90 }}
        >
          <form style={loginCardStyles.formStyle} noValidate>
            {/* title area  */}
            <span style={loginCardStyles.titleTextStyles}>
              Create new password
            </span>
            {/* message area  */}
            <span style={loginCardStyles.messageText2Styles}>
              Enter your new password
            </span>

            {/* password field  */}
            <TextField
              label="Password"
              variant="filled"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              helperText={passwordError}
              error={passwordError !== null ? true : false}
              sx={loginCardStyles.textFieldStyle}
              FormHelperTextProps={{
                sx: loginCardStyles.formHelperTextStyles,
              }}
              InputLabelProps={{
                style: {
                  color: "#515563",
                },
              }}
              InputProps={{
                sx: loginCardStyles.inputPropsStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* confirm Password field  */}
            <TextField
              label="Confirm Password"
              variant="filled"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              helperText={passwordError}
              error={passwordError !== null ? true : false}
              sx={loginCardStyles.textFieldStyle}
              FormHelperTextProps={{
                sx: loginCardStyles.formHelperTextStyles,
              }}
              InputLabelProps={{
                style: {
                  color: "#515563",
                },
              }}
              InputProps={{
                sx: loginCardStyles.inputPropsStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Confirm Button area  */}
            {loading ? (
              <Grid style={{ marginTop: 65 }}>
                <ButtonLoader />
              </Grid>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={confirmPassword && password ? false : true}
                style={
                  confirmPassword && password
                    ? loginCardStyles.buttonStyle
                    : loginCardStyles.buttonDisabledStyle
                }
                onClick={handleCreatePassword}
              >
                Confirm
              </Button>
            )}

            {/* Need help area  */}
            <span
              style={{
                color: "#000000",
                fontSize: 14,
                fontWeight: 400,
                marginTop: 100,
              }}
            >
              Need Help?{" "}
              <Button style={loginCardStyles.resetPasswordStyles}>
                Contact Us
              </Button>
            </span>
          </form>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default CreateNewPasswordCard;
