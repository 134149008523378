import React, { useState } from "react";
import { Grid, Button, LinearProgress } from "@mui/material";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";

import { FileUploader } from "react-drag-drop-files";

import { images } from "../../../constant";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const fileTypes = ["JPEG", "PNG", "JPG", "PJPEG", "GIF", "PDF"];

const AddDocumentCard = ({
  matchesLG,
  matchesXl,
  matchesMD,
  matchesSM,
  matchesXS,
  loading,
  newDocument,
  setNewDocument,
  handleAddDocument,
}) => {
  const { t } = useTranslation("translations");
  const handleChange = (file) => {
    setNewDocument(file);
  };

  return (
    <Grid
      container
      direction="column"
      style={{
        ...healthRecordsStyles.personalInfoMainBox,
        width: matchesLG || matchesXl ? "80%" : "100%",
        minHeight: matchesXS || matchesSM || matchesMD ? "auto" : 615,
      }}
    >
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px dashed #19A6FE",
            borderRadius: 20,
            paddingTop: 20,
            paddingBottom: 30,
            background: "#DFF0FF",
          }}
        >
          <img
            src={images.uploadDocumentIcon}
            alt="upload doc"
            style={{ width: 82, height: 60 }}
          />
          <div
            style={{ paddingTop: 5, paddingBottom: 5 }}
            dangerouslySetInnerHTML={{ __html: t("drag_drop_file_html") }}
          ></div>
          <div>
            {t("or")}{" "}
            <a
              style={{
                color: "#0D3B66",
                fontWeight: 500,
                textDecoration: "underline",
              }}
            >
              {t("browse")}
            </a>{" "}
            {t("files_in_your_computer")}
          </div>
        </div>
      </FileUploader>
      <p>{newDocument ? `${t("file_name")}: ${newDocument[0].name}` : ""}</p>
      {loading ? (
        <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={newDocument ? false : true}
          style={
            newDocument
              ? healthRecordsStyles.addMedicationButtonEnabled
              : healthRecordsStyles.addMedicationButtonDisabled
          }
          onClick={handleAddDocument}
        >
          {t("upload_file")}
        </Button>
      )}
    </Grid>
  );
};

export default AddDocumentCard;
