import {
  ArrowDropDown,
  ArrowDropUp,
  Autorenew,
  CheckCircle,
} from "@mui/icons-material";
import { Card, Stack, Typography, Box, Chip, Collapse } from "@mui/material";
import Dimens from "../../assets/Dimens";
import { drIcon, phonecall, videocall } from "../../assets/icons";
import { COLORS } from "../../assets/colors";
import PriceLabel from "./PriceLabel";
import { useState } from "react";
import { formatDateConsultation } from "../../utils/date-extensions";
import { useTranslation } from "react-i18next";
import { getConsultationName } from "../../utils/consultation.helper";
import { getConsultationStatusLabel } from "../../utils/status.helper";

const ConsultationListCard = (props) => {
  const { consultation, hiddenItem } = props;
  const { t } = useTranslation("translations");
  const [seeMore, setSeeMore] = useState(false);

  const HandleShowDetails = () => {
    setSeeMore((prev) => !prev);
  };

  const getModalityIcon = (modality) => {
    const mModality = `${modality?.join(" ")}`;
    if (mModality.includes("video")) {
      return <img src={videocall} alt="video icon" />;
    }
    if (mModality.includes("phone")) {
      return <img src={phonecall} alt="phonecall icon" />;
    }
    return <div />;
  };

  return (
    <Card
      elevation={1}
      sx={{
        minHeight: Dimens.CONSULTATION_LIST_ITEM_HEIGHT,
        mb: 1,
        mt: { xs: 1, md: 0 },
        borderRadius: Dimens.CONSULTATION_LIST_ITEM_BORDER_RADIUS,
        pt: { md: 0, xs: 1 },
        pb: { md: 0, xs: 1 },
        pl: {
          md: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          xs: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL_MOBILE,
        },
        pr: {
          md: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          xs: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL_MOBILE,
        },
      }}
    >
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent={"space-between"}
        alignItems={{ md: "center", xs: "flex-start" }}
        sx={{
          minHeight: Dimens.CONSULTATION_LIST_ITEM_HEIGHT,
          position: "relative",
        }}
        spacing={1}
      >
        <Stack direction={"row"}>
          <img
            src={drIcon}
            height={Dimens.CONSULTATION_LIST_ITEM_IMAGE_HEIGHT}
            width={Dimens.CONSULTATION_LIST_ITEM_IMAGE_HEIGHT}
            alt="doctor"
          />
          <Box sx={{ width: { md: "150px" }, overflow: "hidden", ml: 1 }}>
            <Typography>
              {consultation?.physician?.firstName}{" "}
              {consultation?.physician.lastName}
            </Typography>
            <Typography variant={"subtitle2"}>
              {t(getConsultationName(consultation.friendlySubTypeName))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { md: "none", xs: "block" },
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            {getModalityIcon(consultation.modality)}
          </Box>
        </Stack>
        <Typography
          variant={"subtitle2"}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            bgcolor: { xs: COLORS.CARD_SECONDARY, md: "transparent" },
            p: { xs: 1, md: 0 },
            mt: { xs: 1, md: 0 },
            mb: { xs: 1, md: 0 },
          }}
        >
          {formatDateConsultation(consultation.whenScheduled)}
        </Typography>
        <PriceLabel
          title={consultation.price ? consultation.price : t("free")}
        />
        <Chip
          label={t(getConsultationStatusLabel(consultation.status))}
          deleteIcon={
            consultation.status === "ConsultRequestCreated" ? (
              <CheckCircle />
            ) : (
              <Autorenew color="inherit" />
            )
          }
          onDelete={() => console.log("")}
          sx={{
            width: "200px",
            bgcolor:
              consultation.status === "ConsultRequestCreated"
                ? COLORS.SUCCESS
                : COLORS.PENDING,
            color: COLORS.BLACK,
            mt: { xs: 1, md: 0 },
          }}
        />
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          {getModalityIcon(consultation.modality)}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          onClick={HandleShowDetails}
          sx={{
            cursor: "pointer",
            alignSelf: { md: "center", xs: "flex-end" },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textDecoration: "underline",
            }}
          >
            {t("view_details")}{" "}
          </Typography>
          {seeMore ? <ArrowDropUp /> : <ArrowDropDown />}
        </Stack>
      </Stack>
      <Collapse in={seeMore}>{hiddenItem}</Collapse>
    </Card>
  );
};

export default ConsultationListCard;
