import { styled, Typography } from "@mui/material";
import Dimens from "../../../assets/Dimens";

const LabelText = styled(Typography)((align) => ({
  fontWeight: Dimens.FONT_WEIGHT_BOLDER,
  fontSize: Dimens.FONT_SIZE_MEDIUM,
  lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
  fontFamily: "Comfortaa",
  textAlign: align,
}));

export default LabelText;
