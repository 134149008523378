import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NavigateToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);

  return <div>Please Login.</div>;
};

export default NavigateToLogin;
