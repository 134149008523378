import { DeleteRounded } from "@mui/icons-material";
import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dimens from "../../../assets/Dimens";
import Applinks from "../../../assets/route-links";
import {
  AlertDialog,
  DefaultDropdown,
  DefaultRadioButton,
  DefaultTextArea,
  Header,
  StyledBodyText,
  StyledCard,
} from "../../../components/common";
import { StyledInput } from "../../../components/common/default-dropdown/DefaultDropdown";
import {
  BehavouralHealthModel,
  MedicalConditionForm,
  MedicationForm,
  PrescriptionLimitationModel,
} from "../../../components/consultations";
import AlergyForm from "../../../components/consultations/AlergyForm";
import DefaultButton from "../../../components/consultations/DefaultButton";
import MedicationRefillForm from "../../../components/consultations/MedicationRefillForm";
import SurgeryForm from "../../../components/consultations/SurgeryForm";
import { getStates, searchAllergies } from "../../../data/requests/common";
import {
  addAllergy,
  addMedicalHistory,
  addMedication,
  addSurgicalHistory,
  deleteMedicalHistory,
  deleteMedication,
  deleteSurgicalHistory,
  markInactiveAllergy,
  searchMedication,
  updateSurgicalHistory,
} from "../../../data/requests/healthRecords";
import { useRequest } from "../../../data/useRequest";
import useFeedback from "../../../providers/feedback.provider";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import MainPageLayout from "../../MainPageLayout";
import EditAllergyModal from "../../../components/healthRecords/medicationAllergies/EditAllergyModal";
import EditSurgicalHistoryModal from "../../../components/healthRecords/surgicalHistory/EditSurgicalHistoryModal";
import useAuth from "../../../providers/auth.provider";
import EditMedicationRefillDialog from "../../../components/consultations/EditMedicationRefillDialog";
import { useTranslation } from "react-i18next";

const PsychiatristQuestionaire = () => {
  const navigate = useNavigate();
  const {
    psychiatristPayload,
    setPsychiatristPayload,
    medicationRefill,
    setMedicationRefill,
    medicationArray,
    setMedicationArray,
    medicalConditionArray,
    setMedicalConditionArray,
    surgeryArray,
    setSurgeryArray,
    alergies,
    setAlergies,
    refill,
    setRefill,
    alergy,
    setAlergy,
    medications,
    setMedications,
    surgeries,
    setSurgeries,
    medicalCondition,
    setMedicalCondition,
  } = usePsychiatrist();
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const states = useRequest(() => getStates());
  const alergiesResp = useRequest(([params]) => searchAllergies(params));
  const medicationSearchResp = useRequest(([params]) =>
    searchMedication(params)
  );

  const theme = useTheme();

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));

  const { addDialog, addSnack } = useFeedback();

  const [disabled, setDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState({
    state: "",
    reason_for_visit: "",
    heightFeet: "",
    weight: "",
  });

  // Local States for daialogs
  const [behavioralHealthModel, setBehavioralHealthModel] = useState(false);
  const [prescriptionModel, setPrescriptionModel] = useState(false);
  const [medicationRefillDialog, setMedicationRefillDialog] = useState(false);
  const [allergyDialog, setAllergyDialog] = useState(false);
  const [medicationDialog, setMedicationDialog] = useState(false);
  const [medicalConditionDialog, setMedicalConditionDialog] = useState(false);
  const [surgeryDialog, setSurgeryDialog] = useState(false);
  const [editAllergyModal, setEditAllergyModal] = useState(false);
  const [editSurgicalHistoryModal, setEditSurgicalHistoryModal] =
    useState(false);
  const [editMedicationRefillModal, setEditMedicationRefillModal] =
    useState(false);

  // Local states for holding delete id's
  const [medicationRefillToDelete, setMedicationRefillToDelete] =
    useState(null);
  const [allergyToDelete, setAllergyToDelete] = useState(null);
  const [medicationToDelete, setMedicationToDelete] = useState(null);
  const [medicalConditionToDelete, setMedicalConditionToDelete] =
    useState(null);
  const [surgeryToDelete, setSurgeryToDelete] = useState(null);
  const [selectedAllergy, setSelectedAllergy] = useState(null);
  const [selectedSurgicalHistory, setSelectedSurgicalHistory] = useState(null);
  const [selectedMedicationRefill, setSelectedMedicationRefill] =
    useState(null);

  // Refs for forms
  const clearMedicationForm = useRef();
  const clearSurgeryForm = useRef();
  const clearMedicationRefillForm = useRef();
  const clearAllergyForm = useRef();
  const clearMedicalConditionForm = useRef();

  useEffect(() => {
    states.execute();
  }, []);

  useEffect(() => {
    if (
      !psychiatristPayload.state ||
      !psychiatristPayload?.ehr?.heightFeet ||
      !psychiatristPayload?.ehr?.weight ||
      !psychiatristPayload?.reason_for_visit
    ) {
      const errors = {
        state: !psychiatristPayload.state ? "state_is_required" : "",
        heightFeet: !psychiatristPayload?.ehr?.heightFeet
          ? "height_is_required"
          : "",
        reason_for_visit: !psychiatristPayload?.reason_for_visit
          ? "reason_for_visit_is_required"
          : "",
        weight: !psychiatristPayload?.ehr?.weight ? "weight_is_required" : "",
      };
      setFormErrors(errors);
      setDisabled(true);
    } else {
      const errors = {
        state: "",
        heightFeet: "",
        reason_for_visit: "",
        weight: "",
      };
      setFormErrors(errors);
      setDisabled(false);
    }
  }, [psychiatristPayload]);

  const handleEditClick = (index) => {
    setSelectedMedicationRefill({ ...medicationRefill[index], index });
    setEditMedicationRefillModal(true);
  };

  const handleEditMedicationRefill = () => {
    const mMedicationRefill = [...medicationRefill];
    mMedicationRefill[selectedMedicationRefill.index] =
      selectedMedicationRefill;
    setMedicationRefill(mMedicationRefill);
    setPsychiatristPayload({
      ...psychiatristPayload,
      prescriptionRefill: {
        isNeeded: selectedMedicationRefill.currentlyUsing,
        prescriptionDetails: selectedMedicationRefill.medicationName,
      },
    });
    setEditMedicationRefillModal(false);
  };

  const handleContinueClick = () => {
    if (disabled) return;
    setBehavioralHealthModel(true);
  };

  const handleBehavioralHealthModelClose = () => {
    setBehavioralHealthModel(false);
    setPrescriptionModel(true);
  };

  const handlePrescriptionModelClose = () => {
    navigate(Applinks.SCHEDULE_PSYCHIATRIST_INTAKE_QUESTIONAIRE);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleInputChange = (key, event) => {
    setPsychiatristPayload({
      ...psychiatristPayload,
      [key]: event.target.value,
    });
  };

  const handleDeleteClick = (index) => {
    setMedicationRefillToDelete(index);
    setMedicationRefillDialog(true);
  };

  const handleAcceptClick = () => {
    if (medicationRefillToDelete !== null) {
      const mRefill = [...medicationRefill];
      mRefill.splice(medicationRefillToDelete, 1);
      setMedicationRefill(mRefill);
      setMedicationRefillToDelete(null);
      setMedicationRefillDialog(false);
    }
  };

  const addRefill = (valueToAdd) => {
    const mRefill = [...medicationRefill];
    mRefill.push(valueToAdd);
    setMedicationRefill(mRefill);
    setPsychiatristPayload({
      ...psychiatristPayload,
      prescriptionRefill: {
        isNeeded: valueToAdd.currentlyUsing,
        prescriptionDetails: valueToAdd.medicationName,
      },
    });
    clearMedicationRefillForm.current();
  };

  const handleMedicationSearch = (query) => {
    medicationSearchResp.execute(query);
  };

  const handleSearchAllergy = (query) => {
    alergiesResp.execute(query);
  };

  const handleAddAllergy = async (allergy) => {
    try {
      const allergyToAdd = {
        medicationAllergyForeignId: allergy.medicationAllergyForeignId,
        medicationAllergyName: allergy.medicationAllergyName,
        medicationAllergyDamConceptIdType: allergy.damConceptIdType,
        medicationAllergyDamConceptId: allergy.damConceptId,
      };
      const addAllergyResponse = await addAllergy(allergyToAdd, user.userId);
      if (addAllergyResponse.success === true) {
        const mAllergies = [...alergies];
        mAllergies.push({
          ...allergyToAdd,
          foreign_id: allergy.medicationAllergyForeignId,
          userMedicationAllergy_id: addAllergyResponse.addedAllergyId,
          allergymedicationname: allergy.medicationAllergyName,
        });
        setAlergies(mAllergies);
        addSnack({
          error: false,
          message: t("allergy_added_successfully"),
        });
        clearAllergyForm.current();
      }
    } catch (err) {
      addDialog({
        title: "error",
        message: err?.response?.data?.message || err.message,
        error: true,
      });
    }
  };

  const handleEditAllergyClick = (index) => {
    setSelectedAllergy(alergies[index]);
    setEditAllergyModal(true);
  };

  const handleDeleteAllergyClick = (index) => {
    setAllergyToDelete(index);
    setAllergyDialog(true);
  };

  const handleDeleteAllergyAcceptClick = async () => {
    setAllergyDialog(false);
    try {
      const mAllergyToDelete = alergies.find(
        (el, ind) => ind === allergyToDelete
      );
      const deleteAllergyResponse = await markInactiveAllergy(
        mAllergyToDelete.userMedicationAllergy_id,
        mAllergyToDelete.foreign_id,
        user.userId
      );
      if (deleteAllergyResponse.success === true) {
        const mAllergies = [...alergies];
        mAllergies.splice(allergyToDelete, 1);
        addSnack({
          error: false,
          message: t("allergy_deleted_successfully"),
        });
        setAlergies(mAllergies);
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err?.response?.data?.message || err.message,
        error: true,
      });
    }
    setAllergyToDelete(null);
  };

  const addMedicalCondition = async (condition) => {
    try {
      const medicalConditionResponse = await addMedicalHistory(
        {
          medicalConditionName: condition.conditionName,
          medicalConditionDescription: condition.description,
          medicalConditionStatus: 1,
        },
        user.userId
      );
      if (medicalConditionResponse.success === true) {
        const mMedicalConditions = [...medicalConditionArray];
        mMedicalConditions.push({
          userMedicalCondition_id: medicalConditionResponse.medicalConditionId,
          name: condition.conditionName,
          description: condition.description,
        });
        addSnack({
          error: false,
          message: t("medical_condition_added_successfully"),
        });
        setMedicalConditionArray(mMedicalConditions);
        clearMedicalConditionForm.current();
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err?.response?.data?.message || err.message,
        error: true,
      });
    }
  };

  const handleConditionEditClick = () => {};

  const handleConditionDeleteClick = (index) => {
    setMedicalConditionToDelete(index);
    setMedicalConditionDialog(true);
  };

  const handleDeleteMedicalConditionAcceptClick = async () => {
    try {
      const selectedMedicalCondition = medicalConditionArray.find(
        (el, ind) => ind === medicalConditionToDelete
      );
      const deleteConditionResponse = await deleteMedicalHistory(
        selectedMedicalCondition.userMedicalCondition_id,
        user.userId
      );
      if (deleteConditionResponse.success === true) {
        const mMedicalConditions = [...medicalConditionArray];
        mMedicalConditions.splice(medicalConditionToDelete, 1);
        addSnack({
          error: false,
          message: t("medical_condition_deleted_successfully"),
        });
        setMedicalConditionArray(mMedicalConditions);
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err.response.data.message || err.message,
        error: true,
      });
    }
    setMedicalConditionDialog(false);
  };

  const addSurgery = async (surgery) => {
    try {
      const surgeryToAdd = {
        procedureName: surgery.name,
        procedureDescription: surgery.description,
        procedureDate: moment(surgery.procedureDate, "MMMM, DD YYYY").format(
          "MMMM, DD YYYY hh:mm:ss z"
        ),
      };
      const addSurgeryResp = await addSurgicalHistory(
        surgeryToAdd,
        user.userId
      );
      if (addSurgeryResp.success === true) {
        const mSurgeryArray = [...surgeryArray];
        mSurgeryArray.push({
          ...surgery,
          userSurgicalHistory_id: addSurgeryResp.procedureId,
        });
        setSurgeryArray(mSurgeryArray);
        addSnack({
          error: false,
          message: t("surgery_added_successfully"),
        });
        clearSurgeryForm.current();
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err.response.data.message || err.message,
        error: true,
      });
    }
  };
  const handleSurgeryEditClick = (index) => {
    setEditSurgicalHistoryModal(true);
    setSelectedSurgicalHistory({ ...surgeryArray[index], index });
  };

  const handleSurgeryDeleteClick = (index) => {
    setSurgeryDialog(true);
    setSurgeryToDelete(index);
  };

  const handleDeleteSurgeryAcceptClick = async () => {
    try {
      const mSurgeryToDelete = surgeryArray.find(
        (el, ind) => ind === surgeryToDelete
      );
      const deleteSurgeryResp = await deleteSurgicalHistory(
        mSurgeryToDelete.userSurgicalHistory_id,
        user.userId
      );
      if (deleteSurgeryResp.success === true) {
        const mSurgeryArray = [...surgeryArray];
        mSurgeryArray.splice(surgeryToDelete, 1);
        setSurgeryArray(mSurgeryArray);
        addSnack({
          error: false,
          message: t("surgery_deleted_successfully"),
        });
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err.response.data.message || err.message,
        error: true,
      });
    }
    setSurgeryDialog(false);
  };

  const handleHeightWeightChange = (key, value) => {
    setPsychiatristPayload({
      ...psychiatristPayload,
      ehr: {
        ...psychiatristPayload.ehr,
        [key]: parseFloat(value),
      },
    });
  };

  const handleAddMedication = async (medication) => {
    try {
      const mMedication = {
        medicationName: medication.medicationName.value,
        medicationFrequency: medication.frequency,
        medicationComment: medication.comment,
        medicationCurrentUse: medication.currentlyUsing,
        medicationForeignId: medication.medicationName.medicationId,
        medicationNDC: medication.medicationName.ndc,
      };
      const medicationAddResp = await addMedication(mMedication, user.userId);
      if (medicationAddResp.success === true) {
        const mMedicationArray = [...medicationArray];
        mMedicationArray.push({
          comment: medication.comment,
          foreign_id: medication.medicationName.medicationId,
          frequency: medication.frequency,
          name: medication.medicationName.value,
          ndc: medication.medicationName.ndc,
          userMedication_id: medicationAddResp.medicationId,
        });
        setMedicationArray(mMedicationArray);
        clearMedicationForm.current();
        addSnack({
          error: false,
          message: t("medication_added_successfully"),
        });
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err?.response?.data?.message || err.message,
        error: true,
      });
    }
  };
  const handleEditMedicationClick = () => {};
  const handleDeleteMedicationClick = (index) => {
    setMedicationToDelete(index);
    setMedicationDialog(true);
  };
  const handleDeleteMedicationAcceptClick = async () => {
    setMedicationDialog(false);
    try {
      const mMedicationToDelete = medicationArray.find(
        (el, ind) => ind === medicationToDelete
      );
      const medicationDeleteResp = await deleteMedication(
        mMedicationToDelete.userMedication_id,
        user.userId
      );
      if (medicationDeleteResp.success === true) {
        const mMedicationArray = [...medicationArray];
        mMedicationArray.splice(medicationToDelete, 1);
        setMedicationArray(mMedicationArray);
        addSnack({
          error: false,
          message: t("medication_deleted_successfully"),
        });
      }
    } catch (err) {
      addDialog({
        title: t("error"),
        message: err?.response?.data?.message || err.message,
        error: true,
      });
    }
    setMedicationToDelete(null);
  };

  const handleEditAllergyModalClose = () => {
    setEditAllergyModal(false);
    setSelectedAllergy(null);
  };

  const handleEditAllergy = () => {};

  const handleEditSurgicalHistoryModalClose = () => {
    setEditSurgicalHistoryModal(false);
    setSelectedSurgicalHistory(null);
  };

  const handleEditSurgicalHistory = async () => {
    try {
      const newData = {
        userId: user.userId,
        procedureName: selectedSurgicalHistory.name,
        procedureDescription: selectedSurgicalHistory.description,
        procedureDate: moment(
          selectedSurgicalHistory.procedureDate,
          "MMMM, DD YYYY"
        ).format("MMMM, DD YYYY hh:mm:ss z"),
      };

      const response = await updateSurgicalHistory(
        newData,
        selectedSurgicalHistory.userSurgicalHistory_id
      );

      if (response.success === true) {
        const mSurgeryArray = [...surgeryArray];
        mSurgeryArray[selectedSurgicalHistory.index] = {
          ...mSurgeryArray[selectedSurgicalHistory.index],
          name: newData.procedureName,
          description: newData.procedureDescription,
          procedureDate: selectedSurgicalHistory.procedureDate,
        };
        setSurgeryArray(mSurgeryArray);
        setSelectedSurgicalHistory(null);
        handleEditSurgicalHistoryModalClose();
      } else {
        addDialog({
          title: t("error"),
          message: response.message,
          error: true,
        });
      }
    } catch (error) {
      addDialog({
        title: t("error"),
        message: error?.response?.data?.message || error.message,
        error: true,
      });
    } finally {
      // setEditLoading(false);
    }
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header title={t("medical_questionaire")} handleGoBack={handleGoBack} />
        <StyledCard sx={{ mt: 2, pl: 2, pt: 2, pb: 2 }}>
          <Stack spacing={3}>
            <a href="#">
              {t("conditions_not_treated_behavioral_health_services")}
            </a>
            <a href="#">{t("perscription_limitations")}</a>
          </Stack>
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText>{t("state_location_question")}</StyledBodyText>
          <DefaultDropdown
            placeholder={t("select_an_option")}
            options={states.data?.states || []}
            error={!!formErrors.state}
            helperText={t(formErrors.state)}
            value={
              psychiatristPayload.state
                ? states.data?.states.find(
                    (el) => psychiatristPayload.state === el.state_id
                  ).name
                : ""
            }
            onChange={(e) => handleInputChange("state", e)}
            renderValue={(option) => (
              <MenuItem
                key={option.state_id}
                value={option.state_id}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  // bgcolor: option === value ? "#FFB74D" : "#fff",
                  "&:hover": {
                    bgcolor: "#FFB74D",
                  },
                }}
              >
                {option.name}
              </MenuItem>
            )}
          />
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText>{t("medical_condition_description")}</StyledBodyText>
          <DefaultTextArea
            minRows={6}
            placeholder={t("add_details_here")}
            error={!!formErrors.reason_for_visit}
            helperText={t(formErrors.reason_for_visit)}
            value={psychiatristPayload.reason_for_visit}
            sx={{ mt: 1 }}
            onChange={(e) => handleInputChange("reason_for_visit", e)}
          />
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("need_medication_refill")}
          </StyledBodyText>
          <DefaultRadioButton
            value={refill}
            negativeLabel={t("no")}
            positiveLabel={t("yes")}
            onChange={() => {
              setRefill(!refill);
            }}
          />
          {refill && (
            <Collapse in={refill}>
              <MedicationRefillForm
                handleSubmit={addRefill}
                medicationRefillArray={medicationRefill}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                clearMedicationRefillForm={clearMedicationRefillForm}
              />
            </Collapse>
          )}
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("any_drug_allergies")}
          </StyledBodyText>
          <DefaultRadioButton
            value={alergy}
            negativeLabel={t("no")}
            onChange={() => {
              setAlergy(!alergy);
            }}
            positiveLabel={t("yes")}
          />
          {alergy && (
            <Collapse in={alergy}>
              <AlergyForm
                handleSubmit={handleAddAllergy}
                alergyArray={alergies}
                onEditClick={handleEditAllergyClick}
                onDeleteClick={handleDeleteAllergyClick}
                alergiesResp={alergiesResp}
                onSearchAllergy={handleSearchAllergy}
                clearAllergyForm={clearAllergyForm}
              />
            </Collapse>
          )}
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>{t("any_medications")}</StyledBodyText>
          <DefaultRadioButton
            negativeLabel={t("no")}
            positiveLabel={t("yes")}
            value={medications}
            onChange={() => {
              setMedications(!medications);
            }}
          />
          {medications && (
            <Collapse in={medications}>
              <MedicationForm
                handleSubmit={handleAddMedication}
                medicationArray={medicationArray}
                onEditClick={handleEditMedicationClick}
                onDeleteClick={handleDeleteMedicationClick}
                addButtonText={t("add_medication")}
                medicationResponse={medicationSearchResp}
                medicationSearch={handleMedicationSearch}
                clearMedicationForm={clearMedicationForm}
              />
            </Collapse>
          )}
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("any_medical_conditions")}
          </StyledBodyText>
          <DefaultRadioButton
            negativeLabel={t("no")}
            value={medicalCondition}
            onChange={() => setMedicalCondition(!medicalCondition)}
            positiveLabel={t("yes")}
          />
          {medicalCondition && (
            <Collapse in={medicalCondition}>
              <MedicalConditionForm
                handleSubmit={addMedicalCondition}
                medicalConditionArray={medicalConditionArray}
                onEditClick={handleConditionEditClick}
                onDeleteClick={handleConditionDeleteClick}
                clearMedicalConditionForm={clearMedicalConditionForm}
              />
            </Collapse>
          )}
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("any_surgeries_to_report")}
          </StyledBodyText>
          <DefaultRadioButton
            negativeLabel={t("no")}
            value={surgeries}
            onChange={() => setSurgeries(!surgeries)}
            positiveLabel={t("yes")}
          />
          {surgeries && (
            <Collapse in={surgeries}>
              <SurgeryForm
                handleSubmit={addSurgery}
                surgeryArray={surgeryArray}
                onEditClick={handleSurgeryEditClick}
                onDeleteClick={handleSurgeryDeleteClick}
                clearSurgeryForm={clearSurgeryForm}
              />
            </Collapse>
          )}
        </StyledCard>
        <StyledCard sx={{ mt: 4 }} elevation={0}>
          <StyledBodyText sx={{ mb: 2 }}>
            {t("verify_and_update_height_weight")}
          </StyledBodyText>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={2}
            justifyContent="space-between"
          >
            <Stack direction={"row"}>
              <Box>
                <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                  {t("height_in_feet")}
                </StyledBodyText>
                <FormControl error={!!formErrors.heightFeet}>
                  <StyledInput
                    placeholder={t("enter_a_value_feet")}
                    value={psychiatristPayload?.ehr?.heightFeet}
                    type="number"
                    style={{ width: 145 }}
                    inputProps={{
                      max: 7,
                      min: 1,
                    }}
                    onChange={(e) =>
                      handleHeightWeightChange("heightFeet", e.target.value)
                    }
                  />
                  <FormHelperText>{t(formErrors.heightFeet)}</FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ ml: Dimens.PADDING_TOP_MEDIUM }}>
                <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                  {t("height_in_inches")}
                </StyledBodyText>
                <StyledInput
                  placeholder={t("enter_a_value_inches")}
                  value={psychiatristPayload?.ehr?.heightInches}
                  type="number"
                  style={{ width: 145 }}
                  inputProps={{
                    max: 11,
                    min: 0,
                  }}
                  onChange={(e) =>
                    handleHeightWeightChange("heightInches", e.target.value)
                  }
                />
              </Box>
            </Stack>
            <Box>
              <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                {t("weight_in_pounds")}
              </StyledBodyText>
              <FormControl error={!!formErrors.weight}>
                <StyledInput
                  placeholder={t("enter_a_value_pounds")}
                  value={psychiatristPayload?.ehr?.weight}
                  type="number"
                  style={{ width: 145 }}
                  inputProps={{
                    max: 500,
                    min: 0,
                  }}
                  onChange={(e) =>
                    handleHeightWeightChange("weight", e.target.value)
                  }
                />
                <FormHelperText>{t(formErrors.weight)}</FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </StyledCard>
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <DefaultButton disabled={disabled} onClick={handleContinueClick}>
            {t("continue")}
          </DefaultButton>
        </Stack>
        <BehavouralHealthModel
          modalOpen={behavioralHealthModel}
          handleClose={handleBehavioralHealthModelClose}
        />
        <PrescriptionLimitationModel
          modalOpen={prescriptionModel}
          handleClose={handlePrescriptionModelClose}
        />
        {/* All Dialogs */}
        <EditMedicationRefillDialog
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          modalOpen={editMedicationRefillModal}
          handleClose={() => setEditMedicationRefillModal(false)}
          setMedicationRefill={setSelectedMedicationRefill}
          medicationRefill={selectedMedicationRefill}
          handleEditMedicationRefill={handleEditMedicationRefill}
        />
        <EditAllergyModal
          modalOpen={editAllergyModal}
          handleClose={handleEditAllergyModalClose}
          allergy={selectedAllergy}
          setAllergy={setSelectedAllergy}
          handleEditAllergy={handleEditAllergy}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
        <EditSurgicalHistoryModal
          modalOpen={editSurgicalHistoryModal}
          handleClose={handleEditSurgicalHistoryModalClose}
          surgicalHistory={selectedSurgicalHistory}
          setSurgicalHistory={setSelectedSurgicalHistory}
          handleEditSurgicalHistory={handleEditSurgicalHistory}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          // loading={editLoading}
        />
        <AlertDialog
          modalOpen={medicationRefillDialog}
          icon={<DeleteRounded />}
          handleClose={() => setMedicationRefillDialog(false)}
          title={t("delete_medication_refill")}
          description={t("delete_medication_refill_info")}
          primaryButtonText={t("accept")}
          secondaryButtonText={t("decline")}
          primaryButtonAction={handleAcceptClick}
          secondaryButtonAction={() => {
            setMedicationRefillDialog(false);
          }}
        />
        <AlertDialog
          modalOpen={allergyDialog}
          icon={<DeleteRounded />}
          handleClose={() => setAllergyDialog(false)}
          title={t("delete_allergy")}
          description={t("delete_allergy_info")}
          primaryButtonText={t("accept")}
          secondaryButtonText={t("decline")}
          primaryButtonAction={handleDeleteAllergyAcceptClick}
          secondaryButtonAction={() => {
            setAllergyDialog(false);
          }}
        />
        <AlertDialog
          modalOpen={medicationDialog}
          icon={<DeleteRounded />}
          handleClose={() => setMedicationDialog(false)}
          title={t("delete_medication")}
          description={t("delete_medication_info")}
          primaryButtonText={t("accept")}
          secondaryButtonText={t("decline")}
          primaryButtonAction={handleDeleteMedicationAcceptClick}
          secondaryButtonAction={() => {
            setMedicationDialog(false);
          }}
        />
        <AlertDialog
          modalOpen={medicalConditionDialog}
          icon={<DeleteRounded />}
          handleClose={() => setMedicalConditionDialog(false)}
          title={t("delete_medical_condition")}
          description={t("delete_medical_condition_info")}
          primaryButtonText={t("accept")}
          secondaryButtonText={t("decline")}
          primaryButtonAction={handleDeleteMedicalConditionAcceptClick}
          secondaryButtonAction={() => {
            setMedicalConditionDialog(false);
          }}
        />
        <AlertDialog
          modalOpen={surgeryDialog}
          icon={<DeleteRounded />}
          handleClose={() => setSurgeryDialog(false)}
          title={t("delete_surgery")}
          description={t("delete_surgery_info")}
          primaryButtonText={t("accept")}
          secondaryButtonText={t("decline")}
          primaryButtonAction={handleDeleteSurgeryAcceptClick}
          secondaryButtonAction={() => {
            setSurgeryDialog(false);
          }}
        />
      </Box>
    </MainPageLayout>
  );
};

export default PsychiatristQuestionaire;
