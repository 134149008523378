import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import NoMedicationsAdded from "../../components/healthRecords/medications/NoMedicationsAdded";
import DeleteMedicationModal from "../../components/healthRecords/medications/DeleteMedicationModal";
import MedicationDeletedModal from "../../components/healthRecords/medications/MedicationDeletedModal";
import MedicalHistoryCard from "../../components/healthRecords/medicalHistory/MedicalHistoryCard";
import AddMedicalHistoryCard from "../../components/healthRecords/medicalHistory/AddMedicalHistoryCard";
import EditMedicalHistoryModal from "../../components/healthRecords/medicalHistory/EditMedicalHistoryModal";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import {
  addMedicalHistory,
  deleteMedicalHistory,
  updateMedicalHistory,
} from "../../data/requests/healthRecords";
import useAuth from "../../providers/auth.provider";
import { useTranslation } from "react-i18next";

const MedicalHistory = () => {
  const { user } = useAuth();

  const { medicalHistory, handleGetHealthRecords } = useHealthRecordsContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation("translations");

  // let user = JSON.parse(localStorage.getItem("user"));

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [newMedicalHistory, setNewMedicalHistory] = useState({
    name: "",
    description: "",
    status: "",
  });
  const [selectedMedicalHistory, setSelectedMedicalHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editMedicalHistoryModal, setEditMedicalHistoryModal] = useState(false);
  const [deleteMedicalHistoryModal, setDeleteMedicalHistoryModal] =
    useState(false);
  const [medicalHistoryDeletedModal, setMedicalHistoryDeletedModal] =
    useState(false);

  // console.log("medical history:", medicalHistory);

  const [medicalHistoryData, setMedicalHistoryData] = useState([]);

  useEffect(() => {
    setMedicalHistoryData(medicalHistory);
  }, [medicalHistory]);

  // [
  //   {
  //     id: 1,
  //     name: "Medical History 1",
  //     description: "Lorem ipsum dolor sit amet, consectetur 1",
  //     status: "Currently in such condition",
  //   },
  //   {
  //     id: 2,
  //     name: "Medical History 2",
  //     description: "Lorem ipsum dolor sit amet, consectetur 2",
  //     status: "Currently in such condition",
  //   },
  //   {
  //     id: 3,
  //     name: "Medical History 3",
  //     description: "Lorem ipsum dolor sit amet, consectetur 3",
  //     status: "Had condition in the past",
  //   },
  //   {
  //     id: 4,
  //     name: "Medical History 4",
  //     description: "Lorem ipsum dolor sit amet, consectetur 4",
  //     status: "Had condition in the past",
  //   },
  // ]

  const handleEditMedicalHistoryModalOpen = () => {
    setEditMedicalHistoryModal(true);
  };
  const handleEditMedicalHistoryModalClose = () => {
    setEditMedicalHistoryModal(false);
  };
  const handleDeleteMedicalHistoryModalOpen = () => {
    setDeleteMedicalHistoryModal(true);
  };
  const handleDeleteMedicalHistoryModalClose = () => {
    setDeleteMedicalHistoryModal(false);
  };
  const handleMedicalHistoryDeletedModalOpen = () => {
    handleDeleteMedicalHistoryModalClose();
    setMedicalHistoryDeletedModal(true);
  };
  const handleMedicalHistoryDeletedModalClose = () => {
    setMedicalHistoryDeletedModal(false);
  };

  const handleAddMedicalHistory = async () => {
    try {
      setLoading(true);
      let newData = {
        userId: user.userId,
        medicalConditionName: newMedicalHistory.name,
        medicalConditionDescription: newMedicalHistory.description,
        medicalConditionStatus: newMedicalHistory.status,
      };
      console.log("new data:", newData);
      let responseTemp = await addMedicalHistory(newData, user.userId);
      console.log("response:", responseTemp);
      await handleGetHealthRecords();

      // const updatedDataTemp = [...medicalHistoryData];
      // const newId = medicalHistoryData.length + 1;

      // const newMed = {
      //   id: newId,
      //   name: newMedicalHistory.name,
      //   description: newMedicalHistory.description,
      //   status: newMedicalHistory.status,
      // };

      // updatedDataTemp.push(newMed);
      // setMedicalHistoryData(updatedDataTemp);

      // Reset the newMedicalHistory state
      setNewMedicalHistory({
        name: "",
        description: "",
        status: "",
      });
    } catch (error) {
      console.log("error adding medical History:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditMedicalHistory = async () => {
    try {
      setModalLoading(true);

      let newData = {
        userId: user.userId,
        medicalConditionId: selectedMedicalHistory.userMedicalCondition_id,
        medicalConditionName: selectedMedicalHistory.name,
        medicalConditionDescription: selectedMedicalHistory.description,
        medicalConditionStatus: selectedMedicalHistory.status,
      };

      let responseTemp = await updateMedicalHistory(
        newData,
        selectedMedicalHistory.userMedicalCondition_id,
        user.userId
      );
      console.log("response:", responseTemp);
      await handleGetHealthRecords();

      // console.log("selected Medical History:", selectedMedicalHistory);
      // const updatedDataTemp = [...medicalHistoryData];
      // const index = updatedDataTemp.findIndex(
      //   (medicalHistory) => medicalHistory.id === selectedMedicalHistory.id
      // );

      // if (index !== -1) {
      //   updatedDataTemp[index] = selectedMedicalHistory;
      //   console.log(updatedDataTemp);
      //   // setTimeout(() => {
      //   setMedicalHistoryData(updatedDataTemp);
      // setSelectedMedicalHistory(null);
      //   // }, 1000);
      // }

      setSelectedMedicalHistory(null);
      handleEditMedicalHistoryModalClose();
    } catch (error) {
      console.log("error editing medical History:", error.message);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteMedicalHistory = async () => {
    try {
      setModalLoading(true);
      let responseTemp = await deleteMedicalHistory(
        selectedMedicalHistory.userMedicalCondition_id,
        user.userId
      );
      console.log("response:", responseTemp);
      await handleGetHealthRecords();
      // const updatedDataTemp = medicalHistoryData.filter(
      //   (medicalHistory) => medicalHistory.id !== selectedMedicalHistory.id
      // );

      // setMedicalHistoryData(updatedDataTemp);
      handleMedicalHistoryDeletedModalOpen();
      setSelectedMedicalHistory(null);
    } catch (error) {
      console.log("error deleting medical History:", error.message);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <div style={healthRecordsStyles.componentMainTitle}>
          <ArrowBackIosOutlinedIcon
            style={healthRecordsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("medical_history")}
        </div>
        <div style={healthRecordsStyles.messageText}>
          {t("medical_history_info")}
        </div>
        {matchesXS || matchesSM || matchesMD ? (
          <Grid container direction={"column"}>
            <Grid item container xs>
              <AddMedicalHistoryCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                matchesMD={matchesMD}
                loading={loading}
                newMedicalHistory={newMedicalHistory}
                setNewMedicalHistory={setNewMedicalHistory}
                handleAddMedicalHistory={handleAddMedicalHistory}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {medicalHistoryData && medicalHistoryData.length > 0 ? (
                medicalHistoryData.map((data, index) => (
                  <MedicalHistoryCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedMedicalHistory={setSelectedMedicalHistory}
                    handleEditMedicalHistoryModalOpen={
                      handleEditMedicalHistoryModalOpen
                    }
                    handleDeleteMedicalHistoryModalOpen={
                      handleDeleteMedicalHistoryModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_medical_history_added")} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={"row"}>
            <Grid
              item
              container
              xs
              alignItems={"center"}
              direction={"column"}
              style={{ marginTop: 60 }}
            >
              {medicalHistoryData && medicalHistoryData.length > 0 ? (
                medicalHistoryData.map((data, index) => (
                  <MedicalHistoryCard
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    data={data}
                    key={index}
                    setSelectedMedicalHistory={setSelectedMedicalHistory}
                    handleEditMedicalHistoryModalOpen={
                      handleEditMedicalHistoryModalOpen
                    }
                    handleDeleteMedicalHistoryModalOpen={
                      handleDeleteMedicalHistoryModalOpen
                    }
                  />
                ))
              ) : (
                <NoMedicationsAdded text={t("no_medical_history_added")} />
              )}
            </Grid>

            <Grid item container xs>
              <AddMedicalHistoryCard
                matchesLG={matchesLG}
                matchesXl={matchesXl}
                matchesSM={matchesSM}
                matchesXS={matchesXS}
                matchesMD={matchesMD}
                loading={loading}
                newMedicalHistory={newMedicalHistory}
                setNewMedicalHistory={setNewMedicalHistory}
                handleAddMedicalHistory={handleAddMedicalHistory}
              />
            </Grid>
          </Grid>
        )}

        <EditMedicalHistoryModal
          modalOpen={editMedicalHistoryModal}
          handleClose={handleEditMedicalHistoryModalClose}
          medicalHistory={selectedMedicalHistory}
          setMedicalHistory={setSelectedMedicalHistory}
          handleEditMedicalHistory={handleEditMedicalHistory}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={modalLoading}
        />
        <DeleteMedicationModal
          modalOpen={deleteMedicalHistoryModal}
          title={t("delete_medical_history")}
          message={t("delete_medical_history_info")}
          handleClose={handleDeleteMedicalHistoryModalClose}
          handleDelete={handleDeleteMedicalHistory}
          medicalHistory={selectedMedicalHistory}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          loading={modalLoading}
        />
        <MedicationDeletedModal
          modalOpen={medicalHistoryDeletedModal}
          title={t("medical_history_deleted")}
          handleClose={handleMedicalHistoryDeletedModalClose}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default MedicalHistory;
