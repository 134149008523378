import { Checkbox, Stack } from "@mui/material";
import React from "react";
import Dimens from "../../../assets/Dimens";
import { StyledCaptionText } from "../../common";
import PropTypes from "prop-types";
import {
  CircleOutlined,
  RadioButtonCheckedOutlined,
} from "@mui/icons-material";

const SymptomCheckLabel = (props) => {
  const { sympthom, checked, onChange } = props;
  return (
    <Stack direction={"row"} alignItems="center" sx={{ width: "260px" }}>
      <Checkbox
        color="default"
        checked={checked}
        onChange={onChange}
        icon={<CircleOutlined />}
        checkedIcon={<RadioButtonCheckedOutlined />}
      />
      <StyledCaptionText
        key={sympthom}
        sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}
      >
        {sympthom}
      </StyledCaptionText>
    </Stack>
  );
};

SymptomCheckLabel.propTypes = {
  sympthom: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SymptomCheckLabel;
