import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import DataCardNameMenu from "../medications/DataCardNameMenu";
import EditDeleteMenu from "../medications/EditDeleteMenu";
import { getAttachments } from "../../../data/requests/messageCenter";
import useAuth from "../../../providers/auth.provider";
import ButtonLoader from "../../ButtonLoader";

const DocumentCard = ({
  data,
  matchesSM,
  matchesXS,
  setSelectedDocument,
  handleEditDocumentModalOpen,
  handleDeleteDocumentModalOpen,
}) => {
  const { user } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("preview:", preview);

  const handleGetPreview = async () => {
    try {
      setPreviewLoading(true);

      if (data) {
        let responseTemp = await getAttachments(
          data.userAttachment_id,
          "attachment",
          user.userId
        );
        const imageUrl = URL.createObjectURL(responseTemp.data);
        console.log("image:", imageUrl);
        setPreview(imageUrl);
      }
    } catch (error) {
      console.log("error getting preview:", error.message);
    } finally {
      setPreviewLoading(false);
    }
  };

  return (
    <Grid
      container
      direction={"column"}
      //   alignItems={"center"}
      style={{
        padding: matchesSM || matchesXS ? 5 : 20,
        width: matchesSM || matchesXS ? "100%" : "80%",
      }}
    >
      <DataCardNameMenu
        name={data.fileName && data.fileName}
        open={open}
        // hideMenu={true}
        handleClick={(e) => {
          setSelectedDocument(data);
          handleClick(e);
        }}
      />
      {previewLoading ? (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          style={{ margin: 20 }}
        >
          <ButtonLoader />{" "}
        </Grid>
      ) : undefined}
      {preview ? (
        <img
          src={preview}
          // src={`data:image/jpg;base64,${preview}`}
          alt="preview"
          style={{ width: "100%", height: 300 }}
        />
      ) : undefined}
      <Divider
        style={{ color: "#19A6FE", marginTop: 10, width: "98%" }}
        variant="middle"
      />
      <EditDeleteMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        onClickPreview={handleGetPreview}
        handleEditClick={handleEditDocumentModalOpen}
        handleDeleteClick={handleDeleteDocumentModalOpen}
        document={true}
      />
    </Grid>
  );
};

export default DocumentCard;
