import { Grid } from "@mui/material";
import React from "react";
import { loginStyles } from "./styles/LoginStyles";
import { images } from "../../constant";

const LoginDesignPage = ({ children }) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={loginStyles.containerStyles}
    >
      <img src={images.dotsIcon} alt="Logo 1" style={loginStyles.logo1Styles} />
      <img
        src={images.orangeIcon}
        alt="Logo 2"
        style={loginStyles.logo2Styles}
      />
      <img
        src={images.dotsWithbgIcon}
        alt="Logo 3"
        style={loginStyles.logo3Styles}
      />
      <img src={images.blueIcon} alt="Logo 4" style={loginStyles.logo4Styles} />
      {children}
    </Grid>
  );
};

export default LoginDesignPage;
