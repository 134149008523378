import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import Applinks from "../../../assets/route-links";
import {
  AlertDialog,
  CheckBox,
  Header,
  StyledBodyText,
} from "../../../components/common";
import DefaultButton from "../../../components/consultations/DefaultButton";
import PriceLabel from "../../../components/consultations/PriceLabel";
import { images } from "../../../constant";
import { createPsychiatristConsultation } from "../../../data/requests/consultations";
import useAuth from "../../../providers/auth.provider";
import useFeedback from "../../../providers/feedback.provider";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import MainPageLayout from "../../MainPageLayout";
import { useTranslation } from "react-i18next";

const PsychiatristPayment = () => {
  const navigate = useNavigate();

  const { addDialog } = useFeedback();
  const { t } = useTranslation("translations");
  const { user } = useAuth();
  const {
    psychiatristPayload,
    medicationRefill,
    medicationArray,
    medicalConditionArray,
    surgeryArray,
    alergies,
    pharmacy,
    intakeQuestionaireData,
    alergy,
    medications,
    surgeries,
    medicalCondition,
    patientConsultData,
    removeFromLocal,
  } = usePsychiatrist();

  const { patient } = patientConsultData;

  const [patientConsent, setPatientConsent] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log(psychiatristPayload.ehr);
  // console.log("base url:", process.env.REACT_APP_BASE_URL)

  const handleConfirmClick = async () => {
    setLoading(true);
    const answers = [];

    intakeQuestionaireData?.questions?.filter((el) => {
      if (el?.type === "multi-choice") {
        return {
          choice: el?.choices?.map((mChoice) => {
            if (mChoice.checked) {
              answers.push({
                ...mChoice,
                choice: mChoice.order,
                question_id: el?.question_id,
                value: el?.value,
              });
            }
          }),
        };
      }
      if (el?.type === "single-choice") {
        if (el.value === "true") {
          const mChoice = el?.choices?.find((mEl) => mEl.order === 1);
          answers.push({
            ...mChoice,
            choice: mChoice.order,
            question_id: el?.question_id,
          });
        } else {
          const mChoice = el?.choices?.find((mEl) => mEl.order === 2);
          answers.push({
            ...mChoice,
            choice: mChoice.order,
            question_id: el?.question_id,
          });
        }
      }
    });

    try {
      let mPsychiatristPayload = {
        patient: {
          user_id: user.userId,
          ehr: {
            personal: {
              heightFeet: psychiatristPayload.ehr.heightFeet,
              heightInches: psychiatristPayload.ehr.heightInches,
              weight: psychiatristPayload.ehr.weight,
            },
            surgical_history: surgeries ? surgeryArray : [],
            allergies: alergy ? alergies : [],
            medications: medications ? medicationArray : [],
            medical_conditions: medicalCondition ? medicalConditionArray : [],
          },
        },
        payment: {},
        modality: "video",
        labs: [],
        questionnaires: [
          {
            answers: answers,
            questionnaire_id: 1,
          },
        ],
        sureScriptPharmacy_id:
          pharmacy?.sureScriptPharmacy_id ||
          patient?.pharmacy?.sureScriptPharmacy_id,
        appointment_details: { ...psychiatristPayload.appointment_details },
        state: psychiatristPayload.state,
        reason_for_visit: psychiatristPayload.reason_for_visit,
        prescription_refill:
          medicationRefill.length > 0
            ? {
                is_needed:
                  medicationRefill[medicationRefill.length - 1].currentlyUsing,
                prescription_details:
                  medicationRefill[medicationRefill.length - 1].medicationName,
              }
            : {
                is_needed: false,
                prescription_details: "",
              },
        patientPhone: psychiatristPayload.patientPhone.toString(),
      };
      const resp = await createPsychiatristConsultation(mPsychiatristPayload);
      console.log("response:::", resp);
      if (resp.data.success === true) {
        // if (resp.success === true) {
        removeFromLocal();
        setShowConfirmDialog(true);
      } else {
        addDialog({
          title: t("error"),
          message: resp.message,
          error: true,
        });
        // removeFromLocal();
      }
    } catch (err) {
      console.log("error:::", err);
      // removeFromLocal();
      addDialog({
        title: t("error"),
        message: err.response.data.message || err.message,
        error: true,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDoneClick = () => {
    removeFromLocal();
    // navigate(Applinks.SCHEDULE_CONSULATATIONS);
    navigate("/consultations");

  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    removeFromLocal();
    // navigate(Applinks.SCHEDULE_CONSULATATIONS);
    navigate("/consultations");

  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header title={t("payment_method")} handleGoBack={handleGoBack} />
        <StyledBodyText sx={{ mt: 3 }}>
          {t("consultation_overview")}
        </StyledBodyText>
        <Box
          sx={{
            mt: 2,
            width: "90%",
            height: Dimens.EHR_CARD_HEIGHT,
            bgcolor: COLORS.PRIMARY_MAIN,
            borderRadius: Dimens.BORDER_RADIUS_LARGE,
            pl: 3,
            pr: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{ height: "100%" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
              {t("service_fee")}
            </StyledBodyText>
            <PriceLabel title={t("free")} />
          </Stack>
        </Box>
        <StyledBodyText sx={{ mt: 4 }}>
          {t("billing_information")}
        </StyledBodyText>

        <CheckBox
          center
          checked={patientConsent}
          onChange={() => setPatientConsent((prev) => !prev)}
        >
          {t("read_and_agree_with")}{" "}
          {/*
          <Link to={"/informed-patient-consent"}>
        */}
          <a
            href="/informed-patient-consent"
            target="_blank"
            rel="noopener noreferrer"
            // onClick={() =>
            //   window.open(
            //     "/informed-patient-consent",
            //     "_blank",
            //     "rel=noopener noreferrer"
            //   )
            // }
            // to={"/informed-patient-consent"}
          >
            {t("informed_patient_consent")}
          </a>
        </CheckBox>
        <CheckBox
          center
          checked={terms}
          onChange={() => setTerms((prev) => !prev)}
        >
          {t("read_and_agree_with")}{" "}
          <a
            href="/terms-of-services"
            target="_blank"
            rel="noopener noreferrer"
            // to={"/terms-of-services"}
            // onClick={() =>
            //   window.open(
            //     "/terms-of-services",
            //     "_blank",
            //     "rel=noopener noreferrer"
            //   )
            // }
          >
            {t("terms_of_use")}
          </a>
          ,{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            // to={"/privacy-policy"}
            // onClick={() =>
            //   window.open(
            //     "/privacy-policy",
            //     "_blank",
            //     "rel=noopener noreferrer"
            //   )
            // }
          >
            {t("privacy_policy")}
          </a>{" "}
          {t("and")}{" "}
          <a
            href="/hippa-privacy-practices"
            target="_blank"
            rel="noopener noreferrer"
            // to={"/privacy-policy"}
            // onClick={() =>
            //   window.open(
            //     "/privacy-policy",
            //     "_blank",
            //     "rel=noopener noreferrer"
            //   )
            // }
          >
            {t("hippa_privacy_practices")}
          </a>
        </CheckBox>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent={"center"}
          sx={{ mt: 7 }}
        >
          <DefaultButton
            variant="outlined"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {t("cancel_appointment")}
          </DefaultButton>
          <DefaultButton
            disabled={!patientConsent || !terms}
            sx={{ ml: { md: 2, xs: 0 }, mt: { md: 0, xs: 2 } }}
            onClick={handleConfirmClick}
            loading={loading}
          >
            {t("confirm")}
          </DefaultButton>
        </Stack>
        <AlertDialog
          modalOpen={showConfirmDialog}
          title={t("consultation_request_submitted")}
          description={t("consultation_request_submitted_info").format(
            "866-533-1827"
          )}
          primaryButtonText={t("done")}
          primaryButtonAction={handleDoneClick}
          icon={
            <img
              src={images.verifyAccountAnimi}
              alt="Done animation"
              style={{ width: 160, height: 160, marginTop: 30 }}
            />
          }
        />
      </Box>
    </MainPageLayout>
  );
};

export default PsychiatristPayment;
