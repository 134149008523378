import { Box, Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { settingsStyles } from "./styles/SettingsStyles";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddPharmacyModal from "./pharmacyPreferences/AddPharmacyModal";
import { COLORS } from "../../assets/colors";
import Dimens from "../../assets/Dimens";
import { searchPharmacies, updatePharmacy } from "../../data/requests/pharmacy";
import { marker } from "../../assets/icons";
import { Map, PharmacyDropdown, StyledBodyText } from "../common";
import useAuth from "../../providers/auth.provider";
import { getPatientAndConsultObject } from "../../data/requests/consultations";
import DefaultButton from "../consultations/DefaultButton";
import { useTranslation } from "react-i18next";
import useFeedback from "../../providers/feedback.provider";

const AnyReactComponent = ({ text }) => (
  <div>
    {text}
    <img src={marker} height="50px" alt="map marker" />
  </div>
);

const PharmacyPreferencesTab = ({
  matchesSM,
  matchesMD,
  newPharmacy,
  setNewPharmacy,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const { addSnack } = useFeedback();

  const [query, setQuery] = useState("");
  const [pharmacies, setPharmacies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPharmacyModal, setAddPharmacyModal] = useState(false);
  const defaultCenter = { lat: 37.09024, lng: -95.712891 };
  const defaultZoom = 4; // Default zoom level

  // State for the map's center
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  // State for the map's zoom level
  const [mapZoom, setMapZoom] = useState(defaultZoom);

  const [otherPharmacy, setOtherPharmacy] = useState(null);
  const [consultObject, setConsultObject] = useState(null);
  const [updatePharmacyLoading, setUpdatePharmacyLoading] = useState(false);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      handleSearchPharmacies();
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [query, 500]);

  useEffect(() => {
    getConsultObject();
  }, []);

  const getConsultObject = async () => {
    setUpdatePharmacyLoading(true);
    try {
      const resp = await getPatientAndConsultObject(user.userId, "video");
      if (resp.success === true) {
        setConsultObject(resp);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUpdatePharmacyLoading(false);
    }
  };

  const handleSearchPharmacies = () => {
    setLoading(true);
    // Determine if the query is a zipcode or a state for appropriate zoom level
    /////////
    // const isZipcode = query.length === 5 && /^\d+$/.test(query); // Basic US zipcode check
    // const zoomLevel = isZipcode ? 15 : 7; // Example zoom levels
    ////////
    getGeocode(query)
      .then((result) => {
        if (result) {
          const { lat, lng } = result;
          ////////
          // setMapCenter({ lat, lng });
          ////////

          // setMapZoom(isZipcode ? 15 : 7); // Adjust zoom level based on whether the query is a zipcode
        } else {
          // Handle the case where geocoding didn't return a result
          console.error("Geocoding failed, no result returned.");
          // Optionally, set the map center to a default location or show an error message to the user
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the geocoding process
        console.error("Error during geocoding:", error);
      });

    searchPharmacies(query)
      .then((res) => {
        console.log("pharmacies::", res.list);
        setPharmacies(res.list);
        // Update map center and zoom
        // setMapCenter({ lat, lng });
        ////////
        // setMapZoom(zoomLevel);
        ////////
      })
      .catch((err) => {
        setPharmacies([]);
        // Optionally handle errors, e.g., invalid location queries
      })
      .finally(() => setLoading(false));
  };

  // const GOOGLE_API_KEY = "AIzaSyDJm5aqTmyT5OEYqUiDIy6JJnsUtN2OpvQ"; // Use your actual API key

  const getGeocode = async (query) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      query
    )}&key=${process.env.GOOGLE_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(
          "Geocoding failed with status:",
          data.status,
          "and error message:",
          data.error_message
        );
        return null; // Or handle differently based on your application's needs
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error; // Or handle it according to your app's error handling strategy
    }
  };

  // const getGeocode = async (query) => {
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //     query
  //   )}&key=${GOOGLE_API_KEY}`;

  //   try {
  //     const response = await fetch(url);
  //     const data = await response.json();

  //     if (data.status === "OK") {
  //       // Assuming you want the first result
  //       const { lat, lng } = data.results[0].geometry.location;
  //       return { lat, lng };
  //     } else {
  //       // Handle no results or errors, possibly throw an error or return a default location
  //       console.error("Geocoding failed:", data.status);
  //       return null; // Or a default location
  //     }
  //   } catch (error) {
  //     console.error("Geocoding error:", error);
  //     throw error; // Or handle it as suits your app's error handling strategy
  //   }
  // };

  const handleAddPharmacy = (option) => {
    setOtherPharmacy(option);
  };

  const handleAddPharmacyModalOpen = () => {
    setAddPharmacyModal(true);
  };
  const handleAddPharmacyModalClose = () => {
    setAddPharmacyModal(false);
  };

  const handleMakeDefaultPharmacy = async () => {
    try {
      const resp = await updatePharmacy({
        sureScriptPharmacy_id: otherPharmacy?.sureScriptPharmacy_id,
        status: true,
      });
      if (resp.success === true) {
        getConsultObject();
        setOtherPharmacy(null);
        addSnack({
          error: false,
          message: t("pharmacy_updated_successfully"),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container direction={"column"}>
      <div style={settingsStyles.pharmacyTitle}>{t("current_pharmacy")}</div>
      <Grid
        container
        direction={"column"}
        style={settingsStyles.currentPharmacyContainer}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid container>
          <LocationOnIcon fontSize="small" style={{ marginRight: 10 }} />{" "}
          <div style={settingsStyles.passwordReqTitle}>
            {consultObject?.patient?.pharmacy?.name}
          </div>
        </Grid>
        <div style={settingsStyles.pharmacyDetailsText}>
          {consultObject?.patient?.pharmacy?.address
            ? consultObject?.patient?.pharmacy?.address + ", "
            : " "}
          {consultObject?.patient?.pharmacy?.city
            ? consultObject?.patient?.pharmacy?.city + ", "
            : " "}
          {consultObject?.patient?.pharmacy?.zipCode}
        </div>
      </Grid>
      {otherPharmacy && (
        <>
          <StyledBodyText sx={{ mt: 4 }}>
            {t("other_pharmacies")}
          </StyledBodyText>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              mt: 2,
              minHeight: Dimens.EHR_CARD_HEIGHT,
              bgcolor: COLORS.CARD_SECONDARY,
              borderRadius: Dimens.BORDER_RADIUS_LARGE,
              pl: 3,
              pr: 1,
              mb: 2,
              pt: { md: 0, xs: 2 },
              pb: { md: 0, xs: 2 },
            }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack sx={{ ml: 1.5 }}>
              <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                {otherPharmacy.storeName}
              </StyledBodyText>
              <StyledBodyText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  fontWeight: Dimens.FONT_WEIGHT_LIGHT,
                }}
              >
                {`${otherPharmacy.data} ${otherPharmacy.address}`}
                {`${otherPharmacy.city}, ${otherPharmacy.stateName}`}
              </StyledBodyText>
            </Stack>
            <DefaultButton
              loading={updatePharmacyLoading}
              sx={{
                bgcolor: COLORS.BUTTON_SECONDARY,
                alignSelf: { md: "center", xs: "flex-end" },
              }}
              onClick={handleMakeDefaultPharmacy}
            >
              {t("make_default")}
            </DefaultButton>
          </Stack>
        </>
      )}
      <div style={settingsStyles.pharmacyTitle}>{t("add_new_pharmacy")}</div>
      <Grid style={{ width: matchesMD ? "100%" : "43%", textAlign: "justify" }}>
        <div style={settingsStyles.addPharmacyDetailsText}>
          {t("this_map_only_show_e_perscriptions")}{" "}
          <a
            style={settingsStyles.linkStyle}
            onClick={handleAddPharmacyModalOpen}
          >
            {t("here")}
          </a>{" "}
          {t("pharmacy_not_accepting_e_prescriptions")}
        </div>
      </Grid>
      <Grid container style={{ marginTop: 30 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          container
          direction={"column"}
          style={{ paddingRight: 20 }}
        >
          <PharmacyDropdown
            pharmacies={pharmacies}
            handleInputChange={(e) => setQuery(e.target.value)}
            loading={loading}
            handleAddPharmacy={(option) => handleAddPharmacy(option)}
          />
        </Grid>
        {/*Pharmacy Map section starts*/}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          container
          style={{ borderRadius: 10 }}
        >
          <Box
            sx={{
              width: "100%",
              border: `1px solid ${COLORS.DISABLED}`,
              height: "475px",
              borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
              mt: { xs: 2, md: 0 },
            }}
          >
            <Map center={mapCenter} zoom={mapZoom}>
              {pharmacies.length > 0 &&
                pharmacies.map((el) => (
                  <AnyReactComponent
                    lat={el.latitude}
                    lng={el.longitude}
                    text={el?.storeName}
                  />
                ))}
            </Map>
          </Box>
        </Grid>
        {/*Pharmacy Map section ended*/}
      </Grid>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Button variant="contained" style={continueButtonStyle}>
          {t("continue")}
        </Button>
      </Grid>
      <AddPharmacyModal
        modalOpen={addPharmacyModal}
        handleClose={handleAddPharmacyModalClose}
        newPharmacy={newPharmacy}
        setNewPharmacy={setNewPharmacy}
        matchesSM={matchesSM}
        matchesMD={matchesMD}
      />
    </Grid>
  );
};

export default PharmacyPreferencesTab;

const pharmaciesCardStyle = {
  background: "#fff",
  marginTop: 16,
  borderRadius: 15,
  padding: 20,
};

const continueButtonStyle = {
  background: "#19A6FE",
  width: 370,
  height: 50,
  marginTop: 32,
  borderRadius: 60,
  color: "#000",
};
