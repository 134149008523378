import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import TextFieldSection from "./TextFieldSection";
import TextFieldPasswordSection from "./TextFieldPasswordSection";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PasswordRequirements from "./accountInfoTab/PasswordRequirements";
import PasswordsMatchError from "./accountInfoTab/PasswordsMatchError";
import { settingsStyles } from "./styles/SettingsStyles";
// import { useNavigate } from "react-router-dom";
import ButtonLoader from "../ButtonLoader";
import { useTranslation } from "react-i18next";

const AccountInfoTab = ({
  accountInfo,
  setAccountInfo,
  matchesSM,
  handleUpdateAccountInfo,
  loading,
}) => {
  // console.log("account info:", accountInfo);
  // const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordMatchError, setPasswordMatchError] = useState(null);

  let userEmail = localStorage.getItem("userEmail");

  const handleChangePassword = (e) => {
    let value = e.target.value;
    setAccountInfo({ ...accountInfo, newPassword: value });
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;
    const isValidLength = lengthRegex.test(value);
    const hasUppercase = uppercaseRegex.test(value);
    const hasLowercase = lowercaseRegex.test(value);
    const hasSymbol = symbolRegex.test(value);
    const hasNumber = numberRegex.test(value);

    if (
      isValidLength &&
      hasUppercase &&
      hasLowercase &&
      hasSymbol &&
      hasNumber
    ) {
      setPasswordError(null);
    } else {
      setPasswordError(t("incorrect_password_error"));
    }
    if (value.length < 1) {
      setPasswordError(null);
    }
  };

  const handleMatchPasswords = (e) => {
    setAccountInfo({
      ...accountInfo,
      confirmPassword: e.target.value,
    });
    if (e.target.value !== accountInfo.newPassword) {
      setPasswordMatchError(t("password_match_error"));
    } else {
      setPasswordMatchError(null);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: 2 }}>
      <Grid
        container
        spacing={{ xs: 0, md: 5 }}
        columns={{ xs: 4, sm: 4, md: 8 }}
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("email")}
            // value={accountInfo.email && accountInfo.email}
            value={userEmail}
            onChange={(e) =>
              setAccountInfo({ ...accountInfo, email: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldPasswordSection
            label={t("current_password")}
            value={accountInfo.currentPassword}
            onChange={(e) =>
              setAccountInfo({
                ...accountInfo,
                currentPassword: e.target.value,
              })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldPasswordSection
            label={t("new_password")}
            value={accountInfo.newPassword}
            onChange={(e) => {
              handleChangePassword(e);
              setAccountInfo({ ...accountInfo, newPassword: e.target.value });
            }}
          />
          {passwordError !== null && <PasswordRequirements />}
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldPasswordSection
            label={t("confirm_new_password")}
            value={accountInfo.confirmPassword}
            onChange={(e) => handleMatchPasswords(e)}
          />
          {passwordMatchError !== null && (
            <PasswordsMatchError passwordMatchError={passwordMatchError} />
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <PhoneInput
            // country={"us"}
            placeholder={t("primary_phone")}
            value={accountInfo.primaryPhone}
            onChange={(value) => {
              console.log("number::",value);
              setAccountInfo({ ...accountInfo, primaryPhone: value });
            }}
            containerStyle={{
              width: matchesSM ? "100%" : "80%",
              // width: 376,
              height: 60,
              borderBottom: "3px solid #515563",
              maringBottom: 30,
            }}
            inputStyle={phoneInputStyles}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <PhoneInput
            // country={"us"}
            placeholder={t("secondary_phone")}
            value={accountInfo.secondaryPhone}
            onChange={(value) =>
              setAccountInfo({ ...accountInfo, secondaryPhone: value })
            }
            containerStyle={{
              width: matchesSM ? "100%" : "80%",
              // width: 376,
              height: 60,
              borderBottom: "3px solid #515563",
              maringBottom: 30,
            }}
            inputStyle={phoneInputStyles}
          />
        </Grid>
        {/*Lanuage field commented out because we don't need it here, its already available at the top/Header*/}
        {/*
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              style={settingsStyles.textfeildBottomMargin}
            >
              <TextFieldSection
                label={t("language")}
                value={accountInfo.language}
                select={true}
                options={["English", "Spanish"]}
                onChange={(e) =>
                  setAccountInfo({ ...accountInfo, language: e.target.value })
                }
              />
            </Grid>
          */}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          {loading ? (
            <Grid
              container
              justifyContent="center"
              style={{ width: matchesSM ? "100%" : 300 }}
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              // onClick={() => {
              //   navigate("/verify-account");
              // }}
              onClick={handleUpdateAccountInfo}
              style={{
                width: matchesSM ? "100%" : 300,
                height: 54,
                borderRadius: 60,
                backgroundColor: "#19A6FE",
                color: "#000",
              }}
            >
              {t("update")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountInfoTab;

const phoneInputStyles = {
  height: 60,
  width: "100%",
  background: "#FFFAF5",
  border: "none",
  borderRadius: "10px 10px 0px 0px",
};
