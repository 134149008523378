import React, { useEffect, useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

import MainPageLayout from "../MainPageLayout";
import { useNavigate } from "react-router-dom";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import SettingsTabs from "../../components/settings/SettingsTabs";
import { settingsStyles } from "../../components/settings/styles/SettingsStyles";
import { useHealthRecordsContext } from "../../providers/HealthRecordsContext";
import {
  updateMember,
  updateMemberPassword,
} from "../../data/requests/settings";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { personalInfo, userContactInfo, handleGetHealthRecords, allStates } =
    useHealthRecordsContext();
  const { t } = useTranslation("translations");

  // let user = localStorage.getItem("user");
  let userEmail = localStorage.getItem("userEmail");

  // console.log("email:", userEmail);

  // console.log("Current user:", JSON.parse(user));

  const navigate = useNavigate();
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  // console.log("states:", allStates);
  // console.log(userContactInfo);
  // console.log(personalInfo);

  const [accountInfo, setAccountInfo] = useState({
    // email: "example@email.com",
    // currentPassword: "",
    // newPassword: "",
    // confirmPassword: "",
    // primaryPhone: "",
    // secondaryPhone: "",
    // language: "English",
  });

  const [personalInfoData, setPersonalInfoData] = useState({
    // firstName: "",
    // lastName: "",
    // dob: new Date(),
    // gender: "",
    // address: "",
    // city: "",
    // state: "",
    // zip: "",
  });

  const [newPharmacy, setNewPharmacy] = useState({
    fax: "",
    address: "",
    name: "",
    primaryPhone: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    let stateIdTemp;
    allStates &&
      allStates.forEach((state) => {
        if (state.abbreviation === userContactInfo?.stateAbbr) {
          // setPersonalInfoData({ ...personalInfoData, stateId: state.state_id });
          stateIdTemp = state.state_id;
        }
      });
    setAccountInfo({
      email: userEmail,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      primaryPhone: personalInfo[0]?.primaryPhone,
      secondaryPhone: personalInfo[0]?.secondaryPhone,
      language: "English",
    });

    setPersonalInfoData({
      firstName: personalInfo[0]?.firstName,
      lastName: personalInfo[0]?.lastName,
      dob: personalInfo[0]?.dob,
      gender: personalInfo[0]?.gender == "f" ? "Female" : "Male",
      address: userContactInfo?.address,
      city: userContactInfo?.city,
      state: userContactInfo?.stateAbbr,
      zip: userContactInfo?.zipCode,
      stateId: stateIdTemp,
    });
  }, [personalInfo, userContactInfo]);

  // useEffect(() => {
  //   allStates &&
  //     allStates.forEach((state) => {
  //       if (state.abbreviation === userContactInfo?.stateAbbr) {
  //         setPersonalInfoData({ ...personalInfoData, stateId: state.state_id });
  //       }
  //     });
  // }, [allStates]);

  // const getMuiFormateDate = (timestamp = { seconds: 0, nanoseconds: 0 }) => {

  //   const { seconds, nanoseconds } = timestamp || {};

  //   const fireBaseTime= new Date(seconds * 1000 + nanoseconds / 1000000);

  //   const date = fireBaseTime.toDateString();

  //   return moment(new Date(date)).format('MM/DD/YYYY')

  // }

  const handleUpdateAccountInfo = async () => {
    try {
      setLoading(true);
      let passowrdData = {
        oldPassword: accountInfo.currentPassword,
        password: accountInfo.newPassword,
        passwordVerify: accountInfo.confirmPassword,
      };
      let personalData = {
        email: accountInfo.email,
        primaryPhone: accountInfo.primaryPhone,
        secondaryPhone: accountInfo.secondaryPhone,
        // language: accountInfo.language,
        firstName: personalInfoData.firstName,
        lastName: personalInfoData.lastName,
        dob: moment(new Date(personalInfoData.dob)).format("MM/DD/YYYY"),
        timezone: 3,
        // timezoneId: 3,
        // state: personalInfoData.state,
        // stateId: personalInfoData.stateId,
        state: personalInfoData.stateId,
        gender: personalInfo[0]?.gender == "Female" ? "f" : "m",
        address: personalInfoData.address,
        address2: "",
        city: personalInfoData.city,
        zipCode: personalInfoData.zip,
        isInternationalPhone: false,
        // ...personalInfo[0],
      };
      // console.log("personalData:", personalData)
      let responseTemp = await updateMember(personalData);
      console.log("responseTemp:", responseTemp);
      if (accountInfo.newPassword) {
        let passwordResponseTemp = await updateMemberPassword(passowrdData);
        console.log("password responseTemp:", passwordResponseTemp);
        if (passwordResponseTemp.success == true) {
          console.log("password changed");
        } else {
          setShowAlert(true);
          console.log("error updating password");
        }
      }
      await handleGetHealthRecords();
    } catch (error) {
      console.log("error updating account info:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePersonalInfo = async () => {
    try {
      setLoading(true);
      let personalData = {
        email: userEmail,
        primaryPhone: accountInfo.primaryPhone,
        secondaryPhone: accountInfo.secondaryPhone,
        // language: accountInfo.language,
        firstName: personalInfoData.firstName,
        lastName: personalInfoData.lastName,
        dob: moment(new Date(personalInfoData.dob)).format("MM/DD/YYYY"),
        timezone: 3,
        // timezoneId: 3,
        // state: personalInfoData.state,
        // stateId: personalInfoData.stateId,
        state: personalInfoData.stateId,
        gender: personalInfo[0]?.gender == "Female" ? "f" : "m",
        address: personalInfoData.address,
        address2: userContactInfo?.address2,
        city: personalInfoData.city,
        zipCode: personalInfoData.zip,
        isInternationalPhone: false,
        // ...personalInfo[0],
      };
      console.log("personalData:", personalData);
      let responseTemp = await updateMember(personalData);
      console.log("responseTemp:", responseTemp);
      await handleGetHealthRecords();
    } catch (error) {
      console.log("error updating personal info:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="error">
            {t("your_current_password_is_incorrect")}
          </Alert>
        </Snackbar>
      )}
      <MainPageLayout>
        <div style={settingsStyles.mainTitle}>
          {" "}
          <ArrowBackIosOutlinedIcon
            style={settingsStyles.backIcon}
            onClick={() => navigate(-1)}
          />
          {t("settings")}
        </div>
        <SettingsTabs
          accountInfo={accountInfo}
          setAccountInfo={setAccountInfo}
          personalInfo={personalInfoData}
          setPersonalInfo={setPersonalInfoData}
          newPharmacy={newPharmacy}
          setNewPharmacy={setNewPharmacy}
          handleUpdateAccountInfo={handleUpdateAccountInfo}
          handleUpdatePersonalInfo={handleUpdatePersonalInfo}
          matchesXS={matchesXS}
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesLG={matchesLG}
          matchesXl={matchesXl}
          loading={loading}
        />
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default Settings;
