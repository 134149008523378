import { ErrorOutline } from "@mui/icons-material";
import { Box, Dialog, Typography, Stack } from "@mui/material";
import React from "react";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import DefaultButton from "../../consultations/DefaultButton";
import PropTypes from "prop-types";

const AlertDialog = (props) => {
  const {
    modalOpen,
    handleClose,
    title,
    description,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonAction,
    secondaryButtonAction,
    actionButtonsOrientation = "column",
    icon = (
      <ErrorOutline
        color="error"
        sx={{
          height: Dimens.WARNING_ICON_DIMENSION,
          width: Dimens.WARNING_ICON_DIMENSION,
          pt: 1,
        }}
      />
    ),
  } = props;

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          pt: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pb: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
          pl: {
            md: Dimens.CONSULTATION_MODEL_PADDING_HORIZANTAL,
            xs: Dimens.PADDING_TOP_SMALL,
          },
          pr: {
            md: Dimens.CONSULTATION_MODEL_PADDING_HORIZANTAL,
            xs: Dimens.PADDING_TOP_SMALL,
          },
          bgcolor: COLORS.TEXT_SECONDARY,
          textAlign: "center",
        }}
      >
        {icon}
        <Typography
          sx={{
            fontFamily: "Comfortaa",
            fontWeight: Dimens.FONT_WEIGHT_BOLD,
            fontSize: Dimens.FONT_SIZE_LARGE,
            lineHeight: Dimens.LINE_HEIGHT_LARGE,
            pt: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontWeight: Dimens.FONT_WEIGHT_LIGHT,
            fontSize: Dimens.FONT_SIZE_SMALL,
            lineHeight: Dimens.LINE_HEIGHT_SMALL,
            pt: 1,
          }}
        >
          {description}
        </Typography>
        <Stack
          direction={actionButtonsOrientation}
          justifyContent={"center"}
          alignItems="center"
          sx={{ pt: 3 }}
        >
          <DefaultButton onClick={primaryButtonAction}>
            {primaryButtonText}
          </DefaultButton>
          {secondaryButtonText && (
            <DefaultButton
              variant="outlined"
              sx={{
                ml: actionButtonsOrientation === "row" ? 1 : 0,
                mt: actionButtonsOrientation === "row" ? 0 : 1,
                color: COLORS.SECONDARY_MAIN,
              }}
              onClick={secondaryButtonAction}
            >
              {secondaryButtonText}
            </DefaultButton>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

AlertDialog.protoTypes = {
  modalOpen: PropTypes.boolean,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  secondaryButtonAction: PropTypes.func,
  actionButtonsOrientation: PropTypes.string,
  icon: PropTypes.node,
};

export default AlertDialog;
