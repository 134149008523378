import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { images } from "../../constant";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { loginCardStyles } from "./styles/LoginCardStyles";
import ButtonLoader from "../ButtonLoader";

const ResetPasswordCard = ({
  email,
  setEmail,
  emailError,
  handleResetPassword,
  loading,
  emailsent,
  emailResent,
  handleResendEmail,
  handleDoneEmailChecked,
}) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ ...loginCardStyles.cardStyle, width: matchesSM ? "94%" : 575 }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src={images.logoIcon}
            alt="Logo"
            style={loginCardStyles.imageStyle}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{ padding: 100, marginTop: 90 }}
          // style={{
          //   padding: matchesSM ? "70px 40px 40px 40px" : "50px 70px 70px 70px",
          //   marginTop: matchesSM ? 50 : 90,
          // }}
        >
          <form style={loginCardStyles.formStyle} noValidate>
            <span style={loginCardStyles.titleTextStyles}>
              {emailResent
                ? "Email Resent"
                : emailsent
                ? "Check your email"
                : "Reset Password"}
            </span>
            <span style={loginCardStyles.messageTextStyles}>
              {emailsent
                ? "Please check your inbox and follow the instructions to reset your password. "
                : " Enter your registered email to receive a one-time link to reset your password."}
            </span>

            {/* Email field  */}
            {/*
          <Grid
            style={{
              border: "1px solid #FFB74D",
              borderBottom: "none",
              borderRadius: "10px 10px 0px 0px",
            }}
            container
          >
        */}
            {emailsent ? (
              <img
                src={images.emailSent}
                alt="email sent"
                style={{ width: 160, height: 160 }}
              />
            ) : (
              <TextField
                label="Email Address"
                variant="filled"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError !== null ? true : false}
                helperText={emailError}
                sx={loginCardStyles.textFieldStyle}
                InputLabelProps={{
                  style: {
                    color: "#515563",
                  },
                }}
                InputProps={{
                  sx: loginCardStyles.inputPropsStyles,
                }}
              />
            )}
            {/*
            </Grid>
            */}

            {/* Register Button area  */}
            {loading ? (
              <Grid style={{ marginTop: 65 }}>
                <ButtonLoader />
              </Grid>
            ) : emailsent ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={loginCardStyles.buttonStyle}
                onClick={handleDoneEmailChecked}
              >
                DONE
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={email ? false : true}
                style={
                  email
                    ? loginCardStyles.buttonStyle
                    : loginCardStyles.buttonDisabledStyle
                }
                onClick={handleResetPassword}
              >
                Continue
              </Button>
            )}

            {/* resend link area  */}
            {emailsent && (
              <span
                style={{ ...loginCardStyles.needHelpTextStyles, marginTop: 40 }}
              >
                Didn’t receive the link?{" "}
                <Button
                  style={loginCardStyles.resendButtonStyles}
                  onClick={handleResendEmail}
                >
                  Resend link
                </Button>
              </span>
            )}
            {/* Need help area  */}
            <span
              style={{
                ...loginCardStyles.needHelpTextStyles,
                marginTop: emailsent ? 35 : 140,
              }}
            >
              Need Help?{" "}
              <Button style={loginCardStyles.resetPasswordStyles}>
                Contact Us
              </Button>
            </span>
          </form>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default ResetPasswordCard;
