import { Box, Grid, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import MainPageLayout from "../../MainPageLayout";
import { useNavigate } from "react-router-dom";
import {
  DefaultDropdown,
  Header,
  // PhoneInput,
  StyledBodyText,
  StyledCaptionText,
  TimeRangePicker,
  DatePicker,
} from "../../../components/common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { CallTypeCard } from "../../../components/consultations";
import { calender, clock } from "../../../assets/icons";
import { Warning } from "@mui/icons-material";
import Dimens from "../../../assets/Dimens";
import { COLORS } from "../../../assets/colors";
import DefaultButton from "../../../components/consultations/DefaultButton";
import Applinks from "../../../assets/route-links";
import useUrgentCare from "../../../providers/urgent-care.provider";
// import { onlyNumbers } from "../../../utils/validations";
// import moment from "moment";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useHealthRecordsContext } from "../../../providers/HealthRecordsContext";

// const stateTimeZones = {
//   Alabama: "America/Chicago",
//   Alaska: "America/Anchorage",
//   "American Samoa": "Pacific/Pago_Pago",
//   Arizona: "America/Phoenix",
//   Arkansas: "America/Chicago",
//   "Armed Forces Americas": "America/New_York",
//   "Armed Forces Non-Americas": "UTC",
//   "Armed Forces Pacific": "Pacific/Honolulu",
//   California: "America/Los_Angeles",
//   Colorado: "America/Denver",
//   Connecticut: "America/New_York",
//   Delaware: "America/New_York",
//   "District of Columbia": "America/New_York",
//   "Federated States of Micronesia": "Pacific/Chuuk",
//   Florida: "America/New_York",
//   Georgia: "America/New_York",
//   Guam: "Pacific/Guam",
//   Hawaii: "Pacific/Honolulu",
//   Idaho: "America/Denver",
//   Illinois: "America/Chicago",
//   Indiana: "America/Indianapolis",
//   Iowa: "America/Chicago",
//   Kansas: "America/Chicago",
//   Kentucky: "America/New_York",
//   Louisiana: "America/Chicago",
//   Maine: "America/New_York",
//   "Marshall Islands": "Pacific/Majuro",
//   Maryland: "America/New_York",
//   Massachusetts: "America/New_York",
//   Michigan: "America/Detroit",
//   Minnesota: "America/Chicago",
//   Mississippi: "America/Chicago",
//   Missouri: "America/Chicago",
//   Montana: "America/Denver",
//   Nebraska: "America/Chicago",
//   Nevada: "America/Los_Angeles",
//   "New Hampshire": "America/New_York",
//   "New Jersey": "America/New_York",
//   "New Mexico": "America/Denver",
//   "New York": "America/New_York",
//   "North Carolina": "America/New_York",
//   "North Dakota": "America/Chicago",
//   "Northern Mariana Islands": "Pacific/Saipan",
//   Ohio: "America/New_York",
//   Oklahoma: "America/Chicago",
//   Oregon: "America/Los_Angeles",
//   Palau: "Pacific/Palau",
//   Pennsylvania: "America/New_York",
//   "Puerto Rico": "America/Puerto_Rico",
//   "Rhode Island": "America/New_York",
//   "South Carolina": "America/New_York",
//   "South Dakota": "America/Chicago",
//   Tennessee: "America/Chicago",
//   Texas: "America/Chicago",
//   Utah: "America/Denver",
//   Vermont: "America/New_York",
//   "Virgin Islands": "America/St_Thomas",
//   Virginia: "America/New_York",
//   Washington: "America/Los_Angeles",
//   "West Virginia": "America/New_York",
//   Wisconsin: "America/Chicago",
//   Wyoming: "America/Denver",
// };

const stateTimeZones = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AS: "Pacific/Pago_Pago",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  AA: "America/New_York",
  AE: "UTC",
  AP: "Pacific/Honolulu",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  DC: "America/New_York",
  FM: "Pacific/Chuuk",
  FL: "America/New_York",
  GA: "America/New_York",
  GU: "Pacific/Guam",
  HI: "Pacific/Honolulu",
  ID: "America/Denver",
  IL: "America/Chicago",
  IN: "America/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MH: "Pacific/Majuro",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  MP: "Pacific/Saipan",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PW: "Pacific/Palau",
  PA: "America/New_York",
  PR: "America/Puerto_Rico",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VI: "America/St_Thomas",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
};

const UrgentCareDateTime = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const { urgentCarePayload, setUrgentCarePayload } = useUrgentCare();
  const { personalInfo } = useHealthRecordsContext();
  const [calenderConsultation, setCalenderConsultation] = useState(false);

  const [timeRange, setTimeRange] = useState(null);
  const [date, setDate] = useState(new Date());
  const [existingDate, setExistingDate] = useState(null);
  // const [timeRange, setTimeRange] = useState(new Date());
  // const [timezoneOffset, setTimezoneOffset] = useState(null);
  const [disabled, setDisabled] = useState(true);

  // console.log("payload::", urgentCarePayload);
  // console.log("user::", personalInfo);

  useEffect(() => {
    if (calenderConsultation && urgentCarePayload.phoneNumber && date) {
      setDisabled(false);
    } else if (!calenderConsultation && urgentCarePayload.phoneNumber) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [calenderConsultation, timeRange, date, urgentCarePayload]);

  // console.log(
  //   moment
  //     .tz(
  //       urgentCarePayload.whenScheduled,
  //       stateTimeZones[urgentCarePayload?.state]
  //     )
  //     .format("DD/MM/YYYY hh:mm a")
  // );

  useEffect(() => {
    setUrgentCarePayload({
      ...urgentCarePayload,
      phoneNumber: personalInfo[0]?.primaryPhone,
    });
    if (urgentCarePayload.whenScheduled === "now") {
      setCalenderConsultation(false);
    } else {
      setCalenderConsultation(true);
      if (urgentCarePayload.whenScheduled !== "now") {
        setExistingDate(urgentCarePayload.whenScheduled);
      }
      // if (urgentCarePayload.whenScheduled !== "now") {
      //   const date = new Date(urgentCarePayload.whenScheduled);
      //   setDate(urgentCarePayload.whenScheduled ? date : new Date());
      //   setTimeRange(urgentCarePayload.whenScheduled ? urgentCarePayload?.whenScheduled : null);
      //   // setTimeRange(moment(date).format("hh:mm A"));
      //   // setDate(date);
      //   // setTimeRange(urgentCarePayload.whenScheduled ? moment(date).format("hh:mm A") : null);
      //   // setTimeRange(
      //   //   urgentCarePayload.whenScheduled
      //   //     ? moment
      //   //         .tz(
      //   //           urgentCarePayload?.whenScheduled,
      //   //           stateTimeZones[urgentCarePayload?.state]
      //   //         )
      //   //         .format("hh:mm A")
      //   //     : null
      //   // );
      // }
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    if (disabled) return;
    if (!calenderConsultation) {
      setUrgentCarePayload({
        ...urgentCarePayload,
        whenScheduled: "now",
      });
    } else {
      // Get the time zone for the selected state
      const userTimeZone = stateTimeZones[urgentCarePayload.state];

      // Extract hours and minutes from the timeRange
      const [hours, minutes] = moment(timeRange, "hh:mm A")
        .format("HH:mm")
        .split(":");

      // Convert the selected date and time to the user's time zone
      const whenScheduled = moment
        .tz(
          `${moment(date).format("YYYY-MM-DD")} ${hours}:${minutes}`,
          userTimeZone
        )
        .toISOString();

      // Calculate the timezoneOffset
      const timezoneOffset = moment.tz(userTimeZone).utcOffset() / 60;
      if (timeRange === null) {
      } else {
        setUrgentCarePayload({
          ...urgentCarePayload,
          whenScheduled,
          timezoneOffset,
        });
      }
    }
    navigate(Applinks.SCHEDULE_PHARMACY_DETAIL);
    // if (disabled) return;
    // if (!calenderConsultation) {
    //   setUrgentCarePayload({
    //     ...urgentCarePayload,
    //     whenScheduled: "now",
    //   });
    // } else {
    //   setUrgentCarePayload({
    //     ...urgentCarePayload,
    //     whenScheduled: `${moment(date).format("YYYY-MM-DD")} ${moment(
    //       timeRange
    //     ).format("hh:mm a")}`,
    //     timezoneOffset: date.getTimezoneOffset() / 60,
    //   });
    // }
    // navigate(Applinks.SCHEDULE_PHARMACY_DETAIL);
  };

  // const handlePhoneChange = (event) => {
  //   setUrgentCarePayload({
  //     ...urgentCarePayload,
  //     phoneNumber: event.target.value,
  //   });
  // };

  const handleLanguageChange = (event) => {
    setUrgentCarePayload({
      ...urgentCarePayload,
      translate: event.target.value === "English" ? "" : event.target.value,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <Box sx={{ width: "100%" }}>
          <Header
            handleGoBack={handleGoBack}
            title={t("consultation_schedule_time")}
            subTitle={t("consultation_schedule_time_info")}
          />
          <StyledBodyText sx={{ mt: 4 }}>
            {t("choose_an_option")}
          </StyledBodyText>
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
            spacing={2}
          >
            <CallTypeCard
              icon={<img src={clock} alt="Clock" />}
              title={t("consultation_within_two_hrs")}
              checked={!calenderConsultation}
              onChange={() => setCalenderConsultation((prev) => !prev)}
            />
            <CallTypeCard
              icon={<img src={calender} alt="Calender" />}
              title={t("choose_day_from_calendar")}
              checked={calenderConsultation}
              onChange={() => setCalenderConsultation((prev) => !prev)}
            />
          </Stack>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              height: Dimens.EHR_CARD_HEIGHT,
              bgcolor: COLORS.TEXT_SECONDARY,
              borderRadius: Dimens.BORDER_RADIUS_LARGE,
              pl: 1,
              pr: 1,
            }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              sx={{ height: "100%" }}
            >
              <Warning />
              <StyledCaptionText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  fontWeight: Dimens.FONT_WEIGHT_LIGHT,
                  ml: 1,
                }}
              >
                {t("consultation_within_two_hrs_info")}
              </StyledCaptionText>
            </Stack>
          </Box>
          {calenderConsultation && (
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12} md={5}>
                <StyledBodyText>{t("select_a_time_range")}</StyledBodyText>
                <TimeRangePicker
                  value={timeRange}
                  handleSubmit={setTimeRange}
                  // urgentCarePayload={urgentCarePayload}
                  existingDate={existingDate}
                  setExistingDate={setExistingDate}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <StyledBodyText>{t("select_a_date")}</StyledBodyText>
                <DatePicker value={date} handleSubmit={setDate} />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              mt: 5,
              bgcolor: COLORS.PRIMARY_MAIN,
              borderRadius: Dimens.BORDER_RADIUS_LARGE,
              pl: 2,
              pr: 2,
              pt: 2,
              pb: 2,
            }}
          >
            <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mb: 1 }}>
              {t("what_language_sould_physician_speak")}
            </StyledBodyText>
            <DefaultDropdown
              showOutline
              textFieldSize="medium"
              placeholder={t("choose_an_option")}
              options={["English", "Spanish"]}
              value={
                urgentCarePayload.translate === "Spanish"
                  ? "Spanish"
                  : "English"
              }
              renderValue={(option) => (
                <MenuItem
                  value={option}
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    // bgcolor: option === value ? "#FFB74D" : "#fff",
                    "&:hover": {
                      bgcolor: "#FFB74D",
                    },
                  }}
                >
                  {option}
                </MenuItem>
              )}
              onChange={handleLanguageChange}
            />
          </Box>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 4 }}>
            {t("confirm_contact_method")}
          </StyledBodyText>
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, mt: 1.5 }}>
            {t("confirm_contact_method_info")}
          </StyledCaptionText>
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, mt: 1 }}>
            {t("confirm_contact_method_info_two")}
          </StyledCaptionText>
          <PhoneInput
            // country={"us"}
            // placeholder={t("primary_phone")}
            value={
              urgentCarePayload?.phoneNumber
                ? urgentCarePayload?.phoneNumber
                : personalInfo[0]?.primaryPhone
            }
            onChange={(value) =>
              setUrgentCarePayload({
                ...urgentCarePayload,
                phoneNumber: value,
              })
            }
            containerStyle={{
              width: matchesSM ? "100%" : 376,
              height: 60,
              borderBottom: "3px solid #515563",
              maringBottom: 30,
            }}
            inputStyle={phoneInputStyles}
          />

          <Stack alignItems={"center"}>
            <DefaultButton
              sx={{ mt: 6 }}
              onClick={handleContinue}
              disabled={disabled}
            >
              {t("continue")}
            </DefaultButton>
          </Stack>
        </Box>
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default UrgentCareDateTime;

const phoneInputStyles = {
  height: 60,
  width: "100%",
  background: "#FFFAF5",
  border: "none",
  borderRadius: "10px 10px 0px 0px",
};
