import { Box, Card, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Dimens from "../../assets/Dimens";
// import { people } from '../../assets/images/index'
import PriceLabel from "./PriceLabel";
import PropTypes from "prop-types";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const ScheduleDoctorItem = (props) => {
  const {
    physicianName,
    spaciality,
    availibility,
    price,
    image,
    hiddenItem,
    selected,
  } = props;
  const [seeMore, setSeeMore] = useState(selected ? true : false);

  const HandleShowDetails = () => {
    setSeeMore((prev) => !prev);
  };

  console.log("image url::", image)

  return (
    <Card
      elevation={1}
      sx={{
        minHeight: Dimens.CONSULTATION_LIST_ITEM_HEIGHT,
        mb: 1,
        borderRadius: Dimens.CONSULTATION_LIST_ITEM_BORDER_RADIUS,
        pl: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
        pr: Dimens.CONSULTATION_LIST_ITEM_PADDING_HORIZANTAL,
      }}
    >
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent={"space-between"}
        alignItems="center"
        sx={{
          minHeight: Dimens.CONSULTATION_LIST_ITEM_HEIGHT, pt: { xs: 2, md: 0 },
          pb: { xs: 2, md: 0 },
        }}
      >

        <Stack direction="row">

          <img
            src={image}
            // src={`${process.env.REACT_APP_VIRTUAL_CARE_GROUP + row?.bioImagePath}`}
            height={Dimens.CONSULTATION_LIST_ITEM_IMAGE_HEIGHT}
            width={Dimens.CONSULTATION_LIST_ITEM_IMAGE_HEIGHT}

          />


          <Box sx={{ ml: { md: 1, xs: 2 } }}>
            <Typography>{physicianName}</Typography>
            <Typography variant={"subtitle2"}>{spaciality}</Typography>
            <Typography
              variant={"subtitle2"}
              sx={{
                fontSize: Dimens.FONT_SIZE_SMALLER,
                fontWeight: Dimens.FONT_WEIGHT_NORMAL,
              }}
            >
              {availibility}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ mt: { xs: 2, md: 0 }, alignSelf: { xs: "flex-end", md: "center" } }}>
          <PriceLabel title={price} />
          <Stack
            direction="row"
            alignItems="center"
            onClick={HandleShowDetails}
            sx={{ cursor: "pointer", mt: 2 }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              View Details{" "}
            </Typography>
            {seeMore ? <ArrowDropUp /> : <ArrowDropDown />}
          </Stack>
        </Box>
      </Stack>
      {seeMore && hiddenItem}
    </Card >
  );
};

ScheduleDoctorItem.propTypes = {
  physicianName: PropTypes.string.isRequired,
  spaciality: PropTypes.string.isRequired,
  availibility: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  image: PropTypes.string,
  hiddenItem: PropTypes.node,
  selected: PropTypes.bool,
};

export default ScheduleDoctorItem;
