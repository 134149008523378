import { Box, Card, Stack, Switch } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Dimens from "../../../assets/Dimens";
import { COLORS } from "../../../assets/colors";
import { StyledBodyText } from "../../common";

const CallTypeCard = (props) => {
  const { icon, title, onChange, cardSx, checked = false } = props;
  return (
    <Card
      sx={{
        width: {md: Dimens.CALL_TYPE_CARD_WIDTH, xs: Dimens.CALL_TYPE_CARD_WIDTH_MOBILE},
        height: Dimens.CALL_TYPE_CARD_HEIGHT,
        borderRadius: Dimens.CONSULTATION_LIST_ITEM_BORDER_RADIUS,
        ...cardSx,
      }}
    >
      <Stack
        direction={"column"}
        justifyContent="flex-end"
        alignItems="space-between"
        sx={{ height: "100%" }}
      >
        <Stack
          direction={{md: "row", xs: 'column'}}
          sx={{
            pt: {md: 0, xs: 1},
            pl: Dimens.PADDING_TOP_MEDIUM,
            pr: Dimens.PADDING_TOP_MEDIUM,
            pb: 1,
          }}
          alignItems={{md: "center", xs: "flex-start"}}
          justifyContent={"space-between"}
        >
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: Dimens.ICON_BOX_DIMENSION,
                height: Dimens.ICON_BOX_DIMENSION,
                bgcolor: COLORS.PRIMARY_LIGHT,
                borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
              }}
            >
              {icon}
            </Box>
            <StyledBodyText sx={{ ml: 1.5 }}>{title}</StyledBodyText>
          </Stack>
          <Switch sx={{alignSelf: {xs: "flex-end"}}} checked={checked} onChange={onChange} />
        </Stack>
        <Box
          sx={{
            bgcolor: COLORS.PRIMARY_MAIN,
            height: Dimens.BOX_LABEL_HEIGHT,
            width: "100%",
          }}
        />
      </Stack>
    </Card>
  );
};

CallTypeCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  onChange: PropTypes.func,
  cardSx: PropTypes.object,
  checked: PropTypes.bool,
};

export default CallTypeCard;
