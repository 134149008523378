import { LocationOn } from "@mui/icons-material";
import {
  Box,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import { marker } from "../../../assets/icons";
import Applinks from "../../../assets/route-links";
import { Header, Map, PharmacyDropdown, StyledBodyText } from "../../../components/common";
import DefaultButton from "../../../components/consultations/DefaultButton";
import { searchPharmacies } from "../../../data/requests/pharmacy";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import MainPageLayout from "../../MainPageLayout";
import { useTranslation } from "react-i18next";

const AnyReactComponent = ({ text }) => (
  <div>
    {text}
    <img src={marker} height="50px" alt="map marker" />
  </div>
);

const PsychiatristPharmacyDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const [query, setQuery] = useState("");
  const { patientConsultData, pharmacy, setPharmacy } = usePsychiatrist();

  const { patient } = patientConsultData;
  const [pharmacies, setPharmacies] = useState([]);
  const [currentPharmacy, setCurrentPharmacy] = useState(
    pharmacy || patient?.pharmacy
  );
  const [otherPharmacy, setOtherPharmacy] = useState(null);
  const [loading, setLoading] = useState(false);
  const defaultCenter = { lat: 37.09024, lng: -95.712891 };
  const defaultZoom = 4; // Default zoom level

  // State for the map's center
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  // State for the map's zoom level
  const [mapZoom, setMapZoom] = useState(defaultZoom);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      handleSearchPharmacies();
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [query, 500]);

  const handleSearchPharmacies = () => {
    setLoading(true);
    // Determine if the query is a zipcode or a state for appropriate zoom level
    /////////
    // const isZipcode = query.length === 5 && /^\d+$/.test(query); // Basic US zipcode check
    // const zoomLevel = isZipcode ? 15 : 7; // Example zoom levels
    ////////
    getGeocode(query)
      .then((result) => {
        if (result) {
          const { lat, lng } = result;
          ////////
          // setMapCenter({ lat, lng });
          ////////

          // setMapZoom(isZipcode ? 15 : 7); // Adjust zoom level based on whether the query is a zipcode
        } else {
          // Handle the case where geocoding didn't return a result
          console.error("Geocoding failed, no result returned.");
          // Optionally, set the map center to a default location or show an error message to the user
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the geocoding process
        console.error("Error during geocoding:", error);
      });

    searchPharmacies(query)
      .then((res) => {
        console.log("pharmacies::", res.list);
        setPharmacies(res.list);
        // Update map center and zoom
        // setMapCenter({ lat, lng });
        ////////
        // setMapZoom(zoomLevel);
        ////////
      })
      .catch((err) => {
        setPharmacies([]);
        // Optionally handle errors, e.g., invalid location queries
      })
      .finally(() => setLoading(false));
    // setLoading(true);
    // searchPharmacies(query)
    //   .then((res) => {
    //     setPharmacies(res.list);
    //   })
    //   .catch((err) => {
    //     setPharmacies([]);
    //   })
    //   .finally(() => setLoading(false));
  };

  const getGeocode = async (query) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      query
    )}&key=${process.env.GOOGLE_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(
          "Geocoding failed with status:",
          data.status,
          "and error message:",
          data.error_message
        );
        return null; // Or handle differently based on your application's needs
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error; // Or handle it according to your app's error handling strategy
    }
  };

  const handleContinue = () => {
    navigate(Applinks.SCHEDULE_PSYCHIATRIST_PAYMENT);
  };

  const handleAddPharmacy = (option) => {
    setOtherPharmacy(option);
  };

  const handleDefaultClick = () => {
    setCurrentPharmacy({ ...otherPharmacy, state: "" });
    setPharmacy(otherPharmacy);
    // find state by stateId in other pharmacy and show state name
    setOtherPharmacy(null);
  };

  return (
    <MainPageLayout>
      <Box sx={{ width: "100%" }}>
        <Header
          title={t("pharmacy_details")}
          subTitle={t("pharmacy_details_info")}
          handleGoBack={() => {
            navigate(-1);
          }}
        />
        <StyledBodyText sx={{ mt: 4 }}>{t("current_pharmacy")}</StyledBodyText>
        
          <Stack direction="row" sx={{ mt: 2,
            width: "90%",
            minHeight: Dimens.EHR_CARD_HEIGHT,
            bgcolor: COLORS.TEXT_SECONDARY,
            borderRadius: Dimens.BORDER_RADIUS_LARGE,
            pl: 3,
            pr: 1, }} alignItems="center">
            <LocationOn />
            <Stack sx={{ ml: 1.5 }}>
              <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                {currentPharmacy?.address}
              </StyledBodyText>
              <StyledBodyText
                sx={{
                  fontSize: Dimens.FONT_SIZE_SMALL,
                  fontWeight: Dimens.FONT_WEIGHT_LIGHT,
                }}
              >
                {`${currentPharmacy.zipCode} ${currentPharmacy.address}`}
                {`${currentPharmacy.city}, ${currentPharmacy.state || ""}`}
              </StyledBodyText>
            </Stack>
          </Stack>
        <StyledBodyText sx={{ mt: 4 }}>{t("other_pharmacies")}</StyledBodyText>
        {otherPharmacy && (
            <Stack
              direction={{md: "row", xs: 'column'}}
              sx={{ mt: 2,
              width: "90%",
              minHeight: Dimens.EHR_CARD_HEIGHT,
              bgcolor: COLORS.CARD_SECONDARY,
              borderRadius: Dimens.BORDER_RADIUS_LARGE,
              pb: {xs: 2, md: 0}, 
              pt: {xs: 2, md: 0},
              pl: 3,
              pr: 1, }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ ml: 1.5 }}>
                <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
                  {otherPharmacy.storeName}
                </StyledBodyText>
                <StyledBodyText
                  sx={{
                    fontSize: Dimens.FONT_SIZE_SMALL,
                    fontWeight: Dimens.FONT_WEIGHT_LIGHT,
                  }}
                >
                  {`${otherPharmacy.data} ${otherPharmacy.address}`}
                  {`${otherPharmacy.city}, ${otherPharmacy.stateName}`}
                </StyledBodyText>
              </Stack>
              <DefaultButton
                sx={{ bgcolor: COLORS.BUTTON_SECONDARY, alignSelf: {xs: "flex-end", md: "center"} }}
                onClick={handleDefaultClick}
              >
                {t("make_default")}
              </DefaultButton>
            </Stack>
        )}
        <StyledBodyText sx={{ mt: 4 }}>{t("current_pharmacy")}</StyledBodyText>
        <Stack
          direction={{ md: "row", xs: "column" }}
          sx={{ mt: 1 }}
          spacing={2}
        >
          <Box sx={{ width: {md: "50%", xs: "100%"} }}>
            <PharmacyDropdown 
            pharmacies={pharmacies} 
            loading={loading} 
            handleInputChange={(e) => setQuery(e.target.value)} 
            handleAddPharmacy={(option) => handleAddPharmacy(option)} 
            />
          </Box>
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              border: `1px solid ${COLORS.DISABLED}`,
              height: "475px",
              borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
            }}
          >
            <Map center={mapCenter} zoom={mapZoom}>
              {pharmacies.length > 0 &&
                pharmacies.map((el) => (
                  <AnyReactComponent
                    lat={el.latitude}
                    lng={el.longitude}
                    text={el?.storeName}
                  />
                ))}
            </Map>
          </Box>
        </Stack>
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <DefaultButton onClick={handleContinue}>
            {t("continue")}
          </DefaultButton>
        </Stack>
      </Box>
    </MainPageLayout>
  );
};

export default PsychiatristPharmacyDetails;
