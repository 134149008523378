import { Card, Stack } from "@mui/material";
import React from "react";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
import { healthform } from "../../../assets/icons";
import { StyledCaptionText } from "../../common";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const MemberConsentCard = (props) => {
  const {onClick} = props
  const { t } = useTranslation("translations");
  return (
    <Card onClick={onClick} sx={{ mt: 2, borderRadius: Dimens.BORDER_RADIUS_LARGE, cursor: 'pointer' }}>
      <Stack direction="row" alignItems="center" sx={{ p: 2 }}>
        <img src={healthform} alt="Health form" />
        <StyledCaptionText
          sx={{
            fontSize: Dimens.FONT_SIZE_SMALL,
            fontWeight: Dimens.FONT_WEIGHT_BOLDER,
            textDecoration: "underline",
            color: COLORS.PRIMARY_DARK,
          }}
        >
          {t("click_to_view_informed_member_consent_full_version")}
        </StyledCaptionText>
      </Stack>
    </Card>
  );
};

MemberConsentCard.propTypes = {
  onClick: PropTypes.func,
};

export default MemberConsentCard;
