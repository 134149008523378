import React, { Fragment, useState, useRef, useEffect } from "react";
import { Divider, Grid, TextField, IconButton } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { messagingStyles } from "./styles/messagingStyles";
import DoctorDetailsModal from "./DoctorDetailsModal";
import moment from "moment";
import ButtonLoader from "../ButtonLoader";
import { useTranslation } from "react-i18next";
import useAuth from "../../providers/auth.provider";
import { getAttachments } from "../../data/requests/messageCenter";

const Chat = ({
  message,
  specificMessage,
  matchesSM,
  matchesXS,
  handleSendMessage,
  newMessage,
  setNewMessage,
  uploadedFile,
  setUploadedFile,
  loading,
}) => {
  const { user } = useAuth();
  const fileInputRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const { t } = useTranslation("translations");

  const [doctorDetailsModal, setDoctorDetailsModal] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (scrollbarsRef.current) {
      // Scroll to the bottom when the component is mounted
      scrollbarsRef.current.scrollToBottom();
    }
  }, [message]);

  useEffect(() => {
    setAttachments([]);
    handleGetPreview();
  }, [handleSendMessage]);

  const handleDoctorDetailsModalOpen = () => {
    setDoctorDetailsModal(true);
  };
  const handleDoctorDetailsModalClose = () => {
    setDoctorDetailsModal(false);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleGetPreview = async () => {
    try {
      let attachmentsTemp = [...message.attachments];
      let temp = [];
      attachmentsTemp.forEach(async (attachment) => {
        let responseTemp = await getAttachments(
          attachment.userAttachment_id,
          "attachment",
          user.userId
        );
        const imageUrl = URL.createObjectURL(responseTemp.data);
        console.log("image:", imageUrl);
        temp.push({ fileName: attachment.fileName, imageUrl: imageUrl });
        setAttachments(temp);
      });
    } catch (error) {
      console.log("error getting preview:", error.message);
    }
  };

  // console.log("specific message:", specificMessage);
  // console.log("messages:", message);
  // console.log("user:", user);
  console.log("attachments:", attachments);

  return (
    <Fragment>
      {/* Header/Top Section */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={messagingStyles.chatCard}
      >
        {/**/}
        {/*
      <div style={messagingStyles.chatCardDoctor}>{doctor}</div>
      <div style={messagingStyles.chatCardDoctor}>{t("dr_name")}</div>
    */}
        <div style={messagingStyles.chatCardDoctor}></div>
        <div>
          {/* More Icon */}
          {/*
          <MoreVertOutlinedIcon
          onClick={handleDoctorDetailsModalOpen}
          style={{ cursor: "pointer" }}
          />
        */}
          <AttachFileIcon
            onClick={handleDoctorDetailsModalOpen}
            style={{ cursor: "pointer" }}
          />
        </div>
      </Grid>

      {/* Divider */}
      <Divider style={messagingStyles.chatCardDivider} />

      {/* Date */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={messagingStyles.chatCardDate}
      >
        {/*
      {datetime}
    */}
        <span style={{ textTransform: "capitalize" }}>
          {specificMessage &&
            specificMessage.whenCreated &&
            moment(specificMessage.whenCreated).format("MMM DD, h:mm a")}
        </span>
      </Grid>

      <Grid
        container
        direction={"column"}
        justifyContent="flex-end"
        // style={{ height: "100%" }}
        // alignItems="flex-end"
      >
        <Scrollbars
          style={messagingStyles.scrollbar}
          autoHide={true}
          ref={scrollbarsRef} // Assign the ref to Scrollbars instance
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...messagingStyles.scrollbarprops,
              }}
            />
          )}
        >
          {/*  question/You" Card start */}
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
              width: matchesSM ? "100%" : "65%",
              ...messagingStyles.chatYouCard,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: 11 }}
            >
              <div style={{ fontWeight: 700 }}>{t("you")}</div>
              <div style={{ fontWeight: 400 }}>
                {/*
      {new Date(datetime).toLocaleTimeString()}
    */}
                {specificMessage &&
                  specificMessage.lastUpdatedDate &&
                  moment(specificMessage.lastUpdatedDate).format("h:mm a")}
              </div>
            </Grid>
            {/*
             */}
            <div style={messagingStyles.chatCardQuestion}>
              {specificMessage &&
                specificMessage.subject &&
                specificMessage.subject}
            </div>
            <div style={messagingStyles.chatCardQuestionDetails}>
              {specificMessage &&
                specificMessage.details &&
                specificMessage.details}
            </div>
          </Grid>
          {/*  question/You" Card end */}

          {/* Read Flag start */}
          {/*
        {specificMessage &&
          specificMessage.whenRead &&
          specificMessage.whenRead !== "" ? (
            <div style={messagingStyles.chatCardReadFlag}>Seen</div>
            ) : (
              <div style={messagingStyles.chatCardReadFlag}>Unseen</div>
              )}
        */}

          {/* Read Flag end */}

          {/* Messages/chats section start */}
          {
            // message.thread.reverse().map((msg) => (
            message &&
              message.thread &&
              message.thread
                .sort(
                  (a, b) => new Date(a.whenCreated) - new Date(b.whenCreated)
                )
                .map((msg, index) =>
                  index === 0 ? undefined : (
                    <Grid
                      key={msg.id}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{
                        width: matchesSM ? "100%" : "65%",
                        ...(msg.whoCreated === user.userId
                          ? messagingStyles.chatYouCard
                          : messagingStyles.chatDoctorCard),
                        // ...(msg.subject == "You"
                        //   ? messagingStyles.chatYouCard
                        //   : messagingStyles.chatDoctorCard),
                        // ...(msg.whoCreatedRoleName == "member"
                        //   ? messagingStyles.chatYouCard
                        //   : messagingStyles.chatDoctorCard),
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ marginBottom: 11 }}
                      >
                        <div style={messagingStyles.chatCardSender}>
                          {msg.subject && msg.subject}
                        </div>
                        <div style={messagingStyles.chatSenderMessageDate}>
                          {msg.whenCreated &&
                            moment(msg.whenCreated).format("hh:mm a")}
                        </div>
                      </Grid>
                      <div style={messagingStyles.chatSenderMessage}>
                        {msg.details && msg.details}
                      </div>
                    </Grid>
                  )
                )
          }
          {/* Messages/chats section end */}
        </Scrollbars>
      </Grid>

      <Grid container style={messagingStyles.chatTextfieldCard}>
        <TextField
          fullWidth
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          sx={{ border: "1px solid #FFB74D" }}
          InputProps={{
            endAdornment: (
              <Fragment>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*, application/pdf"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
                <label htmlFor="upload-input">
                  <IconButton component="span" onClick={handleFileUploadClick}>
                    <AttachFileIcon />
                  </IconButton>
                </label>
                <Divider orientation="vertical" flexItem sx={{ height: 36 }} />
                {loading ? (
                  <ButtonLoader />
                ) : (
                  <IconButton onClick={handleSendMessage}>
                    <SendIcon />
                  </IconButton>
                )}
              </Fragment>
            ),
            sx: messagingStyles.chatTextfieldSXProps,
          }}
        />
      </Grid>
      {uploadedFile && (
        <div>
          Uploaded File: {uploadedFile.name} ({uploadedFile.type})
        </div>
      )}
      <DoctorDetailsModal
        modalOpen={doctorDetailsModal}
        handleClose={handleDoctorDetailsModalClose}
        matchesSM={matchesSM}
        matchesXS={matchesXS}
        attachments={attachments}
      />
    </Fragment>
  );
};

export default Chat;
