import { Stack, Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Dimens from "../../../assets/Dimens";
import Applinks from "../../../assets/route-links";
import {
  DefaultTextField,
  Header,
  // PhoneInput,
  StyledBodyText,
  StyledCaptionText,
} from "../../../components/common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  DoctorHiddenItem,
  ScheduleDoctorItem,
} from "../../../components/consultations";
import DefaultButton from "../../../components/consultations/DefaultButton";
import MainPageLayout from "../../MainPageLayout";
import GenderPicker from "../../../components/consultations/psychiatrist/GenderPicker";
import { useRequest } from "../../../data/useRequest";
import { getProviders } from "../../../data/requests/consultations";
import usePsychiatrist from "../../../providers/psychiatrist-provider";
import { onlyNumbers } from "../../../utils/validations";
import moment from "moment";
import useAuth from "../../../providers/auth.provider";
import { useTranslation } from "react-i18next";
import { useHealthRecordsContext } from "../../../providers/HealthRecordsContext";

const PsychiatristDateTime = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const { personalInfo } = useHealthRecordsContext();
  const { psychiatristPayload, setPsychiatristPayload } = usePsychiatrist();
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const providers = useRequest(([param1, param2, param3, param4]) =>
    getProviders(param1, param2, param3, param4)
  );

  const [disabled, setDisabled] = useState(true);
  const [date, setDate] = useState(null);
  const [gender, setGender] = useState("");

  useEffect(() => {
    providers.execute(date, gender, user.userId, psychiatristPayload.state);
  }, [date, gender]);
  useEffect(() => {
    setPsychiatristPayload({
      ...psychiatristPayload,
      patientPhone: personalInfo[0]?.primaryPhone,
    });
  }, []);

  // useEffect(() => {
  //   const savedPayload = localStorage.getItem("psychiatristSelectedDate");
  //   if (savedPayload) {
  //     const payload = JSON.parse(savedPayload);
  //     console.log("saved date payload:", payload);
  //     // setPsychiatristPayload(payload);
  //     setPsychiatristPayload({
  //       ...psychiatristPayload,
  //       appointment_details: payload?.appointment_details,
  //     });

  //     setDate(moment(payload.date).format("yyyy-MM-DD"));
  //   } else {
  //     setDate(moment(new Date()).format("yyyy-MM-DD"));
  //   }
  // }, []);

  useEffect(() => {
    const savedPayload = localStorage.getItem("psychiatristSelectedDate");
    if (savedPayload) {
      try {
        const payload = JSON.parse(savedPayload);
        console.log("saved date payload:", payload);
        setPsychiatristPayload({
          ...psychiatristPayload,
          appointment_details: payload?.appointment_details,
        });
  
        setDate(moment(payload.date).format("yyyy-MM-DD"));
      } catch (error) {
        console.error("Error parsing saved date payload:", error);
        // Handle the error (e.g., reset to default state or show an error message)
      }
    } else {
      setDate(moment(new Date()).format("yyyy-MM-DD"));
    }
  }, []);
  

  useEffect(() => {
    if (
      psychiatristPayload?.appointment_details?.provider_id &&
      psychiatristPayload?.appointment_details?.time_slot_id &&
      psychiatristPayload?.patientPhone
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [psychiatristPayload]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    navigate(Applinks.SCHEDULE_PSYCHIATRIST_PHARMACY_DETAIL);
  };

  const handleChange = (providerId, timeSlotId) => {
    setPsychiatristPayload({
      ...psychiatristPayload,
      appointment_details: {
        provider_id: providerId,
        time_slot_id: timeSlotId,
      },
    });
    let temp = {
      date: date,
      appointment_details: {
        provider_id: providerId,
        time_slot_id: timeSlotId,
      },
    };
    localStorage.setItem("psychiatristSelectedDate", JSON.stringify(temp));
    // localStorage.setItem("psychiatristSelectedDate", { ...temp });
  };

  const handlePhoneChange = (event) => {
    setPsychiatristPayload({
      ...psychiatristPayload,
      patientPhone: event.target.value,
    });
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleGenderChange = (mGen) => {
    console.log(mGen);
    if (gender === mGen) {
      setGender("");
    } else {
      setGender(mGen);
    }
  };

  console.log("provider: ", providers?.data?.available_providers);
  // console.log("url: ", process.env.REACT_APP_VIRTUAL_CARE_GROUP + providers?.data?.available_providers[0]?.bioImagePath);

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <Box sx={{ width: "100%" }}>
          <Header
            title={t("consultation_schedule_time")}
            subTitle={t("consultation_schedule_time_info")}
            handleGoBack={handleGoBack}
          />
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent="space-between"
            sx={{ mt: 4, mb: 3 }}
          >
            <StyledBodyText>{t("providers")}</StyledBodyText>
            <Stack
              direction={{ md: "row", xs: "column" }}
              alignItems="flex-start"
            >
              <DefaultTextField
                type="date"
                // defaultValue={date}
                value={date}
                onChange={handleDateChange}
                fullWidth={false}
              />
              <GenderPicker
                gender={gender}
                handleGenderChange={handleGenderChange}
              />
            </Stack>
          </Stack>
          {providers?.data?.available_providers?.length === 0 && (
            <StyledCaptionText color="error">
              {t("no_provider_available")}
            </StyledCaptionText>
          )}
          {providers?.data?.available_providers?.length > 0 &&
            providers?.data?.available_providers.map((el) => (
              <ScheduleDoctorItem
                key={el.user_id}
                // image={`${
                //   "https://staging.mytelemedicine.com/go/api" + el.bioImagePath
                // }`}
                // image={`${
                //   "https://staging.getlyric.com/go/api/" + el.bioImagePath
                // }`}
                // image={`${
                //   "https://staging.thevirtualcaregroup.com" + el.bioImagePath
                // }`}
                image={`${process.env.REACT_APP_VIRTUAL_CARE_GROUP + el.bioImagePath}`}
                // image={`${
                //   "https://staging.thevirtualcaregroup.com" + el.bioImagePath
                // }`}
                row={el}
                physicianName={`${el.firstName} ${el.lastName}`}
                spaciality={el.specialties}
                availibility={t("no_provider_available_info").format(
                  moment(date).format("ddd, MM DD")
                )}
                price={el.fee}
                selected={
                  el.user_id ===
                  psychiatristPayload?.appointment_details?.provider_id
                }
                hiddenItem={
                  <DoctorHiddenItem
                    bio={el.bio}
                    selectedSlot={
                      el.user_id ===
                      psychiatristPayload?.appointment_details?.provider_id
                        ? psychiatristPayload?.appointment_details?.time_slot_id
                        : null
                    }
                    availableSlots={el.available_time_slots}
                    handleSelect={(slotId) => handleChange(el.user_id, slotId)}
                  />
                }
              />
            ))}
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 4 }}>
            {t("confirm_contact_method")}
          </StyledBodyText>
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, mt: 1.5 }}>
            {t("confirm_contact_method_info")}
          </StyledCaptionText>
          <StyledCaptionText
            sx={{ fontSize: Dimens.FONT_SIZE_SMALL, mt: 1, mb: 3 }}
          >
            {t("confirm_contact_method_info_two")}
          </StyledCaptionText>
          <PhoneInput
            // country={"us"}
            // placeholder={t("primary_phone")}
            value={
              psychiatristPayload?.patientPhone
                ? psychiatristPayload?.patientPhone
                : personalInfo[0]?.primaryPhone
            }
            onChange={(value) =>
              setPsychiatristPayload({
                ...psychiatristPayload,
                patientPhone: value,
              })
            }
            containerStyle={{
              width: matchesSM ? "100%" : 376,
              height: 60,
              borderBottom: "3px solid #515563",
              maringBottom: 30,
            }}
            inputStyle={phoneInputStyles}
          />
          {/*
        <PhoneInput
        onChange={(e) => handlePhoneChange(onlyNumbers(e))}
        value={psychiatristPayload?.patientPhone}
        />
      */}
          <Stack alignItems="center" sx={{ mt: 3 }}>
            <DefaultButton onClick={handleContinue} disabled={disabled}>
              {t("continue")}
            </DefaultButton>
          </Stack>
        </Box>
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default PsychiatristDateTime;

const phoneInputStyles = {
  height: 60,
  width: "100%",
  background: "#FFFAF5",
  border: "none",
  borderRadius: "10px 10px 0px 0px",
};
