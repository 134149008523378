import { MenuItem, TextField } from "@mui/material";
import React, { Fragment } from "react";
import { settingsStyles } from "./styles/SettingsStyles";
import { loginCardStyles } from "../login/styles/LoginCardStyles";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const TextFieldSection = ({
  label,
  value,
  onChange,
  select,
  type,
  addressFields,
  pharmacyModal,
  options,
  stateField,
  setStateId,
  messageSection,
  setCountryState,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={label}
        variant="filled"
        // fullWidth
        autoFocus
        defaultValue={value}
        placeholder={value}
        type={type}
        onChange={(e) => onChange(e)}
        sx={{
          ...settingsStyles.textFieldStyle,
          width: stateField ? "92%" : addressFields
            ? "95%"
            : pharmacyModal
            ? "100%"
            : matchesSM
            ? "100%"
            : "80%",
        }}
        select={select}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
      >
        {options &&
          options.map((option) =>
            stateField ? (
              <MenuItem
                value={option.abbreviation}
                onClick={() =>
                  messageSection
                    ? setCountryState(option.abbreviation)
                    : setStateId(option.state_id && option.state_id)
                }
              >
                {option.abbreviation}
              </MenuItem>
            ) : (
              <MenuItem value={option}>{option}</MenuItem>
            )
          )}
      </TextField>
    </ThemeProvider>
  );
};

export default TextFieldSection;

// {select && <MenuItem value="English">English</MenuItem>}
// {select && <MenuItem value="Spanish">Spanish</MenuItem>}
