import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { helpCenterCardStyles } from "./styles/HelpCenterStyles";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";

const SearchbarSection = () => {
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Grid container justifyContent={"center"}>
        <TextField
          variant="outlined"
          placeholder={t("search_for_keyword")}
          InputProps={{
            sx: {
              ...searchbarTextfieldStyles,
              width: matchesXS
                ? "90%"
                : matchesSM
                ? "90%"
                : matchesMD
                ? "70%"
                : matchesLG
                ? 400
                : 400,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <SearchOutlinedIcon style={{ marginRight: 10 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default SearchbarSection;

const searchbarTextfieldStyles = {
  // width: 400,
  height: 48,
  borderRadius: 100,
  marginTop: 3,
  textAlign: "center",
  "&:hover fieldset": {
    border: "1px solid #FFB74D!important",
  },
  "&:focus-within fieldset, &:focus-visible fieldset": {
    border: "1px solid #FFB74D!important",
  },
};
