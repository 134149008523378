import React, { Fragment } from "react";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import DateRangeIcon from "@mui/icons-material/DateRange";

import { registrationCardStyles } from "./styles/RegistrationCardStyles";
import { loginCardStyles } from "../login/styles/LoginCardStyles";

const ActivateAccountTextFieldsSection = ({ newUser, setNewUser }) => {
  return (
    <Fragment>
      {/* Email field  */}
      <TextField
        label="Full Name"
        variant="filled"
        fullWidth
        value={newUser.name}
        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        sx={registrationCardStyles.textFieldStyle}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Birthdate"
          // value={newUser.dob}
          onChange={(value) => setNewUser({ ...newUser, dob: value.$d })}
          sx={registrationCardStyles.dateTextFieldStyle}
          slotProps={{
            textField: {
              variant: "filled",
              style: { backgroundColor: "#FFFAF5" },
              inputProps: {
                style: loginCardStyles.inputPropsStyles,
              },
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <DateRangeIcon sx={{ background: "#FFFAF5" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
            inputLabel: {
              style: {
                color: "#515563", // Set the desired label color
              },
            },
          }}
        />
      </LocalizationProvider>
      <TextField
        label="School Zip Code"
        variant="filled"
        fullWidth
        value={newUser.zipCode}
        onChange={(e) => setNewUser({ ...newUser, zipCode: e.target.value })}
        sx={registrationCardStyles.textFieldStyle}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
      />
      <TextField
        label="Language"
        variant="filled"
        fullWidth
        value={newUser.language}
        onChange={(e) => {
          setNewUser({ ...newUser, language: e.target.value });
        }}
        sx={registrationCardStyles.textFieldStyle}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
        select
      >
        <MenuItem
          value={"English"}
          style={registrationCardStyles.languageOptionStyles}
        >
          English
        </MenuItem>
        <MenuItem
          value={"Spanish"}
          style={registrationCardStyles.languageOptionStyles}
        >
          Spanish
        </MenuItem>
      </TextField>
    </Fragment>
  );
};

export default ActivateAccountTextFieldsSection;
