import { CheckCircle, Error } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import useFeedback from "../../../providers/feedback.provider";
import DefaultButton from "../../consultations/DefaultButton";

const ErrorDialog = () => {
  const { openDailog, dailogProps, removeDialog } = useFeedback();
  const [icon, setIcon] = useState();
  React.useEffect(() => {
    if (dailogProps?.error) {
      setIcon("error");
    } else if (openDailog) {
      setIcon("success");
    }
  }, [openDailog, dailogProps?.error]);
  return (
    <Dialog
      open={openDailog}
      keepMounted
      onClose={removeDialog}
      sx={{ paddingLeft: 5, paddingRight: 5 }}
    >
      {icon && icon === "success" ? (
        <DialogTitle sx={{ textAlign: "center" }}>
          <CheckCircle color="success" fontSize="large" />
        </DialogTitle>
      ) : (
        <DialogTitle sx={{ textAlign: "center" }}>
          <Error color="error" fontSize="large" />
        </DialogTitle>
      )}
      <DialogTitle
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {dailogProps?.title}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>{dailogProps?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DefaultButton onClick={removeDialog}>OK</DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
