import React from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import { Card, Grid } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { Scrollbars } from "react-custom-scrollbars-2";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TermsOfUse = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <LoginDesignPage>
        <Card
          sx={{
            ...cardStyle,
            width: matchesXS
              ? "98%"
              : matchesSM
              ? "95%"
              : matchesMD
              ? "90%"
              : matchesLG
              ? "80%"
              : 1115,
          }}
        >
          <Grid
            container
            direction={"column"}
            style={
              matchesSM || matchesXS
                ? subContainerStylesSM
                : matchesMD
                ? subContainerStylesMD
                : matchesLG
                ? subContainerStyles
                : matchesXl
                ? subContainerStyles
                : subContainerStyles
            }
          >
            <Grid
              container
              alignItems={"center"}
              onClick={() => navigate(-1)}
              style={backButtonStyles}
            >
              <ArrowBackIosOutlinedIcon style={backIconStyles} />
              <div style={{ textDecoration: "underline", marginLeft: 12 }}>
                {t("back")}
              </div>
            </Grid>
            <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("terms_of_service")}
            </span>
            <span style={registrationCardStyles.termsOfServicesDate}>
              {t("updated_date")}
            </span>
            <Grid container direction={"column"} alignItems={"center"}>
              <Scrollbars
                style={scrollbarContainerStyle}
                thumbMinSize={30}
                autoHide
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.thumbVerticalStyle,
                    }}
                  />
                )}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      ...registrationCardStyles.trackVerticalStyle,
                    }}
                  />
                )}
              >
                {/*
          {termsOfServicesArray.map((item, index) => (
            <div
            style={termsOfServicesText}
            key={index}
            >
            {item}
            </div>
            ))}
          */}
                <div style={registrationCardStyles.termsOfUseTextTitle}>
                  {t("terms_and_Conditions").toUpperCase()}
                </div>
                {t("terms_and_Conditions_info")}
                <div style={termsOfServicesText}>{t("emergency_call")}</div>
                <div
                  style={{
                    color: "#000",
                    fontSize: 17,
                    fontWeight: 500,
                    marginBottom: 15,
                  }}
                >
                  {t("medical_consultations")}
                </div>
                {t("medical_consultations_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  1. {t("use_of_site")}
                </div>
                {t("use_of_site_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  2. {t("eligibility_enrollment_and_account_security")}
                </div>
                {t("eligibility_enrollment_and_account_security_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  3. {t("electronic_medical_record")}
                </div>
                {t("electronic_medical_record_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  4. {t("payments_and_refunds")}
                </div>
                {t("payments_and_refunds_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  5. {t("site_control")}
                </div>
                {t("site_control_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  6. {t("legal_notice")}
                </div>
                {t("legal_notice_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("disclaimer_of_warranties")}
                </div>
                {t("disclaimer_of_warranties_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("limitation_of_liability")}
                </div>
                {t("limitation_of_liability_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("indemnity")}
                </div>
                {t("indemnity_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("dispute_resolution")}
                </div>
                {t("dispute_resolution_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("force_majeure")}
                </div>
                {t("force_majeure_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("notices")}
                </div>
                {t("notices_info")}
                <div style={{ ...termsOfServicesText, marginTop: 30 }}>
                  {t("entire_agreement")}
                </div>
                {t("entire_agreement_info")}
                <div style={{ marginTop: 50 }}></div>
              </Scrollbars>
            </Grid>
          </Grid>
        </Card>
      </LoginDesignPage>
    </ThemeProvider>
  );
};

export default TermsOfUse;

const cardStyle = {
  position: "absolute",
  width: 1115,
  height: "98vh",
  // height: 758,
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
  // "@media (minWidth: 960px)": {
  //   width: "1115px",
  //   height: "758px",
  // },
};

const subContainerStyles = {
  paddingTop: 70,
  paddingLeft: 134,
  paddingRight: 90,
};
const subContainerStylesSM = {
  padding: 30,
};
const subContainerStylesMD = {
  paddingTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
};
const subContainerStylesLG = {
  padding: 100,
};

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  // width: 750,
  height: 493,
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
  // marginRight: -30,
};

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
  // marginTop: 10,
  // paddingLeft: 30
};
