import React from "react";
import { Divider, Grid } from "@mui/material";

const NoMedicationsAdded = ({ text }) => {
  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      style={{ padding: 20, width: 418 }}
    >
      <div style={{ color: "#000", fontSize: 16, fontWeight: 400 }}>{text}</div>
      <Divider
        style={{ color: "#19A6FE", marginTop: 27, width: "60%" }}
        variant="middle"
      />
    </Grid>
  );
};

export default NoMedicationsAdded;
