import { Button, Grid } from "@mui/material";
import React from "react";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import MedicationTextfield from "../medications/MedicationTextfield";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const AddSurgicalHistoryCard = ({
  matchesLG,
  matchesXl,
  matchesMD,
  matchesSM,
  matchesXS,
  newSurgicalHistory,
  setNewSurgicalHistory,
  handleAddSurgicalHistory,
  loading,
}) => {
  const { t } = useTranslation("translations");
  return (
    <Grid
      container
      direction={"column"}
      style={{
        ...healthRecordsStyles.personalInfoMainBox,
        width: matchesLG || matchesXl ? "80%" : "100%",
        minHeight: matchesXS || matchesSM || matchesMD ? "auto" : 615,
      }}
    >
      <MedicationTextfield
        title={t("procedure_name")}
        placeholder={t("add_procedure_name_here")}
        value={newSurgicalHistory.name}
        onChange={(e) => {
          setNewSurgicalHistory({
            ...newSurgicalHistory,
            name: e.target.value,
          });
        }}
      />

      <MedicationTextfield
        title={t("description")}
        placeholder={t("add_details_here")}
        value={newSurgicalHistory.description}
        multiline={true}
        rows={3}
        onChange={(e) => {
          setNewSurgicalHistory({
            ...newSurgicalHistory,
            description: e.target.value,
          });
        }}
      />
      <MedicationTextfield
        title={t("date")}
        placeholder={"MM/DD/YYYY"}
        value={newSurgicalHistory.procedureDate}
        type={"date"}
        onChange={(e) => {
          setNewSurgicalHistory({
            ...newSurgicalHistory,
            procedureDate: e.target.value,
          });
        }}
      />
      {loading ? (
        <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={
            newSurgicalHistory.name &&
            newSurgicalHistory.description &&
            newSurgicalHistory.procedureDate
              ? false
              : true
          }
          style={
            newSurgicalHistory.name &&
            newSurgicalHistory.description &&
            newSurgicalHistory.procedureDate
              ? healthRecordsStyles.addMedicationButtonEnabled
              : healthRecordsStyles.addMedicationButtonDisabled
          }
          onClick={handleAddSurgicalHistory}
        >
          {t("add_surgical_procedure")}
        </Button>
      )}
    </Grid>
  );
};

export default AddSurgicalHistoryCard;
