import "../../../fonts/comfortaa.css";

export const helpCenterCardStyles = {
  mainContainerStyles: { paddingTop: 70, paddingLeft: 134, paddingRight: 90 },
  messageTextStyles: {
    fontSize: 16,
    fontWeight: 400,
    // fontFamily: "Comfortaa",
    textAlign: "center",
    marginTop: 30,
    color: "#00000",
  },
  contactUsButtonStyles: {
    width: 140,
    height: 36,
    borderRadius: 60,
    background: "#FFB74D",
    color: "#000",
    fontSize: 13,
  },
  backIconStyles: {
    border: "2px solid #2E3A59",
    borderRadius: "50%",
    padding: 1,
    fontSize: 14,
    cursor: "pointer",
  },
  backTextStyles: {
    textDecoration: "underline",
    marginLeft: 12,
    cursor: "pointer",
    textDecoration: "underlined",
    fontSize: 16,
    fontWeight: 400,
  },
  searchbarTextfieldStyles: {
    width: 400,
    height: 48,
    borderRadius: 100,
    marginTop: 3,
    textAlign: "center",
    "&:hover fieldset": {
      border: "1px solid #FFB74D!important",
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "1px solid #FFB74D!important",
    },
  },
};
