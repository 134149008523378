import {
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Dimens from "../../../assets/Dimens";
import { COLORS } from "../../../assets/colors";

export const StyledInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #fff",
    fontSize: 14,
    fontFamily: ["Opan Sans"],
  },
}));

export const DropdownMenuItem = (option, value) => {
  return (
    <MenuItem
      value={value}
      sx={{
        fontSize: 14,
        fontWeight: 400,
        // bgcolor: option === value ? "#FFB74D" : "#fff",
        "&:hover": {
          bgcolor: "#FFB74D",
        },
      }}
    >
      {option}
    </MenuItem>
  );
};

const DefaultDropdown = (props) => {
  const {
    value,
    error,
    helperText,
    onChange,
    options,
    placeholder,
    showOutline,
    textFieldSize = "small",
    renderValue,
    id,
  } = props;
  return (
    <FormControl variant="standard" error={error} fullWidth>
      <Select
        value={value}
        displayEmpty
        name={id}
        error={error}
        helperText={helperText}
        onChange={onChange}
        inputProps={{ style: { color: "#000" } }}
        defaultValue={value}
        input={
          showOutline ? (
            <OutlinedInput
              size={textFieldSize}
              sx={{
                borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
                bgcolor: COLORS.TEXT_SECONDARY,
              }}
            />
          ) : (
            <StyledInput />
          )
        }
        fullWidth
        renderValue={(selected) => {
          if (!selected) {
            return <span>{placeholder}</span>;
          }

          return value;
        }}
        placeholder={"placeholder"}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options && options.map((option) => renderValue(option))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

DefaultDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  showOutline: PropTypes.bool,
  textFieldSize: PropTypes.string,
  id: PropTypes.string,
  renderValue: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DefaultDropdown;
