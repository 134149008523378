export const getConsultationName = (subType) => {
	switch(subType) {
		case "SaaSPsychiatry":
			return "psychiatry";
		case "SaaSPrimaryCare":
			return "primary_care";
	default:
		return subType;
	}
}

export const getConsultationStatus = (status) => {
	switch(status) {
		case "ConsultRequestCreated":
			return "consult_req_created";
		default:
			return status;
	}
}