import React, { useState } from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import ActivateAccountCard from "../../components/registration/ActivateAccountCard";
import SetupAccountCard from "../../components/registration/SetupAccountCard";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState({
    name: "",
    dob: "",
    zipCode: "",
    language: "",
    email: "",
    gender: "",
    primaryPhone: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showActivateAccount, setShowActivateAccount] = useState(true);

  const handleActivateAccount = () => {
    try {
      setLoading(true);
      setShowActivateAccount(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSetupAccount = () => {
    try {
      setLoading(true);
      navigate("/registration-completed");
    } catch (error) {
      console.log("error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginDesignPage>
      {showActivateAccount ? (
        <ActivateAccountCard
          newUser={newUser}
          setNewUser={setNewUser}
          loading={loading}
          handleActivateAccount={handleActivateAccount}
        />
      ) : (
        <SetupAccountCard
          newUser={newUser}
          setNewUser={setNewUser}
          loading={loading}
          handleSetupAccount={handleSetupAccount}
        />
      )}
    </LoginDesignPage>
  );
};

export default Registration;
