import React, { useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import moment from "moment";

import { messagingStyles } from "../styles/messagingStyles";
import { healthRecordsStyles } from "../../healthRecords/styles/healthRecordsStyles";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteFileModal from "./DeleteFileModal";
import FileDeletedModal from "./FileDeletedModal";
import { useTranslation } from "react-i18next";

const UploadFileCard = ({
  uploadedFiles,
  setUploadedFiles,
  matchesSM,
  matchesXS,
  matchesMD,
}) => {
  const fileInputRef = useRef(null);
  const { t } = useTranslation("translations");

  const fileTypes = ["JPEG", "PNG", "JPG", "PJPEG", "GIF", "PDF"];

  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [fileDeletedModal, setFileDeletedModal] = useState(false);

  const handleDeleteFileModalOpen = () => {
    setDeleteFileModal(true);
  };
  const handleDeleteFileModalClose = () => {
    setDeleteFileModal(false);
  };
  const handleFileDeletedModalOpen = () => {
    handleDeleteFileModalClose();
    setFileDeletedModal(true);
  };
  const handleFileDeletedModalClose = () => {
    setFileDeletedModal(false);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    let temp = [...uploadedFiles];
    temp.push(file);
    setUploadedFiles(temp);
    console.log("file:", file);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const formatBytes = (a, b = 2) => {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"][d]
    }`;
  };

  const handleDeleteFile = () => {
    const updatedFiles = uploadedFiles.filter(
      (_, index) => index !== selectedFile
    );
    setUploadedFiles(updatedFiles);
    handleFileDeletedModalOpen();
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md
      lg={5.3}
      xl={5.3}
      container
      direction={"column"}
      style={{
        ...messagingStyles.messegingMainBox,
        height: matchesXS || matchesSM || matchesMD ? "auto" : "auto", //617
        padding: "30px",
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <div style={healthRecordsStyles.textfieldsTitle}>
          {t("upload_a_file")}
        </div>
        <input
          type="file"
          accept="image/*, application/pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
        />
        <label htmlFor="upload-input">
          <IconButton
            component="span"
            disabled={uploadedFiles.length > 0 ? true : false}
            onClick={handleFileUploadClick}
          >
            <AddOutlinedIcon
              fontSize="large"
              style={messagingStyles.uploadFileAddIcon}
            />
          </IconButton>
        </label>
      </Grid>
      {uploadedFiles &&
        uploadedFiles.map((uploadedFile, index) => {
          let size = uploadedFile.size && formatBytes(uploadedFile.size);
          return (
            <Grid container alignItems={"center"} style={{ marginBottom: 15 }}>
              <Grid
                container
                style={messagingStyles.uploadFilesCard}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {uploadedFile.type === "application/pdf" ? (
                  <PictureAsPdfOutlinedIcon
                    fontSize="large"
                    style={{
                      color: "#19A6FE",
                    }}
                  />
                ) : (
                  <InsertDriveFileOutlinedIcon
                    fontSize="large"
                    style={{
                      color: "#19A6FE",
                    }}
                  />
                )}
              </Grid>
              <Grid container direction={"column"} style={{ width: "60%", }}>
                <div
                  style={messagingStyles.uploadedFileName}
                >{`${uploadedFile.name}`}</div>
                <div style={messagingStyles.uploadedFileSizeDate}>
                  {uploadedFile && uploadedFile.type}
                </div>
                <div style={messagingStyles.uploadedFileSizeDate}>
                  {size && size}
                </div>
                <div style={messagingStyles.uploadedFileSizeDate}>
                  {moment(new Date()).format("MMMM DD,YYYY")}
                </div>
              </Grid>
              <DeleteOutlineOutlinedIcon
                fontSize="medium"
                style={messagingStyles.uploadFilesDeleteIcon}
                onClick={() => {
                  setSelectedFile(index);
                  handleDeleteFileModalOpen();
                }}
              />
            </Grid>
          );
        })}
      <DeleteFileModal
        modalOpen={deleteFileModal}
        handleClose={handleDeleteFileModalClose}
        handleDelete={handleDeleteFile}
        title={t("delete_file")}
        message={t("delete_file_info")}
        matchesSM={matchesSM}
        matchesXS={matchesXS}
      />
      <FileDeletedModal
        modalOpen={fileDeletedModal}
        handleClose={handleFileDeletedModalClose}
        title={t("file_deleted")}
        matchesSM={matchesSM}
        matchesXS={matchesXS}
      />
    </Grid>
  );
};

export default UploadFileCard;
