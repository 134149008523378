import React, { Fragment, useState } from "react";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { registrationCardStyles } from "./styles/RegistrationCardStyles";
import { loginCardStyles } from "../login/styles/LoginCardStyles";

import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/material.css'
import "react-phone-input-2/lib/style.css";
import PasswordRequirements from "./PasswordRequirements";

const SetupAccountTextFields = ({ newUser, setNewUser }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (e) => {
    let value = e.target.value;
    setNewUser({ ...newUser, password: value });
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;
    const isValidLength = lengthRegex.test(value);
    const hasUppercase = uppercaseRegex.test(value);
    const hasLowercase = lowercaseRegex.test(value);
    const hasSymbol = symbolRegex.test(value);
    const hasNumber = numberRegex.test(value);

    if (
      isValidLength &&
      hasUppercase &&
      hasLowercase &&
      hasSymbol &&
      hasNumber
    ) {
      setPasswordError(null);
    } else {
      setPasswordError("Incorrect Email Address. Try again");
    }
  };

  return (
    <Fragment>
      {/* Email field  */}
      <TextField
        label="Email Address"
        variant="filled"
        fullWidth
        value={newUser.email}
        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        sx={registrationCardStyles.textFieldStyle}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
      />

      {/* password field  */}
      <TextField
        label="Password"
        variant="filled"
        value={newUser.password}
        onChange={(e) => handleChangePassword(e)}
        type={showPassword ? "text" : "password"}
        helperText={passwordError}
        error={passwordError !== null ? true : false}
        sx={registrationCardStyles.textFieldStyle}
        // style={{position: }}
        FormHelperTextProps={{
          sx: loginCardStyles.formHelperTextStyles,
        }}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {passwordError !== null && <PasswordRequirements />}
      {/* confirm Password field  */}
      <TextField
        label="Confirm Password"
        variant="filled"
        value={newUser.confirmPassword}
        onChange={(e) =>
          setNewUser({ ...newUser, confirmPassword: e.target.value })
        }
        type={showPassword ? "text" : "password"}
        // helperText={passwordError}
        // error={passwordError !== null ? true : false}
        sx={registrationCardStyles.textFieldStyle}
        FormHelperTextProps={{
          sx: loginCardStyles.formHelperTextStyles,
        }}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <PhoneInput
          country={"us"}
          onChange={(value) => setNewUser({ ...newUser, primaryPhone: value })}
          containerStyle={{
            width: 376,
            marginBottom: 30,
            borderBottom: "2px solid #515563",
          }}
          inputStyle={{
            height: 56,
            width: 376,
            background: "#FFFAF5",
            border: "none",
            borderRadius: "10px 10px 0px 0px",
          }}
        />
      </Grid>
      <TextField
        label="Gender"
        variant="filled"
        fullWidth
        value={newUser.gender}
        onChange={(e) => {
          setNewUser({ ...newUser, gender: e.target.value });
        }}
        sx={registrationCardStyles.textFieldStyle}
        InputLabelProps={{
          style: {
            color: "#515563",
          },
        }}
        InputProps={{
          sx: loginCardStyles.inputPropsStyles,
        }}
        select
      >
        <MenuItem
          value={"Female"}
          style={registrationCardStyles.languageOptionStyles}
        >
          Female
        </MenuItem>
        <MenuItem
          value={"Male"}
          style={registrationCardStyles.languageOptionStyles}
        >
          Male
        </MenuItem>
        <MenuItem
          value={"Gender Non-conforming"}
          style={registrationCardStyles.languageOptionStyles}
        >
          Gender Non-conforming
        </MenuItem>
      </TextField>
    </Fragment>
  );
};

export default SetupAccountTextFields;
