import React, { useEffect } from "react";
import LoginDesignPage from "../login/LoginDesignPage";
import { Card, Grid } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { registrationCardStyles } from "../../components/registration/styles/RegistrationCardStyles";
import { helpCenterCardStyles } from "../../components/helpCenter/styles/HelpCenterStyles";
import TopHeader from "../../components/helpCenter/TopHeader";
import ScrollbarView from "../../components/helpCenter/ScrollbarView";
import SearchbarSection from "../../components/helpCenter/SearchbarSection";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    borderRadius: "20px 20px 0px 0px",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "&.Mui-expanded": {
    borderRadius: "20px 20px 0px 0px",
    boxShadow: "5px 2px 10px rgba(0, 0, 0, 0.1)",
    marginTop: 20,
    backgroundColor: "#19A6FE",
    minHeight: 61,
  },
  minHeight: 74,
  margin: "0px 5px 0px 0px",
  marginTop: 20,
  borderRadius: "20px",
  boxShadow: "5px 2px 10px rgba(0, 0, 0, 0.1)",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 30,
  margin: "0px 5px 0px 0px",
  borderRadius: "0px 0px 20px 20px",
  boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.1)",
}));

const HelpCenter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (matchesLG) {
      console.log("large");
    }
    if (matchesXl) {
      console.log("Extra large");
    }
    if (matchesSM) {
      console.log("Small");
    }
    if (matchesMD) {
      console.log("Medium");
    }
    if (matchesXS) {
      console.log("Extra Small");
    }
  }, [matchesLG, matchesMD, matchesSM, matchesXS, matchesXl]);

  return (
    <ThemeProvider theme={theme}>
      <LoginDesignPage>
        <Card
          sx={{
            ...cardStyle,
            width: matchesXS
              ? "98%"
              : matchesSM
              ? "95%"
              : matchesMD
              ? "90%"
              : matchesLG
              ? "80%"
              : 1115,
          }}
        >
          <Grid
            container
            direction={"column"}
            style={
              matchesSM || matchesXS
                ? subContainerStylesSM
                : matchesMD
                ? subContainerStylesMD
                : matchesLG
                ? subContainerStyles
                : matchesXl
                ? subContainerStyles
                : subContainerStyles
            }
          >
            <TopHeader navigate={navigate} />
            <span style={registrationCardStyles.termsOfServicesTitle}>
              {t("help_center")}
            </span>
            <span style={helpCenterCardStyles.messageTextStyles}>
              {t("help_center_info")}
            </span>
            <ScrollbarView>
              <SearchbarSection />
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel1" ? (
                      <RemoveCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    ) : (
                      <AddCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    )
                  }
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Tellus id ullamcorper.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam ut egestas donec id diam facilisis risus, enim, neque.
                    Quis purus egestas fames non ultrices. A bibendum.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={
                    expanded === "panel2" ? (
                      <RemoveCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    ) : (
                      <AddCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    )
                  }
                >
                  <Typography>Tellus id ullamcorper.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam ut egestas donec id diam facilisis risus, enim, neque.
                    Quis purus egestas fames non ultrices. A bibendum.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  expandIcon={
                    expanded === "panel3" ? (
                      <RemoveCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    ) : (
                      <AddCircleIcon
                        fontSize="small"
                        style={{ color: "#001011" }}
                      />
                    )
                  }
                >
                  <Typography>Tellus id ullamcorper.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam ut egestas donec id diam facilisis risus, enim, neque.
                    Quis purus egestas fames non ultrices. A bibendum.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </ScrollbarView>
          </Grid>
        </Card>
      </LoginDesignPage>
    </ThemeProvider>
  );
};

export default HelpCenter;

const cardStyle = {
  position: "absolute",
  width: 1115,
  height: "98vh",
  // height: 758,
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
  // "@media (minWidth: 960px)": {
  //   width: "1115px",
  //   height: "758px",
  // },
};

const subContainerStyles = {
  paddingTop: 70,
  paddingLeft: 134,
  paddingRight: 90,
};
const subContainerStylesSM = {
  padding: 30,
};
const subContainerStylesMD = {
  paddingTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
};
const subContainerStylesLG = {
  paddingTop: 60,
  paddingLeft: 80,
  paddingRight: 80,
};

const scrollbarContainerStyle = {
  position: "relative",
  paddingRight: 30, // Add right padding of 30px
  width: "95%",
  // width: 750,
  height: 493,
};

const termsOfServicesText = {
  fontSize: 16,
  fontWeight: 400,
  textAlign: "justify",
  marginBottom: 30,
  color: "#001011",
  width: "98%",
  // marginRight: -30,
};

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
  marginTop: 10,
  paddingLeft: 30,
};
