import React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import MainLayout from "../components/mainLayout/MainLayout";

const MainPageLayout = ({ children }) => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
//   const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
//   const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <MainLayout>
        <Grid
          container
          direction={"column"}
          style={{
            paddingLeft: matchesSM || matchesXS ? 20 : 40,
            paddingRight: matchesSM || matchesXS ? 20 : matchesMD ? 40 : 80,
          }}
        >
          {children}
        </Grid>
      </MainLayout>
    </ThemeProvider>
  );
};

export default MainPageLayout;
