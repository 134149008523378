import { useState } from "react";

export const useRequest = (request) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const execute = async (...params) => {
    setLoading(true);
    try {
      const response = await request(params);
      setData(response);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    execute,
    loading,
    error,
    data,
  };
};
