import React from "react";
import { Button, Grid } from "@mui/material";

import RegistrationCardLayout from "./RegistrationCardLayout";
import { registrationCardStyles } from "./styles/RegistrationCardStyles";
import ButtonLoader from "../ButtonLoader";
import SetupAccountTextFields from "./SetupAccountTextFields";

const SetupAccountCard = ({
  newUser,
  setNewUser,
  loading,
  handleSetupAccount,
}) => {
  return (
    <RegistrationCardLayout>
      <Grid container direction={"column"}>
        <form style={registrationCardStyles.formStyle} noValidate>
          <span style={registrationCardStyles.titleTextStyles}>
            Let’s set up your account
          </span>
          {/* Text fields section starts*/}
          <SetupAccountTextFields newUser={newUser} setNewUser={setNewUser} />
          {/* Text fields section ended*/}
          {/* Register Button area  */}
          {loading ? (
            <Grid style={{ marginTop: 65 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                newUser.email &&
                newUser.password &&
                newUser.confirmPassword &&
                newUser.gender
                  ? true
                  : false
              }
              style={
                newUser.email ||
                newUser.password ||
                newUser.confirmPassword ||
                newUser.gender
                  ? registrationCardStyles.buttonStyle
                  : registrationCardStyles.buttonDisabledStyle
              }
              onClick={handleSetupAccount}
            >
              Register
            </Button>
          )}
        </form>
      </Grid>
    </RegistrationCardLayout>
  );
};

export default SetupAccountCard;
