import React, { Fragment } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { messagingStyles } from "../styles/messagingStyles";
import { useTranslation } from "react-i18next";

const TopHeaderSection = ({
  matchesLG,
  matchesMD,
  matchesSM,
  matchesXS,
  navigate,
  messagesData,
  title,
  handleSearchMessages,
  searchValue,
}) => {
  const { t } = useTranslation("translations");
  return (
    <Grid
      container
      direction={matchesXS ? "column" : matchesSM ? "column" : "row"}
    >
      <Grid
        item
        xs
        container
        direction={matchesXS || matchesSM ? "column" : "row"}
        alignItems={"center"}
      >
        <div style={messagingStyles.componentMainTitle}>
          {" "}
          <ArrowBackIosOutlinedIcon
            style={messagingStyles.backIcon}
            onClick={() => navigate(-1)}
          />{" "}
          {t(title)}
        </div>
      </Grid>
      <Grid
        item
        xs
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        direction={matchesSM ? "column" : matchesXS ? "column" : "row"}
      >
        {title === "New Message" ? undefined : (
          <Fragment>
            <TextField
              variant="outlined"
              placeholder={t("search_by_name")}
              value={searchValue}
              onChange={(e) => handleSearchMessages(e)}
              InputProps={{
                sx: {
                  ...messagingStyles.searchTextField,
                  width:
                    matchesSM || matchesXS
                      ? 200
                      : matchesMD
                      ? 220
                      : matchesLG
                      ? 300
                      : 320,
                  marginTop: matchesSM || matchesXS ? 1 : undefined,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <AddOutlinedIcon
              fontSize="large"
              onClick={() =>
                navigate("/messages/new-message", {
                  state: {
                    messagesData: messagesData,
                  },
                })
              }
              style={{
                marginLeft: matchesSM || matchesXS || matchesMD ? 5 : 20,
                ...messagingStyles.headerAddIcon,
              }}
            />
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default TopHeaderSection;
