import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import { modalStyle } from "../../../constant";
import { healthRecordsStyles } from "../styles/healthRecordsStyles";
import EditModalTextField from "../medications/EditModalTextField";
import ButtonLoader from "../../ButtonLoader";
import { useTranslation } from "react-i18next";

const EditMedicalHistoryModal = ({
  modalOpen,
  handleClose,
  medicalHistory,
  setMedicalHistory,
  handleEditMedicalHistory,
  matchesSM,
  matchesXS,
  loading,
}) => {
  const {t} = useTranslation("translations")
  //   console.log(typeof handleEditMedicalHistory)
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: matchesSM || matchesXS ? "60%" : 456,
        }}
      >
        {/*animi. section*/}
        <Grid
          container
          direction={"column"}
          // justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: matchesSM || matchesXS ? "0px" : "0px 30px" }}
        >
          <div style={healthRecordsStyles.editModalTitle}>{t("edit_medication")}</div>
          <EditModalTextField
            title={t("condition_name")}
            placeholder={t("add_condition_name_here")}
            value={medicalHistory && medicalHistory.name}
            onChange={(e) => {
              setMedicalHistory({
                ...medicalHistory,
                name: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("description")}
            placeholder={t("add_details_here")}
            value={medicalHistory && medicalHistory.description}
            multiline={true}
            rows={3}
            onChange={(e) => {
              setMedicalHistory({
                ...medicalHistory,
                description: e.target.value,
              });
            }}
          />
          <EditModalTextField
            title={t("status")}
            placeholder={t("select_an_option")}
            value={medicalHistory && medicalHistory.status}
            select={true}
            MedicalHistory={true}
            onChange={(e) => {
              setMedicalHistory({
                ...medicalHistory,
                status: e.target.value,
              });
            }}
          />
          {loading ? (
            <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              fullWidth
              disabled={
                medicalHistory &&
                medicalHistory.name &&
                medicalHistory.description &&
                medicalHistory.status
                  ? false
                  : true
              }
              style={
                medicalHistory &&
                medicalHistory.name &&
                medicalHistory.description &&
                medicalHistory.status
                  ? healthRecordsStyles.addMedicationButtonEnabled
                  : healthRecordsStyles.addMedicationButtonDisabled
              }
              onClick={() => {
                handleEditMedicalHistory();
              }}
            >
              {t("save")}
            </Button>
          )}
          <Button
            variant="text"
            fullWidth
            style={healthRecordsStyles.cancelButton}
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditMedicalHistoryModal;
