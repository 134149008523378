import React from "react";
import { Box, Button, Grid } from "@mui/material";
import TextFieldSection from "./TextFieldSection";
import { settingsStyles } from "./styles/SettingsStyles";
import CityStateZipFieldsCard from "./personalInfoTab/CityStateZipFieldsCard";
import moment from "moment";
import ButtonLoader from "../ButtonLoader";
import { useTranslation } from "react-i18next";

const PersonalInfoTab = ({
  personalInfo,
  setPersonalInfo,
  matchesSM,
  handleUpdatePersonalInfo,
  loading,
}) => {
  const {t} = useTranslation("translations")
  // console.log("personal info:", personalInfo);
  return (
    <Box sx={{ flexGrow: 1, marginTop: 2 }}>
      <Grid
        container
        spacing={{ xs: 0, md: 5 }}
        columns={{ xs: 4, sm: 4, md: 8 }}
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("first_name")}
            value={personalInfo.firstName}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, firstName: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("last_name")}
            value={personalInfo.lastName}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, lastName: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("date_of_birth")}
            value={moment(personalInfo.dob).format("YYYY-MM-DD")}
            type={"date"}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, dob: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("gender")}
            value={personalInfo.gender}
            select={true}
            options={["Female", "Male", "Gender Non-conforming"]}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, gender: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <TextFieldSection
            label={t("address")}
            value={personalInfo.address}
            onChange={(e) =>
              setPersonalInfo({ ...personalInfo, address: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          <CityStateZipFieldsCard
            personalInfo={personalInfo}
            setPersonalInfo={setPersonalInfo}
            matchesSM={matchesSM}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={settingsStyles.textfeildBottomMargin}
        >
          {loading ? (
            <Grid container justifyContent="center" style={{ width: matchesSM ? "100%" : 300 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              onClick={handleUpdatePersonalInfo}
              style={{
                width: matchesSM ? "100%" : 300,
                height: 54,
                borderRadius: 60,
                backgroundColor: "#19A6FE",
                color: "#000",
              }}
            >
              {t("update")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInfoTab;
