import { WatchLater } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
// import moment from "moment";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../assets/colors";
import Dimens from "../../../assets/Dimens";
// import Applinks from "../../../assets/route-links";
import {
  AlertDialog,
  Header,
  StyledBodyText,
  StyledCaptionText,
} from "../../../components/common";
import DefaultButton from "../../../components/consultations/DefaultButton";
import { images } from "../../../constant";
import { addUrgentCareConsultation } from "../../../data/requests/consultations";
import useAuth from "../../../providers/auth.provider";
import useFeedback from "../../../providers/feedback.provider";
import useUrgentCare from "../../../providers/urgent-care.provider";
import MainPageLayout from "../../MainPageLayout";
import { useTranslation } from "react-i18next";

const stateTimeZones = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AS: "Pacific/Pago_Pago",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  AA: "America/New_York",
  AE: "UTC",
  AP: "Pacific/Honolulu",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  DC: "America/New_York",
  FM: "Pacific/Chuuk",
  FL: "America/New_York",
  GA: "America/New_York",
  GU: "Pacific/Guam",
  HI: "Pacific/Honolulu",
  ID: "America/Denver",
  IL: "America/Chicago",
  IN: "America/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MH: "Pacific/Majuro",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  MP: "Pacific/Saipan",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PW: "Pacific/Palau",
  PA: "America/New_York",
  PR: "America/Puerto_Rico",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VI: "America/St_Thomas",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
};

const PaymentMethodUrgent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelDialog, setShowCancelDialog] = useState(false);
  const { urgentCarePayload, consultObject, removeFromLocal, pharmacy } =
    useUrgentCare();
  const { user } = useAuth();
  const { addDialog } = useFeedback();
  const userTimeZone = stateTimeZones[urgentCarePayload.state];

  // console.log("payyload:::", urgentCarePayload);

  const handleConfirmClick = async () => {
    setLoading(true);
    try {
      const resp = await addUrgentCareConsultation({
        ...urgentCarePayload,
        consultationUserId: user.userId,
        videoConsultReadyTextNumber: urgentCarePayload.phoneNumber,
        sureScriptPharmacy_id:
          pharmacy?.sureScriptPharmacy_id ||
          consultObject.patient.pharmacy.sureScriptPharmacy_id,
      });
      if (resp.success === true) {
        removeFromLocal();
        setShowConfirmDialog(true);
      }
    } catch (err) {
      // removeFromLocal()
      addDialog({
        title: t("error"),
        message: err.response.data.message || err.message,
        error: true,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDoneClick = () => {
    navigate("/consultations");
    // navigate(Applinks.SCHEDULE_CONSULATATIONS);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancelClick = () => {
    removeFromLocal();
    navigate("/consultations");
    // navigate(Applinks.SCHEDULE_CONSULATATIONS);
  };

  // console.log("time:", urgentCarePayload.whenScheduled)

  return (
    <MainPageLayout>
      <Header title={t("payment_method")} handleGoBack={handleGoBack} />
      <StyledBodyText sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL, mt: 2 }}>
        {t("consultation_overview")}
      </StyledBodyText>
      <StyledCaptionText sx={{ mt: 1, fontSize: Dimens.FONT_SIZE_SMALL }}>
        {t("diagnostic_consultation_urgentcare")}
      </StyledCaptionText>
      <Stack direction="row" sx={{ mt: 1 }} alignItems="center">
        <WatchLater sx={{ color: COLORS.DISABLED }} />
        <StyledCaptionText sx={{ color: COLORS.DISABLED, ml: 1 }}>
          {
            urgentCarePayload.whenScheduled === "now"
              ? t("now")
              : `${moment
                  .tz(urgentCarePayload.whenScheduled, userTimeZone)
                  .format("hh:mm a")} --${" "}
            ${moment
              .tz(urgentCarePayload.whenScheduled, userTimeZone)
              .add({ hours: 2 })
              .format("hh:mm a - ddd, MMM YYYY")} (Timezone: ${userTimeZone}))`
            //  moment(urgentCarePayload.whenScheduled).format(
            //     "hh:mm a - ddd, MMM YYYY"
            //   )
          }
        </StyledCaptionText>
      </Stack>
      <Box
        sx={{
          mt: 2,
          width: "90%",
          height: Dimens.EHR_CARD_HEIGHT,
          bgcolor: COLORS.PRIMARY_MAIN,
          borderRadius: Dimens.BORDER_RADIUS_LARGE,
          pl: 3,
          pr: 1,
        }}
      >
        <Stack
          direction="row"
          sx={{ height: "100%" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("service_fee")}
          </StyledBodyText>

          <DefaultButton sx={{ bgcolor: COLORS.TEXT_SECONDARY }}>
            {t("Free")}
          </DefaultButton>
        </Stack>
      </Box>
      {/*
      <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM, mt: 2 }}>
        {t("fee_based_on_plan").format("0.00", "0.00")}
      </StyledCaptionText>
    */}
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent="center"
        sx={{ mt: { md: 8, xs: 3 } }}
        spacing={2}
      >
        <DefaultButton
          disabled={loading}
          variant="outlined"
          onClick={handleCancelClick}
        >
          {t("cancel_consultation")}
        </DefaultButton>
        <DefaultButton loading={loading} onClick={handleConfirmClick}>
          {t("confirm")}
        </DefaultButton>
      </Stack>
      <AlertDialog
        modalOpen={showConfirmDialog}
        title={t("consultation_request_submitted")}
        description={t("consultation_request_submitted_info").format(
          "(866) 533-1827"
        )}
        primaryButtonText={t("done")}
        primaryButtonAction={handleDoneClick}
        icon={
          <img
            src={images.verifyAccountAnimi}
            alt="Done animation"
            style={{ width: 160, height: 160, marginTop: 30 }}
          />
        }
      />
      <AlertDialog
        modalOpen={cancelDialog}
        title={t("cancel_consultation")}
        description={t("do_you_want_to_cancel")}
        primaryButtonText={t("yes")}
        primaryButtonAction={handleConfirmCancelClick}
        secondaryButtonText={t("no")}
        secondaryButtonAction={() => setShowCancelDialog(false)}
      />
    </MainPageLayout>
  );
};

export default PaymentMethodUrgent;
