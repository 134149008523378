import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import MainPageLayout from "../MainPageLayout";
import { messagingStyles } from "../../components/messaging/styles/messagingStyles";
import TopHeaderSection from "../../components/messaging/newQuestion/TopHeaderSection";
import MessageCard from "../../components/messaging/MessageCard";
import Chat from "../../components/messaging/Chat";
import { useMessageCenterContext } from "../../providers/MessageCenterContext";
import {
  getAllSpecificMessage,
  replyToMessage,
} from "../../data/requests/messageCenter";
import { messagesSearch } from "../../common";
import { useTranslation } from "react-i18next";

const MessageCenter = () => {
  const { allMessages } = useMessageCenterContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const scrollbarsRef = useRef(null);
  const { t } = useTranslation("translations");

  const matchesXS = useMediaQuery(() => theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const [searchValue, setSearchValue] = useState("");
  const [messagesData, setMessagesData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [specificMessage, setSpecificMessage] = useState(null);

  const [newMessage, setNewMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [smallScreenView, setSmallScreenView] = useState(false);
  const [messageSelected, setMessageSelected] = useState(false);

  // console.log("messages:", allMessages);

  useEffect(() => {
    setMessagesData(allMessages);

    //get the first message
    if (allMessages && allMessages.length > 0) {
      setSpecificMessage(allMessages[0]);
      handleGetSingleMessage(allMessages[0].askADoctorThread_id);
    }
  }, [allMessages]);

  useEffect(() => {
    if (scrollbarsRef.current) {
      // Scroll to the bottom when the component is mounted
      scrollbarsRef.current.scrollToBottom();
    }
  }, [selectedMessage]);

  useEffect(() => {
    if (matchesMD) {
      setSmallScreenView(true);
      // console.log("md screen:", smallScreenView);
    }
    if (matchesSM) {
      setSmallScreenView(true);
      // console.log("sm screen:", smallScreenView);
    }
    if (matchesXS) {
      setSmallScreenView(true);
      // console.log("xs screen:", smallScreenView);
    }
    if (matchesXl) {
      setSmallScreenView(false);
      setMessageSelected(false);
      // console.log("xl screen:", smallScreenView);
    }
    if (matchesLG) {
      setSmallScreenView(false);
      setMessageSelected(false);
      // console.log("lg screen:", smallScreenView);
    }
  }, [matchesMD, matchesSM, matchesXS, matchesLG, matchesXl]);

  const handleSearchMessages = async (e) => {
    let value = e.target.value;
    setSearchValue(value);
    if (value.length >= 1) {
      let search = await messagesSearch(allMessages, value);
      setMessagesData(search);
    } else {
      setMessagesData(allMessages);
    }
  };

  const handleGetSingleMessage = async (threadId) => {
    try {
      let responseTemp = await getAllSpecificMessage(threadId);
      setSelectedMessage(responseTemp);
      console.log("specific message:", responseTemp);
    } catch (error) {
      console.log("error getting single message:", error.message);
    }
  };

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      
      if (uploadedFile) {
        formData.append("AttachmentFile", uploadedFile);
      }
      formData.append("subject", "You");
      formData.append("details", newMessage);
      formData.append("askADoctorThreadId", selectedMessage.askADoctorThreadId);
      // let newData = {
      //   subject: "You",
      //   details: newMessage,
      //   askADoctorThreadId: selectedMessage.askADoctorThreadId,
      // };

      const responseTemp = await replyToMessage(formData); 
      console.log("reply message repsonse:", responseTemp);
      handleGetSingleMessage(selectedMessage.askADoctorThreadId);
      setNewMessage("");
      setUploadedFile(null);
    } catch (error) {
      console.log("error sending new message:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log("message:", selectedMessage);

  return (
    <ThemeProvider theme={theme}>
      <MainPageLayout>
        <TopHeaderSection
          matchesLG={matchesLG}
          matchesMD={matchesMD}
          matchesSM={matchesSM}
          matchesXS={matchesXS}
          navigate={navigate}
          messagesData={messagesData}
          searchValue={searchValue}
          title={"your_messages"}
          handleSearchMessages={handleSearchMessages}
        />
        <Grid
          container
          style={{
            ...messagingStyles.messegingMainBox,
            padding: matchesXS || matchesSM || matchesMD ? "0px" : 40,
          }}
        >
          {smallScreenView && messageSelected ? undefined : (
            <Grid
              item
              xs={12}
              sm={12}
              md
              lg={5.5}
              xl={5.5}
              container
              direction={"column"}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: matchesXS || matchesSM || matchesMD ? 0 : 50,
                }}
              >
                <Scrollbars
                  // autoHide={true}
                  style={messagingStyles.scrollbarAllMessages}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        ...messagingStyles.scrollbarprops,
                      }}
                    />
                  )}
                >
                  {messagesData &&
                    messagesData.map((message, index) => (
                      <MessageCard
                        matchesSM={matchesSM}
                        message={message}
                        key={index}
                        onMessageClick={(messageId) => {
                          setSpecificMessage(message);
                          handleGetSingleMessage(messageId);
                          if (matchesMD) {
                            setMessageSelected(true);
                          }
                          if (matchesSM) {
                            setMessageSelected(true);
                          }
                          if (matchesXS) {
                            setMessageSelected(true);
                          }
                        }}
                      />
                    ))}
                </Scrollbars>
              </div>
            </Grid>
          )}
          {smallScreenView ? (
            !messageSelected ? undefined : (
              <Grid
                item
                xs={12}
                sm={12}
                md
                lg={6.5}
                xl={6.5}
                container
                direction={"column"}
                style={{padding: 10}}
              >
                {smallScreenView && (
                  <Grid
                    container
                    alignItems={"center"}
                    onClick={() => setMessageSelected(false)}
                    style={backButtonStyles}
                  >
                    <ArrowBackIosOutlinedIcon style={backIconStyles} />
                    <div
                      style={{ textDecoration: "underline", marginLeft: 12 }}
                    >
                      Back
                    </div>
                  </Grid>
                )}
                {selectedMessage && (
                  <Chat
                    message={selectedMessage && selectedMessage}
                    setMessage={setSelectedMessage}
                    specificMessage={specificMessage}
                    messagesData={messagesData}
                    setMessagesData={setMessagesData}
                    matchesSM={matchesSM}
                    matchesXS={matchesXS}
                    handleSendMessage={handleSendMessage}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    loading={loading}
                  />
                )}
              </Grid>
            )
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md
              lg={6.5}
              xl={6.5}
              container
              direction={"column"}
            >
              {selectedMessage && (
                <Chat
                  message={selectedMessage && selectedMessage}
                  setMessage={setSelectedMessage}
                  specificMessage={specificMessage}
                  messagesData={messagesData}
                  setMessagesData={setMessagesData}
                  matchesSM={matchesSM}
                  matchesXS={matchesXS}
                  handleSendMessage={handleSendMessage}
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  loading={loading}
                />
              )}
            </Grid>
          )}
        </Grid>
      </MainPageLayout>
    </ThemeProvider>
  );
};

export default MessageCenter;

const backIconStyles = {
  border: "2px solid #2E3A59",
  borderRadius: "50%",
  padding: 1,
  fontSize: 14,
};

const backButtonStyles = {
  cursor: "pointer",
  textDecoration: "underlined",
  fontSize: 16,
  fontWeight: 400,
  marginTop: 20,
  paddingLeft: 10,
};
