const Applinks = {
  SCHEDULE_CONSULATATIONS: "/consultations/schedule",
  SCHEDULE_CONSULATATIONS_URGENT_CARE: "/consultations/schedule/urgent-care",
  SCHEDULE_CONSULATATIONS_BEHAVIORAL_HEALTH:
    "/consultations/schedule/behavioral-health",
  SCHEDULE_CONSULATATIONS_STUDENT_BEHAVIORAL_HEALTH:
    "/consultations/schedule/student-behavioral-health",
  SCHEDULE_CONSULTATION_PSYCHIATRIST: "/consultations/schedule/psychiatrist",
  EHR: "/consultations/ehr",
  MEDICAL_QUESTIONAIRE:
    "/consultations/schedule/urgent-care/medical-questionaire",
  MEDICAL_QUESTIONAIRE_URGENT_CARE:
    "/consultations/schedule/urgent-care/medical-questionaire",
  SCHEDULE_DATE_URGENT_CARE: "/consultations/schedule/urgent-care/date-time",
  SCHEDULE_PHARMACY_DETAIL:
    "/consultations/schedule/urgent-care/pharmacy-details",
  SCHEDULE_PAYMENT_METHOD_URGENT_CARE:
    "/consultations/schedule/urgent-care/payment-method",
  SCHEDULE_PSYCHIATRIST_MEDICAL_QUESTIONAIRE:
    "/consultations/schedule/psychiatrist/medical-questionaire",
  SCHEDULE_PSYCHIATRIST_INTAKE_QUESTIONAIRE:
    "/consultations/schedule/psychiatrist/intake-questionaire",
  SCHEDULE_PSYCHIATRIST_DATE_TIME:
    "/consultations/schedule/psychiatrist/date-time",
  VIDEO_CALL_SCREEN: "/consultation/video-call",
  SCHEDULE_PSYCHIATRIST_PHARMACY_DETAIL:
    "/consultations/schedule/psychiatrist/pharmacy-details",
  SCHEDULE_PSYCHIATRIST_PAYMENT: "/consultations/schedule/psychiatrist/payment",
};

export default Applinks;
