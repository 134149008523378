import "../../../fonts/comfortaa.css";

export const registrationCardStyles = {
  cardStyle: {
    position: "absolute",
    width: 1115,
    height: 758,
    background: "#FFFFFF",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
    "@media (minWidth: 960px)": {
      width: "1115px",
      height: "758px",
    },
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    height: "100%",
  },
  titleTextStyles: {
    color: "#001011",
    fontSize: 23,
    marginBottom: 30,
    marginTop: 90,
    fontWeight: 700,
    textAlign: "center",
    fontFamily: "Comfortaa",
  },
  textFieldStyle: {
    marginBottom: 4,
    color: "#515563",
    height: 60,
    width: 376,
    borderRadius: "10px 10px 0px 0px",
  },
  dateTextFieldStyle: {
    marginBottom: 4,
    // color: "#515563",
    height: 60,
    width: 376,
    borderRadius: "10px 10px 0px 0px",
  },
  languageOptionStyles: {
    cursor: "pointer",
    color: "#001011",
    fontSize: 16,
    fontWeight: 400,
  },
  buttonStyle: {
    background: "#19A6FE",
    color: "#001011",
    borderRadius: 60,
    marginTop: 20,
    height: 50,
    fontWeight: 400,
    width: 376,
  },
  buttonDisabledStyle: {
    background: "#0D3B66",
    color: "#FFF",
    borderRadius: 60,
    marginTop: 20,
    height: 50,
    fontWeight: 400,
    width: 376,
  },
  passwordReqTitle: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Comfortaa",
    marginBottom: 5,
  },
  passwordReqContainer: {
    width: 350,
    // height: 195,
    borderRadius: 10,
    padding: "30px 20px 35px",
    boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
    zIndex: 2,
    position: "absolute",
    marginTop: 320,
    background: "#fff",
  },
  passwordReqItem: { fontSize: 12, fontWeight: 400, marginBottom: 3 },
  termsOfServicesTitle: {
    fontSize: 28,
    fontWeight: 400,
    fontFamily: "Comfortaa",
    marginBottom: 6,
    textAlign: "center",
    marginTop: 30,
  },
  termsOfServicesDate: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Comfortaa",
    textAlign: "center",
    marginBottom: 30,
    color: "#515563",
  },
  termsOfServicesText: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "justify",
    marginBottom: 30,
    color: "#001011",
    width: 720,
    // marginRight: -30,
  },
  termsOfUseTextTitle:{ color: "#000", fontSize: 20, fontWeight: 600, marginTop: 20, marginBottom:20 },
  termsOfUseSubTitle: { color: "#000", fontSize: 18, fontWeight: 600, marginTop:30, marginBottom:15},
  scrollbarContainerStyle: {
    position: "relative",
    paddingRight: 30, // Add right padding of 30px
    width: 750,
    height: 493,
  },
  thumbVerticalStyle: {
    width: "15px",
    borderRadius: "20px",
    background: "#19A6FE",
    height: "132px",
  },
  trackVerticalStyle: {
    position: "absolute",
    width: "15px",
    right: "0",
    top: "0",
    bottom: "0",
    borderRadius: "20px",
    background: "#DFF0FF",
  },
  successTextStyles: {
    color: "#000",
    fontSize: 16,
    fontWeight: 400,
    width: 222,
    textAlign: "center",
    marginBottom: 30,
  },
  successDoneButtonStyles: {
    width: 272,
    height: 50,
    color: "#000",
    background: "#FFB74D",
    borderRadius: 60,
  },
  tableofContentStyles: {
    width: 720,
    borderRadius: 10,
    background: "#DFF0FF",
    padding: "20px 15px",
    marginBottom: 20,
  },
  tableofContentItemStyles: {
    color: "#0D3B66",
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "underline",
    marginBottom: 5,
  },
  tableofContentTitleStyles: {
    color: "black",
    fontSize: 17,
    fontWeight: 700,
    fontFamily: "comfortaa",
    marginLeft: 20,
  },
};
