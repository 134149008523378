import {
  Box,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dimens from "../../../assets/Dimens";
import {
  Header as ConsultationHeader,
  StyledBodyText,
  StyledCaptionText,
} from "../../../components/common";
import SideDrawer from "../../../components/mainLayout/SideDrawer";
import Tabbar from "../../../components/mainLayout/Tabbar";
import Footer from "../../../components/mainLayout/Footer";
import Header from "../../../components/mainLayout/Header";
import { COLORS } from "../../../assets/colors";
import { behavioralImage } from "../../../assets/images";
import LanguageButton from "../../../components/mainLayout/LanguageButton";

const StudentBehaviouralHealthCouseling = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translations");

  const theme = useTheme();
  const matchesXS = useMediaQuery(() => theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(() => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(() => theme.breakpoints.up("lg"));
  const matchesXl = useMediaQuery(() => theme.breakpoints.up("xl"));

  const handleGoBack = () => {
    navigate(-1);
  };
  const Services = [
    t("life"),
    t("retirement"),
    t("mid_life"),
    t("student_life"),
    t("legal"),
    t("relationships"),
    t("disabilities"),
    t("crisis"),
    t("personal_issues"),
  ];

  const ServicesTwo = [
    t("family"),
    t("couples"),
    t("separation_divorce"),
    t("older_relatives"),
    t("adoptions"),
    t("death_loss"),
    t("child_care"),
    t("education"),
  ];

  const ServicesThree = [
    t("health"),
    t("mental_health"),
    t("addictions"),
    t("fitness"),
    t("managing_stress"),
    t("nutrition"),
    t("sleep"),
    t("smoking_cessation"),
    t("alternative_health"),
  ];
  const ServicesFour = [
    t("work"),
    t("time_management"),
    t("career_development"),
    t("work_stress"),
    t("managing_stress"),
    t("managing_people"),
    t("shift_work"),
    t("coping_with_change"),
    t("communication"),
  ];
  const ServicesFive = [
    t("money"),
    t("savings"),
    t("investing"),
    t("budgeting"),
    t("managing_debt"),
    t("home_buying"),
    t("renting"),
    t("estate_planning"),
    t("bankruptcy"),
  ];

  return (
    <>
      <Grid
        container
        direction={"column"}
        style={{
          background: "#DFF0FF",
          borderBottomLeftRadius: Dimens.BORDER_RADIUS_LARGE,
          borderBottomRightRadius: Dimens.BORDER_RADIUS_LARGE,
        }}
      >
        <Header
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Grid item container>
          <Grid
            item
            container
            justifyContent={"center"}
            sx={{ width: { md: "19.5%", xs: "0" } }}
          >
            <Grid item container direction={"column"} alignItems={"center"}>
              <SideDrawer
                matchesSM={matchesSM}
                matchesMD={matchesMD}
                matchesXl={matchesXl}
                matchesLG={matchesLG}
                matchesXS={matchesXS}
              />
            </Grid>
          </Grid>
          <Grid item container sx={{ width: { md: "79.5%", xs: "100%" } }}>
            <ConsultationHeader
              title={t("student_behavioural_health_counseling")}
              // subTitle={t("we_are_here_to_help")}
              handleGoBack={handleGoBack}
            />
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ mt: { md: 5, xs: 2 }, height: { md: "90vh" } }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack
                    // justifyContent={"center"}
                    sx={{
                      p: 2,
                      bgcolor: { md: COLORS.CARD_SECONDARY, xs: "none" },
                      borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
                      height: "60%",
                    }}
                  >
                    <img
                      src={behavioralImage}
                      width={"100%"}
                      alt={t("behavioral_health_counseling")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: COLORS.TEXT_SECONDARY,
                      borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
                    }}
                  >
                    <StyledCaptionText
                      sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM }}
                    >
                      {t("student_behavioural_health_counseling_info")}
                    </StyledCaptionText>
                  </Box>

                  <StyledCaptionText
                    sx={{
                      fontSize: Dimens.FONT_SIZE_MEDIUM,
                      textAlign: "center",
                      mt: Dimens.CARD_PADDING_HORIZANTAL,
                    }}
                  >
                    {t("or_dial")} 855-522-1226
                  </StyledCaptionText>
                  <StyledBodyText sx={{ textAlign: "center", mt: 2 }}>
                    {t("clinical_services")}
                  </StyledBodyText>
                  <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_MEDIUM }}>
                    {t("clinical_services_info")}
                  </StyledCaptionText>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ pt: 5, pb: 5, bgcolor: "#fff" }}>
          <StyledBodyText sx={{ textAlign: "center" }}>
            {t("services")}
          </StyledBodyText>
          <StyledCaptionText sx={{ textAlign: "center" }}>
            {t("include_but_not_limited_to")}
          </StyledCaptionText>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={3}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {Services.map((el, index) => (
                  <>
                    <StyledCaptionText
                      id={el}
                      sx={{
                        ml: 2,
                        fontSize: Dimens.FONT_SIZE_MEDIUM,
                        fontWeight: !index
                          ? Dimens.FONT_WEIGHT_BOLD
                          : Dimens.FONT_WEIGHT_LIGHT,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {el}
                    </StyledCaptionText>
                    {!index && <Divider />}
                  </>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesTwo.map((el, index) => (
                  <>
                    <StyledCaptionText
                      component="div"
                      id={el}
                      sx={{
                        ml: 2,
                        fontSize: Dimens.FONT_SIZE_MEDIUM,
                        fontWeight: !index
                          ? Dimens.FONT_WEIGHT_BOLD
                          : Dimens.FONT_WEIGHT_LIGHT,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {el}
                    </StyledCaptionText>
                    {!index && <Divider />}
                  </>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesThree.map((el, index) => (
                  <>
                    <StyledCaptionText
                      id={el}
                      sx={{
                        ml: 2,
                        fontSize: Dimens.FONT_SIZE_MEDIUM,
                        fontWeight: !index
                          ? Dimens.FONT_WEIGHT_BOLD
                          : Dimens.FONT_WEIGHT_LIGHT,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {el}
                    </StyledCaptionText>
                    {!index && <Divider />}
                  </>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={3}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesFour.map((el, index) => (
                  <>
                    <StyledCaptionText
                      id={el}
                      sx={{
                        ml: 2,
                        fontSize: Dimens.FONT_SIZE_MEDIUM,
                        fontWeight: !index
                          ? Dimens.FONT_WEIGHT_BOLD
                          : Dimens.FONT_WEIGHT_LIGHT,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {el}
                    </StyledCaptionText>
                    {!index && <Divider />}
                  </>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack
                sx={{
                  bgcolor: COLORS.CARD_SECONDARY,
                  width: "250px",
                  borderRadius: Dimens.BORDER_RADIUS_LARGE,
                }}
              >
                {ServicesFive.map((el, index) => (
                  <>
                    <StyledCaptionText
                      id={el}
                      sx={{
                        ml: 2,
                        fontSize: Dimens.FONT_SIZE_MEDIUM,
                        fontWeight: !index
                          ? Dimens.FONT_WEIGHT_BOLD
                          : Dimens.FONT_WEIGHT_LIGHT,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {el}
                    </StyledCaptionText>
                    {!index && <Divider />}
                  </>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Footer
          matchesSM={matchesSM}
          matchesMD={matchesMD}
          matchesXl={matchesXl}
          matchesLG={matchesLG}
          matchesXS={matchesXS}
        />
        <Tabbar />
        <Box
          boxShadow={2}
          sx={{
            display: { xs: "flex", md: "none" },
            position: "fixed",
            bottom: "10%",
            left: theme.spacing(2),
            // borderRadius: 31 / 2,
            zIndex: 1000,
          }}
        >
          <LanguageButton />
        </Box>
      </Grid>
    </>
  );
};

export default StudentBehaviouralHealthCouseling;
