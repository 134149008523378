import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { images } from "../../constant";
import { Grid, useMediaQuery } from "@mui/material";
import { loginCardStyles } from "./styles/LoginCardStyles";
import ButtonLoader from "../ButtonLoader";

import "./styles/VerifyPin.css";

import ReactInputVerificationCode from "react-input-verification-code";

const VerifyAccountCard = ({
  pin,
  setPin,
  handleVerifyAccount,
  loading,
  handleCodeSentModalOpen,
}) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(() => theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(() => theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ ...loginCardStyles.cardStyle, width: matchesSM ? "94%" : 575 }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <img
            src={images.logoIcon}
            alt="Logo"
            style={loginCardStyles.imageStyle}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{ padding: 100, marginTop: 90 }}
        >
          <form style={loginCardStyles.formStyle} noValidate>
            <span style={loginCardStyles.titleTextStyles}>
              Verify your account
            </span>
            {pin.length && pin.length === 6 ? undefined : (
              <span style={loginCardStyles.messageTextStyles}>
                We’ve sent you a 6-pin code to verify your information. Your
                code will expire in 10 minutes.
              </span>
            )}
            <Grid className="custom-styles">
              <ReactInputVerificationCode
                autoFocus
                length={6}
                placeholder=""
                onChange={(e) => setPin(e)}
              />
            </Grid>

            {pin.length && pin.length === 6 ? (
              <img
                src={images.verifyAccountAnimi}
                alt="animi"
                style={{ width: 160, height: 160, marginTop: 30 }}
              />
            ) : undefined}
            {/* Register Button area  */}
            {loading ? (
              <Grid style={{ marginTop: 65 }}>
                <ButtonLoader />
              </Grid>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={pin.length && pin.length === 6 ? false : true}
                style={
                  pin.length && pin.length === 6
                    ? loginCardStyles.buttonStyle
                    : loginCardStyles.buttonDisabledStyle
                }
                onClick={handleVerifyAccount}
              >
                Continue
              </Button>
            )}
            {/*send again area */}
            {pin.length && pin.length === 6 ? undefined : (
              <span style={loginCardStyles.needHelpTextStyles}>
                Didn’t receive the code?
              </span>
            )}
            {pin.length && pin.length === 6 ? undefined : (
              <Button
                style={loginCardStyles.resendButtonStyles}
                onClick={() => handleCodeSentModalOpen()}
              >
                Send again
              </Button>
            )}

            {/* Need help area  */}
            <span style={loginCardStyles.needHelpTextStyles}>
              Need Help?{" "}
              <Button style={loginCardStyles.resetPasswordStyles}>
                Contact Us
              </Button>
            </span>
          </form>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default VerifyAccountCard;
