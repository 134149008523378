import "../../../fonts/comfortaa.css";

export const codeSentModalStyles = {
  titleStyles: {
    color: "#000000",
    fontSize: 23,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Comfortaa",
    textAlign: "center"
  },
  subTitleStyles: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Comfortaa",
    textAlign: "center"
  },
  okayButtonStyles: {
    width: 260,
    borderRadius: 90,
    height: 55,
    fontSize: 13,
    fontWeight: 500,
    background: "#FFB74D",
    color: "#001011",
  },
  imageStyles: { width: 160, height: 160, marginBottom: 30 },
};
