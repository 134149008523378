import * as locales from '@mui/material/locale';
import React, {
	createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';


const localeMap = {
	enUS,
	es: es,
};

export const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const langString = i18n.language.replace('-', '');
	const savedLocaleJson = langString;
	const savedLocale = locales[savedLocaleJson];
	const [locale, setLocale] = useState(savedLocale);
	const [localeFns, setLocaleFns] = useState(localeMap[savedLocaleJson]);

	const changeLocale = useCallback((lang) => {
		i18n.changeLanguage(lang.substring(0, 2));
		document.documentElement.lang = lang.substring(0, 2);
		const mLocale = locales[lang];
		setLocale(mLocale);
		setLocaleFns(localeMap[lang]);

		// window.location.reload();
	}, [i18n]);

	const value = useMemo(() => (
		{ locale, changeLocale, localeFns }
	), [locale, changeLocale, localeFns]);
	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default function useLanguage() {
	return useContext(LanguageContext);
}
