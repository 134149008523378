import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Dimens from "../../assets/Dimens";
import {
  doctoricon,
  emergency,
  group_discussion,
  neurology,
  schedule,
} from "../../assets/icons";
import {
  StyledBodyText,
  StyledCaptionText,
  StyledCard,
} from "../../components/common";
import { healthRecordsStyles } from "../../components/healthRecords/styles/healthRecordsStyles";
import useAuth from "../../providers/auth.provider";
import MainPageLayout from "../MainPageLayout";
import ScheduleConsultationBox from "../../components/consultations/schedule-consultation/ScheduleConsultationBox";
import Applinks from "../../assets/route-links";
import { useTranslation } from "react-i18next";

const MTM_Psychiatry = "MTM_Psychiatry";
const MTM_PrimaryCare_One = "DiagnosticPhone";
const MTM_PrimaryCare_Two = "DiagnosticVideo";
const behavioralHealth = "behavioralHealth";
const studentBehavioralHealth = "st_studentBH";
// const studentBehavioralHealth = "TVCGStudentBH";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation("translations");

  const showPsychiatry = user?.modules?.includes(MTM_Psychiatry);
  const showUrgentCare =
    user?.modules?.includes(MTM_PrimaryCare_One) &&
    user?.modules?.includes(MTM_PrimaryCare_Two);
  const showBehavioralHealth = user?.modules?.includes(behavioralHealth);
  const showStudentBehavioralHealth = user?.modules?.includes(
    studentBehavioralHealth
  );
  const handleMessageClick = () => navigate("/messages/new-message");
  const handleConsultationClick = () =>
    navigate(Applinks.SCHEDULE_CONSULATATIONS);

  return (
    <MainPageLayout>
      <div style={healthRecordsStyles.mainTitle}>{t("home")}</div>
      <StyledCaptionText
        sx={{
          mt: 2,
          fontSize: Dimens.FONT_SIZE_MEDIUM,
          fontWeight: Dimens.FONT_WEIGHT_BOLDER,
        }}
      >
        {t("welcome")} {user.user?.firstName} {user.user?.lastName},
      </StyledCaptionText>
      <StyledCard
        elevation={0}
        sx={{
          pl: Dimens.PADDING_TOP_MEDIUM,
          pt: Dimens.PADDING_TOP_MEDIUM,
          pb: Dimens.PADDING_TOP_MEDIUM,
          pr: Dimens.PADDING_TOP_MEDIUM,
          mt: 2,
        }}
      >
        <StyledCaptionText
          sx={{
            fontSize: Dimens.FONT_SIZE_MEDIUM,
            lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
          }}
        >
          {t("welcome_info_one")}
        </StyledCaptionText>
        <StyledCaptionText
          sx={{
            mt: 2,
            fontSize: Dimens.FONT_SIZE_MEDIUM,
            lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
          }}
        >
          {t("welcome_info_two")}
        </StyledCaptionText>
        {/*
        <StyledCaptionText
          sx={{
            mt: 2,
            fontSize: Dimens.FONT_SIZE_MEDIUM,
            lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
          }}
        >
          {t("welcome_info_three")}
        </StyledCaptionText>
      */}
      </StyledCard>
      <StyledBodyText sx={{ mt: 2, mb: 2 }}>
        {t("schedule_consultation")}
      </StyledBodyText>
      <Stack
        direction={{ md: "row", xs: "column", sm: "column" }}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: 4 }}
        spacing={4}
      >
        {showUrgentCare && (
          <ScheduleConsultationBox
            title={t("urgent_care")}
            description={t("common_acute_conditions")}
            imageUrl={emergency}
            onClick={() =>
              navigate(Applinks.SCHEDULE_CONSULATATIONS_URGENT_CARE)
            }
          />
        )}
        {showBehavioralHealth && (
          <ScheduleConsultationBox
            title={t("behavioral_health_virtual_counseling")}
            description={t("counseling_services")}
            imageUrl={group_discussion}
            onClick={() =>
              navigate(Applinks.SCHEDULE_CONSULATATIONS_BEHAVIORAL_HEALTH)
            }
          />
        )}
        {showStudentBehavioralHealth && (
          <ScheduleConsultationBox
            title={t("student_behavioural_health_counseling")}
            description={t("counseling_services")}
            imageUrl={group_discussion}
            onClick={() =>
              navigate(
                Applinks.SCHEDULE_CONSULATATIONS_STUDENT_BEHAVIORAL_HEALTH
              )
            }
          />
        )}
        {showPsychiatry && (
          <ScheduleConsultationBox
            title={t("psychiatrist")}
            description={t("psychiatric_medical_care")}
            imageUrl={neurology}
            onClick={() =>
              navigate(Applinks.SCHEDULE_CONSULTATION_PSYCHIATRIST)
            }
          />
        )}
      </Stack>
      <StyledBodyText sx={{ mt: 2, mb: 2 }}>{t("my_benifits")}</StyledBodyText>
      <Stack
        direction={{ md: "row", xs: "column", sm: "column" }}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: 4 }}
        spacing={4}
      >
        <ScheduleConsultationBox
          description={t("message_doctor")}
          imageUrl={doctoricon}
          onClick={handleMessageClick}
        />
        <ScheduleConsultationBox
          description={t("make_consultation")}
          imageUrl={schedule}
          onClick={handleConsultationClick}
        />
      </Stack>
    </MainPageLayout>
  );
};

export default Dashboard;
