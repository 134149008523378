import React from "react";
import { settingsStyles } from "../styles/SettingsStyles";
import { Grid } from "@mui/material";

const PasswordsMatchError = ({ passwordMatchError }) => {
  return (
    <Grid
      container
      direction={"column"}
      style={{
        width: 360,
        // height: 195,
        borderRadius: 10,
        padding: "30px 20px 35px",
        boxShadow: "0px 0px 10px rgba(55, 73, 87, 0.2)",
        zIndex: 2,
        position: "absolute",
        backgroundColor: "#FFFFFF",
        padding: 10,
        // marginTop: 20,
      }}
    >
      <span style={{color: "#CF2D23", }}>{passwordMatchError}</span>
    </Grid>
  );
};

export default PasswordsMatchError;
