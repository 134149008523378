import { Card, Stack, Box } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../../assets/colors";
import { StyledBodyText } from "../../common";
import PropTypes from "prop-types";

const GenderPicker = (props) => {
  const { gender, handleGenderChange } = props;
  return (
    <Card
      sx={{
        height: "35px",
        borderRadius: "20px",
        ml: { md: 2, xs: 0 },
        mt: { md: 0, xs: 2 },
        width: 280
      }}
    >
      <Stack direction="row">
        <Box
          sx={{
            width: "50%",
            pl: 2,
            pr: 2,
            pt: 1,
            pb: 1,
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            bgcolor:
              gender === "m" ? COLORS.CARD_SECONDARY : COLORS.TEXT_SECONDARY,
            border: `1px solid ${
              gender === "m" ? COLORS.BUTTON_SECONDARY : COLORS.TEXT_SECONDARY
            }`,
            cursor: "pointer",
          }}
          onClick={() => handleGenderChange("m")}
        >
          <StyledBodyText>Male</StyledBodyText>
        </Box>
        <Box
          sx={{
            width: "50%",
            pl: 2,
            pr: 2,
            pt: 1,
            pb: 1,
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            bgcolor:
              gender === "f" ? COLORS.CARD_SECONDARY : COLORS.TEXT_SECONDARY,
            border: `1px solid ${
              gender === "f" ? COLORS.BUTTON_SECONDARY : COLORS.TEXT_SECONDARY
            }`,
            cursor: "pointer",
          }}
          onClick={() => handleGenderChange("f")}
        >
          <StyledBodyText>Female</StyledBodyText>
        </Box>
      </Stack>
    </Card>
  );
};

GenderPicker.propTypes = {
  gender: PropTypes.string,
  handleGenderChange: PropTypes.func,
};

export default GenderPicker;
