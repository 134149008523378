import { ErrorOutlined } from "@mui/icons-material";
import { Box, Grid, MenuItem, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../assets/colors";
import Dimens from "../../assets/Dimens";
import {
  DefaultDropdown,
  DefaultTextField,
  StyledBodyText,
  StyledCaptionText,
} from "../common";
import DefaultButton from "./DefaultButton";
import PropTypes from "prop-types";
import MedicationRefilItem from "./MedicationRefilItem";
import AutoComplete from "../common/autocomplete/AutoComplete";
import { useTranslation } from "react-i18next";

const MedicationForm = (props) => {
  const {
    handleSubmit,
    medicationArray,
    onDeleteClick,
    onEditClick,
    addButtonText,
    medicationResponse,
    medicationSearch,
    clearMedicationForm
  } = props;
  const [medication, setmedication] = useState({
    medicationName: {},
    frequency: "",
    comment: "",
    currentlyUsing: true,
  });

  const [query, setQuery] = useState("");
  const {t} = useTranslation("translations")

  useEffect(() => {
    medicationSearch(query);
  }, [query]);

  useEffect(() => {
      clearMedicationForm.current = clearForm;
   }, []);

   const clearForm = () => {
      setmedication({
    medicationName: "",
    frequency: "",
    comment: "",
    currentlyUsing: true,
  })
   };

  useEffect(() => {
    if (
      medication.medicationName &&
      medication.frequency &&
      medication.comment &&
      medication.currentlyUsing
    ) {
      setaddMedicationDisabled(false);
    } else {
      setaddMedicationDisabled(true);
    }
  }, [
    medication.medicationName,
    medication.frequency,
    medication.comment,
    medication.currentlyUsing,
  ]);
  const [addMedicationDisabled, setaddMedicationDisabled] = useState(false);
  const handleChange = (key, value) => {
    setmedication({ ...medication, [key]: value });
  };

  const handleAddClick = () => {
    if (addMedicationDisabled) {
      return;
    }
    handleSubmit(medication);
  };

  return (
    <Box>
      <StyledBodyText sx={{ mt: 2 }}>{t("medication")}</StyledBodyText>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          pt: 1,
          pb: 1,
          pl: 1,
          bgcolor: COLORS.PRIMARY_MAIN,
          borderRadius: Dimens.BORDER_RADIUS_MEDIUM,
        }}
      >
        <Stack direction="row" alignItems="center">
          <ErrorOutlined />
          <StyledCaptionText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, ml: 2 }}>
            {t("one_medication_and_dosis_at_time")}
          </StyledCaptionText>
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("medication_name")}
          </StyledBodyText>
          <AutoComplete
            options={medicationResponse?.data?.suggestions || []}
            onInputChange={(text) => setQuery(text)}
            placeholder={t("add_medication_name_here")}
            value={medication.medicationName}
            renderOption={(autoCompleteProps, option) => (
              <l1 {...autoCompleteProps} key={option.code}>
                {option.value}
              </l1>
            )}
            getOptionLabel={(option) => option.value || ""}
            onChange={(e, value) => handleChange("medicationName", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("frequency")}
          </StyledBodyText>
          <DefaultTextField
            type={"number"}
            value={medication.frequency}
            id="frequency"
            onChange={(e) => handleChange("frequency", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("comment")}
          </StyledBodyText>
          <DefaultTextField
            value={medication.comment}
            id="comment"
            multiline
            minRows={2}
            onChange={(e) => handleChange("comment", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
            {t("currently_using")}
          </StyledBodyText>
          <DefaultDropdown
            showOutline
            value={medication.currentlyUsing ? t("yes") : t("no")}
            id="currentlyUsing"
            renderValue={(option) => (
              <MenuItem
                value={option.value}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  // bgcolor: option === value ? "#FFB74D" : "#fff",
                  "&:hover": {
                    bgcolor: "#FFB74D",
                  },
                }}
              >
                {option.label}
              </MenuItem>
            )}
            onChange={(e) => handleChange("currentlyUsing", e.target.value)}
            options={[
              { label: t("yes") , value: true },
              { label: t("no"), value: false },
            ]}
          />
        </Grid>
      </Grid>
      <Stack alignItems={"center"} sx={{ mt: 2, mb: 3 }}>
        <DefaultButton
          sx={{ bgcolor: COLORS.BUTTON_SECONDARY }}
          disabled={addMedicationDisabled}
          onClick={handleAddClick}
        >
          {addButtonText ? addButtonText : t("add_refill")}
        </DefaultButton>
      </Stack>
      {medicationArray.map((el, index) => (
        <MedicationRefilItem
        key={el.userMedication_id}
          medicationName={el.medicationName || el.name}
          comment={el.comment}
          frequency={el.frequency}
          onDeleteClick={() => onDeleteClick(index)}
          onEditClick={() => onEditClick(index)}
        />
      ))}
    </Box>
  );
};
MedicationForm.propTypes = {
  handleSubmit: PropTypes.func,
  medicationArray: PropTypes.array,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  addButtonText: PropTypes.string,
  medicationResponse: PropTypes.object,
  medicationSearch: PropTypes.func,
  clearMedicationForm: PropTypes.object
};

export default MedicationForm;
