export const bloodTypeOptions = [
  {
    name: "A",
    value: 1,
  },
  {
    name: "B",
    value: 2,
  },
  {
    name: "AB",
    value: 3,
  },
  {
    name: "O",
    value: 4,
  },
];
export const maritalStatusOptions = [
  {
    name: "Single",
    value: 1,
  },
  {
    name: "Married",
    value: 2,
  },
  {
    name: "Divorced",
    value: 3,
  },
];
export const smokingOptions = [
  {
    name: "No",
    value: 1,
  },
  { name: "1 - 5 cigarettes/day", value: 2 },
  { name: "6 - 10 cigarettes/day", value: 3 },
  { name: "10+ cigarettes/day", value: 4 },
];
export const drinkingOptions = [
  { name: "No", value: 1 },
  { name: "Rarely", value: 2 },
  { name: "1 day/month", value: 3 },
  { name: "2 days/month", value: 4 },
  { name: "1 day/week", value: 5 },
  { name: "2 days/week", value: 6 },
  { name: "3 days/week", value: 7 },
  { name: "Daily", value: 8 },
];

export const exerciseOptions = [
  { name: "No", value: 1 },
  { name: "Rarely", value: 2 },
  { name: "1 day/month", value: 3 },
  { name: "2 days/month", value: 4 },
  { name: "1 day/week", value: 5 },
  { name: "2 days/week", value: 6 },
  { name: "3 days/week", value: 7 },
  { name: "Daily", value: 8 },
];
export const exerciseLengthOptions = [
  { value: 1, name: "Ten mins" },
  { value: 2, name: "Twenty mins" },
  { value: 3, name: "Thirty mins" },
  { value: 4, name: "Forty-five mins" },
  { value: 5, name: "Sixty mins" },
  { value: 6, name: "Over one hour" },
];
